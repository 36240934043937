<template>
  <div v-show="show" class="overlay">
    <!-- <div class="dialog">
      <div class="dialog__content">
        <h2 class="dialog__title" v-text="title"></h2>
        <date-picker type="datetime" v-model="datetime" :min="start" :max="end" format="YYYY-MM-DD HH:mm"/>
        <flatpickr :config="config" v-model="datetime" class="form-control rounded-0 bg-white"></flatpickr>
        <datepicker v-model="datetime" lang="en" type="date" format="YYYY-MM-DD" width="500" :disabledDates="disabledDates" placeholder="Select date time"></datepicker> 
        <datepicker id="datepicker" v-model="datetime" lang="en" type="datetime" format="YYYY-MM-DD HH:mm:ss a" width="500" placeholder="Select date time"></datepicker> 
      </div>
      <div class="dialog__footer">
        <button @click="confirm(datetime)" class="dialog__confirm">Ok</button>
        <button @click="cancel" class="dialog__cancel">Cancel</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import user from '@/ajax/user'
// import Datepicker from 'vuejs-datetimepicker';
import VueDatetimePickerJs from 'vue-date-time-picker-js';
// import flatpickr from "vue-flatpickr-component"
// import "flatpickr/dist/flatpickr.css";
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
moment.locale('es')

export default {
    props: ['show', 'title', 'description', 'cancel', 'confirm'],
    components: {datePicker: VueDatetimePickerJs },
    data() {
        return {
            datetime: "",
            start: '',
            end: '',
            config:{
              enableTime: true,
              dateFormat: "Y-m-d H:i",
              time_24hr:true,
              disable:[
                {
                  from:'',
                  to:''
                }
              ]
            }
        };
    },

  methods: {
  },
  mounted() {

    user.getAttendanceByDate().then((result) => {
      // console.log(result.data.data.end_time.last_log_start)
      this.start = moment(String(result.data.data.end_time.last_log_start)).format('YYYY-MM-DD HH:mm')
      var date = new Date(this.start);
      var end = new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);
      end.setHours(date.getHours(),date.getMinutes());
      this.end = moment(String(end)).format('YYYY-MM-DD HH:mm')
      this.datetime=this.end;
      // this.config.disable[0].to= moment(String(result.data.data.end_time.last_log_start)).format('YYYY-MM-DD HH:mm')
      // this.config.disable[0].from = moment(String(end)).format('YYYY-MM-DD HH:mm')
    });

  }
}
</script>
