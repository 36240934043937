<template>
  <div class="card border-0 shadow-sm my-4">
    <div class="card-body pb-2">
      <h4 class="text-primary">Settings</h4>
      <div class="mt-4">
        <form @submit.prevent="onSubmitUserConfiguration()">
          <div class="w-25">
            <div class="mb-3">
              <label>Is login required for you?</label>
              <input
                type="checkbox"
                class="form-check-input float-end"
                v-model="reqObj.login_logout_reminder"
                oninvalid="this.setCustomValidity('Please select Goal Type')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <div class="mb-3">
              <label>Do you want reports through Email ?</label>
              <input
                type="checkbox"
                class="form-check-input float-end"
                v-model="reqObj.reports"
                oninvalid="this.setCustomValidity('Please select Goal Type')"
                oninput="this.setCustomValidity('')"
              />
            </div>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  name: "Dashboard",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      reqObj: {
        login_logout_reminder: "",
        reports: "",
      },
      user: [],
      siteLogReq: {
        site_name: "settings",
      },
    };
  },

  // beforeMount(){
  //       this.getUserConfiguration();
  // },

  mounted() {
    this.getUserConfiguration();
    // this.onSubmitUserConfiguration();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    getUserConfiguration() {
      user.getUserConfiguration(this.reqObj).then((result) => {
        this.reqObj = [];
        this.reqObj = result.data.data;
        // console.log(JSON.stringify(result.data));
        // console.log(result.data);
      });
    },

    onSubmitUserConfiguration() {
      user.updateUserConfiguration(this.reqObj).then((result) => {
        if (result.data.code == 200) {
          this.$swal(
            "Success",
            "Configurations Updated Successfully",
            "success"
          );
        } else {
          this.$swal("Error", "Failed To Update Configurations", "error");
        }
      });
    },
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
