var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions.includes('leaves.write')),expression:"permissions.includes('leaves.write')"}]},[_c('div',{staticClass:"card shadow-sm border-0 my-4 mb-3"},[_c('div',{staticClass:"card-body"},[(_vm.firstTable)?_c('div',{staticClass:"d-flex align-items-center flex-md-nowrap flex-wrap justify-content-between gap-md-0 gap-2 px-2"},[_c('h4',{staticClass:"m-0 font-weight-bold text-primary order-md-0 order-1"},[_vm._v(" Leaves ")]),_c('div',{staticClass:"order-3 order-md-0 mt-md-0 mt-2 mx-auto px-md-0 px-2"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[(_vm.permissions.includes('leaves.read'))?_c('router-link',{staticClass:"btn btn-sm",class:this.$route.name == 'LeavesApplication'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary',attrs:{"to":{ name: 'LeavesApplication' }}},[_vm._v(" Leaves ")]):_vm._e(),(_vm.permissions.includes('credit_debit.read'))?_c('router-link',{staticClass:"btn btn-sm",class:this.$route.name == 'AddCreditDebit'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary',attrs:{"to":{ name: 'AddCreditDebit' }}},[_vm._v(" Credit/Debit ")]):_vm._e(),(_vm.permissions.includes('leaves_status.report'))?_c('router-link',{staticClass:"btn btn-sm",class:this.$route.name == 'LeavesStatus'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary',attrs:{"to":{ name: 'LeavesStatus' }}},[_vm._v(" Status ")]):_vm._e()],1)]),(
            _vm.permissions.includes('leaves.read') &&
            _vm.permissions.includes('leaves.add')
          )?_c('router-link',{staticClass:"btn btn-primary btn-sm order-md-0 order-2",attrs:{"to":{ name: 'AddLeaveApplication' }}},[_vm._v(" Apply ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"p-2"},[(_vm.firstTable)?_c('div',{staticClass:"d-sm-flex align-items-center justify-content-between py-3"},[_c('h5',{staticClass:"text-md-start text-center text-primary"},[_vm._v("Your Leaves")])]):_vm._e(),(_vm.permissions.includes('leaves.read') && _vm.firstTable)?_c('div',{staticClass:"mb-5"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.Filtered_Records_First,"isLoading":_vm.isLoadingYour,"sort-options":{
              enabled: true,
            },"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            },"rows":_vm.your_leave_list_rows,"columns":_vm.your_leave_list_column,"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoadingYour=$event},"update:is-loading":function($event){_vm.isLoadingYour=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'Action')?_c('span',[(props.row.stage == 'applied')?_c('div',[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-3"},[(props.row.user_id != _vm.currentUser.id)?_c('button',{staticClass:"btn-view",on:{"click":function($event){return _vm.approve_leave(props)}}},[_c('i',{staticClass:"fa fa-check fs-5"})]):_vm._e(),(props.row.user_id == _vm.currentUser.id)?_c('router-link',{staticClass:"btn-pencil",attrs:{"to":'/update-leave-application/' + props.row.id}},[_c('i',{staticClass:"fa fa-pencil fs-5"})]):_vm._e(),(props.row.user_id != _vm.currentUser.id)?_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.reject_leave(props.row.id)}}},[_c('i',{staticClass:"fa fa-times fs-5"})]):_vm._e(),(props.row.user_id == _vm.currentUser.id)?_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.delete_leave(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash fs-5"})]):_vm._e()],1)]):_c('div',[_c('span',[_vm._v(_vm._s(props.row.stage))])])]):_vm._e()]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'from_date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'to_date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParams}})]}}],null,false,3446814199)})],1):(_vm.firstTable)?_c('div',{staticClass:"container-fluid"},[_vm._m(0)]):_vm._e(),(_vm.secondTable)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-md-row flex-column gap-2"},[_c('h5',{staticClass:"team-text text-primary m-0",class:_vm.tag ? _vm.tag : ''},[_vm._v(" Teams Member Leaves ")]),(_vm.secondTable && !_vm.firstTable)?_c('multiselect',{staticStyle:{"max-width":"200px"},attrs:{"options":_vm.options,"placeholder":"Select one or more User","searchable":false,"close-on-select":false,"show-labels":false,"id":"dashboard"},on:{"input":function($event){return _vm.FilterApply()}},scopedSlots:_vm._u([{key:"selection",fn:function({ values, search, isOpen }){return undefined}}],null,false,3341668123),model:{value:(_vm.dashboard_log),callback:function ($$v) {_vm.dashboard_log=$$v},expression:"dashboard_log"}}):_vm._e(),(_vm.firstTable && _vm.secondTable)?_c('multiselect',{staticStyle:{"max-width":"200px"},attrs:{"options":_vm.options,"placeholder":"Select one or more User","searchable":false,"close-on-select":false,"show-labels":false},on:{"input":function($event){return _vm.FilterApply()}},scopedSlots:_vm._u([{key:"selection",fn:function({ values, search, isOpen }){return undefined}}],null,false,3341668123),model:{value:(_vm.page_log),callback:function ($$v) {_vm.page_log=$$v},expression:"page_log"}}):_vm._e()],1):_vm._e(),(_vm.permissions.includes('leaves.read'))?_c('div',{staticClass:"mt-4"},[_c('vue-good-table',{attrs:{"mode":"remote","total-rows":_vm.Filtered_Records_Second,"isLoading":_vm.isLoadingTeam,"sort-options":{
              enabled: true,
            },"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            },"rows":_vm.team_mates_leave_rows,"columns":_vm.team_mates_leave_list_column,"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChangeSecond,"on-sort-change":_vm.onSortChangeSecond,"on-column-filter":_vm.onColumnFilterSecond,"on-per-page-change":_vm.onPerPageChangeSecond,"update:isLoading":function($event){_vm.isLoadingTeam=$event},"update:is-loading":function($event){_vm.isLoadingTeam=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'User')?_c('span',[(
                    props.row.first_name !== null &&
                    props.row.middle_name !== null &&
                    props.row.last_name !== null
                  )?_c('span',[_vm._v(" "+_vm._s(props.row.first_name + " " + props.row.middle_name + " " + props.row.last_name)+" ")]):(
                    props.row.first_name !== null &&
                    props.row.middle_name == null &&
                    props.row.last_name !== null
                  )?_c('span',[_vm._v(" "+_vm._s(props.row.first_name + " " + props.row.last_name)+" ")]):(
                    props.row.first_name !== null &&
                    props.row.middle_name !== null &&
                    props.row.last_name == null
                  )?_c('span',[_vm._v(" "+_vm._s(props.row.first_name + " " + props.row.middle_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.row.first_name)+" ")])]):(props.column.label == 'Action')?_c('span',[(props.row.stage == 'applied')?_c('div',[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-3 p-2"},[(props.row.user_id != _vm.current_user_id)?_c('button',{staticClass:"btn-view",on:{"click":function($event){return _vm.approve_leave(props)}}},[_c('i',{staticClass:"fa fa-check fs-5"})]):_vm._e(),(props.row.user_id == _vm.current_user_id)?_c('button',{staticClass:"btn-pencil"},[_c('i',{staticClass:"fa fa-pencil fs-5"})]):_vm._e(),(props.row.user_id != _vm.current_user_id)?_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.reject_leave(props.row.id)}}},[_c('i',{staticClass:"fa fa-times fs-5"})]):_vm._e(),(props.row.user_id == _vm.current_user_id)?_c('button',{staticClass:"btn-trash",on:{"click":function($event){return _vm.delete_leave(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash fs-5"})]):_vm._e()])]):_c('div',[_c('span',[_vm._v(_vm._s(props.row.stage))]),(
                      _vm.permissions.includes('approvedleaves.update') &&
                      props.row.stage == 'approved'
                    )?_c('button',{staticClass:"btn btn-link p-0",attrs:{"data-toggle":"modal","data-target":"#exampleModalCenter"},on:{"click":function($event){return _vm.leaveModalHandler('update', props.row, 'team')}}},[_vm._v(" update ")]):_vm._e(),(
                      _vm.permissions.includes('approvedleaves.delete') &&
                      props.row.stage == 'approved'
                    )?_c('button',{staticClass:"btn btn-link p-0",on:{"click":function($event){return _vm.delete_leave(props.row.id)}}},[_vm._v(" delete ")]):_vm._e()])]):_vm._e()]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'from_date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'to_date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'no_of_days')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"No of days"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParamsSecond}})]}}],null,false,3018774635)})],1):_vm._e()])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"leaveModal","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"card-header modal-header"},[_c('h3',{staticClass:"mb-0",attrs:{"id":"exampleModalLongTitle"},domProps:{"textContent":_vm._s(_vm.modal_title)}}),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[(_vm.modal_title == 'Update Leave')?_c('Leaves_popupVue',{attrs:{"leave_data":_vm.modal_data},on:{"close_modal":function($event){return _vm.closeModal()}}}):_vm._e(),(_vm.modal_title == 'Apply Leave')?_c('Leaves_popupVue',{on:{"close_modal":function($event){return _vm.closeModal()}}}):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col"},[_c('b',{staticClass:"text"},[_c('h3',[_vm._v("Empty")])])])])
}]

export { render, staticRenderFns }