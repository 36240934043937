<template>
    <div class="container-fluid border border-0 p-0 d-flex flex-column" style="min-height: 100vh;" v-if="permissions.length">
      <div class="container-fluid p-0">
        <header-bar></header-bar>
      </div>
      <div class="border-0 px-3" id="main-content">
        <router-view></router-view>
      </div>
    </div>
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import HeaderBar from "@/views/Layouts/Header.vue";
import SideBar from "@/views/Layouts/Sidebar.vue";
import swal from "sweetalert2";
//import VueSimpleAlert from "vue-simple-alert";
//Vue.use(VueSimpleAlert, { reverseButtons: true });
import myMixin from "@/mixins/Permission";

window.Swal = swal;


export default {
  name: "Dashboard",
  mixins: [myMixin],
  props: ["show"],
  components: { HeaderBar, SideBar},
  data: function () {
    return {
      reqObj: {
        end_time: "",
        flag: "",
        user_id: "",
        breakType: "",
        breakTime: null,
      },
      start_time: "",
      permissions:[]
    };
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser"]),
    getUsers() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        let rows = result.data.data;
        // Setting accesible id's for current users
        let accesible_id=[];
        rows.forEach(user=>accesible_id.push({id:user.id}));
        localStorage.setItem('accesible_id',JSON.stringify(accesible_id));
      });
    },
  },
  beforeRouteUpdate(_,_2,next){
      localStorage.setItem("current_user", this.currentUser.id);
      localStorage.setItem("user_role", this.currentUser.user_role);
    const user_role=localStorage.getItem("user_role");
    if(user_role!=1){
      this.getUsers();
    }
    next();
  },
  mounted() {
    // this.AttendanceAlert();
    // this.getTodaysQuotes();
    this.getUserPermissions().then(()=>{
      this.permissions=localStorage.getItem("permissions");
    });
    // $(".fl-flex-label").flexLabel();
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  // beforeRouteEnter(to, from, next) {
  //   localStorage.removeItem("current_user");
  //   localStorage.removeItem("user_role");
  //   localStorage.removeItem("accesible_id");
  //   next();
  // },
};
</script>

<style>
/* @import url("//fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap"); */
/* .btn {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
} */
/* .overlay {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
}
.dialog {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22.5rem;
  max-width: 100%;
  width: 24rem;
}
.dialog__content {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dialog__title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.dialog__description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.dialog__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.dialog__cancel {
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
}
.dialog__cancel:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.dialog__cancel {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dialog__cancel:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  --tw-ring-opacity: 0.5;
}
.dialog__cancel {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dialog__cancel:hover {
  --tw-text-opacity: 1;
}
.dialog__confirm {
  --tw-bg-opacity: 1;
  border-radius: 0.75rem;
  font-weight: 500;
  margin-right: 1rem;
}
.dialog__confirm:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.dialog__confirm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.dialog__confirm:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-opacity: 0.5;
}
.dialog__confirm {
  --tw-text-opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dialog__confirm:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}
.mandatory:after {
  content: " *";
  color: red;
}
#content-wrapper::-webkit-scrollbar-thumb{
  background: transparent;
}
#content-wrapper::-webkit-scrollbar-track{
  background: transparent;
}
#content-wrapper::-webkit-scrollbar{
  width:0px;
  height:0px;
} */
</style>
