<template>
  <div class="container-fluid text-center mt-5">
    <!-- Dashboard Summary section -->
    <div class="row">
      <!-- Dashboard Summary section -->
      <div :class="[currentUser.user_type == 4 ? null : 'col-md-6', 'col-12']">
        <DashboardSummary />
      </div>
      <!-- <div v-if="currentUser.user_type == 4" class="">
        <div class="">
          <DashboardSummary />
        </div>
      </div> -->
      <div
        class="col-12 col-md-6 mt-3 mt-md-0"
        v-if="currentUser.user_type != 4"
      >
        <RewardChart />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSummary from "./DashboardSummary.vue";
import RewardChart from "./RewardChart.vue";
import user from "@/ajax/user";
import { mapState } from "vuex";
import Vue from "vue";
import SideBar from "@/views/Layouts/Sidebar.vue";
import myMixin from "@/mixins/Permission";
import swal from "sweetalert2";
window.Swal = swal;

export default {
  name: "Header",
  mixins: [myMixin],
  components: {
    SideBar,
    RewardChart,
    DashboardSummary,
  },
  data: function () {
    return {
      reqObj: {
        flag: "",
        user_id: "",
        breakType: "",
        breakTime: null,
      },
      rewards: [],
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  methods: {
    // userprofile() {
    //   this.$router
    //     .push({ name: "Profile", params: { id: this.currentUser.id } })
    //     .then(() => {
    //       this.$router.go();
    //     });
    // },
    hideModalEventHandler() {
      this.reward = [];
    },
  },
};
</script>

<style>
/* .StartDay {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1);
} */
</style>
