var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex me-3"},_vm._l((_vm.avatarList),function(item,index){return _c('div',{key:item.user_id,staticClass:"rounded-circle img-fluid",style:({
        'z-index': index + 1,
        'margin-left':
          (index == 0 && _vm.avatarList.length == 1) ||
          (index == 0 && _vm.avatarList.length > 1)
            ? '4px'
            : '-10px',
      })},[(!Object.hasOwn(item, 'popover_list'))?_c('v-lazy-image',{staticClass:"object-fit-contain rounded-circle img",staticStyle:{"width":"32px","height":"32px","background-color":"rgba(0, 0, 0, 0.5)"},attrs:{"title":`${item.first_name} ${item.last_name}`,"type":"button","src":item.image ? item.image : _vm.default_img_url},on:{"click":function($event){$event.stopPropagation();}}}):_c('div',[_c('div',{staticClass:"rounded-circle border-light text-white text-center bg-info p-1 shadow-sm",staticStyle:{"width":"32px","height":"32px"},attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#moreUser"},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',[_vm._v(_vm._s(item.popover_list.length)+"+")])]),_c('div',{staticClass:"collapse shadow-sm rounded-2 bg-white border mt-1",staticStyle:{"min-width":"8.5rem"},attrs:{"id":"moreUser"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},_vm._l((item.popover_list),function(i,idx){return _c('v-lazy-image',{key:i.user_id,staticClass:"rounded-circle my-1 img",staticStyle:{"width":"32px","height":"32px","margin-left":"-10px"},style:({ 'z-index': idx + 1 }),attrs:{"type":"button","title":`${i.first_name} ${i.last_name}`,"src":i.image ? i.image : _vm.default_img_url},on:{"click":function($event){$event.stopPropagation();}}})}),1)])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }