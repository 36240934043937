<template>  <div class="container-fluid">
  <!-- style="border:1px solid gray;border-radius:5px;overflow:hidden" -->
  <div class="row bg-white border rounded-3">
    <div class="col-4 p-0 border-end border-light" style="overflow-x:scroll">
      <table class="table m-0 text-dark text-center table-striped">
        <thead>
          <tr valign="middle">
            <th scope="col" style="padding: 3px; width: 35%;" title="User Name">Name</th>
            <th scope="col" style="padding: 3px; width: 12%;" title="Working Days">WD</th> <!-- Working Days -->
            <th scope="col" style="padding: 3px; width: 8%; " title="Present">P</th> <!-- Present -->
            <th scope="col" style="padding: 3px; width: 8%; " title="Opening Balance">OB</th> <!-- Opening Balance -->
            <th scope="col" style="padding: 3px; width: 8%; " title="Leave">L</th> <!-- Leave -->
            <th scope="col" style="padding: 3px; width: 8%; " title="Closing Balance">CB</th> <!-- Closing Balance -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(v,k,index) in formatted_data" :key="idGen(index)" style="height:60px;" class="align-middle">
            <td style="padding: 0px; " v-text="k" title="User Name"></td>
            <td style="padding: 0px; " :key="idGen(index)" v-text="v['workingdays']" title="Working Days"></td>
            <td style="padding: 0px; " :key="idGen(index)" v-text="v['present']" title="Present"></td>
            <td style="padding: 0px; " :key="idGen(index)" v-text="v['opening']" title="Opening Balance"></td>
            <td style="padding: 0px; " :key="idGen(index)" v-text="v['leave']" title="Leave"></td>
            <td style="padding: 0px; " :key="idGen(index)" v-text="v['closing']" title="Closing Balance"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-8 p-0" style="overflow-x:scroll">
      <table class="table text-nowrap m-0 text-center table-striped">
        <thead class="text-dark">
          <tr>
            <td scope="col" id="days" :title="dayConverter(item)" v-for="(item,index) in dates_list" :key="idGen(index)" v-text="dayConverter(item)" :class="holidays.includes(item) ? 'bg-primary text-white' : '' " style="padding: 3px; border-right:1px solid lightgray;min-width:50px"></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attendance,index) in formatted_attendance_data[0]" :key="index" style="height: 60px;" class="align-middle">
            <td v-for="(v,k,c) in attendance" :key="c" style="padding: 3px; border-right:1px solid lightgray;color:black;min-width:" :class="getSpentTime(v)?'':''" :style="`color:${setColorDetails(v)}`">
              <span v-if="v.doe==null ? true : new Date(v.doe)<new Date(v.date) ? false : true">
                  <span :title="'Time(HH:MM) Day ' + dayConverter(v.date)" v-text="getSpentTime(v)[0]"></span><br>
                  <span :title="'Report(HH:MM) Day ' + dayConverter(v.date) " v-text="getSpentTime(v)[1]"></span>
              </span>
              <i class="fa fa-ban fs-5 text-secondary" v-else></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import myMixin from '@/mixins/Permission'
import moment from 'moment';
moment.locale('en');

export default{
  data(){
    return{
      holidays:this.monthly_report.holidays,
      formatted_data:[],
      formatted_attendance_data:[],
      dates_list:[]
    }
  },
  mixins: [myMixin],
  methods:{
    idGen(id){
      return id+Math.random()+10;
    },
    numberOfUser(mdata){
      let key=Object.keys(mdata)[0];
      return mdata[key].length;
    },
    getSpentTime(v){
      // days = document.documentElement('days')
      // console.log("--- days",days)
      if(v.doe==null){
        // console.log("before--- ",v[i]);
        return this.hoursMinutesCalcualtor(v);
      }
      else{
        // console.log(k,v,i)
        if(!new Date(v.doe).getDate()<=new Date(v.date).getDate()){
          return this.hoursMinutesCalcualtor(v);
        }
      }
    },

    //total time calculation
    hoursMinutesCalcualtor(obj){
      if (obj["total_time_spent"] == null && obj["approved_time"] == 0) {
        return ['-', '-']
      }
      else if (obj["total_time_spent"] == null && obj["approved_time"] >= 0) {
        return ['-', this.ConvertMinutesToTime2(obj["approved_time"])]
      }
      else if (obj["total_time_spent"] > 0 && obj["approved_time"] == 0) {
        let minits = parseInt(Math.floor((obj["total_time_spent"]/60)))
        return [this.ConvertMinutesToTime2(minits), '-']
      }
      else if (obj["total_time_spent"] > 0 && obj["approved_time"] >= 0) {
        let minits = parseInt(Math.floor((obj["total_time_spent"]/60)))
        return [this.ConvertMinutesToTime2(minits),this.ConvertMinutesToTime2(obj["approved_time"])]
      }
    },
    getUserName(mdata){
      let key=Object.keys(mdata)[0];
      return mdata[key];
    },
    getActualValue(item,i){
      for(let value in item[i]){
        return item[i][value];
      }
    },
    formatDate(dataStr){
      let date=new Date(dataStr);
      return date.getDate();
    },
    setColorDetails(v){
      let obj=v;
      if(obj["approved_time"]===null || obj["approved_time"]===undefined){
        obj["approved_time"]=0;
      }
      let filled_hours= Math.round(obj["approved_time"]/60);
      let default_hours=Math.round(obj["user_per_day_min"]/60);
      let login_hours=Math.trunc(obj['total_time_spent']/3600);
      // console.log("approved_time ",filled_hours);
      // console.log("default_hours ",default_hours);

      if( (filled_hours<default_hours) || (filled_hours>login_hours) ){
        // console.log("no");
        return 'red';
      }
      // if(filled_hours===default_hours){
      else{
        // console.log("yes");
        return 'green';
      }
    },
    dayConverter(date){
      if(this.holidays.includes(date)){
        return 'Holiday'
      }
      else{
        return `${new Date(date).getDate()} ${moment(date).format("ddd").substring(0,1)}`;
      }
    },
    formatDatewiseData(data){
      const temp=Object.entries(data).map(item=>{
        const [date,value]=item;
        const result = value.reduce((acc,cv)=>{
          if(acc[cv.full_name]){
            acc[cv.full_name].push({date:date,...cv});
          }
          else{
            acc[cv.full_name]=[];
            acc[cv.full_name].push({date:date,...cv});
          }
          return acc;
        },{})
        return result;
      })
      .reduce((acc,cv)=>{
        if(acc.length>0){
          let obj=acc[0];
          Object.entries(obj).map(item=>{
            const [key,value]=item;
            obj[key].push(cv[key][0]);
          });
        }
        else{
          acc.push(cv);
        }
          return acc;
        },[]);
      this.formatted_attendance_data=temp;
    },
    formatData(data){
      const valid_users=data[Object.keys(data)[0]].map(item=>item.full_name);
      const {leave,closing,opening,present,workingdays,holidays,selected_dates,...datewise_data}=data;
      const template={leave,closing,opening,present,workingdays};
      const formatted_template=Object.entries(template).map(item=>{
        let label=item[0];
        return item[1].map(item=>{
            let [name,value] = Object.entries(item)[0];
            return {[name]:{[label]:value}};
        }).filter(item=>valid_users.includes(Object.keys(item)[0]));
      }).flat().reduce((acc,cv)=>{
        let [key,value]=Object.entries(cv)[0];
        if(acc[key]){
          acc[key]=Object.assign(acc[key],cv[key]);
        }
        else{
          acc[key]=Object.assign({},cv[key]);
        }
        // const details=Object.keys(acc[key]);
        // if(details.includes("leave") && details.includes("closing") && details.includes("present")){
        //   const result=acc[key]["closing"]-acc[key]["leave"];
        //   acc[key]["opening"]= result<0 ? 0 : result;
        // }
        return acc;
      },{});
      this.formatted_data=formatted_template;
      this.dates_list=Object.keys(datewise_data);
      this.formatDatewiseData(datewise_data);
    }
  },
  computed:{
    getMAReport(){
      let edited_obj={};
      for(let item in this.monthly_report){
        if(item!=="present" && item!=="workingdays" && item!=="leave" && item!=="selected_dates" && item!=="opening" && item!=="holidays" ){
          edited_obj[item]=this.monthly_report[item];
        }
      }
      return edited_obj;
    },
    getPresentCount(){
      let edited_obj={};
      for(let item in this.monthly_report){
        if(item==="present"){
          edited_obj[item]=this.monthly_report[item];
        }
      }
      return edited_obj;
    },
    getLeaveCount(){
      let edited_obj={};
      for(let item in this.monthly_report){
        if(item==="leave"){
          edited_obj[item]=this.monthly_report[item];
        }
      }
      return edited_obj;
    },
    getOpeningCount(){
      let edited_obj={};
      for(let item in this.monthly_report){
        if(item==="opening"){
          edited_obj[item]=this.monthly_report[item];
        }
      }
      return edited_obj;
    },
    getWorkingDays(){
      let edited_obj={};
      for(let item in this.monthly_report){
        if(item==="workingdays"){
          edited_obj[item]=this.monthly_report[item];
        }
      }
      // console.log('----',edited_obj)
      return edited_obj;
    },
    getOpeningLeaveResult(){
      let leave_obj={}
      for(let item in this.monthly_report){
        if(item==="leave"){
          leave_obj[item]=this.monthly_report[item];
        }
      }
      let opening_obj={}
      for(let item in this.monthly_report){
        if(item==="opening"){
          opening_obj[item]=this.monthly_report[item];
        }
      }
      let diff_obj={"diff":[]}
      opening_obj["opening"].forEach((obj,idx)=>{
        let temp=leave_obj["leave"][idx]
        for(let i in obj){
          diff_obj["diff"].push({i:obj[i]-temp[i]})
        }
      });
      // console.log("diff ",diff_obj);
      return diff_obj;
    },
  },
  props:['monthly_report'],
  watch:{
    monthly_report:{
      handler(nv,ov){
        if(nv!=null){
          this.formatData(nv);
        }
      },
      immediate:true
    }
  },
  updated(){
    // console.log("updated ",this.monthly_report)
    this.holidays=this.monthly_report.holidays;
  }
}

</script>
