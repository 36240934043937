<template>
    <!-- Outer container to vertically  -->
    <div class="login-page">
        <div class="login-wrapper shadow-sm">
            <h5 class="login-title">Hello again!</h5>
            <span>Welcome to optimis! Please enter your details</span>
            <form class="user">
                <div class="mt-3">
                    <label for="usrname" class="">Email</label>
                    <input type="email" id="usrname" v-model="reqObj.username" class="form-control" placeholder="Enter your email"/>
                </div>
                <div class="mt-3">
                    <label for="passwd" class="">Password</label>
                    <input type="password" id="passwd" v-model="reqObj.password" class="form-control" placeholder="Enter your password"/>
                </div>
                <div class="login-password">
                    <!-- <div class="custom-checkbox">
                        <input type="checkbox" id="remember_me" class=""/>
                        <label for="remember_me" class="form-check-label">Remember me</label>
                        <span class="checkmark"></span>
                    </div> -->
                    <a href="javasacript:;" @click="forgotpassword">Forgot Password ?</a>
                </div>
                <div class="signin-btn my-4">
                    <button class="btn btn-primary w-100" type="submit" @click.prevent="login">Sign in</button>
                </div>
                <!-- <div class="account-link">Don't have an account?
                    <a href="/resend-confirmation" class="login-account">Create account</a>
                </div> -->
                <!-- <div class="my-3 d-flex align-items-center">
                <hr class="w-50">
                <span class="px-4">OR</span>
                <hr class="w-50">
                </div>
                <div class="mb-2">
                <button class="btn btn-outline-secondary w-100">
                    <img src="../../assets/google.svg" class="mb-1 mx-2" style="height:20px;width:20px"><span>Continue with google</span>
                </button>
                <button class="mt-4 btn btn-outline-secondary w-100">
                    <img src="../../assets/apple.svg" class="mb-1 mr-1" style="height:20px;width:20px"> <span>Continue with apple</span>
                </button>
                </div>
                <div class="mt-4 text-center">
                <small>Don't have an account? <a href="#">Create account</a></small>
                </div> -->
            </form>
        </div>
    </div>
</template>
<script>
import axios from "@/ajax/axios";
import user from "@/ajax/user";
import { mapActions } from "vuex";
import { BootstrapVue } from "bootstrap-vue";
import swal from "vue-sweetalert2";
export default {
  name: "Login",
  data: function () {
    return {
      reqObj: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {
    // this.testMethod()
    // this.testMethodWithoutAsync()
    user.getVersion();
    // this.getCurrentUser();
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "setAccessToken"]),

    forgotpassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
    login() {
      var regex = /^$/;
      if (regex.test(this.reqObj.username)) {
        this.$swal.fire({
                title: "Enter Your Username",
                color: "white",
                toast: true,
                icon: "error",
                iconColor: "white",
                position: "top",
                background: "rgb(220, 20, 60)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              })
        // alert("Enter Your Username");
        return;
      }
      if (regex.test(this.reqObj.password)) {
        this.$swal.fire({
                title: "Enter Your Password",
                color: "white",
                toast: true,
                icon: "error",
                iconColor: "white",
                position: "top",
                background: "rgb(220, 20, 60)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              })
        // alert("Enter Your Password");
        return;
      }
      user.loginUser(this.reqObj).then((result) => {
        var actualResult = result.data;
        var resultCode = actualResult.code;
        var resultData = actualResult.data;

        //failure in token response //e.x incorrect username and password
        if (resultCode != 200) {
          // Swal.fire("Error",JSON.stringify(actualResult.message),'error')
          this.$swal.fire({
                title: JSON.stringify(actualResult.message),
                color: "white",
                toast: true,
                icon: "error",
                iconColor: "white",
                position: "top",
                background: "rgb(220, 20, 60)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              })
          // alert(JSON.stringify(actualResult.message));
        } else {
          this.setAccessToken(resultData.access_token);
          this.getCurrentUser();
        }

        //this.getCurrentUser();
        // Set User to store with the below uncomment once we get data
        // this.setCurrentUser(data);
      });
    },
    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        //set user in store
        this.setCurrentUser(result.data);
        this.$nextTick(() => {
          this.$router.push({ name: "DashboardContent" });
        });
        //redirect to dashboard
        //this.$router.push('  ')
      });
    },
    async testMethod() {
      const response = await axios.getUsers();
    },
    testMethodWithoutAsync() {
      axios.getUsers().then((result) => {});
    },
  },
  components: {
    // BootstrapVue,
  },
};
</script>
