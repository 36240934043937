<template>
  <!-- Begin Page Content -->
  <div>
    <div class="card shadow-sm border-0 my-4">
      <div class="card-body">
        <div
          class="d-flex flex-md-row flex-column gap-md-0 gap-2 justify-content-between align-items-center px-2 pb-3"
        >
          <h4 class="m-0 font-weight-bold text-primary">Daily Discrepancy</h4>
          <div
            class="d-flex flex-md-row flex-column justify-content-between align-items-center gap-2 col-md-5 col-12"
          >
            <flat-pickr
              placeholder="Select date range"
              v-model="config.date"
              :modelValue="config.date"
              :config="config"
              class="form-control"
              @on-change="GetArrayOfStartDate"
            ></flat-pickr>

            <multiselect
              v-model="selected_status"
              :options="status_dropdown"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Status"
              label="label"
              track-by="name"
              :preselect-first="true"
              return="name"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>

            <button class="btn btn-primary" @click="OnApplyFilters">
              Filter
            </button>
          </div>
        </div>
        <div v-if="permissions.includes('reports.read')" class="p-2">
          <div class="" v-for="(value, key, count) in rows" :key="count">
            <div
              class="mb-3 border rounded-2 border-bottom-0"
              v-for="(value2, key, count) in value"
              :key="count"
            >
              <div class="p-2">
                <h5
                  v-text="key"
                  class="text-md-start text-center text-primary m-0"
                ></h5>
              </div>
              <vue-good-table
                :rows="value2.users"
                :columns="column"
                styleClass="vgt-table no-border"
              >
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!permissions.includes('reports.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import Multiselect from "vue-multiselect";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const date = new Date();

export default {
  name: "Daily_Discrepancy",
  mixins: [myMixin],
  components: {
    flatPickr,
    Multiselect,
  },
  data: function () {
    return {
      config: {
        date: null,
        mode: "range",
        dateFormat: "Y-m-d",
        defaultDate: [
          new Date().toISOString().substring(),
          new Date().toISOString().substring(),
        ],
        enable: [
          {
            from: "2000-01-01",
            to: new Date().toISOString().substring(0, 10),
          },
        ],
      },
      siteLogReq: {
        site_name: "daily_discrepancy",
      },

      serverParams: {
        columnFilters: {
          start_date_from: null,
          start_date_to: null,
          status: null,
        },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },

      selected_start_date: [],
      selected_status: [{ label: "All Status", name: "" }],
      status_dropdown: [
        { label: "Not In", name: "Not In" },
        { label: "In for Short Time", name: "In for Short Time" },
        { label: "Didn't filled the Report", name: "Didn't filled the Report" },
        { label: "All Status", name: "" },
      ],

      rows: [],
      column: [
        {
          label: "User",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
        {
          label: "Status",
          field: "status",
          thClass: "custom-th",
          tdClass: "custom-td",
        },
      ],

      permissions: window.localStorage.getItem("permissions"),
    };
  },
  mounted() {
    this.setCurrentPage("Reports");
    if (this.permissions.includes("reports.read")) {
      const d = new Date(new Date().setDate(date.getDate() - 1))
        .toISOString()
        .substring();
      this.GetArrayOfStartDate([d, d]);
      this.OnApplyFilters();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    OnApplyFilters() {
      this.getdailydiscrepancy();
    },

    getdailydiscrepancy() {
      this.serverParams.columnFilters["start_date_from"] =
        this.selected_start_date[0];
      this.serverParams.columnFilters["start_date_to"] =
        this.selected_start_date[1];
      this.serverParams.columnFilters["status"] = this.selected_status.name;

      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perPage,
      };
      // console.log("---- fields ", fields);
      user.getdailydiscrepancy(fields).then((result) => {
        // console.log("---- data ", result.data.data.data);
        this.rows = result.data.data.data;
      });
    },

    GetArrayOfStartDate(selectedDates) {
      let dates = [];
      for (var i = 0; i < selectedDates.length; i++) {
        var select_date = new Date(selectedDates[i]);
        select_date.setDate(select_date.getDate() + 1);
        dates.push(select_date.toISOString().slice(0, 10));
      }
      this.selected_start_date = dates;
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
