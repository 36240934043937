<template>
  <div>
    <!-- Begin Page Content -->
    <div class="" :style="firstTable ? '' : ''">
      <div class="card shadow-sm border-0 my-4 pb-3" v-if="firstTable">
        <div class="card-body">
          <div
            class="d-flex justify-content-md-between justify-content-center flex-md-nowrap flex-wrap gap-md-0 gap-2 align-items-center pb-3"
          >
            <h4 class="text-primary m-0">Request Update</h4>
            <div class="d-flex justify-content-center align-items-center gap-2">
              <button
                class="btn btn-primary rounded-start btn-sm"
                @click="ChangeDate('pre')"
              >
                <i class="fa fa-arrow-left"></i>
              </button>
              <datepicker
                type="date"
                @input="FilterApplyOnDate()"
                placeholder="yyyy-mm-dd"
                v-model="reqObj.searched_date"
                :typeable="false"
                :clear-button="true"
                :calendar-button="true"
                :clear-button-icon="'fa fa-times'"
                :input-class="'border-start-0'"
                :calendar-button-icon="'fa fa-calendar'"
                :bootstrap-styling="true"
                :format="formateDate"
                :disabled-dates="disabledDates"
              >
              </datepicker>
              <button
                class="btn btn-primary btn-sm rounded-end"
                @click="ChangeDate('next')"
              >
                <i class="fa fa-arrow-right"></i>
              </button>
            </div>
            <button class="btn btn-primary" @click="UpdateAttendanceTime('')">
              Add Time
            </button>
          </div>
          <div class="">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :isLoading.sync="isLoadingYour"
              :totalRows="Filtered_Records_First"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'has_start_time', type: 'asc' },
                initialSortBy: { field: 'has_end_time', type: 'asc' },
              }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" scope="props">
                <span
                  v-if="
                    props.column.label == 'Action' &&
                    props.row.has_end_time != null
                  "
                >
                  <button class="btn-pencil">
                    <i
                      class="fa fa-pencil fs-5"
                      @click="UpdateAttendanceTime(props.row)"
                    ></i>
                  </button>
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div
        class="card border-0 shadow-sm mb-4 pb-3"
        v-if="
          totalRecordsSecond != 0 &&
          permissions.includes('attendance.update') &&
          secondTable
        "
      >
        <div class="card-body">
          <div
            class="px-2 pb-3 d-flex justify-content-md-between justify-content-center gap-2 gap-md-0 align-items-center flex-md-nowrap flex-wrap"
          >
            <h4 class="text-primary m-0">Pending Approval</h4>
            <multiselect
              style="max-width: 250px"
              @input="FilterApply()"
              v-model="selected_log"
              :options="options"
              placeholder="Select Approval status"
              class=""
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>
          </div>
          <div class="p-2">
            <vue-good-table
              mode="remote"
              styleClass="vgt-table no-border"
              @on-page-change="onPageChangeSecond"
              @on-sort-change="onSortChangeSecond"
              @on-column-filter="onColumnFilterSecond"
              @on-per-page-change="onPerPageChangeSecond"
              :isLoading.sync="isLoadingTeam"
              :totalRows="Filtered_Records_Second"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'start_time', type: 'asc' },
                initialSortBy: { field: 'end_time', type: 'asc' },
              }"
              :rows="request_for_update_row"
              :columns="request_for_update_column"
            >
              <template slot="table-row" scope="props">
                <span v-if="props.column.field == 'start_time'">
                  <!-- <span v-if="props.row.upd_old_start_time != null && formatUTCtoIST(props.row.upd_old_start_time) != formatUTCtoIST(props.row.start_time)" class="bg-light"><b>From :</b> {{formatUTCtoIST(props.row.upd_old_start_time)}} <b><br> To: </b> {{formatUTCtoIST(props.row.start_time)}}</span> -->
                  <span
                    v-if="
                      props.row.old_start_time != null &&
                      formatUTCtoIST(props.row.old_start_time) !=
                        formatUTCtoIST(props.row.start_time)
                    "
                    class="bg-light"
                    ><b>From :</b>
                    {{ formatUTCtoIST(props.row.old_start_time) }}
                    <b
                      ><br />
                      To:
                    </b>
                    {{ formatUTCtoIST(props.row.start_time) }}</span
                  >
                  <span v-else>{{ formatUTCtoIST(props.row.start_time) }}</span>
                </span>
                <span v-else-if="props.column.field == 'end_time'">
                  <!-- <span v-if="props.row.upd_old_end_time != null && formatUTCtoIST(props.row.upd_old_end_time) != formatUTCtoIST(props.row.end_time)" class="bg-light"><b>From :</b> {{formatUTCtoIST(props.row.upd_old_end_time)}} <b><br> To: </b> {{formatUTCtoIST(props.row.end_time)}}</span> -->
                  <span
                    v-if="
                      props.row.old_end_time != null &&
                      formatUTCtoIST(props.row.old_end_time) !=
                        formatUTCtoIST(props.row.end_time)
                    "
                    class="bg-light"
                    ><b>From :</b> {{ formatUTCtoIST(props.row.old_end_time) }}
                    <b
                      ><br />
                      To:
                    </b>
                    {{ formatUTCtoIST(props.row.end_time) }}</span
                  >
                  <span v-else>{{ formatUTCtoIST(props.row.end_time) }}</span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <template v-if="props.column.label == 'Action'">
                  <template
                    v-if="
                      permissions.includes('attendance.approve') &&
                      currentUser.id != props.row.user_id
                    "
                  >
                    <div
                      class="d-flex justify-content-center align-items-center gap-3 p-2"
                    >
                      <button
                        class="btn-view"
                        v-on:click.prevent="ApproveTime(props.row.id, 'accept')"
                        v-if="
                          props.row.approved_by == null &&
                          props.row.rejected_by == null
                        "
                      >
                        <i class="fa fa-check fs-5"></i>
                      </button>
                      <button
                        class="btn btn-trash"
                        v-on:click.prevent="ApproveTime(props.row.id, 'reject')"
                        v-if="
                          props.row.rejected_by == null &&
                          props.row.approved_by == null
                        "
                      >
                        <i class="fa fa-times fs-5"></i>
                      </button>
                    </div>
                  </template>
                  <div v-else class="p-3">-</div>
                </template>
              </template>
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'date'">
                  <datepicker
                    :clear-button="true"
                    :format="'yyyy-MM-dd'"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e ? e.toISOString().substring(0, 10) : ''
                        )
                    "
                  />
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParamsSecond"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="addTimeModal"
        aria-hidden="true"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="mb-0 text-dark"
                id="taskModalLongTitle"
                v-text="modal_title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="d-flex justify-content-md-center justify-content-start gap-2 flex-md-nowrap flex-wrap"
                v-if="modal_title"
              >
                <div class="mb-3">
                  <label class="form-label">Start Time</label>
                  <div
                    class="d-flex justify-content-start gap-2 align-items-center"
                  >
                    <datepicker
                      type="date"
                      placeholder="yyyy-mm-dd"
                      v-model="start_date_val"
                      :typeable="false"
                      :clear-button="true"
                      :calendar-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :input-class="'border-start-0'"
                      :calendar-button-icon="'fa fa-calendar'"
                      :bootstrap-styling="true"
                      :format="formateDate"
                      :disabled-dates="disabledDates"
                    ></datepicker>
                    <vue-timepicker
                      v-model="start_time_val"
                      input-class="form-control"
                      format="hh:mm A"
                    ></vue-timepicker>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">End Time</label>
                  <div
                    class="d-flex justify-content-start gap-2 align-items-center"
                  >
                    <datepicker
                      type="date"
                      placeholder="yyyy-mm-dd"
                      v-model="end_date_val"
                      :typeable="false"
                      :clear-button="true"
                      :calendar-button="true"
                      :clear-button-icon="'fa fa-times'"
                      :input-class="'border-start-0'"
                      :calendar-button-icon="'fa fa-calendar'"
                      :bootstrap-styling="true"
                      :format="formateDate"
                      :disabled-dates="disabledDates"
                    ></datepicker>
                    <vue-timepicker
                      v-model="end_time_val"
                      input-class="form-control"
                      format="hh:mm A"
                    ></vue-timepicker>
                  </div>
                </div>
              </div>
              <div v-if="displayErr != ''">
                <small class="text text-danger">
                  {{ displayErr }}
                </small>
              </div>
              <div
                class="d-flex justify-content-end align-items-center gap-2 mt-2"
              >
                <button class="btn btn-primary" @click="SendUpdateRequest()">
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <b-modal
        centered
        modal-header-close
        id="update-attendance-popup"
        size="lg"
      >
        <template #modal-header> </template>
        <table class="table m-0">
          <thead>
            <tr>
              <th
                colspan="2"
                scope="col"
                class="bg-primary text-center text-light"
              >
                Start Time
              </th>
              <th class="border-0"></th>
              <th
                colspan="2"
                scope="col"
                class="bg-primary text-center text-light"
              >
                End Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <datepicker
                  type="date"
                  placeholder="yyyy-mm-dd"
                  v-model="start_date_val"
                  :typeable="false"
                  :clear-button="true"
                  :calendar-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                  :format="formateDate"
                  :disabled-dates="disabledDates"
                ></datepicker>
              </td>
              <td>
                <vue-timepicker
                  v-model="start_time_val"
                  input-class="form-control"
                  format="hh:mm A"
                ></vue-timepicker>
              </td>
              <td></td>
              <td>
                <datepicker
                  type="date"
                  placeholder="yyyy-mm-dd"
                  v-model="end_date_val"
                  :typeable="false"
                  :clear-button="true"
                  :calendar-button="true"
                  :clear-button-icon="'fa fa-times'"
                  :input-class="'border-start-0'"
                  :calendar-button-icon="'fa fa-calendar'"
                  :bootstrap-styling="true"
                  :format="formateDate"
                  :disabled-dates="disabledDates"
                ></datepicker>
              </td>
              <td>
                <vue-timepicker
                  v-model="end_time_val"
                  input-class="form-control"
                  format="hh:mm A"
                ></vue-timepicker>
              </td>
            </tr>
            <tr v-if="displayErr != ''">
              <td colspan="2" class="m-0 p-0">
                <p class="text text-danger m-0 p-0 pt-3 ml-1">
                  {{ displayErr }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button class="btn btn-danger rounded-0 mr-3" @click="hideModal"
              ><b>Close</b></b-button
            >
            <button
              class="btn btn-primary rounded-0"
              @click="SendUpdateRequest()"
            >
              Submit
            </button>
          </div>
        </template>
      </b-modal> -->
      <div v-if="!permissions.includes('attendance.update')">
        <UnauthorizedPage></UnauthorizedPage>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
import Multiselect from "vue-multiselect";
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
import CustomPagination from "../../components/CustomPagination.vue";
import { Modal } from "bootstrap";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "UpdateAttendance",
  mixins: [myMixin],
  components: {
    UnauthorizedPage,
    Datepicker,
    VueTimepicker,
    Multiselect,
    CustomPagination,
  },
  data: function () {
    return {
      start_time_val: {
        hh: "",
        mm: "",
        A: "PM",
      },
      end_time_val: {
        hh: "",
        mm: "",
        A: "PM",
      },
      siteLogReq: {
        site_name: "attendance_request_update",
      },
      start_date_val: "",
      end_date_val: "",
      currentPage: "",
      permissions: window.localStorage.getItem("permissions"),
      isLoadingYour: false,
      isLoadingTeam: false,
      columns: [
        {
          label: "Start Time",
          field: "has_start_time",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          formatFn: this.formatUTCtoIST,
        },
        {
          label: "End Time",
          field: "has_end_time",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          formatFn: this.formatUTCtoIST,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th",
          tdClass: "custom-td",
          thClass: "disable-sorting",
        },
      ],
      rows: [],
      TotalRecordsFirst: null,
      Filtered_Records_First: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      reqObj: {
        searched_date: new Date(),
        curr_date: "",
        user_log_id: "",
        date_of_update_attendance: "",
        user_id: "",
        start_time: "",
        end_time: "",
        before_s_t: "",
        before_e_t: "",
        before_s_d: "",
        before_e_d: "",
      },
      displayErr: "",
      //requests for update attendance table
      currentPageSecond: "",
      request_for_update_column: [
        {
          label: "Username",
          field: "full_name",
          sortable: true,
          thClass: "custom-th vgt-center-align",
          tdClass: "custom-td vgt-center-align",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date",
          field: "date",
          sortable: true,
          thClass: "custom-th vgt-center-align",
          tdClass: "custom-td vgt-center-align",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "request to change this date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Start Time",
          field: "start_time",
          sortable: true,
          thClass: "custom-th vgt-center-align",
          tdClass: "custom-td vgt-center-align",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Start Time", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "End Time",
          field: "end_time",
          sortable: true,
          thClass: "custom-th vgt-center-align",
          tdClass: "custom-td vgt-center-align",
          filterable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "End Time", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterSecond, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          tdClass: "custom-td vgt-center-align",
          thClass: "disable-sorting custom-th vgt-center-align",
        },
      ],
      request_for_update_row: [],
      Filtered_Records_Second: 0,
      totalRecordsSecond: null,
      serverParamsSecond: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      disabledDates: {
        from: new Date(new Date().setDate(new Date().getDate())),
      },
      addtime: false,
      check_log_by_date: new Date(),
      selected_log: "Remaining",
      options: ["Remaining", "Approved", "Rejected", "All"],
      perpage_list: [100, 200, 300, 400, 500, "All"],
      modal_title: null,
    };
  },
  props: {
    firstTable: {
      default: true,
      type: Boolean,
    },
    secondTable: {
      default: true,
      type: Boolean,
    },
  },
  mounted() {
    this.setCurrentPage("Attendance");
    $("#addTimeModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("attendance.update")) {
      //this.getUserPermissions();
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    hideModalEventHandler() {
      this.modal_title = null;
    },
    getAttendanceLogByDate() {
      if (this.reqObj.searched_date) {
        this.reqObj.searched_date = moment(this.reqObj.searched_date).format(
          "yyyy-MM-DD"
        );
      }
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        searched_date: this.reqObj.searched_date,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("search",fields);
      user.getAttendanceLogByDate(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.Filtered_Records_First = result.data.data.f_total;
        this.TotalRecordsFirst = result.data.data.total;
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAttendanceLogByDate();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getAttendanceLogByDate();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getAttendanceLogByDate();
    },
    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getAttendanceLogByDate();
    },
    UpdateAttendanceTime(data) {
      data
        ? (this.modal_title = "Update Time")
        : (this.modal_title = "Add Time");
      this.reqObj.curr_date = moment(this.reqObj.searched_date).format(
        "yyyy-MM-DD"
      );
      this.displayErr = "";
      this.reqObj.before_s_t = new Date(
        data.has_start_time + "Z"
      ).toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      this.reqObj.before_e_t = new Date(data.has_end_time + "Z").toLocaleString(
        "en-US",
        { hour: "2-digit", minute: "2-digit", hour12: true }
      );
      this.reqObj.before_s_d = moment(data.has_start_time).format("yyyy-MM-DD");
      this.reqObj.before_e_d = moment(data.has_end_time).format("yyyy-MM-DD");

      // this.$root.$emit("bv::show::modal", "update-attendance-popup");
      if (data != "") {
        this.reqObj.user_id = data.user_id;
        this.reqObj.user_log_id = data.id;
        this.reqObj.date_of_update_attendance = moment(data.start_time).format(
          "yyyy-MM-DD"
        );
        this.start_time_val = new Date(
          data.has_start_time + "Z"
        ).toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        this.end_time_val = new Date(data.has_end_time + "Z").toLocaleString(
          "en-US",
          { hour: "2-digit", minute: "2-digit", hour12: true }
        );
        this.start_date_val = moment(data.has_start_time).format("yyyy-MM-DD");
        this.end_date_val = moment(data.has_end_time).format("yyyy-MM-DD");
      } else {
        this.reqObj.user_id = localStorage.getItem("current_user");
        this.reqObj.user_log_id = null;
        this.start_time_val = "No Data";
        this.end_time_val = "No Data";
        this.start_date_val = moment(this.reqObj.searched_date).format(
          "yyyy-MM-DD"
        );
        this.end_date_val = moment(this.reqObj.searched_date).format(
          "yyyy-MM-DD"
        );
      }
      $("#addTimeModal").modal("show");
    },
    SendUpdateRequest() {
      this.reqObj.date_of_update_attendance = this.reqObj.searched_date;
      this.displayErr = ""; //null the error value
      if (
        typeof this.start_time_val === "string" &&
        this.start_time_val.length > 0
      ) {
      } else {
        this.start_time_val = "No Data";
      }
      if (
        typeof this.end_time_val === "string" &&
        this.end_time_val.length > 0
      ) {
      } else {
        this.end_time_val = "No Data";
      }

      var check_values = 0; //0

      if (this.start_time_val != "No Data" && this.end_time_val != "No Data") {
        check_values++; //1
      } else {
        this.displayErr = "** Enter both time to update attendance";
      }

      if (
        check_values == 1 &&
        moment(this.start_date_val).format("yyyy-MM-DD") ==
          moment(this.end_date_val).format("yyyy-MM-DD")
      ) {
        if (
          this.ConvertTime12To24(this.start_time_val) <
          this.ConvertTime12To24(this.end_time_val)
        ) {
          check_values++; //2
        } else {
          this.displayErr = "** Start time must be less then end time";
        }
      } else {
        if (
          moment(this.start_date_val).format("yyyy-MM-DD") <
          moment(this.end_date_val).format("yyyy-MM-DD")
        ) {
          check_values++; //2
        } else {
          this.displayErr = "** Start time must be less then end time";
        }
      } //2

      if (
        moment(this.start_date_val).format("yyyy-MM-DD") ==
          moment(new Date().toDateString()).format("YYYY-MM-DD") &&
        moment(this.end_date_val).format("yyyy-MM-DD") ==
          moment(new Date().toDateString()).format("YYYY-MM-DD")
      ) {
        if (
          this.ConvertTime12To24(this.end_time_val) <
          new Date().toLocaleTimeString()
        ) {
          check_values++; //3
        } else {
          this.displayErr = "** You can not add future time";
        }
      } else {
        check_values++;
      } //3

      if (
        this.reqObj.before_s_t == this.start_time_val &&
        this.reqObj.before_e_t == this.end_time_val &&
        this.reqObj.before_s_d ==
          moment(this.start_date_val).format("yyyy-MM-DD") &&
        this.reqObj.before_e_d == moment(this.end_date_val).format("yyyy-MM-DD")
      ) {
        this.displayErr = "** Change time if you want to update";
      } else {
        check_values++;
      } //4

      if (check_values == 4) {
        this.reqObj.start_time = moment(
          new Date(
            moment(this.start_date_val).format("yyyy-MM-DD") +
              "T" +
              this.ConvertTime12To24(this.start_time_val)
          ).toJSON()
        ).format("YYYY-MM-DD HH:mm:ss");
        this.reqObj.end_time = moment(
          new Date(
            moment(this.end_date_val).format("yyyy-MM-DD") +
              "T" +
              this.ConvertTime12To24(this.end_time_val)
          ).toJSON()
        ).format("YYYY-MM-DD HH:mm:ss");
        this.CreateAttendanceUpdateRequest();
      }
    },
    CreateAttendanceUpdateRequest() {
      user.CreateAttendanceUpdateRequest(this.reqObj).then((result) => {
        if (result.data.code == 200) {
          this.$swal("Success", "Request submitted successfully...", "success");
          this.getAttendanceLogByDate();
          this.get_update_attendance_request();
        } else if (result.data.code == 403) {
          this.$swal(
            "Error",
            "Request time is already submitted...",
            "warning"
          );
        } else {
          this.$swal("Error", "Request not submitted...", "warning");
        }
        // <!-- console.log("reached"); -->
        $("#addTimeModal").modal("hide");
      });
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    formatUTCtoIST(value) {
      if (value != null) {
        var dt = new Date(value + "Z");
        var local_time = dt.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        return moment(dt).format("yyyy-MM-DD") + " " + local_time;
      } else {
        return "Active";
      }
      // moment.utc(value).local().format("mm")
      // {{this.start_time_val.hh = moment.utc(props.row.has_start_time).local().format("hh")}}
      // {{this.start_time_val.mm = moment.utc(props.row.has_start_time).local().format("mm")}}
    },
    ConvertTime12To24(val) {
      if (val) {
        var hrs = Number(val.match(/^(\d+)/)[1]);
        var mnts = Number(val.match(/:(\d+)/)[1]);
        var format = val.match(/\s(.*)$/)[1];
        if (format == "PM" && hrs < 12) hrs = hrs + 12;
        if (format == "AM" && hrs == 12) hrs = hrs - 12;
        var hours = hrs.toString();
        var minutes = mnts.toString();
        if (hrs < 10) hours = "0" + hours;
        if (mnts < 10) minutes = "0" + minutes;
        var timeend = hours + ":" + minutes + ":00";
        return timeend;
      } else {
        return false;
      }
    },

    get_update_attendance_request() {
      this.serverParamsSecond.columnFilters = Object.assign(
        {},
        this.serverParamsSecond.columnFilters,
        { log_type: this.selected_log }
      );
      var fields = {
        filters: this.serverParamsSecond.columnFilters,
        messageData: this.message,
        sort: this.serverParamsSecond.sort,
        page: this.serverParamsSecond.page,
        perpage: this.serverParamsSecond.perpage,
      };
      // console.log("search",fields);
      user.get_update_attendance_request(fields).then((result) => {
        this.request_for_update_row = [];
        this.request_for_update_row = result.data.data.data;
        this.Filtered_Records_Second = result.data.data.f_total;
        this.totalRecordsSecond = result.data.data.total;
        $("#addTimeModal").modal("hide");
      });
    },
    updateParamsSecond(newProps) {
      this.serverParamsSecond = Object.assign(
        {},
        this.serverParamsSecond,
        newProps
      );
    },
    onPageChangeSecond(params) {
      this.updateParamsSecond({ page: params.currentPage });
      this.get_update_attendance_request();
    },
    onPerPageChangeSecond(params) {
      this.updateParamsSecond({ perpage: params.currentPerPage });
      this.serverParamsSecond.page = 1;
      this.get_update_attendance_request();
    },
    onSortChangeSecond(params) {
      this.updateParamsSecond({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.get_update_attendance_request();
    },
    onColumnFilterSecond(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsSecond(newObj);
      this.serverParamsSecond.page = 1;
      this.get_update_attendance_request();
    },
    ApproveTime(data, action) {
      let this2 = this;
      if (action == "accept") {
        this.$swal({
          html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Accepting....</h3>
            </div>`,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen() {
            let time = 0;
            let time_id = setInterval(() => {
              time++;
            }, 1000);

            setTimeout(() => {
              if (time >= 15) {
                clearInterval(time_id);
                this.$swal({
                  title: "Slow internet connection",
                  icon: "error",
                  timer: 2500,
                });
              }
              if (navigator.onLine && time < 15) {
                var para = { id: data, action: action };
                // console.log(para);
                user
                  .UpdateAttendance(para)
                  .then((result) => {
                    if (result.data.code == 200) {
                      Swal.fire(
                        result.data.data,
                        result.data.message,
                        "success"
                      );
                      Swal.fire({
                        title: result.data.data,
                        text: result.data.message,
                        icon: "success",
                        timer: 2500,
                      }).then(() => {
                        this2.getAttendanceLogByDate();
                        this2.get_update_attendance_request();
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    Swal.fire({
                      title: "Something went wrong",
                      text: "Unable to perform the action",
                      icon: "error",
                      timer: 2500,
                    });
                  });
              } else {
                Swal.fire({
                  title: "No internet connection",
                  text: "Please check your internet connecction",
                  icon: "error",
                  timer: 2500,
                });
              }
            }, 1000);
          },
        });
      } else {
        this.$swal({
          html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Rejecting....</h3>
            </div>`,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen() {
            let time = 0;
            let time_id = setInterval(() => {
              time++;
            }, 1000);

            setTimeout(() => {
              if (time >= 15) {
                clearInterval(time_id);
                this.$swal({
                  title: "Slow internet connection",
                  icon: "error",
                  timer: 2500,
                });
              }
              if (navigator.onLine && time < 15) {
                var para = { id: data, action: action };
                user
                  .UpdateAttendance(para)
                  .then((result) => {
                    if (result.data.code == 200) {
                      Swal.fire({
                        title: result.data.data,
                        text: result.data.message,
                        icon: "success",
                        timer: 2500,
                      }).then(() => {
                        this2.getAttendanceLogByDate();
                        this2.get_update_attendance_request();
                      });
                      // Swal.fire(result.data.data, result.data.message, 'success')
                    }
                  })
                  .catch(() => {
                    Swal.fire({
                      title: "Something went wrong",
                      text: "Unable to perform the action",
                      icon: "error",
                      timer: 2500,
                    });
                  });
              } else {
                Swal.fire({
                  title: "No internet connection",
                  text: "Please check your internet connecction",
                  icon: "error",
                  timer: 2500,
                });
              }
            }, 1000);
          },
        });
      }
    },
    FilterApply() {
      this.serverParamsSecond.columnFilters = {};
      this.serverParamsSecond.page = 1;
      this.serverParamsSecond.perpage = 100;
      this.serverParamsSecond.sort = [
        {
          field: "",
          type: "asc",
        },
      ];
      this.get_update_attendance_request();
    },
    FilterApplyOnDate(val) {
      if (val) {
        this.reqObj.present_absent_filter = val;
      }
      this.getAttendanceLogByDate();
    },
    ChangeDate(val) {
      if (val == "pre") {
        let get_date = new Date(this.reqObj.searched_date);
        this.reqObj.searched_date = moment(
          new Date(get_date.setDate(get_date.getDate() - 1))
        ).format("yyyy-MM-DD");
      }
      if (val == "next") {
        let get_date = new Date(this.reqObj.searched_date);
        if (
          new Date().toISOString().slice(0, 10) >=
          moment(new Date(get_date.setDate(get_date.getDate() + 1))).format(
            "yyyy-MM-DD"
          )
        ) {
          let get_date = new Date(this.reqObj.searched_date);
          this.reqObj.searched_date = moment(
            new Date(get_date.setDate(get_date.getDate() + 1))
          ).format("yyyy-MM-DD");
        }
      }
      this.FilterApplyOnDate();
    },
    hideModal() {
      // this.$root.$emit("bv::hide::modal", "update-attendance-popup");
      $("#updateAttendanceModal").modal("hide");
    },
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}

::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
