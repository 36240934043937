<template>
  <!-- Begin Page Content -->
  <div class="container-fluid" style="min-height: 600px">

    <!-- Previous goal record start -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <div class="col">
      <h1 class="h3 mb-0 text-gray-800">Previous Goal </h1><span>{{reqObj.previousgoal}}</span>
      </div>
    </div> <br>

   <div class="d-sm-flex align-items-left">
      <div class="col-sm-2">
      <label for="goal complete">Complete goal percentage</label>
      </div>
      <div class="col-sm-2">
        <select class="form-control form-control-sm" v-model="reqObj.goal_percent" >
          <option value="">Select percentage</option>
          <option v-for="goal_percent in percentage" :key="goal_percent" > {{ goal_percent }}</option>
        </select>
      </div>
    </div> <br>

    <!-- if goal percent=100 then textbox is hide or if goal percent is not less than 100 then textbox is display for enter reason of incomplete goal -->
    <div class="d-sm-flex align-items-left" v-if="reqObj.goal_percent!='100'">
      <div class="col-sm">
      <textarea id="textarea-formatter" rows="2" wrap="soft" class="form-control form-control-sm" v-model="reqObj.reasonof_previousgoal" placeholder="Reason of incomplete goal" @input="reqObj.reasonof_previousgoalerror=''"></textarea>

      <span class="text-danger">{{reqObj.reasonof_previousgoalerror}}</span>

      </div>
      </div>
      <br>
      <div class="text-right">
      <a
      @click="updatePreviousgoal()"
        class="startbuttons
          d-sm-inline-block
          btn btn-sm btn-primary
          shadow-sm
        "
      > <i class="fas fa-sm text-white-50"></i> Submit</a>
    </div><br> <br>

    <!-- previous goal record end -->

    <!-- Todays goal start-->

    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <div class="col">
        <h1 class="h3 mb-0 text-gray-800">Today's Goal</h1>
      </div>
    </div>

      <div class="col-sm">
        <textarea rows="2" wrap="soft" class="form-control form-control-sm" v-model="reqObj.todaysgoal" placeholder="Enter your Goal for the day !" @input="reqObj.todaysgoalerror=''"></textarea>
          <span class="text-danger">{{ reqObj.todaysgoalerror }}</span>
      </div><br>
        <div class="text-right">
          <a
            @click="updateTodaysgoal()"
            class="
            startbuttons
            d-sm-inline-block
            btn btn-sm btn-primary
            shadow-sm
            "
          ><i class="fas fa-sm text-white-50"></i> Submit</a>
        </div>
  <!-- end of todays goal -->
    <div v-show="currentUser.state == 'work'"></div>
  </div>
  <!-- End of Main Content -->
</template>
<script>

import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import axios from 'axios';
import myMixin from '@/mixins/Permission'


export default {
  name: "Todaysgoal",
  mixins: [myMixin],
  data: function () {
    return {
      reqObj: {
        previousgoal:"",
        todaysgoal: "",
        todaysgoalerror: "",
        reasonof_previousgoal: "",
        reasonof_previousgoalerror: "",
        goal_percent: "",
      },
      percentage:['10','20','30','40','50','60','70','80','90','100']
    };
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

   getPreviousGoal() {
      user.getPreviousGoal(this.reqObj).then((result) => {
        // console.log(result.data);
        const resultData=result.data.data;
        this.reqObj.previousgoal = resultData.data.goal;
        this.reqObj.reasonof_previousgoal = resultData.data.reasonof_incomplete_goal;
        this.reqObj.goal_percent = resultData.data.goal_complete;
      });
    },
    updatePreviousgoal() {
      user.updatePreviousgoal(this.reqObj).then((result) => {
        if (this.reqObj.reasonof_previousgoal=="") {
          this.reqObj.reasonof_previousgoalerror="Field is required"
        }
        if(result.data.code == '200') {
          this.getPreviousGoal();
          alert("Submited successfully")
        }
        else{
          alert("You don't have any previous goal");
        }
      });
    },

    getTodaysgoal() {
      user.getTodaysgoal(this.reqObj).then((result) => {
        const resultData=result.data.data;
        this.reqObj.todaysgoal=resultData.data.goal;
      });
    },
    updateTodaysgoal() {
      user.updateTodaysgoal(this.reqObj).then((result) => {
        // console.log("this.reqObj.todaysgoal",this.reqObj.todaysgoal);
        if (this.reqObj.todaysgoal=="") {
          this.reqObj.todaysgoalerror="Field is required"
        }
        else {
          alert("Goal created successfully")
        }
      });
    },
    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        //console.log(result.data)
        //set user in store
        this.getTodaysgoal();
        this.getPreviousGoal();
      });
    },
    logout() {
      this.logoutUser();
      this.$nextTick(() => {
        this.getCurrentUser();
      });
    },
  },
  beforeMount() {
    this.setCurrentPage("Todaysgoal");
  },
  mounted() {
    this.getUserPermissions();
    this.getPreviousGoal();
    this.getCurrentUser();

    // console.log("2");
    // console.log(this.$store);
    // console.log("3");
    // console.log(this.$store.state);
    // console.log("4");
    // console.log(this.$store.state.userStore);
    //  console.log("5");
    // console.log(this.$store.state.userStore.currentUser);
    // console.log("6");
    // console.log(this.$store.getters['userStore/getUser']);
    // console.log(this.getCurrentUser);
    // console.log("user");
        // $(".fl-flex-label").flexLabel();
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field{
      width: 400px;
}
</style>
