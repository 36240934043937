<template>
     <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">
            <div class=" col-lg-6 col-md-6">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Hello <b v-text="reqObj.first_name"></b></h1><hr>
                                        <p>Set new password</p>
                                    </div>
                                    <form class="user">
                                        <div class="form-group">
                                            <input v-model="reqObj.password" type="password" class="form-control form-control-user"
                                                id="exampleInputPassword" placeholder="Password" >
                                            <p style="color : #FF0000;">{{passwordError}}</p>
                                        </div>
                                        <div class="form-group">
                                            <input v-model="reqObj.confirmpassword" type="password" class="form-control form-control-user"
                                                id="exampleInputConfirmPassword" placeholder="Confirm Password" >
                                            <p style="color : #FF0000;">{{confirmpasswordError}}</p>
                                            <p class="text-gray-900">{{validationError}}</p>
                                        </div>
                                        <a @click="addnewpassword" class="btn btn-primary btn-user btn-block">
                                            Submit
                                        </a>
                                        <hr>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import user from '@/ajax/user'
import { mapActions } from 'vuex'
import axios from '@/ajax/axios'
import {useRoute} from 'vue-router'
export default {
  name: 'App',
  data: function () {
    return {
      reqObj: {
        password: '',
        confirmpassword:'',
        first_name : '',
        user_id:'',
        time_stamp:'',
      },
      passwordError:'',
      confirmpasswordError:'',
      validationError:'',
    }
  },
  mounted () {
    // this.testMethod()
    // this.testMethodWithoutAsync()
    this.CheckTokenIsValidOrNot();
    this.getUserInfo();
  },
  methods: {
    ...mapActions('userStore', ['setCurrentUser', 'setAccessToken']),
    addnewpassword() {
      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      if (this.reqObj.password == ''){
          this.passwordError = "please enter new password"
      }
      else if(this.reqObj.confirmpassword == ''){
          this.confirmpasswordError = "please enter confirm password"
      }
      else if(this.reqObj.password !== this.reqObj.confirmpassword){
          alert("Password Are Not Match")
      }else if(!regex.test(this.reqObj.password)){
          this.validationError = "Please add uppercase,lowercase,special character,digits,minimum length is 6."
      }else{
            this.reqObj.time_stamp = this.$route.query.token
            user.addnewpassword(this.reqObj).then((result) => {
            var actualResult=result.data
            var resultCode=actualResult.code
            var resultData=actualResult.data;
            //failure in token response //e.x incorrect username and password
            if(resultCode == 200)
            {
                alert(JSON.stringify(actualResult.message))
                this.$router.push({name: 'Login'});
            }
            // else{
            //     alert(JSON.stringify(actualResult.message))
            // }
          })
      }
    },
    CheckTokenIsValidOrNot(){
        if(this.$route.query.token_valid == "False"){
            alert('Your token is invalid')
            this.$router.push({name: 'Login'});
        }
        if(this.$route.query.token == null){
            this.$router.push({name: 'Login'});
        }
    },
    getUserInfo() {
      user.getUserInfo(this.$route.query.token).then((result) => {
        // console.log(result.data);
        this.reqObj.first_name = result.data.first_name
      });
    },
  }
}
</script>
