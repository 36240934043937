<template>
  <div class="m">
    <div v-if="currentUser.user_role == 1">
      <div class="go-back my-3">
        <router-link tag="a" to="/invoice"
          ><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Go
          Back</router-link
        >
      </div>
      <!-- Invoice Form -->
      <div class="card shadow-sm border-0 mb-4">
        <div class="card-body" :class="disable_invoice ? 'bg-light' : ''">
          <h4 class="text-primary" v-text="title"></h4>
          <div
            class="d-flex justify-content-center align-items-center p-5"
            v-if="asset_data == null && add_invoice == false"
          >
            <span class="spinner-border text-primary"></span>
          </div>
          <div class="pt-3" v-else>
            <form @submit.prevent="submitInvoice">
              <div class="row gy-2">
                <div class="col-md-6">
                  <label class="form-label mandatory">Invoice Number</label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="invoiceObj.invoice_no"
                    :disabled="disable_invoice"
                    required
                    placeholder="Enter the invoice number"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Invoice Date</label>
                  <flat-pickr
                    class="form-control"
                    v-model="invoiceObj.invoice_date"
                    :class="disable_invoice ? 'bg-light' : 'bg-white'"
                    modelValue=""
                    :disabled="disable_invoice"
                    placeholder="Enter the invoice date"
                    @on-change="inputDate($event, 'invoice_date')"
                    ref="invoiceDatePickr"
                  ></flat-pickr>
                  <small
                    class="text-danger"
                    v-text="error_msg.invoice_date_msg"
                  ></small>
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Due Date</label>
                  <flat-pickr
                    class="form-control"
                    v-model="invoiceObj.due_date"
                    :class="disable_invoice ? 'bg-light' : 'bg-white'"
                    modelValue=""
                    :disabled="disable_invoice"
                    placeholder="Enter the due date"
                    @on-change="inputDate($event, 'due_date')"
                    ref="dueDatePickr"
                  ></flat-pickr>
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Payment Method</label>
                  <input
                    v-model="invoiceObj.payment_method"
                    class="form-control"
                    :disabled="disable_invoice"
                    type="text"
                    placeholder="Enter the payment method"
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Vendor</label>
                  <multiselect
                    :options="vendors"
                    v-model="invoiceObj.vendor_id"
                    track-by="id"
                    label="name"
                    :disabled="disable_invoice"
                    placeholder="Select the vendor"
                    required
                  ></multiselect>
                  <small
                    class="text-danger"
                    v-text="error_msg.vendor_msg"
                  ></small>
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Price</label>
                  <input
                    v-model.number="invoiceObj.price"
                    min="0"
                    @keypress="isNumber"
                    class="form-control"
                    :disabled="disable_invoice"
                    type="number"
                    placeholder="Enter the price"
                    required
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">GST / IGST</label>
                  <input
                    v-model.number="invoiceObj.GST"
                    @keypress="isNumber"
                    class="form-control"
                    type="number"
                    :disabled="disable_invoice"
                    placeholder="Enter the GST/IGST"
                    required
                  />
                </div>
                <div class="col-md-6">
                  <label class="form-label mandatory">Transaction id</label>
                  <input
                    v-model.number="invoiceObj.transaction_id"
                    class="form-control"
                    type="text"
                    :disabled="disable_invoice"
                    placeholder="Enter the Transaction id"
                  />
                </div>
                <div class="col-md-6">
                  <input
                    class="form-check-input me-2"
                    style="scale: 1"
                    :disabled="disable_invoice"
                    v-model="invoiceObj.paid"
                    type="checkbox"
                  />
                  <label for="remember_me" class="form-check-label">Paid</label>
                </div>
              </div>
              <div
                class="d-flex justify-content-end align-items-center gap-2"
                v-if="!disable_invoice"
              >
                <button
                  class="btn btn-primary btn-sm"
                  type="submit"
                  v-if="invoice_id == null"
                >
                  Save
                </button>
                <button class="btn btn-primary btn-sm" type="submit" v-else>
                  Update
                </button>
                <router-link
                  tag="button"
                  :to="{ name: 'Invoice' }"
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                >
                  Cancel
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Asset From -->
      <div class="card border-0 shadow-sm my-4" v-if="invoice_id">
        <div class="card-body">
          <h3 class="m-0 text-primary mb-4">Assets</h3>
          <div
            class="d-flex justify-content-center align-items-center p-5"
            v-if="asset_data == null && add_invoice == false"
          >
            <div
              class="spinner-border text-primary"
              style="height: 4rem; width: 4rem"
            ></div>
          </div>
          <form @submit.prevent="submitAssets" v-else>
            <div class="">
              <div
                class="mb-3"
                v-for="(item, index) in asset_data"
                :key="index"
              >
                <div class="rounded-3 border px-3 py-3">
                  <small
                    v-if="Object.keys(item).includes('cat_error_msg')"
                    class="text-danger d-block px-3 text-right"
                    v-text="item.cat_error_msg"
                  ></small>
                  <div
                    class="d-flex justify-content-end align-items-center mb-2 p-0 row"
                  >
                    <div class="col-md-3 col-12">
                      <multiselect
                        :options="category"
                        track="id"
                        v-model="item.asset_cat_id"
                        label="name"
                        @select="categorySelectHandler($event, item)"
                      ></multiselect>
                    </div>
                  </div>
                  <div
                    class="row mb-3 no-gutters"
                    v-for="(field, index) in item.fields"
                    :key="index"
                  >
                    <div class="col-md-2 col-12">
                      <h6
                        class="m-0 text-dark pt-2 mb-md-0 mb-2"
                        v-text="field.field_name"
                        :class="field.is_mandatory ? 'mandatory' : ''"
                        v-if="field.is_active"
                      ></h6>
                    </div>
                    <div class="col-md-4 col-12">
                      <!-- For str -->
                      <input
                        :readonly="field.field_name == 'Prefix'"
                        class="form-control"
                        type="text"
                        :required="field.is_mandatory"
                        v-model="field.default_value"
                        :placeholder="'Enter the ' + field.field_name"
                        v-if="field.field_type == 'str' && field.is_active"
                        :maxlength="field.max_chars"
                      />
                      <!-- For int -->
                      <input
                        class="form-control"
                        type="number"
                        :required="field.is_mandatory"
                        v-model.number="field.default_value"
                        :placeholder="'Enter the ' + field.field_name"
                        v-if="field.field_type == 'int' && field.is_active"
                        :max="field.max_limit"
                        :min="field.min_limit"
                        @keypress="isNumber"
                      />
                      <!-- For float -->
                      <input
                        class="form-control"
                        type="number"
                        :required="field.is_mandatory"
                        v-model.number="field.default_value"
                        :placeholder="'Enter the ' + field.field_name"
                        v-if="field.field_type == 'float' && field.is_active"
                        step="0.5"
                        :max="field.max_limit"
                        :min="field.min_limit"
                        @keypress="isNumber"
                      />
                      <!-- For bool -->
                      <input
                        type="checkbox"
                        v-model="field.default_value"
                        class="mt-2 ml-1"
                        :required="field.is_mandatory"
                        style="scale: 1.3"
                        v-if="field.field_type == 'bool' && field.is_active"
                      />
                      <!-- For singleselect -->
                      <multiselect
                        :options="field.options"
                        v-if="
                          field.field_type == 'singleselect dropdown' &&
                          field.is_active
                        "
                        :placeholder="'Enter the ' + field.field_name"
                        v-model="field.default_value"
                      ></multiselect>
                      <small
                        class="text-danger m-0"
                        v-if="
                          field.field_type == 'singleselect dropdown' &&
                          field.is_active
                        "
                        v-text="field.error_msg"
                      ></small>
                      <!-- For multiselect -->
                      <multiselect
                        :options="field.options"
                        :multiple="true"
                        v-if="
                          field.field_type == 'multiselect dropdown' &&
                          field.is_active
                        "
                        :placeholder="'Enter the ' + field.field_name"
                        :close-on-select="false"
                        v-model="field.default_value"
                      ></multiselect>
                      <small
                        class="text-danger"
                        v-if="
                          field.field_type == 'multiselect dropdown' &&
                          field.is_active
                        "
                        v-text="field.error_msg"
                      ></small>
                      <!-- For single date -->
                      <!-- <small v-if="field.field_type == 'single date' && field.is_active" v-text="field.option_error_msg"></small> -->
                      <flat-pickr
                        :config="field.config"
                        v-model="field.default_value"
                        modelValue=""
                        class="form-control bg-white"
                        v-if="
                          field.field_type == 'single date' && field.is_active
                        "
                        :placeholder="'Enter the ' + field.field_name"
                        @on-change="dateHandler($event, field)"
                      ></flat-pickr>
                      <small
                        class="text-danger"
                        v-if="
                          field.field_type == 'single date' && field.is_active
                        "
                        v-text="field.error_msg"
                      ></small>
                      <!-- For range date -->
                      <flat-pickr
                        :config="field.config"
                        v-model="field.default_value"
                        modelValue=""
                        class="form-control bg-white"
                        @on-change="dateHandler($event, field)"
                        v-if="
                          field.field_type == 'range date' && field.is_active
                        "
                        :placeholder="'Enter the ' + field.field_name"
                      ></flat-pickr>
                      <small
                        class="text-danger"
                        v-if="
                          field.field_type == 'range date' && field.is_active
                        "
                        v-text="field.error_msg"
                      ></small>
                    </div>
                  </div>
                  <button
                    class="btn btn-danger btn-sm d-block ms-auto"
                    type="button"
                    v-if="invoice_id && item.remove"
                    @click="removeAsset(index)"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <button
                class="btn btn-primary ms-md-auto mx-md-0 mx-auto mb-md-0 mb-2 d-block btn-sm"
                type="button"
                @click="addAsset"
              >
                Add Asset
              </button>
            </div>
            <div
              class="d-flex justify-content-md-start justify-content-center align-items-center px-2 gap-2"
              v-if="asset_data.length > 0"
            >
              <button
                class="btn btn-primary btn-sm"
                type="submit"
                style="width: 6rem"
                v-if="add_invoice"
              >
                Save
              </button>
              <button
                class="btn btn-primary btn-sm"
                type="submit"
                style="width: 6rem"
                v-else
              >
                Update
              </button>
              <router-link
                tag="button"
                :to="{ name: 'Invoice' }"
                class="btn btn-outline-secondary btn-sm"
                type="button"
                style="width: 6rem"
              >
                Cancel
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized access</h2>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import Multiselect from "vue-multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueGoodTable from "vue-good-table";
Vue.use(VueGoodTable);
import moment from "moment";
import { mapState, mapActions } from "vuex";
// import { Swal } from "sweetalert2/dist/sweetalert2";
export default {
  props: ["id"],
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    // this.listVendors();
    // this.getAssetCategory();
    // this.add_invoice=true;
    //   if (this.id) {
    //     this.add_invoice=false;
    //     this.invoice_id = this.id;
    //   }
    this.listVendors()
      .then((res) => this.getAssetCategory())
      .then((res) => {
        this.add_invoice = true;
        if (this.id) {
          this.add_invoice = false;
          this.invoice_id = this.id;
        }
      });
  },
  components: {
    flatPickr,
    Multiselect,
  },
  data() {
    return {
      title: "Add Invoice",
      field_list: [
        "int",
        "float",
        "str",
        "bool",
        "single date",
        "range date",
        "singleselect dropdown",
        "multiselect dropdown",
      ],
      invoice_id: null,
      reqObj: {
        invoice_id: null,
        assets: [],
      },
      add_invoice: false,
      asset_obj: {
        asset_cat_id: null,
        model_no: null,
        serial_no: null,
        name: null,
        description: null,
        price: null,
        prefix: null,
        fields: [],
      },
      invoiceObj: {
        invoice_no: null,
        invoice_date: null,
        due_date: null,
        price: null,
        GST: null,
        transaction_id: null,
        vendor_id: null,
        payment_method: null,
        paid: false,
      },
      error_msg: {
        due_date_msg: "",
        invoice_date_msg: "",
        vendor_msg: "",
      },
      vendors: [],
      asset_data: null,
      fields_error_msg: "",
      category: [],
      selected_category: null,
      disable_invoice: false,
    };
  },
  watch: {
    invoice_id(nv, ov) {
      if (nv && this.add_invoice == false) {
        this.disable_invoice = false;
        this.title = "Update Invoice";
        this.getInvoiceById(nv);
      } else {
        this.disable_invoice = true;
        this.title = "Add Invoice";
        this.getInvoiceById(nv);
      }
    },
  },
  methods: {
    getInvoiceById(id) {
      user.getInvoiceById(id).then((res) => {
        this.invoiceObj = Object.assign(this.invoiceObj, res.data.data.invoice);
        this.asset_data = res.data.data.assets;
        const vendor = this.vendors.find(
          (item) => item.id === this.invoiceObj["vendor_id"]
        );
        this.invoiceObj["vendor_id"] = vendor;

        this.asset_data.map((item) => {
          let new_fields = [
            {
              field_name: "Prefix",
              field_type: "str",
              default_value: item.prefix,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Name",
              field_type: "str",
              default_value: item.name,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Model",
              default_value: item.model,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Serial No.",
              default_value: item.serial_no,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Status",
              default_value: item.status,
              field_type: "singleselect dropdown",
              options: ["ACTIVE", "INACTIVE", "STOCK", "SOLD"],
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Warranty",
              default_value: item.warranty,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Is Insured",
              default_value: item.is_insured,
              field_type: "bool",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Insurance Date",
              default_value: item.insurance_date,
              field_type: "single date",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Purchase Date",
              default_value: item.purchase_date,
              field_type: "single date",
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Description",
              default_value: item.description,
              field_type: "str",
              max_chars: 1000,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Price",
              default_value: item.price,
              field_type: "int",
              min_limit: 0,
              is_active: true,
              is_mandatory: false,
            },
          ];
          item.fields.unshift(...new_fields);
          item.fields.map((obj) => {
            if (obj.field_type == "range date") {
              obj.config.enable = [
                {
                  from: obj.start_date,
                  to: obj.end_date,
                },
              ];
            }
          });
          item.asset_cat_id = this.category.find((cat) => {
            return cat.id === item.asset_cat_id;
          });
        });
      });
    },
    dateHandler(event, item) {
      item.default_value = moment(event[0]).format("YYYY-MM-DD");
    },
    inputDate(event, key) {
      this.invoiceObj[key] = event[0];
      this.invoiceObj.invoice_date = moment(
        this.invoiceObj.invoice_date
      ).format("YYYY-MM-DD");
      if (this.invoiceObj.invoice_date && this.invoiceObj.due_date) {
        this.invoiceObj.due_date = moment(this.invoiceObj.due_date).format(
          "YYYY-MM-DD"
        );
        if (
          moment(this.invoiceObj.due_date).isSameOrBefore(
            moment(this.invoiceObj.invoice_date)
          )
        ) {
          // console.log("triggered");
          this.invoiceObj.due_date = this.invoiceObj.invoice_date;
          this.$refs.dueDatePickr.fp.setDate(this.invoiceObj.due_date);
        }
      }
      this.$forceUpdate();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    listVendors() {
      return user.listVendors().then((res) => {
        this.vendors = [];
        this.vendors = res.data.data;
      });
    },
    assetCategoryValidator(assets) {
      let validate = true;
      for (let i = 0; i < assets.length; i++) {
        // To break the main loop using subloop
        let break_loop = false;
        // To check for category selected or not
        if (!assets[i].asset_cat_id) {
          assets[i].cat_error_msg = "*** Please select the category ***";
          validate = false;
          this.$forceUpdate();
          break;
        } else {
          assets[i].cat_error_msg = "";
          this.$forceUpdate();
        }
        // To check for single,multiselect dropdown and single and range date validation
        let temp_fields = assets[i]["fields"];
        for (let j = 0; j < temp_fields.length; j++) {
          if (temp_fields[j].is_active && temp_fields[j].is_mandatory) {
            if (
              temp_fields[j].default_value == null &&
              (temp_fields[j].field_type == "singleselect dropdown" ||
                temp_fields[j].field_type == "multiselect dropdown")
            ) {
              temp_fields[j].error_msg = "Please select option";
              break_loop = true;
              break;
            } else {
              temp_fields[j].error_msg = "";
              break_loop = false;
            }

            if (
              temp_fields[j].default_value == null &&
              (temp_fields[j].field_type == "single date" ||
                temp_fields[j].field_type == "range date")
            ) {
              temp_fields[j].error_msg = "Please select date";
              break_loop = true;
              break;
            } else {
              temp_fields[j].error_msg = "";
              break_loop = false;
            }
          }
        }
        // To break the main loop also after the error in added assets
        if (break_loop) {
          validate = false;
          this.$forceUpdate();
          break;
        }
      }
      return validate;
    },
    categoryFieldsFormatter(assets) {
      let temp = JSON.parse(JSON.stringify(assets));
      temp.forEach((item) => {
        // To make entries of data in asset_obj
        let slice_amount = item.remove ? 10 : 11;
        if (item.remove) {
          item.name = item.fields[0].default_value;
          item.model = item.fields[1].default_value;
          item.serial_no = item.fields[2].default_value;
          item.status = item.fields[3].default_value;
          item.warranty = item.fields[4].default_value;
          item.is_insured = item.fields[5].default_value;
          item.insurance_date = item.fields[6].default_value;
          item.purchase_date = item.fields[7].default_value;
          item.description = item.fields[8].default_value;
          item.price = Number(item.fields[9].default_value);
          // item.asset_cat_id = item.asset_cat_id.id;
          item.fields = item.fields.slice(slice_amount);
          delete item.remove;
        } else {
          item.prefix = item.fields[0].default_value;
          item.name = item.fields[1].default_value;
          item.model = item.fields[2].default_value;
          item.serial_no = item.fields[3].default_value;
          item.status = item.fields[4].default_value;
          item.warranty = item.fields[5].default_value;
          item.is_insured = item.fields[6].default_value;
          item.insurance_date = item.fields[7].default_value;
          item.purchase_date = item.fields[8].default_value;
          item.description = item.fields[9].default_value;
          item.price = Number(item.fields[10].default_value);
          // item.asset_cat_id = item.asset_cat_id.id;
          item.fields = item.fields.slice(slice_amount);
        }
        item.asset_cat_id = item.asset_cat_id.id;
        delete item.cat_error_msg;
        // console.log(item);
      });
      return temp;
    },
    submitAssets() {
      this.reqObj.invoice_id = Number(this.invoice_id);
      let check = this.assetCategoryValidator(this.asset_data);
      if (check) {
        this.reqObj.assets = this.categoryFieldsFormatter(this.asset_data);
        if (this.invoice_id && this.add_invoice == false) {
          user
            .updateInvoiceAssets(this.reqObj)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.$router.push({ name: "Invoice" });
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        } else {
          user
            .addAssets(this.reqObj)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.$router.push({ name: "Invoice" });
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      }
    },
    submitInvoice() {
      if (!this.invoiceObj.invoice_date) {
        this.error_msg.invoice_date_msg = "** Value cannot be empty **";
        return;
      } else {
        this.error_msg.invoice_date_msg = "";
      }
      if (!this.invoiceObj.vendor_id) {
        this.error_msg.vendor_msg = "** Please select vendor **";
        return;
      } else {
        this.error_msg.vendor_msg = "";
      }

      let tempObj = Object.assign({}, this.invoiceObj, {
        vendor_id: this.invoiceObj.vendor_id.id,
      });

      if (this.invoice_id) {
        user
          .updateInvoice(tempObj)
          .then((res) => {
            Swal.fire({
              title: res.data.message,
              icon: "success",
            });
            this.$router.push({ name: "Invoice" });
          })
          .catch((err) => {
            Swal.fire({
              title: err.data.detail,
              icon: "error",
            });
          });
      } else {
        user
          .addInvoice(tempObj)
          .then((res) => {
            this.disable_invoice = true;
            this.invoice_id = res.data.data.invoice_id;
            Swal.fire({
              title: res.data.message,
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              title: err.data.detail,
              icon: "error",
            });
          });
      }
    },
    removeAsset(index) {
      this.asset_data.splice(index, 1);
    },
    categorySelectHandler(event, item) {
      item.fields = JSON.parse(JSON.stringify(event.fields));
      // item.invoice_id=this.invoice_id;
      let new_fields = [
        {
          field_name: "Name",
          field_type: "str",
          default_value: "",
          max_chars: 1000,
          is_active: true,
          is_mandatory: true,
        },
        {
          field_name: "Model",
          default_value: "",
          field_type: "str",
          max_chars: 255,
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Serial No.",
          default_value: "",
          field_type: "str",
          max_chars: 255,
          is_active: true,
          is_mandatory: true,
        },
        {
          field_name: "Status",
          default_value: null,
          field_type: "singleselect dropdown",
          options: ["ACTIVE", "INACTIVE", "STOCK", "SOLD"],
          max_chars: 255,
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Warranty",
          default_value: null,
          field_type: "str",
          max_chars: 255,
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Is Insured",
          default_value: null,
          field_type: "bool",
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Insurance Date",
          default_value: null,
          field_type: "single date",
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Purchase Date",
          default_value: null,
          field_type: "single date",
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Description",
          default_value: "",
          field_type: "str",
          max_chars: 1000,
          is_active: true,
          is_mandatory: false,
        },
        {
          field_name: "Price",
          default_value: "",
          field_type: "int",
          min_limit: 0,
          is_active: true,
          is_mandatory: false,
        },
      ];
      item.fields.unshift(...new_fields);
      item.fields.map((obj) => {
        if (obj.field_type == "range date") {
          obj.config.enable = [
            {
              from: obj.start_date,
              to: obj.end_date,
            },
          ];
        }
      });
      this.$forceUpdate();
    },
    addAsset() {
      let item = Object.assign({}, this.asset_obj, { remove: true });
      this.asset_data.push(item);
    },
    getAssetCategory() {
      return user.getAssetCategoryList().then((res) => {
        this.category = [];
        this.category = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.title {
  color: black;
}

.check-input {
  scale: 1.3;
  margin-top: 10px;
}

.asset-card {
  /* border-radius: 8px; */
}

.mandatory:after {
  content: "*";
  padding: 0px 2px;
  color: red;
}
</style>
