<template>
  <div v-if="permissions.includes('leaves.write')">
    <div class="card shadow-sm border-0 my-4">
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-between flex-md-nowrap flex-wrap gap-md-0 gap-2 px-2 pb-3"
        >
          <h4 class="m-0 font-weight-bold text-primary order-md-0 order-1">
            Leaves
          </h4>
          <div
            class="order-3 order-md-0 mt-md-0 mt-2 mx-auto px-md-0 px-2"
            v-if="
              permissions.includes('users.read') ||
              permissions.includes('users.enable')
            "
          >
            <div class="btn-group" role="group">
              <router-link
                :to="{ name: 'LeavesApplication' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesApplication'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves.read')"
              >
                Leaves
              </router-link>
              <router-link
                :to="{ name: 'AddCreditDebit' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'AddCreditDebit'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('credit_debit.read')"
              >
                Credit/Debit
              </router-link>
              <router-link
                :to="{ name: 'LeavesStatus' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesStatus'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves_status.report')"
              >
                Status
              </router-link>
            </div>
          </div>
          <router-link
            class="btn btn-primary btn-sm order-md-0 order-2"
            :to="{ name: 'AddLeaveApplication' }"
            v-if="
              permissions.includes('leaves.read') &&
              permissions.includes('leaves.add')
            "
          >
            Apply
          </router-link>
        </div>
        <!-- Credit debit form -->
        <div class="px-2">
          <credit-debit-entry
            @fetch-data="getCreditDebitEntries"
          ></credit-debit-entry>
        </div>
      </div>
    </div>

    <!-- Credit debit table -->
    <div class="card border-0 shadow-sm my-4">
      <div class="card-body border-0">
        <div
          class="d-flex justify-content-between align-items-center px-2 pb-3 flex-wrap"
        >
          <h4 class="text-primary m-0">Credit/Debit Entries</h4>
          <div
            class="d-flex justify-content-between align-items-center flex-md-row flex-column gap-2 col-lg-7"
          >
            <datepicker
              :minimumView="'month'"
              :maximumView="'year'"
              v-model="filterObj.date"
              :format="'yyyy-MM'"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0'"
              wrapper-class="col-4"
              :calendar-button-icon="'fa fa-calendar'"
              placeholder="Select month"
              :bootstrap-styling="true"
              @input="dateHandler"
            >
            </datepicker>
            <multiselect
              v-model="filterObj.user_ids"
              :options="users"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Select Multiple User"
              label="first_name"
              :custom-label="custom_label"
              track-by="id"
              group-label="label"
              group-values="users_data"
              :group-select="true"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>
            <multiselect
              v-model="filterObj.leave_type"
              :options="leave_type"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Leave Type"
              label="name"
              track-by="id"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>
            <button class="btn btn-primary" @click="getCreditDebitEntries">
              Filter
            </button>
          </div>
        </div>
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :columns="credit_debit_column"
          :rows="credit_debit_rows"
          :pagination-options="{
            enabled: true,
            perPageDropdown: perpage_list,
          }"
          styleClass="vgt-table no-border"
        >
          <template slot="table-row" scope="props">
            <div
              v-if="props.column.label == 'Action'"
              class="d-flex justify-content-center align-items-center gap-3 p-2"
            >
              <button
                class="btn-pencil"
                @click="creditDebitModalHandler(props.row)"
              >
                <i class="fa fa-pencil fs-5"></i>
              </button>
              <button
                class="btn btn-trash"
                @click="deleteCreditDebitEntry(props.row.id)"
              >
                <i class="fa fa-trash fs-5"></i>
              </button>
            </div>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <CustomPagination
              class="p-2"
              :pageTabs="4"
              :total="props.total"
              :pageChanged="props.pageChanged"
              :perPageChanged="props.perPageChanged"
              :serverParams="serverParams"
            ></CustomPagination>
          </template>
        </vue-good-table>
      </div>
    </div>
    <div
      class="modal fade"
      id="creditDebitModal"
      role="dialog"
      aria-hidden="true"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="text-dark font-weight-bold m-0"
              id="taskModalLongTitle"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="overflow-y: auto">
            <credit-debit-entry
              :cdObj="cdObj"
              @close-modal="hideModalEventHandler"
              @fetch-data="getCreditDebitEntries"
            ></credit-debit-entry>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <UnauthorizedPage></UnauthorizedPage>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import CreditDebitEntry from "./CreditDebitEntry.vue";
import CustomPagination from "../../components/CustomPagination.vue";

const original_date = new Date();

export default {
  name: "AddLeave",
  components: {
    Multiselect,
    Datepicker,
    UnauthorizedPage,
    CreditDebitEntry,
    CustomPagination,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "credit_debit_leaves",
      },
      filterObj: {
        date: moment().format("YYYY-MM"),
        user_ids: [],
        leave_type: null,
      },
      credit_debit_column: [
        {
          label: "User",
          field: "full_name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Date",
          field: "date",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Leave Type",
          field: "name",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "No of days",
          field: "no_of_days",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Credit",
          field: "credit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Debit",
          field: "debit",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      credit_debit_rows: [],
      isDisabled: false,
      isTouched: false,
      users: [],
      permissions: window.localStorage.getItem("permissions"),
      totalRecords: 0,
      isLoading: false,
      serverParams: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500],
      modal_title: null,
      cdObj: null,
      users: [],
      leave_type: [],
    };
  },
  mounted() {
    $("#creditDebitModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("leaves.write")) {
      this.LeaveTypeDropdown();
      this.getuseridDropdown();
      this.getCreditDebitEntries();
      // this.date_range_set();
      // this.leave.no_of_days = this.no_of_days[0]
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser"]),
    LeaveTypeDropdown() {
      user.getLeaveType().then((result) => {
        this.leave_type = result.data.data;
      });
    },
    deleteCreditDebitEntry(id) {
      user
        .delete_credit_debit_entries(id)
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
            });
            this.getCreditDebitEntries();
          } else {
            Swal.fire({
              title: "Error",
              text: "Something Went Wrong",
              icon: "error",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Error",
            text: "Something Went Wrong",
            icon: "error",
          });
        });
    },
    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        this.setCurrentUser(result.data);
        // this.leave.user_id = result.data.id
      });
    },
    hideModalEventHandler() {
      this.modal_title = null;
      this.cdObj = null;
      $("#creditDebitModal").modal("hide");
    },
    creditDebitModalHandler(item) {
      this.modal_title = "Update Credit/Debit Entry";
      this.cdObj = item;
      $("#creditDebitModal").modal("show");
    },
    dateHandler(event) {
      this.filterObj.date = moment(event).format("yyyy-MM");
      // this.getCreditDebitEntries();
    },
    getCreditDebitEntries() {
      this.serverParams.filters = Object.assign(
        this.serverParams.filters,
        this.filterObj,
        {
          user_ids: this.filterObj.user_ids.map((item) => item.id),
          leave_type: this.filterObj.leave_type
            ? this.filterObj.leave_type.id
            : null,
        }
      );
      user.get_leave_credit_debit_entries(this.serverParams).then((result) => {
        this.credit_debit_rows.length = 0;
        this.credit_debit_rows = result.data.data.data;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getCreditDebitEntries();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.serverParams.page = 1;
      this.getCreditDebitEntries();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.getCreditDebitEntries();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.getCreditDebitEntries();
    },
    date_range_set() {
      if (original_date.getDate() > 3) {
        this.state.to = new Date(
          original_date.getFullYear(),
          original_date.getMonth(),
          3
        );
        this.state.from = new Date(
          original_date.getFullYear(),
          original_date.getMonth() + 1,
          4
        );
      } else {
        this.state.to = new Date(
          original_date.getFullYear(),
          original_date.getMonth() - 1,
          3
        );
        this.state.from = new Date(
          original_date.getFullYear(),
          original_date.getMonth(),
          4
        );
      }
    },
    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = [{ label: "Select all", users_data: result.data.data }];
      });
    },
    custom_label(name) {
      // console.log("-----",name.first_name)
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
label {
  padding-top: 8px;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}

::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
