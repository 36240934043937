<template>
  <!-- Begin Page Content -->
  <div class="">
    <div
      class="card border-0 shadow-sm my-4"
      v-if="permissions.includes('users.enable')"
    >
      <div class="card-body">
        <div
          class="d-flex flex-md-row flex-column align-items-center justify-content-between gap-2 px-2 pb-3"
        >
          <h4 class="m-0 font-weight-bold text-primary">Users</h4>
          <!-- <router-link
            class="startbuttons d-none d-sm-inline-block m-0 float-right btn btn-sm btn-success shadow-sm btn-sm"
            :to="'/add-user'"
            v-if="permissions.includes('users.write') && permissions.includes('users.add')"
          >
            Add User
          </router-link> -->
          <div
            class="btn-group"
            role="group"
            style="width: 15rem"
            v-if="
              permissions.includes('users.read') &&
              permissions.includes('users.enable')
            "
          >
            <router-link
              :to="{ name: 'Users' }"
              class="btn btn-sm"
              :class="
                this.$route.name == 'Users'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
            >
              Active
            </router-link>
            <router-link
              :to="{ name: 'DeletedUsers' }"
              class="btn btn-sm"
              :class="
                this.$route.name == 'DeletedUsers'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
            >
              Inactive
            </router-link>
            <!-- Hide due to unresponsive graphical view -->
            <!-- <router-link
            :to="{ name: 'Hierarchy' }"
            class="btn rounded-0"
            :class="
              this.$route.name == 'Hierarchy'
                ? 'btn-primary'
                : 'btn-outline-primary bg-white text-primary'
            "
            v-if="permissions.includes('hierarchy.read')"
          >
            Graphical View
          </router-link> -->
            <!-- <router-link
              :to="{ name: 'HierarchyFileView' }"
              class="btn rounded-0"
              :class="
                this.$route.name == 'HierarchyFileView'
                  ? 'btn-primary'
                  : 'btn-outline-primary bg-white text-primary'
              "
              v-if="permissions.includes('hierarchy.read')"
            >
              File View
            </router-link> -->
          </div>
          <div class="px-5">&nbsp;</div>
        </div>
        <div v-if="permissions.includes('users.enable')" class="p-2">
          <div class="table-responsive">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="rows"
              :columns="columns"
              styleClass="vgt-table"
            >
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.label == 'Date Of Joining'">
                  <datepicker
                    :clear-button="true"
                    :clear-button-icon="'fa fa-times'"
                    placeholder="yyyy-mm-dd"
                    format="yyyy-MM-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
                <span v-else-if="column.label == 'Slot'">
                  <!-- <flatPickr class="form-control bg-white" :config="time_config" modelValue=""
                @on-change="timeFormat($event,updateFilters,column)"
                @input="timeFormat($event,updateFilters,column)"
            ></flatPickr> -->
                  <input
                    type="time"
                    class="form-control rounded-1"
                    style="height: 25.47px; font-size: 14px"
                    @input="(e) => updateFilters(column, e.target.value)"
                  />
                </span>
              </template>
              <template slot="table-row" scope="props">
                <!-- {{props.column}} -->
                <span v-if="props.column.label == 'Action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 p-2"
                  >
                    <router-link
                      :to="'/prof/' + props.row.id"
                      v-if="permissions.includes('users.read')"
                      class="btn btn-view"
                      tag="button"
                    >
                      <i class="fa fa-eye fs-5"></i>
                    </router-link>
                    <button
                      class="btn btn-add"
                      v-if="permissions.includes('users.enable')"
                      @click="make_user_active(props.row.id)"
                    >
                      <i class="fa fa-user fs-5"></i>
                    </button>
                  </div>
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!permissions.includes('users.enable')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";

Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "DeletedUsers",
  mixins: [myMixin],
  components: { UnauthorizedPage, Datepicker, flatPickr },
  data: function () {
    return {
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "",
      isLoading: false,
      siteLogReq: {
        site_name: "inactive_users",
      },
      columns: [
        {
          label: "Name",
          field: "full_name",
          // field: "first_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "user name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reporting To",
          field: "rt_name",
          // field: "first_name",
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "reporting to", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date Of Joining",
          field: this.monthYearFormat,
          sortable: true,
          width: "12%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "date of joining", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UserRole",
          field: "role_name",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "User Role", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "UserType",
          field: "user_type",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "user type", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Slot",
          field: "login_slot",
          sortable: true,
          width: "8%",
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Login Slot", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          width: "8%",
          thClass: "custom-th disable-sorting",
          tdClass: "custom-td",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 100,
      },
      time_config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        defaultDate: "00:00:00",
      },
      perpage_list: [100, 200, 300, 400, 500, "All"],
    };
  },

  mounted() {
    if (this.permissions.includes("users.enable")) {
      this.get_all_deleted_users();
      // this.getUserPermissions();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    timeFormat(event, func, column) {
      func(column, moment(event[0]).format("HH:mm:ss"));
    },
    formateDate(date) {
      date = moment(date).format("YYYY-MM-DD");
      return date;
    },
    monthYearFormat(date) {
      let o_date = new Date(date.doj);
      let today_date = new Date();
      if (today_date.getFullYear() > o_date.getFullYear()) {
        return `${date.doj} (${
          today_date.getFullYear() - o_date.getFullYear()
        } year ago)`;
      } else if (today_date.getMonth() > o_date.getMonth()) {
        return `${date.doj} (${
          today_date.getMonth() - o_date.getMonth()
        } months ago)`;
      } else if (today_date.getMonth() === o_date.getMonth()) {
        return `${date.doj} (${
          today_date.getDate() - o_date.getDate()
        } days ago)`;
      } else if (today_date.getDate() === o_date.getDate()) {
        return today_date.toISOString().substring(0, 10);
      } else {
        return "-";
      }
    },
    //concate first and last name
    reportToconcatNames: function (rowObj) {
      if (
        rowObj.rt_first_name != null &&
        rowObj.rt_last_name != null &&
        rowObj.rt_middle_name != null
      ) {
        return (
          rowObj.rt_first_name +
          " " +
          rowObj.rt_last_name +
          " " +
          rowObj.rt_middle_name
        );
      } else if (rowObj.rt_middle_name == null) {
        return rowObj.rt_first_name + " " + rowObj.rt_last_name;
      } else if (rowObj.rt_last_name == null && rowObj.rt_middle_name == null) {
        return rowObj.rt_first_name;
      }
    },
    //concate first and last name
    concatNames: function (rowObj) {
      if (
        rowObj.first_name != null &&
        rowObj.last_name != null &&
        rowObj.middle_name != null
      ) {
        return (
          rowObj.first_name + " " + rowObj.last_name + " " + rowObj.middle_name
        );
      } else if (rowObj.middle_name == null) {
        return rowObj.first_name + " " + rowObj.last_name;
      } else if (rowObj.last_name == null && rowObj.middle_name == null) {
        return rowObj.first_name;
      }
    },

    get_all_deleted_users() {
      user.get_all_deleted_users().then((result) => {
        this.rows = [];
        this.rows = result.data.data;
        this.totalRecords = result.data.data.length;
      });
    },
    make_user_active(id) {
      this.$swal({
        title: "Active",
        text: "Are you sure you want to Active this user?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Active it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          user.make_user_active(id).then((result) => {
            if (result) {
              this.$swal("success", "User is activated...", "success");
              this.DeletedUsersFilterlist();
            }
          });
        } else {
          // this.$swal('Cancelled',)
          // user.delete_user(id, (error) => {
          // console.log(error);
          // });
        }
      });
    },
    DeletedUsersFilterlist() {
      var fields = {
        filters: this.serverParams.columnFilters,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perPage,
      };
      // console.log("search",fields);
      user.DeletedUsersFilterlist(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
    // getClientbyid(id) {
    //   user.getClientbyid(id).then((result) => {
    //     console.log(result.data);
    //   });
    // },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.DeletedUsersFilterlist();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.DeletedUsersFilterlist();
    },

    onSortChange(params) {
      let prm = params;
      // typeof prm[0].field == "function" ? prm[0].field = 'doj': '';
      // console.log(prm)
      this.updateParams({
        sort: [
          {
            type: prm[0].type,
            field: typeof prm[0].field == "function" ? "doj" : prm[0].field,
          },
        ],
      });
      this.DeletedUsersFilterlist();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      if (Object.keys(newObj.columnFilters).includes("bound monthYearFormat")) {
        newObj.columnFilters.doj =
          newObj.columnFilters["bound monthYearFormat"];
        delete newObj.columnFilters["bound monthYearFormat"];
      }
      this.updateParams(newObj);
      this.DeletedUsersFilterlist();
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
