<template>
  <div class="border-0">
    <!-- {{time}}----{{config}} -->
    <span class="d-flex justify-content-center align-items-center m-0 p-0 bg-white border rounded" id="box" :style="(computedDimension==null ? '' : computedDimension)">
      <input class="form-control border-0" type="number" min="0" v-model="time.hours" placeholder="hh" required id="input_for_hours"
      :max="limit" @keypress="isNumber">
      <span><h3 class="my-1" style="color: #343434d9;">:</h3></span>
      <input class="form-control border-0" type="number" max="60" min="0" v-model="time.minutes" placeholder="mm" required @keypress="isNumber">
      <!-- <input class="form-control border-0" type="number" min="0" v-model="time.hours" placeholder="hh" required id="input_for_hours"
      :max="limit" :style="(computedDimension==null ? '' : computedDimension)">
      <span><h3 class="my-1">:</h3></span>
      <input class="form-control border-0" type="number" max="60" min="0" v-model="time.minutes" placeholder="mm"
      :style="(computedDimension==null ? '' : computedDimension)" required > -->
    </span>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        time:this.config,
        limit:this.max_limit
      }
    },
    computed:{
      maxHour(){
        let hr=Math.floor(this.limit/60);
        return hr
      },
      maxMin(){
        let hr=this.limit/60;
        let shr=Math.floor(hr);
        let min=Math.round((hr-shr)*60);
        return min;
      },
      maxMin(){
        let hr=this.limit/60;
        let shr=Math.floor(hr);
        let min=Math.round((hr-shr)*60);
        return min;
      },
      computedDimension(){
        let height=this.height;
        let css_string=`height:${height};`;
        return height==null ? null : css_string;
      }
    },
    methods:{
      isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    },
    watch:{
      time:{
        handler(nv,ov){
          if(nv.minutes==60){
            nv.hours++;nv.minutes=0;
            // console.log(nv.hours);
          }
          if(nv.minutes>60){
            let strMin=String(nv.minutes);
            nv.minutes=Number(strMin.substring(0,strMin.length-1));
            // console.log(nv.minutes);
          }
          if(String(nv.minutes).includes('0') && String(nv.minutes)[0]=='0'){
            let strMin=String(nv.minutes);
            let numbers=["0","1","2","3","4","5","6","7","8","9"];
            if(numbers.includes(strMin[strMin.length-1])){
              nv.minutes=Number(strMin[strMin.length-1]);
            }
          }
          if(String(nv.hours).includes('0') && String(nv.hours)[0]=='0'){
            let strHr=String(nv.hours);
            let numbers=["0","1","2","3","4","5","6","7","8","9"];
            if(numbers.includes(strHr[strHr.length-1])){
              nv.hours=Number(strHr[strHr.length-1]);
            }
          }
          if(this.limit!=''){
            // console.log("Hii")
            if(nv.hours>this.maxHour){
              nv.hours=this.maxHour
            }
            if(nv.minutes>this.maxMin && nv.hours==this.maxHour){
              nv.minutes=this.maxMin
            }
          }
        },
        deep:true,
        immediate:true
      }
    },
    props:{
      config:{
        type:Object,
        required:true,
        default:{hours:0,minutes:0}
      },
      max_limit:{
        type:String,
        default:"",
      },
      height:{
        type:String,
        default:null
      },
      width:{
        type:String,
        default:null
      }
    },
    updated(){
      this.time=this.config;
      // this.$forceUpdate();
    }
  }
</script>

<style scoped>
  #box{
    width:12rem;
  }
</style>
