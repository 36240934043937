<template>
  <div v-if="permissions.includes('tasks.write')" class="vue-tempalte task">
    <form
      @submit.prevent="onSubmitTask()"
      style="background-color: #ffffff"
      v-if="data_loaded"
    >
      <div class="m-0">
        <div class="m-0 p-0 align-self-start">
          <!-- To disable all the inputs -->
          <!-- {{ permissions.includes('tasks.write') }}
          {{ full_permission==false }}
          {{  buttonText!=='Create' }} -->
          <div
            class="position-absolute"
            style="width: 96%; height: 85%; z-index: 1"
            v-if="
              permissions.includes('tasks.write') &&
              full_permission == false &&
              buttonText !== 'Create'
            "
          ></div>

          <div class="row text-dark mb-3" v-if="buttonText !== 'Create'">
            <div class="col-md-6 col-12">
              <label
                class="form-label font-weight-bold mr-3 mb-0 pl-0"
                for="task_id"
                >Taks Id :
              </label>
              {{ reqObj.task_id }}
            </div>
            <div class="col-md-6 col-12">
              <label
                class="form-label font-weight-bold mr-3 mb-0"
                for="client_name"
                >Client Name :</label
              >
              {{ reqObj.client_name }}
            </div>
          </div>

          <!-- ------------------------- -->
          <div class="row text-dark mb-2">
            <div class="col-md-6 col-12 mb-md-0 mb-2">
              <label
                class="form-label font-weight-bold"
                id="mandatory"
                for="project_name"
                >Project</label
              >
              <select
                v-model="reqObj.project"
                :disabled="!permissions.includes('tasks.write')"
                @change="
                  onProjectSelected();
                  getAllAssignedProjectUsers();
                "
                id="project_name"
                class="form-control"
                :state="null"
                required
                oninvalid="this.setCustomValidity('Please select project')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select Project</option>
                <option
                  :key="index"
                  v-for="(item, index) in projects"
                  :value="item.id"
                >
                  {{ item.project_name }} ({{ item.subclientname }})
                </option>
              </select>
            </div>
            <div class="col-md-6 col-12 mb-md-0 mb-2">
              <label
                class="form-label font-weight-bold"
                for="milestone"
                id="mandatory"
                >Mile Stone</label
              >
              <select
                v-model="reqObj.milestone_id"
                :disabled="!permissions.includes('tasks.write')"
                class="form-control"
                id="milestonr"
                :state="null"
                @click="onMilestoneSelected(reqObj.milestone_id)"
                required
                oninvalid="this.setCustomValidity('Please select name of the milestone')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select Milestone Name</option>
                <option
                  :key="index"
                  v-for="(item, index) in milestones"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row text-dark">
            <div class="col">
              <label
                class="form-label font-weight-bold"
                for="textarea-formatter"
                id="mandatory"
                >Short Description</label
              >
              <textarea
                id="textarea-formatter"
                rows="2"
                wrap="soft"
                class="form-control"
                v-model="reqObj.short_desc"
                :disabled="!permissions.includes('tasks.write')"
                @input="
                  reqObj.short_desc.length > 255
                    ? (reqObj.short_desc = reqObj.short_desc.slice(0, 255))
                    : ''
                "
                :state="null"
                placeholder="Enter short description"
                oninvalid="this.setCustomValidity('Please enter short description')"
                oninput="this.setCustomValidity('')"
                required
              ></textarea>
              <p
                class="text-muted text-end m-0 p-0"
                v-text="reqObj.short_desc.length + '/255'"
              ></p>
            </div>
          </div>

          <div class="row text-dark">
            <div class="col">
              <label
                class="form-label font-weight-bold"
                for="textarea-formatter"
                id="mandatory"
                >Description</label
              >
              <textarea
                id="textarea-formatter"
                rows="3"
                wrap="soft"
                class="form-control"
                v-model="reqObj.description"
                @input="
                  reqObj.description.length > 1000
                    ? (reqObj.description = reqObj.description.slice(0, 1000))
                    : ''
                "
                :disabled="!permissions.includes('tasks.write')"
                :state="null"
                required
                placeholder="Enter task description"
                oninvalid="this.setCustomValidity('Please enter task description')"
                oninput="this.setCustomValidity('')"
              ></textarea>
              <p
                class="text-muted text-end m-0"
                v-text="reqObj.description.length + '/1000'"
              ></p>
            </div>
          </div>

          <div class="row text-dark d-xl-flex d-block mb-3">
            <div class="col-xl-4 col-12 mb-3">
              <label
                class="form-label font-weight-bold"
                for="sdate"
                id="mandatory"
                >Start Date</label
              >
              <datepicker
                placeholder="yyyy-mm-dd"
                v-model="reqObj.start_date"
                :typeable="true"
                :disabled="!permissions.includes('tasks.write')"
                :format="formateDate"
                :clear-button="true"
                :calendar-button="true"
                :clear-button-icon="'fa fa-times'"
                :input-class="'border-start-0'"
                :calendar-button-icon="'fa fa-calendar'"
                :bootstrap-styling="true"
                @input="reqObj.due_date=reqObj.start_date"
                required
              ></datepicker>
            </div>

            <div class="col-xl-4 col-12 mb-3">
              <label class="form-label font-weight-bold" for="edate"
                >End Date</label
              >
              <datepicker
                placeholder="yyyy-mm-dd"
                v-model="reqObj.due_date"
                :typeable="true"
                :disabled="!permissions.includes('tasks.write')"
                :disabled-dates="{ to: new Date(reqObj.start_date) }"
                :calendar-button="true"
                :clear-button="true"
                :clear-button-icon="'fa fa-times'"
                :input-class="'border-start-0'"
                :calendar-button-icon="'fa fa-calendar'"
                :bootstrap-styling="true"
                :format="formateDate"
              ></datepicker>
            </div>

            <div class="col-xl-4 col-12 mb-3">
              <label class="form-label font-weight-bold m-0 mb-2" for="etime"
                >Estimated Time</label
              >
              <time-picker-vue
                @input="calculate_date()"
                :config="reqObj.estimated_time"
                :disabled="!permissions.includes('tasks.write')"
                :height="'2.6rem'"
              ></time-picker-vue>
              <p class="text-danger m-2" v-if="this.valueErr">
                {{ this.valueErr }}
              </p>
            </div>
          </div>

          <div class="row text-dark mb-1">
            <div
              class="col-xl-4 col-12 mb-3 d-flex justify-content-between align-items-center"
            >
              <div class="w-100">
                <span class="text-dark font-weight-bold" id="mandatory"
                  >Priority</span
                >

                <div
                  class="d-flex align-items-start justify-content-start mt-2 gap-3 flex-wrap"
                >
                  <div class="form-check d-flex align-items-center">
                    <input
                    class="form-check-input"
                    v-model="reqObj.priorityId"
                    :disabled="!permissions.includes('tasks.write')"
                    value="1"
                    type="radio"
                    name="status"
                    id="high"
                    style="width: 20px; height: 20px; margin-top: 0"
                    />
                    <label
                      class="form-check-label mx-2 text-dark font-weight-bold"
                      for="high"
                      style="font-size: 15px"
                      >High</label
                    >
                  </div>

                  <div class="form-check d-flex align-items-center mb-3">
                    <input
                    class="form-check-input"
                    v-model="reqObj.priorityId"
                    value="2"
                    type="radio"
                    :disabled="!permissions.includes('tasks.write')"
                    name="status"
                    id="low"
                    style="width: 20px; height: 20px; margin-top: 0"
                    />
                    <label
                      class="form-check-label mx-2 text-dark font-weight-bold"
                      for="low"
                      style="font-size: 15px"
                      >Low</label
                    >
                  </div>

                  <div class="form-check d-flex align-items-center mb-2">
                    <input
                    class="form-check-input"
                      v-model="reqObj.priorityId"
                      value="3"
                      type="radio"
                      :disabled="!permissions.includes('tasks.write')"
                      name="status"
                      id="medium"
                      style="width: 20px; height: 20px; margin-top: 0"
                      />
                      <label
                        class="form-check-label mx-2 text-dark font-weight-bold"
                        for="medium"
                        style="font-size: 15px"
                        >Medium</label
                      >
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-12 mb-3">
              <div class="flex-grow-1">
                <label
                  class="form-label font-weight-bold"
                  id="mandatory"
                  for="status"
                  >Status</label
                >
                <select
                  v-model="reqObj.statusId"
                  class="form-control"
                  id="status"
                  :disabled="!permissions.includes('tasks.write')"
                  :state="null"
                  @click="onMilestoneSelected(reqObj.statusId)"
                  required
                  oninvalid="this.setCustomValidity('Please select status')"
                  oninput="this.setCustomValidity('')"
                >
                  <option value="">Select Project Status</option>
                  <option
                    :key="index"
                    v-for="(item, index) in status"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-xl-4 col-12 mb-3">
              <div class="m-0 flex-grow-1">
                <label
                  class="form-label font-weight-bold"
                  for="tracker"
                  id="mandatory"
                  >Tracker</label
                >
                <select
                  v-model="reqObj.task_categories"
                  class="form-control"
                  :disabled="!permissions.includes('tasks.write')"
                  id="tracker"
                  :state="null"
                  required
                  oninvalid="this.setCustomValidity('Please select category')"
                  oninput="this.setCustomValidity('')"
                >
                  <option value="" selected>Select Categories</option>
                  <option
                    v-for="items in categories"
                    :key="items.id"
                    :value="items.id"
                    v-text="items.name"
                  ></option>
                </select>
              </div>
            </div>
          </div>

          <div class="row text-dark mb-3">
            <div class="col-md-8 col-12">
              <label
                for="assign"
                class="form-label text-dark font-weight-bold"
                id="mandatory"
                >Assign</label
              >
              <multiselect
                @input="calculate_date()"
                v-model="reqObj.users"
                :hide-selected="true"
                :options="users"
                :close-on-select="false"
                :disabled="!permissions.includes('tasks.write')"
                :multiple="true"
                placeholder="Select Multiple User"
                label="first_name"
                track-by="id"
                :custom-label="custom_label"
                group-label="label"
                group-values="user_data"
                :group-select="true"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                ></template>
              </multiselect>
              <p v-if="multiselect_user_err != ''" class="alert text-danger">
                {{ multiselect_user_err }}
              </p>
            </div>
            <div
              class="col-md-4 col-12 mb-md-0 mb-2 d-flex flex-column align-items-start justify-content-start position-relative"
              style="z-index: 2"
            >
              <label for="file" class="form-label text-dark font-weight-bold"
                >Select File</label
              >
              <button
                class="btn border shadow-sm m-0"
                type="button"
                style="
                  background-color: #f4f5f8;
                  border-color: #b7b7b7;
                  color: #adacac;
                "
              >
                Choose File
              </button>
            </div>
          </div>
        </div>

        <div
          class="m-0 col-12"
          id="user_box"
          style="overflow:auto;"
          v-if="
            permissions.includes('task_timing.set') &&
            (this.reqObj.estimated_time.hours != 0 ||
              this.reqObj.estimated_time.minutes != 0) &&
            this.reqObj.users.length > 0
          "
        >
          <h6 class="mb-4">Allocated Time</h6>
          <div class="row g-0">
            <div class="col-4">
              <h6 class="font-weight-bold">Username</h6>
            </div>
            <div class="col-4">
              <h6 class="font-weight-bold text-center">Total Hours</h6>
            </div>
            <div class="col-4">
              <h6 class="font-weight-bold text-center">Daily Hours</h6>
            </div>
          </div>

          <div
            class="row g-0"
            v-for="(value, i) in reqObj.users"
            :key="i"
          >
            <div
              class="col-4 d-flex justify-content-start align-items-start my-2"
            >
              <p type="text" class="">
                <span v-text="i+1"></span>.
                <span class="text-center">
                  {{ custom_label(value) }}
                </span>
              </p>
            </div>

            <div
              class="col-4 d-flex justify-content-between align-items-center flex-column my-2"
            >
              <div class="d-flex justify-content-center aling-items-center">
                {{ /* total hours validation start */ }}
                <input
                  type="number"
                  v-model="value.at_hours"
                  class="form-control time_cal_hours"
                  min="0"
                  @keypress.stop
                  :disabled="!permissions.includes('tasks.write')"
                  placeholder="hh"
                  v-bind:max="
                    Math.trunc(
                      (Math.trunc(reqObj.estimated_time.hours * 60) +
                        reqObj.estimated_time.minutes) /
                        60
                    )
                  "
                  id="input_size"
                />

                <input
                  v-if="
                    value.at_hours <
                    Math.trunc(
                      (Math.trunc(reqObj.estimated_time.hours * 60) +
                        reqObj.estimated_time.minutes) /
                        60
                    )
                  "
                  type="number"
                  v-model="value.at_minutes"
                  :disabled="!permissions.includes('tasks.write')"
                  class="form-control time_cal_minutes"
                  min="0"
                  @keypress.stop
                  placeholder="mm"
                  max="59"
                  id="input_size"
                />

                <input
                  v-else
                  type="number"
                  v-model="value.at_minutes"
                  class="form-control time_cal_minutes"
                  min="0"
                  placeholder="mm"
                  @keypress.stop
                  :disabled="!permissions.includes('tasks.write')"
                  v-bind:max="
                    Math.trunc(
                      (Math.trunc(reqObj.estimated_time.hours * 60) +
                        reqObj.estimated_time.minutes) %
                        60
                    )
                  "
                  id="input_size"
                />
              </div>
              <div class="p-0 m-0 text-center my-1 px-2">
                {{ /* total hours validation end */ }}
                <small
                  v-if="show_total_time_err[i] != ''"
                  class="text-danger text-wrap"
                  style="font-size: 12px"
                >
                  {{ show_total_time_err[i] }}
                </small>
              </div>
            </div>

            <div
              class="col-4 d-flex justify-content-between align-items-center flex-column my-2"
            >
              <div class="d-flex justify-content-center aling-items-center">
                {{ /* daily hours validation start */ }}
                <input
                  type="number"
                  v-model="value.md_hours"
                  class="form-control time_cal_hours"
                  min="0"
                  @keypress.stop
                  :disabled="!permissions.includes('tasks.write')"
                  placeholder="hh"
                  v-bind:max="Math.trunc(value.per_day_minutes / 60)"
                  id="input_size"
                />
                <input
                  v-if="value.md_hours < Math.trunc(value.per_day_minutes / 60)"
                  type="number"
                  v-model="value.md_minutes"
                  class="form-control time_cal_minutes"
                  :disabled="!permissions.includes('tasks.write')"
                  min="0"
                  @keypress.stop
                  placeholder="mm"
                  max="59"
                  id="input_size"
                />

                <input
                  v-else
                  type="number"
                  v-model="value.md_minutes"
                  class="form-control time_cal_minutes"
                  :disabled="!permissions.includes('tasks.write')"
                  min="0"
                  @keypress.stop
                  placeholder="mm"
                  v-bind:max="Math.trunc(value.per_day_minutes % 60)"
                  id="input_size"
                />
              </div>
              {{ /* daily hours validation end */ }}
              <div class="m-0 p-0 text-center my-1 px-2">
                <small
                  v-if="show_daily_time_err[i] != ''"
                  class="text-danger text-wrap"
                  style="font-size: 12px"
                >
                  {{ show_daily_time_err[i] }}
                </small>
              </div>
            </div>
          </div>
          <div class="row g-0 text-center">
            <div class="col-4"></div>
            <div class="col-4">
              <small
                v-if="Total_h_err != ''"
                class="text-danger text-wrap"
                style="font-size: 12px"
              >
                {{ Total_h_err }}
              </small>
            </div>
            <div class="col-4">
              <small
                v-if="Total_h_err != ''"
                class="text-danger text-wrap"
                style="font-size: 12px"
              >
                {{ Daily_h_err }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end gap-2 mt-5">
        <button
          class="btn btn-light border text-primary py-2 mr-4 m-0"
          @click.prevent="$emit('close-modal')"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary border shadow-sm py-2 m-0"
          v-text="'Create'"
          v-if="permissions.includes('tasks.add') && buttonText === 'Create'"
        ></button>
        <button
          v-if="
            permissions.includes('tasks.write') &&
            buttonText === 'Update' &&
            full_permission == true
          "
          class="btn btn-primary border shadow-sm py-2 m-0"
          v-text="'Update'"
        ></button>
      </div>
    </form>
    <div
      class="d-flex justify-content-center align-items-center p-0"
      v-else
      style="height: 50rem"
    >
      <div
        class="spinner-border text-primary mb-5"
        style="height: 8rem; width: 8rem"
      ></div>
    </div>
  </div>
  <div v-else>
    <UnauthorizedPage></UnauthorizedPage>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import user from "@/ajax/user";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import TimePickerVue from "../../components/TimePicker.vue";
import { mapState, mapActions } from "vuex";

Vue.use(VueSweetalert2);

export default {
  name: "AddTask",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
    Multiselect,
    flatPickr,
    TimePickerVue,
  },
  props: {
    status_id: {
      type: Number,
    },
    update_task_id: {
      type: Number,
    },
    full_permission: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      show_total_time_err: [],
      show_daily_time_err: [],
      multiselect_user_err: "",
      isHidden: true,
      isSubmitOrNot: false,
      g_t_d_m: 0,
      t_a_t: 0,
      Daily_h_err: "",
      Total_h_err: "",
      reqObj: {
        project: "",
        milestone_id: "",
        short_desc: "",
        description: "",
        start_date: new Date().toISOString().slice(0, 10),
        due_date: "",
        estimated_time: { hours: 0, minutes: 0 },
        actual_start_time: "",
        statusId: "",
        priorityId: "",
        users: [],
        files: [],
        task_categories: "",
        created_by: null,
        client_name: "",
        task_id: null,
      },
      time_maping_obj: {},
      FILE: null,
      users: [],
      projects: [],
      milestones: [],
      editid: 0,
      status: [],
      priority: [],
      img_obj_id: [],
      img_save_path: [],
      task_files: [],
      categories: [],
      permissions: window.localStorage.getItem("permissions"),
      file_type: "task_file",
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      hidedates:"",
      config: {
        dateFormat: "H:i",
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        enableInput: false,
      },
      valueErr: "",
      fromTasks: JSON.parse(localStorage.getItem("fromTask")),
      buttonText: "Create",
      data_loaded: false,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    if (this.permissions.includes("tasks.write")) {
      // this.getAllTasks();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        this.editid = this.$route.params.id;
        this.getTaskbyid(this.$route.params.id);
        // document.getElementById("project-list").disabled = true
      }
      // $(".fl-flex-label").flexLabel();
      // this.getAllMilestones();
      this.getprojectDropdown();
      // this.getassignuserDropdown();
      this.getAllTasksStatus();
      this.getAllPriorities();
      this.getUserPermissions();
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    resetObject() {
      (this.reqObj = {
        project: "",
        milestone_id: "",
        short_desc: "",
        description: "",
        start_date: new Date().toISOString().slice(0, 10),
        due_date: "",
        estimated_time: { hours: 0, minutes: 0 },
        actual_start_time: "",
        statusId: null,
        priorityId: "",
        users: "",
        files: [],
        task_categories: "",
        created_by: null,
      }),
        this.$emit("close-modal");
    },

    calculate_date() {
      this.isHidden = false;
      this.reqObj.task_id = this.editid;
      user.get_per_day_minutes_of_users(this.reqObj).then((result) => {
        this.reqObj.users = result.data.data;
        // console.log(this.reqObj.users);
      });
    },
    custom_label(name) {
      if (name.id == this.currentUser.id) {
        return "me";
      } else {
        // console.log(this.users);
        if (name.last_name != null && name.middle_name != null) {
          return `${name.first_name} ${name.middle_name} ${name.last_name}`;
        } else if (name.last_name == null) {
          // console.log(name.first_name+name.middle_name);
          return `${name.first_name} ${name.middle_name}`;
        } else if (name.middle_name == null) {
          return `${name.first_name} ${name.last_name}`;
        }
      }
    },
    estimatedhouresInput(selectedDates, time, instance) {
      this.reqObj.estimated_hours = time;
    },

    getprojectDropdown() {
      user.getAllProjects().then((result) => {
        this.projects = result.data.data;
        if (result.data.data[0]) {
          // this.reqObj.project = result.data.data[(result.data.data).length-1]['project_id'];
          // this.onProjectSelected();
          // this.getAllAssignedProjectUsers();
        }
      });
    },

    getAllAssignedProjectUsers() {
      // console.log("get all assigned project user")
      user.getAllAssignedProjectUsers(this.reqObj.project).then((result) => {
        // this.users = result.data.data;
        this.users = [{ label: "Select all", user_data: result.data.data }];
      });
    },
    getTaskUnAssignUsers(id) {
      user.getTaskUnAssignUsers(id).then((result) => {
        this.reqObj.users = result.data.data;
        if ((result.data.code = 200)) {
          this.calculate_date();
        }
      });
    },
    getTaskCategory() {
      user.getTaskCategoryDropdown(this.reqObj).then((result) => {
        if (result.data.data[0]) {
          this.categories = result.data.data;
          this.reqObj.task_categories = result.data.data[0]["id"];
        }
      });
    },
    // getAllAssignedProjectUsers(project_id){
    //   user.getAllAssignedProjectUsers(project_id).then((result) => {
    //       // console.log(result.data.data)
    //       this.user = result.data.data
    //   });
    // },

    getassignuserDropdown() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      var fullDate = `?searched_date=${year}-${month}-${day}`;
      user.getAttendanceReport(fullDate).then((result) => {
        this.user = result.data.data.users;
      });
    },

    // getassignuserDropdown() {
    // let date = new Date()
    // let day = date.getDate();
    // let month = date.getMonth()+1;
    // let year = date.getFullYear();
    // var fullDate = `?searched_date=${year}-${month}-${day}`;
    //   user.getAttendanceReport(fullDate).then((result) => {
    //     this.user = result.data.data.users;
    //   });
    // },

    // getAllMilestones() {
    //   user.getAllMilestones().then((result) => {
    //     this.milestones = result.data.data;
    // });
    // },
    // getAllTasks() {
    //   user.getAllTasks(this.reqObj).then((result) => {
    //    });
    // },

    getAllTasksStatus() {
      user.getAllTasksStatus(this.reqObj).then((result) => {
        if (result.data.data[0]) {
          this.status = result.data.data;
          // this.reqObj.statusId = result.data.data[0]["id"];
        }
      });
    },

    getAllPriorities() {
      user.getAllPriorities(this.reqObj).then((result) => {
        if (result.data.data[0]) {
          this.priority = result.data.data;
          this.reqObj.priorityId = result.data.data[0]["id"];
        }
      });
    },

    onMilestoneSelected(item) {
      for (let index = 0; index < this.milestones.length; index++) {
        if (this.milestones[index].id == item) {
          this.reqObj.actual_start_time =
            this.milestones[index].actual_start_time;
        }
      }
    },
    onProjectSelected() {
      // console.log("project selected");
      user.getAllProjectMilestones(this.reqObj.project).then((result) => {
        if (result.data.data[0]) {
          this.milestones = result.data.data;
          // this.reqObj.milestone_id = result.data.data[(result.data.data).length-1]['id'];
        }
      });
      if (this.project_id) {
        const old_selected_users = [];
        if (this.project_id != this.reqObj.project) {
          this.old_selected_users = this.reqObj.users;
          this.reqObj.users = [];
        } else {
          this.reqObj.users = this.old_selected_users;
        }
      }
    },

    // number validation for estimated hours
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },

    getTaskbyid(id) {
      this.data_loaded = false;
      user.getTaskbyid(id).then((result) => {
        if (result.data.data != "") {
          // console.log("Update::::"+ JSON.stringify(result));
          this.reqObj.project = result.data.data[0]["project_id"];
          this.reqObj.milestone_id = result.data.data[0]["milestones_id"];
          this.reqObj.short_desc = result.data.data[0]["short_desc"];
          this.reqObj.description = result.data.data[0]["description"];
          this.reqObj.start_date = result.data.data[0]["start_date"];
          this.reqObj.due_date = result.data.data[0]["due_date"];
          this.hidedates=moment(this.reqObj.due_date).format("YYYY-MM-DD");
          this.reqObj.priorityId = result.data.data[0]["priority"];
          this.reqObj.statusId = result.data.data[0]["task_status_id"];
          this.reqObj.task_categories = result.data.data[0]["category_id"];
          this.reqObj.created_by = result.data.data[0]["created_by"];
          this.reqObj.task_id = result.data.data[0]["id"];
          this.reqObj.client_name = result.data.data[0]["client_name"];
          // this.reqObj.task_categories = result.data.data[0]["category_id"];
          this.onProjectSelected();
          this.getTaskUnAssignUsers(this.editid);
          this.getAllAssignedProjectUsers();
          this.project_id = this.reqObj.project;
          this.reqObj.estimated_time.hours = parseInt(
            result.data.data[0]["new_estimated_minutes"] / 60
          );
          this.reqObj.estimated_time.minutes = parseInt(
            result.data.data[0]["new_estimated_minutes"] % 60
          );
        } else {
          this.$swal("Error", "You can update only your task...", "warning");
          // this.$router.push({ name: "NewTask" });getTaskbyid
          this.$emit("close-modal");
        }
        this.data_loaded = true;
      });
    },

    // onSunmitTask() is used for task create or update if id editid=0 then createTask() perform otherwise updateTask() is perform
    getPreAssighedUrl() {
      user.getPreAssighedUrl(this.file_type).then((result) => {
        // console.log(result.data.data.url);
        this.img_obj_id.push(result.data.data.object_key);
        this.img_save_path.push(result.data.data.url);
      });
    },
    onFileUpload(event) {
      // if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)){
      //     if(event.target.files[0].size < 1000000){
      this.FILE = event.target.files;
      for (var i = 0; i < this.FILE.length; i++) {
        this.getPreAssighedUrl();
        this.task_files.push(this.FILE[i]);
      }
      // console.log("this.FILE.type", this.FILE.type)
      // console.log(this.img_save_path)
      // this.getPreAssighedUrl();
      // console.log(this.FILE)
      //     }else{
      //         this.$swal('Error','Select an image whose size is less than 1 MB', 'warning')
      //         event.target.value = []
      //     }
      // }else{
      //     this.$swal('Error','Only jpg/jpeg and png files are allowed!', 'warning')
      //     event.target.value = []
      // }
    },
    onSubmitTask() {
      this.CheckValidation();
      // console.log(this.reqObj);
      // console.log("in submit task edit id ",this.editid);
      if (this.reqObj.users.length < 1) {
        this.isSubmitOrNot = false;
        this.multiselect_user_err = "**please select at least one user";
      } else {
        this.multiselect_user_err = "";
      }
      // this.calculate_date()
      // console.log("before check validation");
      // console.log(this.fromTasks);
      // var total_minutes = parseInt(this.reqObj.estimated_time.hours*60)+parseInt(this.reqObj.estimated_time.minutes)
      this.reqObj.start_date = moment(this.reqObj.start_date).format(
        "yyyy-MM-DD"
      );
      this.reqObj.due_date = this.reqObj.due_date ? moment(this.reqObj.due_date).format("yyyy-MM-DD") : null;
      if (this.editid == 0) {
        if (this.isSubmitOrNot) {
          const formData = new FormData();
          if (this.FILE) {
            for (var i = 0; i < this.FILE.length; i++) {
              this.reqObj.files[i] = {
                name: this.FILE[i].name,
                path: this.img_obj_id[i],
              };
              formData.append("file", this.task_files[i]);
              // console.log("save path"+this.img_save_path[i],"image id"+this.img_obj_id[i],"image data"+this.task_files[i])
              axios
                .put(this.img_save_path[i], this.task_files[i], {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(function (result) {
                  if (result.status == 200) {
                  } else {
                    this.$swal(
                      "Error",
                      "Files not uploaded successfully...",
                      "warning"
                    );
                  }
                });
            }
          }

          // check the values of selected time is not null
          // if(total_minutes != 0){
          user.createTask(this.reqObj).then((result) => {
            // console.log("created--------", this.reqObj.users);
            if (result.status == 200) {
              this.$emit("close-modal");
              Swal.fire({
                title: "Task Created Succesfully...!",
                color: "white",
                toast: true,
                icon: "success",
                iconColor: "white",
                position: "top",
                background: "rgb(64, 205, 157)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              }).then(() => {
                // a;
              });
            } else {
              this.$emit("close-modal");
              Swal.fire({
                title: "Task not created...!",
                color: "white",
                toast: true,
                icon: "warning",
                iconColor: "white",
                position: "top",
                background: "#f27474",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              }).then(() => {});
            }
          });
        }
      } else {
        if (this.isSubmitOrNot) {
          user.updateTask(this.editid, this.reqObj).then((result) => {
            if (result.status == 200) {
              this.$emit("close-modal");
              Swal.fire({
                title: "Task Updated Succesfully...!",
                color: "white",
                toast: true,
                icon: "success",
                iconColor: "white",
                position: "top",
                background: "rgb(64, 205, 157)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              }).then(() => {
                // this.resetObject();
                // console.log(this.reqObj.estimated_time);
              });
            } else {
              this.$emit("close-modal");
              Swal.fire({
                title: "Task not Updated...!",
                color: "white",
                toast: true,
                icon: "error",
                iconColor: "white",
                position: "top",
                background: "#f27474",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              });
              // this.resetObject();
              // console.log(this.reqObj.estimated_time);
            }
          });
        }
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    CheckValidation() {
      if (
        !this.permissions.includes("task_timing.set") ||
        parseInt(
          Math.trunc(
            parseInt(this.reqObj.estimated_time.hours * 60) +
              parseInt(this.reqObj.estimated_time.minutes)
          )
        ) == 0
      ) {
        this.isSubmitOrNot = true;
      } else {
        // define null or 0 before use
        this.g_t_d_m = 0;
        this.t_a_t = 0;
        var check_operation = 0;
        this.Total_h_err = "";
        this.Daily_h_err = "";
        var check_d_h_null_or_not = 0;
        var check_t_h_null_or_not = 0;
        var check_d_h_greater_than_t_h = 0;
        var get_daily_time = [];
        var get_total_time = [];
        this.show_total_time_err = [];
        this.show_daily_time_err = [];
        // check all value throught loop
        for (var i = 0; i < this.reqObj.users.length; i++) {
          // calculate daily hours time
          if (
            (this.reqObj.users[i]["md_hours"] != "" ||
              this.reqObj.users[i]["md_hours"] != 0) &&
            (this.reqObj.users[i]["md_minutes"] != "" ||
              this.reqObj.users[i]["md_minutes"] != 0)
          ) {
            this.g_t_d_m =
              this.g_t_d_m +
              (parseInt(this.reqObj.users[i]["md_hours"]) * 60 +
                parseInt(this.reqObj.users[i]["md_minutes"]));
            get_daily_time.push(
              parseInt(this.reqObj.users[i]["md_hours"]) * 60 +
                parseInt(this.reqObj.users[i]["md_minutes"])
            );
          } else if (
            (this.reqObj.users[i]["md_hours"] == "" ||
              this.reqObj.users[i]["md_hours"] == 0) &&
            (this.reqObj.users[i]["md_minutes"] == "" ||
              this.reqObj.users[i]["md_minutes"] == 0)
          ) {
            check_d_h_null_or_not++;
            this.show_daily_time_err[i] =
              " **Please assign daily hours to the assignees...";
          } else {
            if (
              this.reqObj.users[i]["md_hours"] == "" &&
              this.reqObj.users[i]["md_hours"] == 0
            ) {
              this.g_t_d_m =
                this.g_t_d_m + parseInt(this.reqObj.users[i]["md_minutes"]);
              get_daily_time.push(parseInt(this.reqObj.users[i]["md_minutes"]));
            } else {
              this.g_t_d_m =
                this.g_t_d_m + parseInt(this.reqObj.users[i]["md_hours"]) * 60;
              get_daily_time.push(
                parseInt(this.reqObj.users[i]["md_hours"]) * 60
              );
            }
          }
          // calculate total hours time
          if (
            (this.reqObj.users[i]["at_hours"] != "" ||
              this.reqObj.users[i]["at_hours"] != 0) &&
            (this.reqObj.users[i]["at_minutes"] != "" ||
              this.reqObj.users[i]["at_minutes"] != 0)
          ) {
            this.t_a_t =
              this.t_a_t +
              parseInt(this.reqObj.users[i]["at_hours"]) * 60 +
              parseInt(this.reqObj.users[i]["at_minutes"]);
            get_total_time.push(
              parseInt(this.reqObj.users[i]["at_hours"]) * 60 +
                parseInt(this.reqObj.users[i]["at_minutes"])
            );
          } else if (
            (this.reqObj.users[i]["at_hours"] == "" ||
              this.reqObj.users[i]["at_hours"] == 0) &&
            (this.reqObj.users[i]["at_minutes"] == "" ||
              this.reqObj.users[i]["at_minutes"] == 0)
          ) {
            check_t_h_null_or_not++;
            this.show_total_time_err[i] =
              " **Please assign total hours to the assignees...";
          } else {
            if (
              this.reqObj.users[i]["at_hours"] == "" &&
              this.reqObj.users[i]["at_hours"] == 0
            ) {
              this.t_a_t =
                this.t_a_t + parseInt(this.reqObj.users[i]["at_minutes"]);
              get_total_time.push(parseInt(this.reqObj.users[i]["at_minutes"]));
            } else {
              this.t_a_t =
                this.t_a_t + parseInt(this.reqObj.users[i]["at_hours"]) * 60;
              get_total_time.push(
                parseInt(this.reqObj.users[i]["at_hours"]) * 60
              );
            }
          }
          if (get_daily_time[i] > get_total_time[i]) {
            check_d_h_greater_than_t_h++;
            this.show_daily_time_err[i] =
              " **Daily hours should not be exceed total hours...";
          }
        }
        // console.log(this.g_t_d_m ,this.t_a_t, parseInt(Math.trunc((parseInt(this.reqObj.estimated_time.hours*60)+parseInt(this.reqObj.estimated_time.minutes)))))
        if (
          this.g_t_d_m <=
            parseInt(
              Math.trunc(
                parseInt(this.reqObj.estimated_time.hours * 60) +
                  parseInt(this.reqObj.estimated_time.minutes)
              )
            ) &&
          this.g_t_d_m != 0
        ) {
          check_operation++;
        } else {
          if (
            this.g_t_d_m >
            parseInt(
              Math.trunc(
                parseInt(this.reqObj.estimated_time.hours * 60) +
                  parseInt(this.reqObj.estimated_time.minutes)
              )
            )
          ) {
            this.Daily_h_err =
              " **Daily hours should not be exceed " +
              this.ConvertMinutesToTime(
                parseInt(
                  Math.trunc(
                    parseInt(this.reqObj.estimated_time.hours * 60) +
                      parseInt(this.reqObj.estimated_time.minutes)
                  )
                )
              ) +
              "...";
          }
        }
        if (
          this.t_a_t <=
            parseInt(
              Math.trunc(
                parseInt(this.reqObj.estimated_time.hours * 60) +
                  parseInt(this.reqObj.estimated_time.minutes)
              )
            ) &&
          this.t_a_t != 0
        ) {
          check_operation++;
        } else {
          if (
            this.t_a_t >
            parseInt(
              Math.trunc(
                parseInt(this.reqObj.estimated_time.hours * 60) +
                  parseInt(this.reqObj.estimated_time.minutes)
              )
            )
          ) {
            this.Total_h_err =
              " **Total hours should not be exceed " +
              this.ConvertMinutesToTime(
                parseInt(
                  Math.trunc(
                    parseInt(this.reqObj.estimated_time.hours * 60) +
                      parseInt(this.reqObj.estimated_time.minutes)
                  )
                )
              ) +
              "...";
          }
        }
        if (
          check_operation == 2 &&
          this.show_total_time_err.length == 0 &&
          this.show_daily_time_err.length == 0
        ) {
          this.isSubmitOrNot = true;
        }
      }
    },
    newUpdateTask() {
      this.reqObj["action"] = "update";
      user
        .UpdateTaskDetails(this.reqObj)
        .then(() => {
          Swal.fire("Success", "Task updated successfully...", "success").then(
            () => {
              this.resetObject();
            }
          );
        })
        .catch(() => {
          Swal.fire("Error", "Something went wrong....", "error");
        });
    },
  },
  watch: {
    status_id: {
      handler(nv, ov) {
        if (nv !== undefined) {
          this.reqObj.statusId = nv;
          this.buttonText = "Create";
          this.getTaskCategory();
        }
      },
      immediate: true,
    },
    update_task_id: {
      handler(nv, ov) {
        // console.log(nv);
        if (nv !== undefined) {
          this.editid = nv;
          this.getTaskbyid(this.editid);
          this.buttonText = "Update";
          this.getTaskCategory();
          this.reqObj.task_id = null;
          this.reqObj.client_name = "";
        } else {
          this.data_loaded = true;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(_, from, update) {
    let fromTask = true;
    // console.log(to);
    if (from.path == "/tasks") {
      fromTask = true;
      localStorage.setItem("fromTask", fromTask);
    }
    if (from.path == "/dashboard") {
      fromTask = false;
      localStorage.setItem("fromTask", fromTask);
    }
    update();
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
@font-face {
  font-family: "dm-sans";
  src: url("../fonts/DMSans-Regular.ttf");
}
* {
  font-family: "dm-sans";
}
#mandatory::after {
  content: "*";
  font-size: 15px;
  padding: 0px 2px;
  position: absolute;
  color: red;
}
#input_size {
  height: 40px;
  width: 60px;
}
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.addtask_multiselect .multiselect__tags {
  height: auto;
  max-height: 120px;
  max-width: 100%;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 0px !important;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  overflow-y: scroll;
  overflow-x: none !important;
  scroll-behavior: smooth;
}
.time_cal_hours {
  width: 110px;
  /* border:1px solid red; */
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  height: 45px;
}
.time_cal_minutes {
  width: 110px;
  /* border:1px solid blue; */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
  height: 45px;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
