<template>
  <transition model="out-in" name="fade">
    <div class="mt-4" v-if="show_alert">
      <div
        class="alert alert-warning d-flex justify-content-between align-items-center py-1"
        role="alert"
      >
        <strong class="fs-6"
          >You have {{ request_count }} pending report clarification
          requests</strong
        >
        <button class="btn btn-link" @click="redirectToActivities">
          Resolve Now
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import user from "@/ajax/user";
export default {
  data() {
    return {
      request_count: 0,
      show_alert: false,
    };
  },
  mounted() {
    this.getAllReportClarificationRequests();
    // this.checkForHidingAlert();
  },
  methods: {
    checkForHidingAlert() {
      if (this.$route.name == "Reports") {
        const hide_clarification_alert = JSON.parse(
          localStorage.getItem("hide_clarification_alert")
        );
        if (hide_clarification_alert) {
          this.show_alert = false;
          localStorage.removeItem("hide_clarification_alert");
          return;
        }
      }
      this.getAllReportClarificationRequests();
    },
    redirectToActivities() {
      if (this.$route.name == "Reports") {
        this.$router.go(0);
        localStorage.setItem("hide_clarification_alert", true);
      } else {
        this.$router.push("/reports");
      }
      localStorage.setItem("show_clarification_requests", true);
    },
    getAllReportClarificationRequests() {
      user
        .get_report_clarification_requests()
        .then((res) => {
          this.request_count = res.data.data.count;
          if (this.request_count) {
            this.show_alert = true;
          }
        })
        .catch((err) => {
          this.show_alert = false;
          this.request_count = 0;
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-action {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
