var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.permissions.includes('goals.read'))?_c('div',{staticClass:"card border-0 shadow-sm my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-2 pb-3"},[_c('h4',{staticClass:"text-primary m-0"},[_vm._v("Goals")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.goalModalHandler('add', undefined, 'your')}}},[_vm._v(" Add Goal ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions.includes('goals.read')),expression:"permissions.includes('goals.read')"}],staticClass:"p-2"},[_c('h5',{staticClass:"text-primary mb-3"},[_vm._v("Your Goals")]),_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table no-border","mode":"remote","totalRows":_vm.Filtered_Records_Your_Goals,"isLoading":_vm.isLoadingYourGoals,"pagination-options":{
              enabled: true,
              perpageDropdown: _vm.perpage_list,
            },"rows":_vm.your_goals_list_row,"columns":_vm.your_goals_list_column},on:{"on-page-change":_vm.onPageChangeYourGoals,"on-sort-change":_vm.onSortChangeYourGoals,"on-column-filter":_vm.onColumnFilterYourGoals,"on-per-page-change":_vm.onPerPageChangeYourGoals,"update:isLoading":function($event){_vm.isLoadingYourGoals=$event},"update:is-loading":function($event){_vm.isLoadingYourGoals=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"table-icon d-inline-block"},[(
                    props.row.created_by == _vm.currentUser.id &&
                    _vm.permissions.includes('goals.update')
                  )?_c('button',{staticClass:"btn-pencil",on:{"click":function($event){return _vm.goalModalHandler('update', props.row.id, 'your')}}},[_c('i',{staticClass:"fa fa-pencil fs-5"})]):_vm._e()]):(props.column.field == 'assigned_by')?_c('span',[(props.row.created_by == props.row.user_id)?_c('p',[_vm._v("Self")]):_c('p',[_vm._v(_vm._s(_vm.ConcateFirstNameAndLastName(props.row)))])]):(props.column.field == 'description')?_c('span',[_c('read-more',{attrs:{"more-str":"read more","text":props.row.description,"less-str":"read less","max-chars":100}})],1):(props.column.field == 'progress')?_c('span',[_c('div',{},[_c('span',{staticClass:"fw-bold d-block"},[_vm._v(_vm._s(((props.row.progress / 100) * 100).toFixed(0))+"%")]),_c('small',{staticClass:"text-primary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.UpdateProgress(props.row)}}},[_vm._v("update")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'start_date')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'end_date')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'progress')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"progress"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParamsYourGoals}})]}}],null,false,1549979477)})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions.includes('goals.read')),expression:"permissions.includes('goals.read')"}],staticClass:"p-2"},[_c('h5',{staticClass:"text-primary mb-3"},[_vm._v("Team Mates Goals")]),_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table no-border","mode":"remote","totalRows":_vm.Filtered_Records_Team_mates_Goals,"isLoading":_vm.isLoadingTeamMatesGoals,"pagination-options":{
              enabled: true,
              perpageDropdown: _vm.perpage_list,
            },"rows":_vm.team_mates_goals_list_row,"columns":_vm.team_mates_goals_list_column},on:{"on-page-change":_vm.onPageChangeTeamMatesGoals,"on-sort-change":_vm.onSortChangeTeamMatesGoals,"on-column-filter":_vm.onColumnFilterTeamMatesGoals,"on-per-page-change":_vm.onPerPageChangeTeamMatesGoals,"update:isLoading":function($event){_vm.isLoadingTeamMatesGoals=$event},"update:is-loading":function($event){_vm.isLoadingTeamMatesGoals=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',{staticClass:"p-2"},[(
                    props.row.created_by == _vm.currentUser.id &&
                    _vm.permissions.includes('goals.update')
                  )?_c('button',{staticClass:"btn-pencil",on:{"click":function($event){return _vm.goalModalHandler('update', props.row.id, 'team')}}},[_c('i',{staticClass:"fa fa-pencil fs-5"})]):_vm._e()]):(props.column.field == 'assigned_by')?_c('span',[_c('p',[_vm._v(_vm._s(_vm.ConcateAssignedBy(props.row)))])]):(props.column.field == 'username')?_c('span',[_c('p',[_vm._v(_vm._s(_vm.ConcateFirstNameAndLastName(props.row)))])]):_vm._e(),(props.column.field == 'description')?_c('span',[_c('read-more',{attrs:{"more-str":"read more","text":props.row.description,"less-str":"read less","max-chars":100}})],1):(props.column.field == 'progress')?_c('span',[_vm._v(" "+_vm._s(((props.row.progress / 100) * 100).toFixed(0))+"% ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'start_date')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'end_date')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"format":_vm.formateDate,"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.field == 'progress')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"number","min":"0","placeholder":"progress"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParamsTeamMatesGoals}})]}}],null,false,1110945888)})],1)])])]):_c('div',[_c('UnauthorizedPage')],1),_c('div',{staticClass:"modal fade",attrs:{"id":"goalModal","role":"dialog","aria-hidden":"true","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"m-0 text-dark",attrs:{"id":"taskModalLongTitle"},domProps:{"textContent":_vm._s(_vm.modal_title)}}),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[(_vm.modal_title == 'Add Goal')?_c('add-goals',{on:{"close-modal":function($event){return _vm.goalModalHandler('close')}}}):_vm._e(),(_vm.modal_title == 'Update Goal')?_c('add-goals',{attrs:{"goal_id":_vm.goal_id},on:{"close-modal":function($event){return _vm.goalModalHandler('close')}}}):_vm._e()],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }