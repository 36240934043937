var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.permissions.includes('quotes.list'))?_c('div',{},[_c('div',{staticClass:"card border-0 shadow-sm my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-2 pb-3"},[_c('h4',{staticClass:"text-primary m-0"},[_vm._v("Quotes")]),(_vm.permissions.includes('quotes.create'))?_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.quoteModalHandler('add')}}},[_vm._v(" Add Quote ")]):_vm._e()]),(_vm.permissions.includes('quotes.list'))?_c('div',{staticClass:"p-2"},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table no-border","mode":"remote","totalRows":_vm.totalRecords,"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            },"rows":_vm.rows,"columns":_vm.columns},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'Action')?[(_vm.permissions.includes('quotes.delete'))?_c('button',{staticClass:"btn-trash m-2",on:{"click":function($event){return _vm.delete_quote(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash fs-5"})]):_vm._e()]:_vm._e()]}},{key:"column-filter",fn:function({ column, updateFilters }){return [(column.field == 'date')?_c('span',[_c('datepicker',{attrs:{"typeable":true,"format":'yyyy-MM-dd',"placeholder":"yyyy-mm-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('CustomPagination',{staticClass:"p-2",attrs:{"pageTabs":4,"total":props.total,"pageChanged":props.pageChanged,"perPageChanged":props.perPageChanged,"serverParams":_vm.serverParams}})]}}],null,false,17397461)})],1):_vm._e()])]),_c('div',{staticClass:"modal fade",attrs:{"id":"quoteModal","aria-hidden":"true","tabindex":"-1","data-bs-backdrop":"static","data-bs-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"text-dark font-weight-bold mb-0",attrs:{"id":"taskModalLongTitle"},domProps:{"textContent":_vm._s(_vm.modal_title)}}),_c('button',{staticClass:"btn-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}})]),_c('div',{staticClass:"modal-body"},[(_vm.modal_title == 'Add Quote')?_c('add-quotes',{on:{"close-modal":function($event){return _vm.quoteModalHandler('close')}}}):_vm._e()],1)])])])]):_c('div',[_c('UnauthorizedPage')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }