<template>
<div class="container-fluid" style="color:#000000">
  <div class="pl-4 pr-5 pt-0">
    <div class="row m-0">
      <div class="col-10 m-0 p-0" style="color:black;font-size:24px">
        <b>Leaves</b>
      </div>
      <div class="col-md p-0 text-right">
        <div class="d-inline">
          <router-link
            style="color:#49085e;background-image:linear-gradient(to right,#EDF1FF , #FAE3D09B ,#CCFFF0A3,#F9E5E7C4);font-weight: bold;"
            :to="{ name: 'AddCreditDebit' }"
            class="btn shadow-none rounded p-0 p-1 mb-2 creditdebitbuttonstatus"
            v-if="permissions.includes('credit_debit.read')"
          >
          <!-- <img src="../img/plus-slash-minus.svg" alt="plus minus sign" class="mb-1 mr-2">  -->
          Credit/Debit
          </router-link>
        </div>
        <div class="d-inline ml-5">
          <router-link
          style="color:#49085e;background-image:linear-gradient(to right,#EDF1FF , #FAE3D09B ,#CCFFF0A3,#F9E5E7C4);font-weight: bold;"
            :to="{ name:'LeavesStatus'}"
            class="btn shadow-none rounded p-0 p-1 mb-2 creditdebitbuttonstatus"
            v-if="permissions.includes('leaves_status.report')"
          >
          Status
          </router-link>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <ul class="nav mt-3 mb-3 w-100" style="border-bottom:1px solid #C9C9C96C;">
        <li class="nav-item mr-4">
          <button
          id="your_leaves"
          class="btn shadow-none rounded-0 p-0 pb-2"
          @click="ViewData(true,false)"
          :style="
                  this.your_leave == true
                    ? style='color:#49085e;border-bottom:3px solid #49085e;'
                    : style='color:#79869F'
                ">
         <img src="../img/home.svg" alt="Card View image" class="mb-1 mr-2">  Your Leaves</button>
        </li>
        <li class="nav-item">
          <button
          id="team_mates_leaves"
          class="btn shadow-none rounded-0 p-0 pb-2"
          @click="ViewData(false,true)"
          :style="
                  this.team_member_leave == true
                    ? style='color:#49085e;border-bottom:3px solid #49085e;'
                    : style='color:#79869F'
                ">
          <img src="../img/kanban-view.svg" alt="Card View image" class="mb-1 mr-2">   Team Members Leaves</button>
        </li>
      </ul>
    </div>
    <span v-if="your_leave">
    <div class="row m-0 p-0">
      <div class="col m-0 p-0 mt-3 mb-3">
        <div class="card" style="width:380px;border:0px;box-shadow: 0px 4px 10px #EFEFEF;border-radius: 10px;height: 114px;">
          <div class="card-body container">
            <div class="row">
              <div class="col col-lg-3 col-md-3 text-center">
                <div class="pt-2 pb-2" style="font-size:37px;color:#4E73DF;background-color:#EDF1FF;border-radius: 10px;">{{SetTotal(this.total_count)}}</div>
              </div>
              <div class="col offset-md-1">
                <div style="font-size:22px;">Available <br>Leaves</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col m-0 p-0 mt-3 mb-3">
        <div class="card" style="width:380px;border:0px;box-shadow: 0px 4px 10px #EFEFEF;border-radius: 10px;height: 114px;">
          <div class="card-body container">
            <div class="row">
              <div class="col col-lg-3 col-md-3 text-center">
                <div class="pt-2 pb-2" style="font-size:37px;color:#FC9B3A;background-color:#FAE3D09B;border-radius: 10px;">{{SetTotal(this.total_pending)}}</div>
              </div>
              <div class="col offset-md-1">
                <div style="font-size:22px;">Pending Leaves <br>Request</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col m-0 p-0 mt-3 mb-3">
        <div class="card" style="width:380px;border:0px;box-shadow: 0px 4px 10px #EFEFEF;border-radius: 10px;height: 114px;">
          <div class="card-body container">
            <div class="row">
              <div class="col col-lg-3 col-md-3 text-center">
                <div class="pt-2 pb-2" style="font-size:37px;color:#00B17B;background-color:#CCFFF0A3;border-radius: 10px;">{{SetTotal(this.total_approved)}}</div>
              </div>
              <div class="col offset-md-1">
                <div style="font-size:22px;">Approved <br>Leaves</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col m-0 p-0 mt-3 mb-3">
        <div class="card" style="width:380px;border:0px;box-shadow: 0px 4px 10px #EFEFEF;border-radius: 10px;height: 114px;">
          <div class="card-body container">
            <div class="row">
              <div class="col col-lg-3 col-md-3 text-center">
                <div class="pt-2 pb-2" style="font-size:37px;color:#EF5450;background-color:#F9E5E7C4;border-radius: 10px;">{{SetTotal(this.total_rejected)}}</div>
              </div>
              <div class="col offset-md-1">
                <div style="font-size:22px;">Rejected <br>Leaves</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row m-3 p-2"> -->
      <!-- <div class="col container">
        <div class="row alert bg-white" style="border:0px;box-shadow:2px 2px 7px #dcdede;">
          <div class="d-flex flex-row col-sm col-md-1 col-lg-1">
            <div class="p-2"><span class="pl-3 pr-3 pt-1 pb-1" style="border:2px solid red;border-radius:50%;"><i class="fa fa-exclamation text-danger" aria-hidden="true"></i></span></div>
          </div>
          <div class="d-flex flex-row col-sm col-md-8 col-lg-8">
            <div class="p-2"><span style="font-size:20px"> You were absent on 12th August 2022</span></div>
          </div>
          <div class="d-flex flex-row col-sm col-md-3 col-lg-3">
            <div class="p-2"><a href="#" class="alert-link">Apply On Duty</a></div>
            <div class="p-2"><a href="#" class="alert-link">Apply Leave</a></div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    </span>
    <div class="row m-0 mt-4" style="background: #FFFFFF 0% 0% no-repeat padding-box;box-shadow: 0px 4px 10px #EFEFEF;border-radius: 10px;" id="LeaveUi">
      <div class="container-fluid ml-3 mr-3">
        <div class="row mb-4 mt-4">
          <div class="col" style="color:black;font-size:24px">
            <b class="">Leaves</b>
            <br>
            <select v-model="searchRange" @change="searchRangeFilter()" class="form-control" style="width: 237px;margin-top:15px;height: 35px;">
              <option value="2">Last 2 months</option>
              <option value="4">Last 4 months</option>
              <option value="6">Last 6 months</option>
              <option value="8">Last 8 months</option>
              <option value="10">Last 10 months</option>
              <option value="12">Last 12 months</option>
            </select>
          </div>
          <div class="col text-right" v-if="your_leave">
            <button
              class="btn"
              style="width: 170px;height: 44px;background: #4E73DF 0% 0% no-repeat padding-box;color:#FFFFFF"
              v-if="permissions.includes('leaves.read') && permissions.includes('leaves.add')"
              @click="PerformActionOnLeave('create',null)"
            >
              <b>Apply</b>
            </button>
          </div>
        </div>
        <div class="row p-0 m-0 pb-5">
          <div class="col p-0 m-0">
              <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="result_count"
                :isLoading.sync="isLoading"
                :sort-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                }"
                :rows="rows"
                :columns="columns"
              >
                <template slot="table-row" scope="props">
                  <span v-if="props.column.label == 'User'">
                    {{custom_label(props.row)}}
                  </span>
                  <span v-else-if="props.column.field == 'reason'">
                     <read-more more-str="read more" :text="props.row.reason" less-str="read less" :max-chars="30"></read-more>
                  </span>
                  <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.stage == 'approved'" class="text-success font-weight-bold" >Approved</span>
                    <span v-if="props.row.stage == 'applied'" class="text-warning font-weight-bold" >Pending</span>
                    <span v-if="props.row.stage == 'rejected'" class="text-danger font-weight-bold" >Rejected</span>
                    <span v-if="props.row.stage == 'deleted'" class="text-primary font-weight-bold" >Deleted</span>
                    <!-- <span v-if="props.row.stage == 'deleted'" class="text-success font-bold" >{{props.row.stage}}</span> -->
                  </span>
                  <span v-else-if="props.column.label == 'Action'">
                    <button v-if="permissions.includes('approvedleaves.update') && props.row.stage=='approved' && team_member_leave"
                        class="btn btn-link"
                        data-toggle="modal" data-target="#exampleModalCenter"
                        @click="modalDataTransfer(props.row)"
                        >
                        update
                    </button>
                    <div v-if="props.row.stage == 'applied'">
                        <!-- view button -->
                          <i v-if="your_leave" class="btn" @click="DisplayLeave(props.row.id)">
                            <img src="../img/view-optimis.png" alt="view">
                          </i>
                          <i v-if="your_leave" @click="PerformActionOnLeave('update',props.row.id)" class="btn">
                              <img src="../img/Group 2005.png" alt="edit">
                            </i
                          >
                          <!-- <router-link
                            :to="'/update-leave-application/' + props.row.id"
                            v-bind:class="[
                              currentPage == 'UpdateLeaveApplication'
                                ? 'active'
                                : '',
                              'nav-item',
                            ]"
                            v-if="props.row.user_id == currentUser.id"
                          >

                            <i class="btn action-btn"
                              style="background-color:#FC9B3A;color:white;width: 36px;height: 36px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                              </svg>
                              </i
                            >
                          </router-link> -->

                          <i v-if="props.row.user_id != currentUser.id"
                             class="btn m-0 p-0 mr-3"
                             @click="approve_leave(props)"
                          >
                              <img src="../img/Group 2005.svg" alt="reject">
                          </i>

                          <i
                            @click="reject_leave(props.row.id)"
                            class="btn m-0 p-0"
                            v-if="props.row.user_id != currentUser.id"
                            >
                              <img src="../img/Group 2008.svg" alt="reject">
                          </i>

                          <a
                            @click="delete_leave(props.row.id)"
                            class="btn"
                            v-if="props.row.user_id == currentUser.id"
                            >
                              <img src="../img/Group 2007.png" alt="edit">
                            </a
                          >
                    </div>
                    <div v-else>
                        <button v-if="your_leave" class="btn" @click="DisplayLeave(props.row.id)">
                          <img src="../img/view-optimis.png" alt="view">
                        </button>
                    </div>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>

                </template>

                <template
                  slot="column-filter"
                  slot-scope="{ column, updateFilters }"
                >
                  <!-- <span v-if="column.field == 'no_of_days'">
                    <input type="" :clear-button="true" placeholder="Days"
                      @input="(e) => updateFilters(column,e)"/>
                  </span> -->
                  <span v-if="column.field == 'from_date'">
                    <date-picker
                    v-model="time1"
                    placeholder="yyyy-mm-dd"
                    type="date"
                    value-type="YYYY-MM-DD"
                    :editable="false"
                    @input="
                      (e) =>
                        updateFilters(column,e)
                    ">
                    </date-picker>
                  </span>
                  <span v-else-if="column.field == 'to_date'">
                    <date-picker
                    v-model="time2"
                    placeholder="yyyy-mm-dd"
                    type="date"
                    value-type="YYYY-MM-DD"
                    :editable="false"
                    @input="
                      (e) =>
                        updateFilters(column,e)
                    ">
                    </date-picker>
                  </span>
                </template>
              </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="card-header modal-header">
          <h4 class="modal-title text-primary font-weight-bold " id="exampleModalLongTitle">leave application</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="card-body">
          <Leaves_popupVue :leave_data="modal_data" @close_modal="closeModal()"></Leaves_popupVue>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <b-modal centered modal-header-close id="leave-model-popup" size='xl'>
      <template #modal-header>
        <div class="d-inline p-2">
        <h2 style="color:black!important;font-size:24px"><b>Leave Details</b></h2>
        </div>
        <div class="d-inline p-2">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="HideModelPopupofLeave('details')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>
      <div class="container-fluid" style="color:black!important;font-size:18px!important">
        <div class="row mt-3 mb-3">
          <div class="col-md-2"><b>Leave Date(s)</b></div>
          <div class="col-md-4">
            <p v-if="formateDate(this.leave_details_popup['from_date']) != formateDate(this.leave_details_popup['to_date'])"><span> {{formateDate(this.leave_details_popup['from_date'])}} <b>To</b> {{formateDate(this.leave_details_popup['to_date'])}} (<b>{{this.leave_details_popup['no_of_days']}}</b> days)</span></p>
            <p v-else>
              {{formateDate(this.leave_details_popup['from_date'])}} (<b>{{this.leave_details_popup['no_of_days']}}</b> days)
            </p>
          </div>
          <div class="col-md-2"><b>Leave Reason </b></div>
          <div class="col-md-4"><p>
            <textarea type="text" class="w-100" v-text="leave_details_popup['reason']" style="border:none;background-color:white;outline:none" readonly></textarea>
          </p></div>
        </div>
        <div class="row mt-4">
          <div class="col-md-2"><b>Leave Applied on </b></div>
          <div class="col-md-4"><p>{{formateDate(this.leave_details_popup['created_at'])}}</p></div>
          <div class="col-md-2"><b>Leave Status :-</b></div>
          <div class="col-md-4">
            <div class="d-inline p-2">
              <span v-if="leave_details_popup['stage'] == 'approved'" class="text text-success font-weight-bold rounded p-1" style="background-color:rgba(0,500,0,0.1)">Approved</span>
              <span v-if="leave_details_popup['stage'] == 'applied'" class="text text-warning font-weight-bold rounded p-1" style="background-color:rgba(600,200,0,0.1)">Pending</span>
              <span v-if="leave_details_popup['stage'] == 'rejected'" class="text text-danger font-weight-bold rounded p-1" style="background-color:rgba(500,0,0,0.1)">Rejected</span>
              <span v-if="leave_details_popup['stage'] == 'deleted'" class="text text-primary font-weight-bold rounded p-1" style="background-color:rgba(0,0,500,0.1)">Deleted</span>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
          <button class="btn btn-primary " @click="hideModal" style="border-radius: 7px;height: 44px;width: 116px;" >OK</button>
        </div>
      </template>
  </b-modal>
  <b-modal centered modal-header-close id="leave-action-model-popup" size='xl'>
      <template #modal-header>
        <div class="d-inline p-2">
        <h2 style="color:black!important;font-size:24px"><b>Apply Leaves</b></h2>
        </div>
        <div class="d-inline p-2">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="HideModelPopupofLeave('action')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>
      <div class="container-fluid leave-action" style="color:black!important;font-size:18px!important">
        <div class="row mt-3 mb-3 p-2">
          <div class="col container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">User</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <select v-model="leave.user_id" class="form-control form-control-lg" required>
                  <option value="">Select user</option>
                  <option :key="index" v-for="(item, index) in users" :value="item.id">
                    {{custom_label(item)}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">Leave Type</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <select @change="days_count()" v-model="leave.types" class="form-control form-control-lg">
                  <option value="">Select Type</option>
                  <option value="full">Full Day</option>
                  <option value="half">Half Day</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4"></div>

        </div>
        <div v-if="this.leave.types != 'half'" class="row mt-3 mb-3 p-2">
          <div class="col container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">From Date</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <datepicker
                  placeholder="yyyy-mm-dd"
                  v-model="leave.from_date"
                  :clear-button="true"
                  :format="formateDate"
                  @input="set_date();days_count()"
                  required
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="col container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">To Date</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <datepicker
                  placeholder="yyyy-mm-dd"
                  v-model="leave.to_date"
                  :clear-button="true"
                  :disabled-dates="{to:disable_dates}"
                  :format="formateDate"
                  @closed="days_count()"
                  required
                >
                </datepicker>
              </div>
            </div>
          </div>
          <div class="col-4 container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">No of Days</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input type="text" v-model="leave.no_of_days"  class="form-control form-control-lg" readonly style="width: 126px;text-align:center">
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row mt-3 mb-3 p-2">
          <div class="col-4 container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">Date</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <datepicker
                  placeholder="yyyy-mm-dd"
                  v-model="leave.from_date"
                  :clear-button="true"
                  :format="formateDate"
                  @input="set_date()"
                  required
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="col-4 container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">Select Timing</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <select v-model="leave.half_day_for" class="form-control form-control-lg">
                  <option value="">Select Timing</option>
                  <option value="1nd Half">First Half</option>
                  <option value="2nd Half">Second Half</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-4"></div>
        </div>

        <div class="row mt-3 p-2">
          <div class="col container-fluid">
            <div class="row">
              <div class="col">
                <p class="m-0 p-0 mb-2">Reason</p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <textarea v-model="leave.reason" style="font-size:20px" class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="ex : for attending friends wedding"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
          <b-button class="btn btn-light mr-3 text-primary" @click="HideModelPopupofLeave('action')" style="border: 1px solid #B7B7B7;border-radius: 7px;height: 44px;width: 116px;"><b>Cancel</b></b-button>
          <button class="btn btn-primary " @click="verifyOpertations()" style="border-radius: 7px;height: 44px;width: 116px;" >Apply</button>
        </div>
      </template>
  </b-modal> -->

</div>
</template>

<script>
import user from '@/ajax/user';
import { mapState,mapActions } from 'vuex';
import Vue from 'vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import myMixin from '@/mixins/Permission'
import UnauthorizedPage from '@/views/Layouts/UnauthorizedPage.vue'
import Multiselect from 'vue-multiselect'
import ReadMore from 'vue-read-more';
import Leaves_popupVue from "./Leaves_popup.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.use(ReadMore);
Vue.use(VueSweetalert2);
const original_date = new Date();

export default {
  name: "Leaves",
  mixins: [myMixin],
  components:
  {
    Multiselect,
    Datepicker,
    UnauthorizedPage,
    Leaves_popupVue,
    DatePicker
  },
  data() {
    return {
      leave: {
        reason: "",
        from_date: "",
        to_date: "",
        no_of_days: 0.0,
        user_id: "",
        types: "",
        half_day_for: "",
        approved_by: 0,
        reject_by: 0,
        stage: "",
        user_id:"",
      },
      disable_dates:null,
      time1: null,
      time2: null,
      searchRange:"2",
      leave_details_popup:{},
      total_pending:null,
      total_approved:null,
      total_rejected:null,
      current_month: new Date().getMonth(),
      currentPage:'',
      isLoading: false,
      your_leave:true,
      team_member_leave:false,
      rows:[],
      columns:[],
      total_count:0,
      result_count:0,
      your_leave_list_column: [
        {
          label: "No Of Days",
          field: "no_of_days",
          width:'10%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "no of days", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Leave Type",
          field: "leave_type",
          width:'10%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "leaves", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["full", "half"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "From Date",
          field: "from_date",
          width:'10%',
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "To Date",
          field: "to_date",
          width:'10%',
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reason",
          field: "reason",
          width:'30%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "reason", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Status",
          field: "status",
          width:'10%',
        },
        {
          label: "Action",
          field: "action",
          width:'20%',
          sortable: false,
          thClass: "disable-sorting",
        },
      ],
      team_mates_leave_list_column: [
        {
          label: "User",
          field: "user_name",
          width:'15%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "users", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "No Of Days",
          field: "no_of_days",
          width:'10%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "no of days", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Leave Type",
          field: "leave_type",
          width:'10%',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "leaves", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["full", "half"],// dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "From Date",
          field: "from_date",
          width:'10%',
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "To Date",
          field: "to_date",
          width:'10%',
          sortable: true,
          filterable: true,
          formatFn: this.formatTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "To Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Reason",
          field: "reason",
          sortable: true,
          filterable: true,
          width: '15%',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "reason", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            // trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          width: '10%',
          thClass: "disable-sorting",
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
        setcurrentPage: 1,
      },
      permissions:window.localStorage.getItem('permissions'),
      modal_data:[],
      users:[],
      editid:0,
    };
  },

  beforeMount() {
    this.setCurrentPage("Attendance");
  },

  mounted() {
    if(this.permissions.includes('leaves.read')){
      this.getUserPermissions();
      this.ViewData(true,false);
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    modalDataTransfer(data){
      this.modal_data = data
    },
    closeModal(){
      // console.log("close modal");
      this.getTeamMatesLeaveList()
      $("#exampleModalCenter").modal('hide');
    },
    ViewData(y_l,t_m_l){
      this.your_leave = y_l
      this.team_member_leave = t_m_l
      this.total_count = null
      this.result_count = null
      if(this.your_leave){
        this.columns = this.your_leave_list_column
        this.getYourLeaveList()
      }else{
        this.columns = this.team_mates_leave_list_column
        this.getTeamMatesLeaveList()
      }
    },
    // get your leave list
    getYourLeaveList() {
      this.serverParams.columnFilters['searchRange'] = parseInt(this.searchRange)
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page:this.serverParams.page,
        perpage:this.serverParams.perPage
      };
      // console.log("search",fields);
      user.getYourLeaveList(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.result_count = result.data.data.f_total;
        this.total_count = result.data.data.total;
        this.total_pending = null
        this.total_approved = null
        this.total_rejected = null
        for(var i=0;i<this.rows.length;i++){
          if(this.rows[i]['stage'] == "applied"){
            this.total_pending++
          }else if(this.rows[i]['stage'] == "approved"){
            this.total_approved++
          }else if(this.rows[i]['stage'] == "rejected"){
            this.total_rejected++
          }
        }
      });
    },
    // get team mates leaves list
    getTeamMatesLeaveList() {
      this.serverParams.columnFilters['searchRange'] = parseInt(this.searchRange)
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page:this.serverParams.page,
        perpage:this.serverParams.perPage,
      };
      // console.log("search",fields);
    user.getTeamMatesLeaveList(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.result_count = result.data.data.f_total;
        // this.total_count = result.data.data.total;
      });
    },
    // start common functions
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      if(this.your_leave){
        this.getYourLeaveList();
      }else{
        this.getTeamMatesLeaveList()
      }
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      if(this.your_leave){
        this.getYourLeaveList();
      }else{
        this.getTeamMatesLeaveList()
      }
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
        if(this.your_leave){
          this.getYourLeaveList();
        }else{
          this.getTeamMatesLeaveList()
        }
    },
    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "" && params.columnFilters[key] !== null)
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
        if(this.your_leave){
          this.getYourLeaveList();
        }else{
          this.getTeamMatesLeaveList()
        }
    },
    // end common functions
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },

    // operational functions
    approve_leave(props) {
      // this.showDialog = true;
      let this2 = this;
      this.$swal({
        html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Approving....</h3>
            </div>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen() {
          let time = 0;
          let time_id = setInterval(() => {
            time++;
          }, 1000);

          setTimeout(() => {
            if (time >= 15) {
              clearInterval(time_id);
              this.$swal({
                title: "Slow internet connection",
                icon: "error",
                timer: 2500,
              });
            }
            if (navigator.onLine && time < 15) {
              user
                .approve_leaves(props.row.id)
                .then((result) => {
                  if (result.data.code == 200) {
                    Swal.fire({
                      title: result.data.message,
                      // text: "Leave approved succesfully",
                      icon: "success",
                      timer: 2500,
                    }).then(() => {
                      this2.getTeamMatesLeaveList();
                    });
                  } else {
                    Swal.fire({
                      title:result.data.message,
                      // text: "Failed to approve leave",
                      icon: "error",
                      timer: 2500,
                    });
                  }
                })
                .catch(() => {
                  Swal.fire({
                    title: "Something went wrong",
                    text: "Unable to perform the action",
                    icon: "error",
                    timer: 2500,
                  });
                });
            } else {
              Swal.fire({
                title: "No internet connection",
                text: "Please check your internet connecction",
                icon: "error",
                timer: 2500,
              });
            }
          }, 1000);
        },
      });
    },
    DisplayLeave(id){
      this.leave_details_popup = {}
       user.getLeaveById(id).then((result) => {
          if(result.data.code == 200){
            this.leave_details_popup = result.data.data.leaves[0]
            this.$root.$emit('bv::show::modal', 'leave-model-popup');
          }
       })
    },
    formatTime(value){
      return moment(value).format("yyyy-MM-DD");
    },
    reject_leave(id) {
      let this2 = this;
      Swal.fire({
        title: "Reject",
        text: "Are you sure you want to reject?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, reject it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            html: `<div class='p-5 overflow-hidden h-100'>
              <div class='spinner-border text-primary' style='width:4rem;height:4rem'></div>
              <h3 class='mt-5'>Rejecting....</h3>
            </div>`,
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen() {
              let time = 0;
              let time_id = setInterval(() => {
                time++;
              }, 1000);

              setTimeout(() => {
                if (time >= 15) {
                  clearInterval(time_id);
                  this.$swal({
                    title: "Slow internet connection",
                    icon: "error",
                    timer: 2500,
                  });
                }
                if (navigator.onLine && time < 15) {
                  user
                    .reject_leave(id)
                    .then((result) => {
                      if (result.data.code == 200) {
                        Swal.fire({
                          title: result.data.message,
                          // text: "Leave rejected successfully",
                          icon: "success",
                          timer: 2500,
                        }).then(() => this2.getTeamMatesLeaveList());
                      } else {
                        Swal.fire({
                          title: result.data.message,
                          // text: "Failed to reject the leave",
                          icon: "error",
                          timer: 2500,
                        });
                      }
                    })
                    .catch(() => {
                      Swal.fire({
                        title: "Something went wrong",
                        text: "Unable to reject the leave",
                        icon: "error",
                        timer: 2500,
                      });
                    });
                } else {
                  Swal.fire({
                    title: "No internet connection",
                    text: "Please check your internet connecction",
                    icon: "error",
                    timer: 2500,
                  });
                }
              }, 1000);
            },
          });
        }
      });
    },
    delete_leave(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // this.$swal("Deleted", "You successfully delete this Leave", "success");
          user.delete_leave(id).then((result) => {
            // this.$router.push({name: 'LeavesApplication'});
            // alert("deleted successfully");
            Swal.fire("Success","Deleted Successfully.....!","success");
            this.getYourLeaveList()
            this.getTeamMatesLeaveList()
          });
          // window.location.reload();
        }
      });
    },
    custom_label (name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if(name.last_name!=null && name.middle_name!=null){
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      }
      else if(name.last_name==null){
        return `${name.first_name} ${name.middle_name}`;
      }
      else if(name.middle_name==null){
        return `${name.first_name} ${name.last_name}`;
      }
    },
    SetTotal(val){
      var s = val+"";
      if(val != null){
        while (s.length < 2) s = "0" + s;
        return s;
      }else{
        return '00'
      }
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'leave-model-popup');
    },
    HideModelPopupofLeave(type){
      if(type == 'details'){
        this.$root.$emit('bv::hide::modal', 'leave-model-popup');
      }
      if(type == 'action'){
        this.$root.$emit('bv::hide::modal', 'leave-action-model-popup');
      }
    },
    PerformActionOnLeave(action,leave_id){
      if(action == 'update' && leave_id != null){
        this.editid = leave_id
        this.getuseridDropdown();
        this.getLeavesById(leave_id)
        this.$root.$emit('bv::show::modal', 'leave-action-model-popup');
      }
      if(action == 'create' && leave_id == null){
        this.leave.reason = "";
        this.leave.from_date = "";
        this.leave.to_date = "";
        this.leave.no_of_days = 0;
        this.leave.user_id = "";
        this.leave.types = "";
        this.leave.half_day_for = "";
        this.leave.approved_by = 0;
        this.leave.reject_by = 0;
        this.leave.stage = "";
        this.editid == 0
        this.getuseridDropdown();
        this.$root.$emit('bv::show::modal', 'leave-action-model-popup');
      }
    },
    searchRangeFilter(){
      if(this.your_leave){
        this.getYourLeaveList()
      }else{
        this.getTeamMatesLeaveList()
      }
    },
    set_date() {
      this.disable_dates = this.leave.from_date
    },
    days_count() {
      const moment = require("moment");
      if (this.leave.types == "full") {
        const startDate = moment(this.leave.from_date).format("YYYY-MM-DD");
        const endDate = moment(this.leave.to_date).format("YYYY-MM-DD");
        // if(startDate.getDay() == 6 || startDate.getDay() == 5) {
        // }
        const diffInDays = moment(endDate).diff(moment(startDate), "days");
        this.leave.no_of_days = diffInDays + 1;
      }else{
        this.leave.no_of_days = 0.5;
      }
    },

    verifyOpertations() {
      // if(this.leave.no_of_days > 0){

      // }else{
      //   break
      // }
      if (this.leave.to_date == "" || this.leave.to_date == null) {
        this.leave.to_date = this.leave.from_date;
      }

      if (this.leave.from_date > this.leave.to_date) {
        this.leave.to_date = this.leave.from_date;
      }

      if (this.editid == 0) {
        this.leave.stage = "applied";
        user.createLeaves(this.leave).then((result) => {
          if (result.data.code == 200) {
            this.getYourLeaveList()
            this.HideModelPopupofLeave('action')
            this.$swal("Success", "Leave created successfully...", "success");
          }
          if (result.data.code == 400) {
            this.HideModelPopupofLeave('action')
            this.$swal("Error", "Not valid data...", "warning");
          }
          // this.leave.reason = "";
          // this.leave.from_date = "";
          // this.leave.to_date = "";
          // this.leave.no_of_days = 0;
          // this.leave.user_id = "";
          // this.leave.types = "";
          // this.leave.half_day_for = "";
          // this.leave.approved_by = 0;
          // this.leave.reject_by = 0;
          // this.leave.stage = "";
        });
      } else {
        this.leave.stage = "applied";
        user.updateLeaves(this.editid, this.leave).then((result) => {
          if (result.data.code == 200) {
            this.getYourLeaveList()
            this.HideModelPopupofLeave('action')
            this.$swal('Success','Leave updated successfully...', 'success')
          } else if (result.data.code == 400) {
            this.HideModelPopupofLeave('action')
            this.$swal('Error','Leave already applied...', 'warning')
          }
        });
      }
    },
    getLeavesById(id) {
      user.getLeaveById(id).then((result) => {
        // console.log(result.data.data);
        this.leave.reason = result.data.data.leaves[0]["reason"];
        this.leave.from_date = result.data.data.leaves[0]["from_date"];
        this.leave.to_date = result.data.data.leaves[0]["to_date"];
        this.leave.no_of_days = result.data.data.leaves[0]["no_of_days"];
        this.leave.types = result.data.data.leaves[0]["leave_type"];
        this.leave.half_day_for = result.data.data.leaves[0]["half"];
        this.leave.user_id = result.data.data.leaves[0]["user_id"];
        // this.leave.user_id = {id: result.data.data.leaves[0]["user_id"],first_name: result.data.data.leaves[0]["first_name"],last_name: result.data.data.leaves[0]["last_name"],middle_name: result.data.data.leaves[0]["middle_name"]}
      });
    },
    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = result.data.data;
      });
    },
  },
  computed: {
    ...mapState('userStore', ['currentUser'])
  },
};
</script>
<style>
/* .leave-action .mx-datepicker .mx-input-wrapper .mx-input {
    text-align: left;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
.leave-action input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
    width: 22px;
    height: 22px;
}
.leave-action .vdp-datepicker input[type="text"] {
    display: block;
    width: 100%;
    height:48px;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    text-align: left;
}
.leave-action .multiselect__tags {
    height: 48px;
    max-height:none;
    max-width: none;
    display:block;
    padding: 8px 40px 0 8px;
    border-radius: 0.35rem!important;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    background-clip: padding-box;
    font-size: 14px;
    overflow-y: none;
    overflow-x:none!important;
    scroll-behavior: none;
    color: #6e707e;
} */
/* vue good table css */
/* #LeaveUi .vgt-table thead th {
    text-align: left;
    color: #606266;
    vertical-align: bottom;
    border-bottom: 1px solid #dcdfe6;
    padding-right: 1.5em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
#LeaveUi .vgt-table thead{
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
#LeaveUi .vgt-table.bordered th {
    border: none;
    text-align: left;
    background: #EFF0F7 0% 0% no-repeat padding-box;
    text-align: center;
} */
/* .vgt-table.bordered th span{
    background: #EFF0F7 0% 0% no-repeat padding-box;
} */
/* .vgt-table.bordered th span,button{
    background-color: #858585;
} */
/*.vgt-table th.filter-th {
    padding:0px;
} */
/* #LeaveUi .vgt-table.bordered td{
    text-align: left;
    border:none;
    border-bottom: 1px solid #EFF0F7 ;
    text-align: center;
}

#LeaveUi .vgt-wrap__footer {
    color: #606266;
    font-size: 1.1rem;
    padding: 1em;
    border: none;
    background: white;
}
#LeaveUi .vgt-global-search {
    padding: 5px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    border: none;
    border-bottom: 0;
    background-color: white!important;
}
#LeaveUi table.vgt-table {
    border: none;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
} */
/* #LeaveUi .vgt-wrap__footer .footer__row-count__select {
    font-size: 1.1rem;
    background: #EFF0F7 0% 0% no-repeat padding-box;
    width: 57px;
    height: 35px;
    padding: 0;
    border: 0;
    border-radius: 0;
    margin-left: 8px;
    color: #606266;
    font-weight: 700;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 5px;
    padding-left: 5px;
}
#LeaveUi .vgt-pull-right {
    float: right!important;
    background: #EFF0F7 0% 0% no-repeat padding-box;
    font-size: 1.1rem;
}
#LeaveUi .vgt-inner-wrap {
    border-radius:0;
    box-shadow:none;
} */

/* search boxes sizes */
/* #LeaveUi .vgt-input{
    text-align: center;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
#LeaveUi .vgt-select{
    text-align: center;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 30px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
#LeaveUi .vgt-table th.filter-th {
    padding: 0em 0.75em 0.75em 0.75em;
} */

/* action button css */
.action-btn{
    border-radius: 13px!important;
    padding: 4px 7px 4px 7px;
    margin: 0px;
    margin-left: 12px;
    margin-right: 12px;
}

.mx-datepicker .mx-input-wrapper .mx-input {
    text-align: center;
}
.modal-footer {
    border-top: 0px solid #e3e6f0;
}
.creditdebitbuttonstatus:hover{
  background-image:linear-gradient(to left,#EDF1FF , #FAE3D09B ,#CCFFF0A3,#F9E5E7C4)!important;
}
</style>

