<template>
  <div>
    <div
      class="card shadow-sm border-0 my-4"
      v-show="permissions.includes('leaves_status.report')"
    >
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-between flex-md-nowrap flex-wrap gap-md-0 gap-2 px-2 pb-3"
        >
          <h4 class="m-0 font-weight-bold text-primary order-md-0 order-1">
            Leaves
          </h4>
          <div
            class="order-3 order-md-0 mt-md-0 mt-2 mx-auto px-md-0 px-2"
            v-if="
              permissions.includes('users.read') ||
              permissions.includes('users.enable')
            "
          >
            <div class="btn-group" :disabled="disabled" role="group">
              <router-link
                :to="{ name: 'LeavesApplication' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesApplication'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves.read')"
                :disabled="disabled"
              >
                Leaves
              </router-link>
              <router-link
                :to="{ name: 'AddCreditDebit' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'AddCreditDebit'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('credit_debit.read')"
              >
                Credit/Debit
              </router-link>
              <router-link
                :to="{ name: 'LeavesStatus' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'LeavesStatus'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
                v-if="permissions.includes('leaves_status.report')"
              >
                Status
              </router-link>
            </div>
          </div>
          <router-link
            class="btn btn-primary btn-sm order-md-0 order-2"
            :to="{ name: 'AddLeaveApplication' }"
            v-if="
              permissions.includes('leaves.read') &&
              permissions.includes('leaves.add')
            "
            :disabled="disabled"
            :class="disabled ? 'disabled' : ''"
          >
            Apply
          </router-link>
        </div>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap mb-3 px-2"
        >
          <div
            class="d-flex justify-content-between align-items-center flex-wrap gap-3"
          >
            <p class="m-0"><b>From</b></p>
            <datepicker
              :minimumView="'month'"
              :maximumView="'year'"
              v-model="selected_months.from_month"
              :format="'yyyy-MM'"
              :disabled="disabled"
              :disabled-dates="{
                from: fromDateForPicker,
              }"
              :class="disabled ? 'disabled' : ''"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0'"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
            >
            </datepicker>
            <p class="m-0"><b>TO</b></p>
            <div class="">
              <datepicker
                :minimumView="'month'"
                :maximumView="'year'"
                v-model="selected_months.to_month"
                :disabled-dates="{
                  from: fromDateForPicker,
                }"
                :format="'yyyy-MM'"
                :disabled="disabled"
                :class="disabled ? 'disabled' : ''"
                :clear-button="true"
                :calendar-button="true"
                :clear-button-icon="'fa fa-times'"
                :input-class="'border-start-0'"
                :calendar-button-icon="'fa fa-calendar'"
                :bootstrap-styling="true"
              >
              </datepicker>
            </div>
          </div>
          <div
            class="d-flex justify-content-between align-items-center gap-3 flex-wrap mt-md-0 mt-4"
          >
            <div class="" v-if="permissions.includes('credit_debit.read')">
              <datepicker
                :minimumView="'month'"
                :maximumView="'year'"
                v-model="credit_debit_month"
                :format="'yyyy-MM'"
                :disabled="disabled"
                :clear-button="true"
                :calendar-button="true"
                :clear-button-icon="'fa fa-times'"
                :input-class="'border-start-0'"
                :calendar-button-icon="'fa fa-calendar'"
                :bootstrap-styling="true"
                :class="disabled ? 'disabled' : ''"
              >
              </datepicker>
            </div>
            <div class="" v-if="permissions.includes('credit_debit.read')">
              <a
                @click="LWP_month(credit_debit_month)"
                class="btn btn-outline-primary"
                title="Credit | Leave debit | LWP Credit Entry "
                :disabled="disabled"
                :class="disabled ? 'disabled' : ''"
              >
                Leave Calculate
              </a>
            </div>
          </div>
        </div>

        <!-- New table -->
        <div class="row rounded-0 px-3" style="overflow-x: auto">
          <div class="col-4 p-0 border-right border-secondary">
            <!-- <table class="table m-0 text-dark text-center first-table"> -->
            <table class="table m-0 text-dark text-center table-bordered">
              <thead>
                <tr>
                  <td colspan="3">&emsp;</td>
                </tr>
                <tr valign="middle">
                  <th scope="col" style="border-right: 0px solid black">
                    User
                  </th>
                  <th scope="col" style="border-right: 0px solid black">
                    Opening Balance
                  </th>
                  <!-- <th scope="col" style="border-right:1px solid black">Closing Balance</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in rows" :key="index">
                  <td v-text="i.user_detail.first_name"></td>
                  <td v-text="getOpeningValues(i.month_detail, 'opening')"></td>
                  <!-- <td v-text="getOpeningValues(i.month_detail,'closing')"></td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="col-5 p-0"
            :class="monthlist !== null ? 'd-flex' : ''"
            :style="monthlist !== null ? 'overflow-x:scroll' : ''"
            @scroll="scroller"
          >
            <!-- class="table table-bordered text-nowrap bg-white m-0 text-center table-hover credit-debit-table" -->
            <table
              class="table table-bordered text-nowrap bg-white m-0 text-center table-hover table-bordered"
              v-for="(value, main_key) in monthlist"
              :key="main_key"
            >
              <thead class="text-dark">
                <tr>
                  <!-- <th colspan="2" v-for="(v,k,c) in monthlist" v-text="k.substring(0,7)" :key="c"></th> -->
                  <th colspan="2" v-text="main_key.substring(0, 7)"></th>
                </tr>
              </thead>
              <thead class="text-dark">
                <tr>
                  <th>Credit</th>
                  <th>Debit</th>
                </tr>
              </thead>
              <tbody v-for="(value, key, count) in rows" :key="count">
                <tr>
                  <td
                    :id="'' + count + main_key.split('-')[1]"
                    v-if="value['month_detail'][main_key].credit !== null"
                    @click="
                      getUserLeaveTypes(
                        main_key,
                        'credit',
                        value['user_detail']['id']
                      )
                    "
                    tabindex="0"
                  >
                    <b-popover
                      :target="'' + count + main_key.split('-')[1]"
                      :ref="'' + count + main_key.split('-')[1]"
                      triggers="click blur"
                      placement="top"
                      variant="info"
                      @keypress="scroller"
                    >
                      <template #title>
                        <h6 class="text-center m-0 p-0">Leave Data</h6>
                      </template>
                      <div>
                        <table
                          class="table table-borderless text-center p-0 m-0 text-dark"
                          style="min-width: 15rem"
                        >
                          <thead>
                            <th
                              class="py-0 px-2"
                              v-for="(item, key, count) in leaveData"
                              :key="count"
                              style="width: 5rem"
                              v-text="item.leave_type_name"
                            ></th>
                          </thead>
                          <tbody>
                            <tr v-if="leaveData.length > 0">
                              <td
                                :class="
                                  item.leave_type_name == null ? 'py-2' : 'p-0'
                                "
                                v-for="(item, key, count) in leaveData"
                                :key="count"
                                tabindex="-1"
                              >
                                <span
                                  class="p-0"
                                  v-text="item.credit"
                                  v-if="item.leave_type_name !== null"
                                ></span>
                                <span class="py-5" v-else>No Leaves</span>
                              </td>
                            </tr>
                            <tr v-else>
                              <td>
                                <span
                                  class="spinner-border"
                                  style="height: 3rem; width: 3rem"
                                ></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-popover>
                    <span
                      v-text="value['month_detail'][main_key].credit"
                    ></span>
                    <span
                      v-if="value['month_detail'][main_key].credit_lwp > 0"
                      class="text-danger"
                      v-text="
                        ` (${value['month_detail'][main_key].credit_lwp} L)`
                      "
                    ></span>
                  </td>
                  <td v-text="'-'" v-else></td>

                  <td
                    v-if="value['month_detail'][main_key].debit !== null"
                    :id="'p' + count + main_key.split('-')[1]"
                    tabindex="0"
                    @click="
                      getUserLeaveTypes(
                        main_key,
                        'debit',
                        value['user_detail']['id']
                      )
                    "
                    @keypress="scroller"
                  >
                    <b-popover
                      :target="'p' + count + main_key.split('-')[1]"
                      :ref="'p' + count + main_key.split('-')[1]"
                      triggers="click blur"
                      variant="primary"
                      placement="top"
                    >
                      <template #title>
                        <h6 class="text-center m-0 p-0">Leave Data</h6>
                      </template>
                      <div>
                        <table
                          class="table table-borderless text-center p-0 m-0 text-dark"
                          style="min-width: 15rem"
                        >
                          <thead>
                            <th
                              class="py-0 px-2"
                              v-for="(item, key, count) in leaveData"
                              :key="count"
                              style="width: 5rem"
                              v-text="item.leave_type_name"
                            ></th>
                          </thead>
                          <tbody>
                            <tr v-if="leaveData.length > 0">
                              <td
                                :class="
                                  item.leave_type_name == null ? 'py-2' : 'p-0'
                                "
                                v-for="(item, key, count) in leaveData"
                                :key="count"
                                tabindex="-1"
                              >
                                <span
                                  class="p-0"
                                  v-text="item.debit"
                                  v-if="item.leave_type_name !== null"
                                ></span>
                                <span v-else>No Leaves</span>
                              </td>
                            </tr>
                            <tr v-else>
                              <td>
                                <span
                                  class="spinner-border"
                                  style="height: 3rem; width: 3rem"
                                ></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-popover>
                    <span v-text="value['month_detail'][main_key].debit"></span>
                    <span
                      v-if="value['month_detail'][main_key].debit_lwp > 0"
                      class="text-danger"
                      v-text="
                        ` (${value['month_detail'][main_key].debit_lwp} L)`
                      "
                    ></span>
                  </td>
                  <!-- <td  v-if="value['month_detail'][main_key].credit!==null">
                  <span v-text="value['month_detail'][main_key].credit"></span>
                  <span v-if="value['month_detail'][main_key].credit_lwp>0" class="text-danger" v-text="` (${value['month_detail'][main_key].credit_lwp} L)`"></span>
                </td>
                <td v-text="'-'" v-else></td>
                <td v-if="value['month_detail'][main_key].debit!==null">
                  <span v-text="value['month_detail'][main_key].debit"></span>
                  <span v-if="value['month_detail'][main_key].debit_lwp>0" class="text-danger" v-text="` (${value['month_detail'][main_key].debit_lwp} L)`"></span></td>
                <td v-text="'-'" v-else></td> -->
                </tr>
              </tbody>
            </table>
            <!-- <h3 class="text-center mt-5" v-if="isEmpty" >No data available</h3> -->
            <div
              class="container-fluid m-0 p-5 d-flex justify-content-center align-items-center"
              v-if="rows.length == 0"
            >
              <div
                class="spinner-border"
                role="status"
                style="height: 3rem; width: 3rem; color: #4e73df"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!-- <div class="col" v-else>No data</div> -->

          <div class="col-3 p-0">
            <!-- <table class="table text-nowrap bg-white m-0 text-center third-table"> -->
            <table
              class="table text-nowrap bg-white m-0 text-center table-bordered"
            >
              <thead class="text-dark">
                <tr>
                  <th>&emsp;</th>
                </tr>
                <tr>
                  <!-- <th scope="col" v-for="(v,k,c) in getMAReport" :key="c" v-text="new Date(k).getDate()"  style="border-right:1px solid black"></th> -->
                  <!-- <th scope="col" style="border-right:1px solid black">Value of credit / debit month wish</th> -->
                  <th scope="col">Closing Balance</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="i in numberOfUser(monthly_report)" :key="i" > -->
                <!-- <td v-for="(v,k,c) in getMAReport" :key="c" v-text="getSpentTime(v,i-1)" style="border-right:1px solid black;color:black" :class="getSpentTime(v,i-1)?'px-5':''" :style="`color:${setColorDetails(v,i-1)}`"></td> -->
                <!-- <th scope="col" style="border-right:1px solid black">Value 1 Opening Balance</th> -->
                <!-- </tr> -->
                <tr v-for="(value, index) in rows" :key="index">
                  <td
                    v-text="getOpeningValues(value.month_detail, 'closing')"
                  ></td>
                  <!-- <td v-text="getCreditDebit(value['month_detail'])"></td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- End New table -->

        <!-- <div class="card-body">
          <div class="table-responsive">
            <vue-good-table
              :rows="rows"
              :columns="columns"
              :sort-options="{
                enabled: true,
              }"
              @on-cell-click="cellClickHandler"
            >
            </vue-good-table>
          </div>
        </div> -->
      </div>
    </div>
    <vue-modal name="credit_debit">
      <div class="p-0 m-0">
        <div
          class="px-4 py-0 m-0 d-flex justify-content-between align-items-center card-header"
        >
          <h4
            class="text-primary my-3"
            v-text="
              cell_selected == 'credit'
                ? 'Credit Details'
                : cell_selected == 'debit'
                ? 'Debit Details'
                : ''
            "
          ></h4>
          <h6 class="text-dark mt-2">Username: {{ user_name }}</h6>
        </div>
        <div class="px-4 mt-4">
          <vue-good-table
            :columns="
              cell_selected == 'credit'
                ? credit_column
                : cell_selected == 'debit'
                ? debit_column
                : ''
            "
            :rows="
              cell_selected == 'credit'
                ? credit_rows
                : cell_selected == 'debit'
                ? debit_rows
                : ''
            "
            theme="polar-bear"
          >
          </vue-good-table>
        </div>
      </div>
    </vue-modal>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import VueDatetimePickerJs from "vue-date-time-picker-js";
import Vue from "vue";
import VModal from "vue-js-modal";
import { BPopover } from "bootstrap-vue";

Vue.use(VModal, { componentName: "vue-modal" });

export default {
  name: "AddLeave",
  components: {
    Multiselect,
    Datepicker,
    datePicker: VueDatetimePickerJs,
    BPopover,
  },
  data() {
    return {
      siteLogReq: {
        site_name: "leaves_status",
      },
      disabled: false,
      dateVal: "",
      credit_debit_month: new Date(),
      selected_months: {
        from_month: new Date(),
        to_month: new Date(),
      },
      currentPage: "",
      columns: [
        {
          label: "User",
          field: "first_name",
        },
        {
          label: "Opening Balance",
          field: "prev_month",
        },
        {
          label: "Credit",
          field: "credit",
        },
        {
          label: "Debit",
          field: "debit",
        },
        {
          label: "Closing Balance",
          field: "leave_balance",
        },
      ],
      credit_column: [
        {
          field: "lwp",
          label: "Username",
        },
        {
          field: "lwp",
          label: "LWP",
        },
        {
          field: "general",
          label: "General",
        },
        {
          field: "comp_off",
          label: "Comp Off",
        },
      ],
      debit_column: [
        {
          field: "lwp",
          label: "column_1",
        },
        {
          field: "general",
          label: "column_2",
        },
        {
          field: "comp_off",
          label: "column_3",
        },
      ],
      rows: [],
      credit_rows: [],
      debit_rows: [],
      dialog: false,
      cell_selected: "",
      user_name: "",
      total_records: null,
      monthlist: null,
      permissions: window.localStorage.getItem("permissions"),
      isEmpty: true,
      leaveData: [],
    };
  },
  mounted() {
    if (this.permissions.includes("leaves_status.report")) {
      this.getCurrentUser();
      // this.getLeaveReport();
      this.FilterApply(); //month pass remaining
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  watch: {
    "selected_months.from_month": function () {
      if (this.selected_months.from_month > this.selected_months.to_month) {
        this.selected_months.to_month = this.selected_months.from_month;
      } else {
        this.FilterApply();
      }
    },
    "selected_months.to_month": function () {
      if (this.selected_months.from_month > this.selected_months.to_month) {
        this.selected_months.to_month = this.selected_months.from_month;
      } else {
        this.FilterApply();
      }
    },
  },
  computed: {
    fromDateForPicker() {
      return new Date();
    },
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser"]),
    scroller(event) {
      this.$root.$emit("bv::hide::popover");
      this.leaveData = [];
    },
    // credit_month(month){
    //   month = moment(month).format("yyyy-MM-DD");
    //   user.credit_month(month).then((result) => {
    //     console.log("---- credit_month ",result)
    //   });
    // },
    // debit_month(month) {
    //   month = moment(month).format("yyyy-MM-DD");
    //   user.leave_debit_month(month).then((result) => {
    //     console.log("---- debit_month ",result)
    //   });
    // },
    LWP_month(month) {
      this.disabled = true;
      let dates = [];
      let today_date = moment().format("YYYY-MM-DD");
      let selected_date = moment(month).format("YYYY-MM-DD");
      dates.push(selected_date);
      if (moment(selected_date).isBefore(today_date, "month")) {
        dates.push(today_date);
      }
      // month = moment(month).format("yyyy-MM-DD");
      this.monthlist = [];
      this.leaveData = [];
      this.rows = [];
      user
        .LWP_month(dates)
        .then((result) => {
          this.FilterApply();
          this.$swal("Success", "Leave Calculate Successfully...", "success");
        })
        .catch((err) => {
          this.$swal("Error", "Something went wrong !!!", "error");
        });
      this.disabled = false;
    },

    getOpeningValues(monthly_data, state) {
      let keys = Object.keys(monthly_data);
      // console.log(monthly_data);
      if (state == "opening") {
        if (monthly_data[[keys[0]]].open !== null) {
          return monthly_data[keys[0]].open;
        } else {
          return "0";
        }
      } else {
        if (keys.length > 1) {
          // console.log(monthly_data[keys[keys.length-1]]);
          if (monthly_data[keys[keys.length - 1]].close !== null) {
            return monthly_data[keys[keys.length - 1]].close;
          } else {
            return "0";
          }
        } else {
          // console.log(monthly_data[keys[0]]);
          if (monthly_data[keys[0]].close) {
            return monthly_data[keys[0]].close;
          } else {
            return "0";
          }
        }
      }
    },
    getCreditDebit(monthly_data) {
      let credit = 0,
        debit = 0;
      for (let i in monthly_data) {
        credit += monthly_data[i].credit;
        debit += monthly_data[i].debit;
      }
      return Number.isNaN(credit / debit) ? 0 : credit / debit;
    },
    // get month range
    FilterApply() {
      this.disabled = true;
      if (
        this.selected_months.from_month != null &&
        this.selected_months.to_month != null
      ) {
        // console.log('send',this.selected_months)
        this.rows = [];
        this.monthlist = [];
        user.getLeaveReport(this.selected_months).then((result) => {
          // console.log("users", result.data.data);
          // console.log(this.getMonthList);
          this.rows = result.data.data.users;
          if (this.rows.length === 0) {
            this.isEmpty = true;
          }
          // For looping
          this.total_records = Object.keys(this.rows).length;
          // For tracing
          let keys = Object.keys(this.rows);
          let item = this.rows[keys[0]];
          this.monthlist = item["month_detail"];
        });
      }
      this.disabled = false;
    },
    getLeaveReport() {
      var selectedDate = "";
      if (
        typeof this.searched_date_input_box != "undefined" &&
        this.searched_date_input_box != "" &&
        this.searched_date_input_box != 0
      ) {
        selectedDate =
          "?searched_date=" +
          moment(this.searched_date_input_box).format("yyyy-MM-DD");
      }
      user.getLeaveReport(selectedDate).then((result) => {
        // console.log("users", result.data.data);
        this.rows = result.data.data.users;
      });

      // var prev_month = "";
      // var today = new Date(this.searched_date_input_box);
      // var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      // prev_month = "?searched_date=" + moment(lastDayOfMonth).format('yyyy-MM-DD');

      // user.getLeaveReport(prev_month).then((result) => {
      //     for (let i=0; i < this.rows.length; i++) {
      //         if (this.rows[i].user_id == result.data.data.users[i].user_id) {
      //             result.data.data.users[0]["leave_for_last_month"] =result.data.data.users[i].current_leave;
      //         }
      //     }
      //     this.rows = result.data.data.users.leave_for_last_month
      // });
      // // console.log("i",this.rows)
    },

    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        this.setCurrentUser(result.data);
      });
    },
    cellClickHandler(params) {
      // console.log(params);
      if (params.column.field == "credit") {
        this.cell_selected = "credit";
        this.$modal.show("credit_debit");
        this.user_name = params.row.first_name;
      } else if (params.column.field == "debit") {
        this.cell_selected = "debit";
        this.$modal.show("credit_debit");
        this.user_name = params.row.first_name;
      } else {
      }
    },
    getUserLeaveTypes(month, leave_type, id) {
      // console.log(month);
      // console.log(leave_type);
      // console.log(id);
      // console.log(id.month_detail);
      this.leaveData = [];
      user.getUserLeaveTypes(month, leave_type, id).then((result) => {
        this.leaveData = result.data.data;
        // if(this.leaveData[0].leave_type_name==null){
        //   // console.log(this.leaveData[0]);
        //   this.leaveData=null;
        //   // console.log(this.leaveData);
        // }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.first-table {
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
.credit-debit-table {
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
.third-table {
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
