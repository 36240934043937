<template>
  <div class="pt-3 pb-2" v-show="permissions.includes('leaves.write')">
    <div class="">
      <form
        onSubmit="return false;"
        data-parsley-validate="true"
        @submit.prevent="verifyOpertations()"
      >
        <div class="d-sm-flex align-items-left mb-3">
          <div class="col-sm-2">
            <label for="User" class="mandatory">User</label>
          </div>
          <multiselect
            v-model="user_dropdown"
            :options="users"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Multiple User"
            label="first_name"
            :custom-label="custom_label"
            track-by="id"
            group-label="label"
          >
            <template
              slot="selection"
              slot-scope="{ values, search, isOpen }"
            ></template>
          </multiselect>
        </div>

        <div class="d-sm-flex align-items-left mb-3">
          <div class="col-sm-2">
            <label for="Leave Type" class="mandatory">Type</label>
          </div>
          <select
            v-model="leave.types"
            class="form-control"
            :state="null"
            placeholder="Full \ Half"
            oninput="this.setCustomValidity('')"
            @change="ClearValue()"
            required
          >
            <option value="" selected>Select Leave Type</option>
            <option
              :key="index"
              v-for="(item, index) in types"
              :value="item.value"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <!-- If type Half -->
        <div v-if="leave.types == 'half'">
          <div class="d-sm-flex align-items-left mb-3">
            <div class="col-sm-2">
              <label for="Half">Half</label>
            </div>
            <select
              v-model="leave.half_day_for"
              class="form-control"
              placeholder="1st / 2nd half"
              oninvalid="this.setCustomValidity('Please select type')"
              oninput="this.setCustomValidity('')"
              required
            >
              <option value="">Select option</option>
              <option
                :key="index"
                v-for="(item, index) in half_day_for"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="d-sm-flex align-items-left mb-3">
            <!-- Date -->
            <div class="col-sm-2">
              <label for="From Date" class="mandatory">Date</label>
            </div>
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="leave.from_date"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :format="formateDate"
              @input="set_date()"
              required
            ></datepicker>
            <!-- <datepicker placeholder="yyyy-mm-dd" v-model="leave.from_date" :clear-button="true" :format="formateDate" required></datepicker> -->
          </div>
        </div>

        <!-- If type Full -->
        <div v-if="leave.types == 'full'">
          <div class="d-sm-flex align-items-left mb-3">
            <div class="col-sm-2">
              <label for="no of days">No of Days</label>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="leave.no_of_days"
              :state="null"
              placeholder="Days"
              oninput="this.setCustomValidity('')"
              disabled
            />
          </div>
          <div class="d-sm-flex align-items-left mb-3">
            <!-- From Date -->
            <div class="col-sm-2">
              <label for="From Date" class="mandatory">From Date</label>
            </div>
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="leave.from_date"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :format="formateDate"
              @input="set_date()"
              required
            ></datepicker>

            <!-- To Date -->
            <div class="col-sm-2 text-center">
              <label for="To Date" class="mandatory">To Date</label>
            </div>
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="leave.to_date"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :disabled-dates="state1"
              :format="formateDate"
              @closed="days_count()"
              required
            >
            </datepicker>
          </div>
        </div>
        <div class="d-sm-flex align-items-left mb-3">
          <div class="col-sm-2">
            <label for="Leave Reason" class="mandatory">Reason</label>
          </div>
          <textarea
            id="textarea-formatter"
            class="form-control"
            v-model="leave.reason"
            :state="null"
            required
            placeholder="Enter Reason"
            oninvalid="this.setCustomValidity('Please enter Reason')"
            oninput="this.setCustomValidity('')"
          >
          </textarea>
        </div>
        <!-- {{ leave_data }} -->
        <div class="text-end">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import moment, { relativeTimeThreshold } from "moment";
import { mapState, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

const original_date = new Date();

export default {
  name: "AddLeave",
  components: { Multiselect, Datepicker },
  props: {
    leave_data: {
      default: null,
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  data() {
    return {
      leave: {
        reason: "",
        from_date: "",
        to_date: "",
        no_of_days: 0.0,
        user_id: "",
        types: "",
        half_day_for: "",
        approved_by: 0,
        reject_by: 0,
        stage: "",
      },
      user_id: [],
      users: [],
      leave_no_of_days: "",
      dropdowndays: [],
      current_is: "",
      leavetype: [],
      user_dropdown: null,

      types: {
        0: { name: "Full", value: "full" },
        1: { name: "Half", value: "half" },
      },

      state: {
        to: new Date(
          original_date.getFullYear(),
          original_date.getMonth(),
          original_date.getDate()
        ),
      },
      state1: {
        to: "",
      },
      half_day_for: {
        0: { name: "1st Half" },
        1: { name: "2nd Half" },
      },
      editid: 0,
      permissions: window.localStorage.getItem("permissions"),
    };
  },
  mounted() {
    // this.editid = this.leave_data.user_id;
    if (this.permissions.includes("leaves.write")) {
      // this.LeaveTypeDropdown();
      this.getuseridDropdown();
      // this.getCurrentUser();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        // console.log(this.editid);
        // console.log("route",this.$route.params.id)
        // this.getLeavesById(this.$route.params.id);
      }
      if (this.$router.history.current.name) {
        this.current_is = this.$router.history.current.name;
      }
      // this.DropDownNoOfDays();
    }
  },
  watch: {
    leave_data: {
      handler(nv, ov) {
        // console.log("base",nv)
        if (nv) {
          this.user_dropdown = { ...nv };
          this.leave.reason = nv.reason;
          this.leave.from_date = nv.from_date;
          this.leave.to_date = nv.to_date;
          this.leave.no_of_days = nv.no_of_days;
          this.leave.user_id = nv.user_id;
          this.leave.types = nv.leave_type;
          this.leave.half_day_for = nv.half;
          this.leave.id = nv.id;
        } else {
          // this.user_dropdown=this.users.find(item=>item.id==this.currentUser.id);
          // console.log(this.user_dropdown);
        }
      },
      immediate: true,
    },
    "leave.to_date": function () {
      if (
        moment(this.leave.from_date).format("YYYY-MM-DD") >
        moment(this.leave.to_date).format("YYYY-MM-DD")
      ) {
        this.leave.to_date = this.leave.from_date;
      }
      this.days_count();
    },
    "leave.from_date": function () {
      if (this.leave.to_date != "" || this.leave.to_date != null) {
        if (
          moment(this.leave.from_date).format("YYYY-MM-DD") >
          moment(this.leave.to_date).format("YYYY-MM-DD")
        ) {
          this.leave.to_date = this.leave.from_date;
        }
      }
      this.days_count();
    },
    "leave.types": function () {
      if (this.leave.types == "half") {
        this.leave.to_date = this.leave.from_date;
        this.leave.no_of_days = 0.5;
      } else if (this.leave.types == "full") {
        if (this.leave.no_of_days == 0.5) {
          this.leave.no_of_days = 1;
        }
      }
    },
  },

  methods: {
    ...mapActions("userStore", ["setCurrentUser"]),
    set_date() {
      if (this.leave.from_date) {
        this.leave.from_date = new Date(this.leave.from_date);
        this.leave.to_date = new Date(this.leave.from_date);
      }
    },
    days_count() {
      const moment = require("moment");
      if (this.leave.types == "full") {
        const startDate = moment(this.leave.from_date).format("YYYY-MM-DD");
        const endDate = moment(this.leave.to_date).format("YYYY-MM-DD");
        // if(startDate.getDay() == 6 || startDate.getDay() == 5) {
        // }
        const diffInDays = moment(endDate).diff(moment(startDate), "days");
        this.leave.no_of_days = diffInDays + 1;
      }
    },

    custom_label(name) {
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },

    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = result.data.data;
        this.users.find((obj) => {
          if (obj.id === this.user_dropdown.id) this.user_dropdown = obj;
        });
      });
    },

    verifyOpertations() {
      // console.log("424");
      if (this.leave.to_date == "" || this.leave.to_date == null) {
        this.leave.to_date = this.leave.from_date;
      }

      if (this.leave.from_date > this.leave.to_date) {
        this.leave.to_date = this.leave.from_date;
      }

      if (Object.keys(this.user_dropdown).includes("user_id")) {
        this.leave.user_id = this.user_dropdown.user_id;
        // console.log("existing");
      } else {
        this.leave.user_id = this.user_dropdown.id;
        // console.log("new");
      }
      let f_date = new Date(this.leave.from_date);
      let t_date = new Date(this.leave.to_date);
      this.leave.from_date = moment(f_date).format("YYYY-MM-DD HH:mm:ss");
      this.leave.to_date = moment(t_date).format("YYYY-MM-DD HH:mm:ss");
      // this.leave.user_id=this.leave.user_id;
      // delete this.leave.user_id.id;
      if (this.leave_data)
        user.UpdateApprovedLeaves(this.leave).then((result) => {
          if (result.data.code == 200) {
            this.$emit("close_modal");
            this.$swal("Success", "leave updated successfully...", "success");
          } else if (result.data.code == 404) {
            this.$emit("close_modal");
            this.$swal("Error", "leave not found...", "warning");
          } else if (result.data.code == 401) {
            this.$emit("close_modal");
            this.$swal(
              "Error",
              "Not authorized for update approved leaves...",
              "warning"
            );
          } else if (result.data.code == 400) {
            if (
              Object.keys(result.data.data ? result.data.data : []).includes(
                "is_sandwich"
              )
            ) {
              this.$swal({
                title: "Do you want to apply sandwich leave ?",
                // text:'A sandwich leave will occur according to your date selection',
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                width: "40em",
              }).then((data) => {
                if (data.isConfirmed == true) {
                  result.data.is_sandwich = true;
                  this.leave.is_sandwich = result.data.is_sandwich;

                  user.UpdateApprovedLeaves(this.leave).then((result) => {
                    if (result.data.code == 200) {
                      this.$swal(
                        "Success",
                        "Leave updated successfully...",
                        "success"
                      );
                      delete this.leave.is_sandwich;
                      this.$emit("close_modal");
                    }
                    if (result.data.code == 400) {
                      delete this.leave.is_sandwich;
                      this.$swal("Error", result.data.message, "error");
                      this.$emit("close_modal");
                    }
                  });
                  // console.log("sandwitch ",result.data.is_sandwich);
                } else {
                  // this.leave.user_id = user_arr;
                  this.disabled = false;
                }
              });
            } else {
              this.$emit("close_modal");
              this.$swal("Error", result.data.message, "warning");
            }

            return;
          } else {
            this.$emit("close_modal");
            // this.$swal("Error", "something went wrong...", "warning");
            this.$swal("Error", result.data.message, "warning");
          }
        });
    },

    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    ClearValue() {
      this.leave.from_date = "";
      this.leave.to_date = "";
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
label {
  padding-top: 5px;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
