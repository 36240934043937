<template>
  <div class="my-4 px-2" style="height: 100vh">
    <!-- Title -->
    <div>
      <ReportClarificationAlert />
      <div
        class="d-flex justify-content-between flex-md-row flex-column align-items-center"
      >
        <h4 class="text-dark">Tasks</h4>
        <span
          :style="{
            color: current_page == 'team_task' ? '#583D72' : '#79869F',
            cursor: 'pointer',
          }"
        >
          <input
            v-model="task_ids"
            :disabled="disabled"
            @keydown.enter="getAllTasks(false)"
            class="form-control"
            style="width: 20rem"
            type="search"
            placeholder="Search By Task Id, Description"
            aria-label="Search"
          />
        </span>
      </div>
      <!-- Naviagation -->
      <div class="row border-bottom g-0 mt-3">
        <div class="col-lg-9 col-12">
          <ul class="nav">
            <li
              class="nav-item p-0 me-2"
              @click="pageChanger('my_task')"
              :style="yourStyle"
            >
              <span
                class="nav-link p-0 d-flex align-items-center"
                :style="{
                  color: current_page == 'my_task' ? '#583D72' : '#79869F',
                  cursor: 'pointer',
                }"
              >
                <img src="../img/box copy.svg" class="img-fluid me-2" />
                <span>My Tasks</span>
              </span>
            </li>
            <li
              class="nav-item p-0"
              @click="pageChanger('team_task')"
              :style="teamStyle"
            >
              <span
                class="nav-link p-0 active d-flex align-items-center"
                :style="{
                  color: current_page == 'team_task' ? '#583D72' : '#79869F',
                  cursor: 'pointer',
                }"
              >
                <img src="../img/grup copy.svg" class="img-fluid me-2" />
                <span>Team Member's Tasks</span>
              </span>
            </li>
            <!-- <li class="nav-item mr-5" @click="pageChanger('team_task')">
              <span class="nav-link px-0 active d-flex align-items-center" :style="{ 'color' : current_page == 'team_task' ? '#583D72' : '#79869F' , 'cursor' : 'pointer'  }">
                <input v-model="task_id" class="form-control" type="search" placeholder="Search By Task Id ( Ex: 109,211,302) " aria-label="Search">
              </span>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- Navigation complete -->
    </div>
    <!-- Title completed -->
    <!-- <h4>Filter</h4> -->
    <!-- <redmine-filter :page="'y_task'"></redmine-filter> -->
    <div
      class="d-flex flex-md-row flex-column justify-content-start align-items-center my-3 gap-3 flex-wrap"
    >
      <div class="col-lg-2 col-md-3 col-12">
        <multiselect
          v-model="selected_projects"
          :hide-selected="true"
          :options="projects"
          :multiple="true"
          placeholder="Select one or more project"
          label="project_name"
          track-by="id"
          group-label="label"
          group-values="projects_data"
          :group-select="true"
          :disabled="disabled"
          @input="onProjectSelection"
        >
        </multiselect>
      </div>
      <!-- @select="onProjectSelection"
        @remove="onProjectSelection" -->

      <div class="col-lg-2 col-md-3 col-12">
        <multiselect
          v-model="selected_milestone"
          :hide-selected="true"
          :options="milestones"
          :multiple="true"
          placeholder="Select one or more milestone"
          label="name"
          track-by="id"
          group-label="label"
          group-values="milestone_data"
          :group-select="true"
          :disabled="disabled"
        ></multiselect>
      </div>

      <div class="col-lg-2 col-md-3 col-12" v-if="current_page == 'team_task'">
        <multiselect
          v-model="selected_users"
          :hide-selected="true"
          :options="active_users_list"
          :multiple="true"
          placeholder="Select one or more User"
          label="first_name"
          track-by="id"
          :custom-label="custom_label"
          group-label="label"
          group-values="users_data"
          :group-select="true"
          :disabled="disabled"
        >
          <template
            slot="selection"
            slot-scope="{ values, search, isOpen }"
          ></template>
        </multiselect>
      </div>

      <div class="d-flex justify-content-center align-items-center gap-3">
        <p class="m-0">From</p>
        <date-picker
          type="date"
          v-model="quarter_date.from"
          :format="'yyyy-MM-dd'"
          placeholder="Select from date"
          :calendar-button="true"
          :wrapper-class="'col-10'"
          :input-class="'border-start-0'"
          :calendar-button-icon="'fa fa-calendar'"
          :bootstrap-styling="true"
          :disabled="disabled"
        >
        </date-picker>
        <!-- :wrapper-class="'col-12'" -->
      </div>
      <div class="d-flex justify-content-center align-items-center gap-4">
        <!-- :wrapper-class="'col-12'" -->
        <p class="m-0">To</p>
        <date-picker
          type="date"
          v-model="quarter_date.to"
          :format="'yyyy-MM-dd'"
          placeholder="Select to date"
          :calendar-button="true"
          :wrapper-class="'col-10'"
          :input-class="'border-start-0'"
          :calendar-button-icon="'fa fa-calendar'"
          :bootstrap-styling="true"
          :disabled="disabled"
        >
        </date-picker>
      </div>

      <div class="ms-lg-auto mx-md-0 mx-auto">
        <button
          class="btn btn-primary"
          @click="getAllTasks(true)"
          :disabled="disabled"
          :class="disabled ? 'disabled' : ''"
        >
          Filter
        </button>
      </div>
    </div>
    <!-- Spinner -->
    <!-- <div style="border:2px solid red;position:absolute;height:100%;width:100%;background-color:rgba(0,0,0,0.2)">dsa</div> -->
    <div
      class="justify-content-center align-items-stretch d-flex my-5 p-5"
      v-if="tasks.length == 0"
    >
      <div
        class="spinner-border text-primary font-weight-bold"
        role="status"
        style="width: 4rem; height: 4rem"
      ></div>
    </div>
    <!-- Draggable Main Container-->
    <div
      class="p-0 d-flex h-100 flex-lg-row flex-column align-items-start align-self-center justify-content-between gap-2"
      style="overflow-x: scroll; width: 100%"
      v-else
    >
      <!-- Task columns container-->
      <div
        class="ml-lg-5 d-flex flex-column align-items-start justify-content-start mx-lg-0 mx-auto"
        v-for="(data, index) in tasks"
        :style="{ height: calculatedHeight }"
        :key="index"
      >
        <!-- {{  calculatedHeight }} -->
        <!-- Task Status name -->
        <div
          class="w-100"
          style="
            top: 0;
            position: sticky;
            align-self: flex-start;
            background: #f8f9fd;
            z-index: 5;
            z-index: 5;
          "
        >
          <div
            class="text-white text-center d-flex justify-content-between align-items-center mx-auto"
            id="task-width"
            :style="{ 'background-color': data.category.color }"
          >
            <span
              class="font-weight-bold p-0 m-0 flex-grow-1"
              id="task-status-font"
              v-text="data.category.name"
            ></span>
            <h5 class="me-2">
              <span
                class="badge badge-light p-1"
                v-text="data.tasks.length"
              ></span>
            </h5>
          </div>
          <!-- Add Task Button -->
          <button
            class="btn btn-lg px-1 py-2 my-4 mx-auto d-block font-weight-bold text-primary"
            style="
              background-color: #edf1ff;
              font-size: 16px;
              border-radius: 10px;
              width: 210px;
              height: 43px;
            "
            v-if="permissions.includes('tasks.add')"
            @click.stop="triggerTaskModal(data.category, 'addTask')"
          >
            <img
              src="../img/icon.svg"
              class="mr-2 mb-1"
              style="width: 12px; height: 12px"
            />
            Add Task
          </button>
        </div>
        <!-- rgb(248, 249, 252) -->

        <!-- Drag table -->
        <vue-drag
          class="d-flex align-items-center w-100 justify-content-start flex-column h-100"
          style="background: #ebeef9; min-width: 335px"
          :list="data.tasks"
          group="same"
          :force-fallback="true"
          :scroll-sensitivity="500"
          direction="vertical"
          @end="dragToggler"
          @start="dragToggler"
          animation="200"
          draggable=".card-draggable"
          @change="changeStatus($event, data.category.id)"
        >
          <div v-if="data.tasks.length == 0">
            <h5 class="text-center text-dark py-5">No tasks</h5>
          </div>

          <!-- Task content card starts -->
          <div
            class="card border-0 my-2"
            :class="
              current_page === 'team_task'
                ? currentUser.id === task.created_by || task.full_permission
                  ? 'card-draggable'
                  : dragClass
                : 'card-draggable'
            "
            id="card-dimension"
            style="z-index: 4"
            v-for="task in data.tasks"
            :key="task.id"
            @click="triggerTaskModal(task, 'addReport')"
            v-else
          >
            <!-- @click="triggerTaskModal(task, 'updateTask',task.full_permission)" -->
            <!-- cross button -->
            <span
              class="text-center px-3 position-absolute"
              v-if="
                permissions.includes('tasks.delete') && task.full_permission
              "
              style="font-weight: 600; left: 90%"
              id="cross"
              @click.stop="delete_task(task.id)"
              >&times;</span
            >
            <!-- -------------- -->
            <div class="card-body">
              <h5
                class="text-muted p-0 m-0"
                id="project-name-font"
                v-text="task.project_name"
              ></h5>
              <h5
                class="font-weight-bold mt-2 text-dark"
                id="task-title-font"
                v-text="'(' + task.id + ') ' + taskTitleTrimer(task.short_desc)"
              ></h5>
              <p
                class="p-0"
                id="task-description-font"
                v-text="taskDescriptionTrimer(task.description)"
              ></p>
              <!-- To show priority -->
            </div>

            <!-- This is the card footer -->
            <div
              class="d-flex justify-content-start mx-2 align-items-center my-1"
            >
              <div class="m-0 p-0">
                <Avatar
                  class="ml-3"
                  @disableModal="avtarModalController"
                  :users_details="task.users_details"
                  v-if="task.users_details !== null"
                  :limit="3"
                >
                </Avatar>
              </div>
              <span
                class="pt-1 px-3"
                @click.stop
                :class="{
                  'status-success': task.priority == 'LOW' ? true : false,
                  'status-danger': task.priority == 'HIGH' ? true : false,
                  'status-warning': task.priority == 'MEDIUM' ? true : false,
                }"
                v-text="task.priority"
              ></span>
            </div>

            <div
              class="d-flex justify-content-center align-items-stretch p-0 card-footer flex-column bg-white border-0 pt-2 overflow-hidden"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="px-2 ml-3 mb-2">
                  <span>
                    <!-- <img
              src="../img/paper-clip.png"
              alt=""
              style="height:15px;width:15px"
            /> -->
                  </span>
                  <!--
          :class="{
                'status-success': daysLeftCounter(task.due_date) > 0,
                'status-danger': daysLeftCounter(task.due_date) < 0,
              }" -->
                  <span
                    v-if="task.due_date"
                    @click.stop
                    class="p-1 ms-0"
                    :class="{
                      'status-success':
                        daysLeftCounter(task.due_date).includes('left') ||
                        daysLeftCounter(task.due_date).includes('today'),
                      'status-danger': daysLeftCounter(task.due_date).includes(
                        'ago'
                      ),
                    }"
                  >
                    <img
                      src="../img/clock.svg"
                      class="mb-0 p-0"
                      style="height: 11px; width: 11px; margin: 0px 5px"
                    />
                    <!-- {{task.due_date}} -->
                    <!-- <div v-if="daysLeftCounter(task.due_date) > 0"> -->
                    <span style="font-size: 12px" class="">{{
                      daysLeftCounter(task.due_date)
                    }}</span>
                    <!-- <span v-if="daysLeftCounter(task.due_date) < 0" style="font-size:12px;" class="">{{ChangeDateForat(task.due_date)}}</span>
              <span v-if="daysLeftCounter(task.due_date) < 0" style="font-size:12px;" class="">{{ChangeDateForat(task.due_date)}}</span> -->
                    <!-- </div> -->
                    <!-- <div v-if="daysLeftCounter(task.due_date) < 0">
              <span style="font-size:12px" class="" v-text="daysLeftCounter(task.due_date) + ' Days Left'"></span>
            </div> -->
                    <!-- <span style="font-size:12px" class="" v-text="daysLeftCounter(task.due_date) + ' Days Left'"></span> -->
                  </span>
                </div>

                <button
                  class="btn btn-light rounded-0 text-primary d-block"
                  style="font-size: 13px"
                  v-if="
                    currentUser.id === task.created_by || task.full_permission
                  "
                  @click.stop="
                    triggerTaskModal(task, 'updateTask', task.full_permission)
                  "
                >
                  <!-- <img  v-if="task.due_date" src="../img/icon.svg" class="mr-2 mb-1" style="width:12px;height:12px" /> -->
                  <span>Update Task</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Task content card ends -->
        </vue-drag>
      </div>
    </div>
    <!-- Add and update modal -->
    <div
      class="modal fade"
      id="taskModal"
      role="dialog"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title text-dark font-weight-bold pl-3"
              id="taskModalLongTitle"
              style="font-size: 26px"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body"
            style="overflow-y: auto"
            v-if="current_modal !== null"
          >
            <add-task
              v-if="current_modal === 'updateTask'"
              @close-modal="closeModal"
              :full_permission="full_permission"
              :update_task_id="task_update_id"
            ></add-task>
            <add-task
              v-if="current_modal === 'addTask'"
              @close-modal="closeModal"
              :full_permission="full_permission"
              :status_id="task_status_id"
            ></add-task>
            <add-report
              :report_task_id="task_report_id"
              v-if="current_modal == 'addReport'"
              :action="action"
              @close-modal="closeModal"
            ></add-report>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import addTask from "./AddTask.vue";
import addReport from "./AddReports.vue";
import user from "@/ajax/user";
import myMixin from "@/mixins/Permission";
import moment from "moment";
import Avatar from "../../components/Avatar.vue";
import redmineFilter from "../../components/redmine_filter.vue";
import DatePicker from "vuejs-datepicker";
import ReportClarificationAlert from "./ReportClarificationAlert.vue";

export default {
  mixins: [myMixin],
  computed: {
    ...mapState("userStore", ["currentUser"]),
    yourStyle() {
      if (this.current_page == "my_task") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    teamStyle() {
      if (this.current_page == "team_task") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    calculatedHeight() {
      let height = this.tasks.map((obj) => {
        return obj.tasks.length;
      });
      if (height > 1) {
        return Math.max(...height) * 20 + "rem";
      } else {
        return Math.max(...height) * 27.5 + "rem";
      }
    },
  },
  components: {
    "vue-drag": draggable,
    addTask,
    addReport,
    Avatar,
    redmineFilter,
    DatePicker,
    ReportClarificationAlert,
    // VueSweetalert2
  },
  data() {
    return {
      siteLogReq: {
        site_name: "tasks",
      },
      disabled: false,
      full_permission: true,
      dragClass: "",
      fromModal: true,
      tasks: [],
      modalFor: "add",
      task_status_id: null,
      task_update_id: null,
      task_report_id: null,
      status_data: {
        task_status_id: null,
        task_id: null,
        action: null,
      },
      internet_connection: true,
      current_page: "my_task",
      current_modal: null,
      permissions: localStorage.getItem("permissions"),
      modal_title: "Task",

      selected_start_date: new Date(),

      projects: [],
      milestones: [],
      active_users_list: [],
      task_ids: "",
      selected_projects: [],
      selected_milestone: [],
      selected_users: [],
      action: null,
      default_quarter_date: {
        from: moment().subtract(2, "months").startOf("month").toDate(),
        to: moment().endOf("month").toDate(),
      },
      quarter_date: {
        from: moment().subtract(2, "months").startOf("month").toDate(),
        to: moment().endOf("month").toDate(),
      },
    };
  },
  updated() {
    // console.log("updated");
  },
  mounted() {
    // console.log("mounted");
    this.setCurrentPage("Tasks");
    this.getUserPermissions();
    $("#taskModal").on("hidden.bs.modal", this.hideModalHandler);
    this.getAllProjects();
    this.getAllMilestone();
    this.getUsers();
    this.getAllTasks(false);
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    hideModalHandler() {
      this.current_modal = null;
    },
    // get users list
    onProjectSelection(event) {
      let project_ids = this.selected_projects.map((item) => item.id);
      this.selected_milestone = this.selected_milestone.filter((item) => {
        return project_ids.includes(item.project_id);
      });
      this.getAllMilestone(project_ids);
    },
    avtarModalController(condition) {
      this.fromModal = condition;
    },
    dragToggler(e) {
      if (e.type == "start") {
        this.dragClass = "card-draggable";
      }
      if (e.type == "end") {
        this.dragClass = "index";
      }
    },
    getUsers() {
      // tasks users filter are show according project manager and report to me [OPTI-480]
      user.getprojectandreportmeDropdown().then((result) => {
        // this.users = result.data.data;
        this.users = [{ label: "Select all", users_data: result.data.data }];
        this.userListFilter();
      });
    },
    userListFilter() {
      let temp = [];
      if (this.users.length !== 0) {
        // console.log(this.users[0]['users_data'])
        this.users[0]["users_data"].forEach((item) => {
          // console.log("doe ",item.doe);
          if (item.doe !== null) {
            if (this.selected_start_date <= item.doe) {
              temp.push(item);
            }
          } else {
            temp.push(item);
          }
        });
        // console.log(temp)
        this.active_users_list = [{ label: "Select all", users_data: temp }];
      }
    },
    // get project list
    getAllProjects() {
      user.getAllProjects().then((result) => {
        this.projects = [
          { label: "Select all", projects_data: result.data.data },
        ];
      });
    },
    // get milestone list
    getAllMilestone(project_ids = null) {
      let dataObj = {};
      project_ids == null
        ? (dataObj = { project_ids: [] })
        : (dataObj = { project_ids: project_ids });
      user.get_project_milestone(dataObj).then((result) => {
        this.milestones = [
          { label: "Select all", milestone_data: result.data.data },
        ];
      });
    },
    pageChanger(page) {
      if (this.disabled) {
        return;
      }
      if (this.current_page != page) {
        // console.log(page, this.current_page);
        this.current_page = page;
        this.tasks = [];
        this.selected_milestone =
          this.selected_projects =
          this.selected_users =
            [];
        this.task_ids = "";
        this.quarter_date = structuredClone(this.default_quarter_date);
        this.getAllTasks(false);
      }
      this.getAllMilestone();
    },
    moveDisabler() {
      if (this.disableMove === true) {
        return false;
      } else {
        return null;
      }
    },
    daysLeftCounter(due_date) {
      var end_date = moment.utc(due_date).local().format();
      var now = moment().local().format();
      var ms = moment(now).diff(moment(end_date));

      // convert milisecond to hours
      var minutes = Math.trunc(ms / 60000);
      var hours = Math.trunc(minutes / 60);
      var days = Math.trunc(hours / 24);
      var months = Math.trunc(days / 30.417);
      var years = Math.trunc(months / 12);
      var return_val = "";

      if (days >= 0 && months >= 0 && years >= 0) {
        if (days == 0 && months == 0 && years == 0) {
          return_val = "today";
        } else {
          if (years < 1) {
            if (months < 1) {
              if (days < 1) {
                return_val = "";
              } else {
                return_val = days + " days ago";
              }
            } else {
              return_val = months + " months ago";
            }
          } else {
            return_val = years + " years ago";
          }
        }
      } else {
        years = Math.abs(years);
        months = Math.abs(months);
        days = Math.abs(days);
        if (years < 1) {
          if (months < 1) {
            if (days < 1) {
              return_val = "";
            } else {
              return_val = days + " days left";
            }
          } else {
            return_val = months + " months left";
          }
        } else {
          return_val = years + " years left";
        }
      }
      return return_val;
    },
    changeStatus(event, status_id) {
      // console.log(event);
      if (Object.keys(event).includes("added")) {
        // console.log("------------ if")
        // console.log("----------element",event);
        this.status_data.task_status_id = status_id;
        this.status_data.task_id = event.added.element.tasks_id;
        this.status_data.action = "drag";
        user
          .UpdateTaskDetails(this.status_data)
          .then((result) => {
            if (result.data.code == 200) {
              Swal.fire({
                title: "Task Status Changes Succesfully...!",
                color: "white",
                toast: true,
                icon: "success",
                iconColor: "white",
                position: "top",
                background: "rgb(64, 205, 157)",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                width: "30rem",
              }).then(() => {
                this.getAllTasks(false);
              });
            } else if (result.data.code == 400) {
              this.$swal
                .fire("Invalid", "You can only update your own task", "warning")
                .then(() => {
                  this.getAllTasks(false);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    triggerTaskModal(data, name, full_permission) {
      if (name == "addTask") {
        // console.log("clicked");
        this.current_modal = name;
        this.full_permission = full_permission;
        this.modal_title = "Add Task";
        this.task_status_id = data.id;
        $("#taskModal").modal("show");
      }
      if (name == "updateTask") {
        // console.log("update task");
        this.current_modal = name;
        this.full_permission = full_permission;
        this.permissions.includes("tasks.write") && full_permission
          ? (this.modal_title = "Update Task")
          : (this.modal_title = "View Task");
        this.task_update_id = data.id;
        $("#taskModal").modal("show");
      }
      if (
        name == "addReport" &&
        this.fromModal == true &&
        this.current_page == "my_task"
      ) {
        this.current_modal = name;
        this.task_report_id = data.id;
        this.modal_title = "Add Report";
        $("#taskModal").modal("show");
      }
      this.fromModal = true;
    },
    closeModal() {
      $("#taskModal").modal("hide");
      this.getAllTasks(false);
    },
    getAllTasks(from_filter) {
      this.disabled = true;
      let project_ids = this.selected_projects.map((obj) => {
        return obj.id;
      });
      let milestone_ids = this.selected_milestone.map((obj) => {
        return obj.id;
      });
      let user_ids = this.selected_users.map((obj) => {
        return obj.id;
      });
      let quarter_date = [
        moment(this.quarter_date.from).format("YYYY-MM-DD"),
        moment(this.quarter_date.to).format("YYYY-MM-DD"),
      ];
      // console.log("search ---- ", this.task_ids);
      // console.log("from_filter ", from_filter);
      // console.log("search_query ----- ",)
      from_filter == true ? (this.tasks = []) : "";
      if (this.task_ids) {
        project_ids = [];
        milestone_ids = [];
        user_ids = [];
        quarter_date = [
          // moment().startOf("year").format("YYYY-MM-DD"),
          // moment().endOf("year").format("YYYY-MM-DD"),
        ];
      }
      user
        .GetAllTasks({
          action: this.current_page,
          filters: {
            projects: project_ids,
            milestones: milestone_ids,
            users: user_ids,
            tasks: this.task_ids,
            quarter_date: [
              moment(this.quarter_date.from).format("YYYY-MM-DD"),
              moment(this.quarter_date.to).format("YYYY-MM-DD"),
            ],
          },
        })
        .then((result) => {
          // this.internet_connection = true;
          // console.log(result);
          this.tasks = result.data.data;
          this.disabled = false;
        })
        .catch((err) => {
          console.log(err);
          // this.disabled = false;
        });
    },
    taskTitleTrimer(data) {
      if (data.length >= 25) {
        return data.substring(0, 25) + "....";
      } else {
        return data;
      }
    },
    taskDescriptionTrimer(data) {
      if (data.length >= 100) {
        return data.substring(0, 100) + "....";
      } else {
        return data;
      }
    },
    custom_label(name) {
      // console.log(this.users);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        // console.log(name.first_name+name.middle_name);
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    ChangeDateForat(val) {
      return moment(val).format("DD MMM");
    },
    delete_task(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
        icon: "question",
      }).then((result) => {
        if (result.value) {
          // this.$swal('Deleted', 'You successfully delete this task', 'success')
          user
            .delete_task(id)
            .then((result) => {
              if (result.data.code == 424) {
                this.$swal(result.data.data, result.data.message, "warning");
              }
              if (result.data.code == 200) {
                this.$swal.fire({
                  title: result.data.message,
                  color: "white",
                  toast: true,
                  icon: "success",
                  iconColor: "white",
                  position: "top",
                  background: "rgb(64, 205, 157)",
                  timer: 1000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                  width: "30rem",
                });
                this.getAllTasks(false);
              }
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                title: "No internet connection",
                text: "Please check your internet connection and try again",
                icon: "error",
                timer: 500,
              });
            });
        } else {
          user.delete_task((error) => {
            console.log(error);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#cross {
  cursor: pointer;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
#cross:hover {
  color: red;
}
#task-status-font {
  font-size: 17px;
}
#card-dimension {
  height: 250px;
  width: 315px;
}
#days-reminder img {
  margin: 0px 5px;
}
#task-width {
  width: 269px;
  height: 50px;
  border-radius: 10px;
}
#task-title-font {
  font-size: 16px;
}
#task-description-font {
  font-size: 13px;
}
#project-name-font {
  font-size: 13px;
}
.status-success {
  background-color: #00b17b33;
  border-radius: 8px;
  font-size: 10.43px;
  color: #00b17b;
}
.status-warning {
  background-color: #ff8c1933;
  border-radius: 8px;
  font-size: 10.43px;
  color: #ff8c19;
}
.status-danger {
  background-color: #ff302940;
  border-radius: 8px;
  font-size: 10.43px;
  color: #ff3029;
}
#days-reminder {
  background-color: #e9f3fd;
  border-radius: 8px;
  font-size: 13px;
  color: #809fb8;
}
/* ::-webkit-scrollbar- {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background:transparent;
}
::-webkit-scrollbar-track {
  background:transparent;
} */
.index {
  z-index: 100;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
