<template>
  <div>
    <form @submit.prevent="onSubmit()">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="text">Text <em>*</em></label>
          <textarea
            rows="2"
            wrap="soft"
            id="text"
            v-model="reqObj.notification_text"
            placeholder="Enter Text"
            class="form-control"
            required
          ></textarea>
        </div>
      </div>
      <div class="text-end mt-4">
        <button class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import user from "@/ajax/user";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";

export default {
  components: { Datepicker, UnauthorizedPage },
  mixins: [myMixin],
  data() {
    return {
      permissions: window.localStorage.getItem("permissions"),
      reqObj: {
        notification_text: "",
      },
      editid: 0,
    };
  },
  mounted() {
    if (this.permissions.includes("notifications.create")) {
      this.getUserPermissions();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        // console.log("Update task" + this.$route.params.id);
        this.editid = this.$route.params.id;
        // this.getQuotesById(this.editid);
      }
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    onSubmit() {
      if (this.editid == 0) {
        user
          .CreateNotification(this.reqObj)
          .then((result) => {
            if (result.data.code == 200) {
              Swal.fire({
                title: "Success",
                text: "Notification Added Successfully !!!",
                icon: "success",
                timer: 2500,
              });
              this.$emit("close-modal");
            } else {
              Swal.fire({
                title: "Error",
                text: "Failed to Add Notification !!!",
                icon: "error",
                timer: 2500,
              });
              this.$emit("close-modal");
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Something went wrong !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      }
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>
