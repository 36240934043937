<template>
  <div class="container">
    <!-- Outer Row -->
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-6">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <!-- Nested Row within Card Body -->
            <div class="row" v-show="displaypage == 1">
              <div class="col-lg-12">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Forgot Your Password?</h1>
                  </div>
                  <div class="text-center">
                    <p class="text-gray-900 mb-4">
                      Enter and submit your <b>username</b>. An email containing
                      instructions and a link to reset your password will be
                      sent to your email address.
                    </p>
                  </div>
                  <form class="user">
                    <div class="form-group">
                      <input
                        v-model="reqObj.username"
                        type="email"
                        class="form-control form-control-user"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        placeholder="Enter Username..."
                      />
                    </div>

                    <a id="afterclick"
                      @click="forgotpassword"
                      class="btn btn-primary btn-user btn-block"
                    >
                      Submit
                    </a>
                    <hr />
                  </form>
                </div>
              </div>
            </div>

            <div class="row" v-show="displaypage !== 1">
              <div class="col-lg-12">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4"> Email Sent to your email address</h1><hr>
                  </div>
                  <div class="text-center">
                    <p class="text-gray-900 mb-4">
                     If an account is associated with the email address you have entered, you will receive an email containing instructions and a link to reset your password.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/ajax/axios";
import user from "@/ajax/user";
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",
  data: function () {
    return {
      reqObj: {
        username: "",
      },
      displaypage:1,
    };
  },
  mounted() {
    // this.testMethod()
    // this.testMethodWithoutAsync()
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "setAccessToken"]),

    forgotpassword() {
      const element = document.getElementById('afterclick');
      element.setAttribute('disabled', '');
      var regex = /^$/;
      if (regex.test(this.reqObj.username)) {
        alert("Enter Your Username");
      }
      else{
      user.CheckUsername(this.reqObj).then((result) => {
        var actualResult = result.data;
        var resultCode = actualResult.code;
        var resultData = actualResult.data;

        //failure in token response //e.x incorrect username and password
        if (resultCode != 200) {
          alert(JSON.stringify(actualResult.message));
        } else {
            this.displaypage = 0;
        }
        //this.getCurrentUser();
        // Set User to store with the below uncomment once we get data
        // this.setCurrentUser(data);
      });
     }
    },
  },
};
</script>
