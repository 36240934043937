<template>
  <div
    v-if="permissions.includes('reports.write') && data_loaded"
    class="vue-tempalte add-report"
  >
    <div class="row g-0 mb-2">
      <div class="col-12 row g-0 mb-md-2">
        <div class="col-md-2 col-6">Task id :</div>
        <div class="col-md-4 col-6 fw-bold">{{ task_details["tasks_id"] }}</div>
        <div class="col-md-2 col-6">Client Name :</div>
        <div class="col-md-4 col-6 fw-bold">
          {{ task_details["client_name"] }} /
          {{ task_details["subclientname"] }}
        </div>
      </div>
      <div class="col-12 row g-0 mb-md-2">
        <div class="col-md-2 col-6">Project Name :</div>
        <div class="col-md-4 col-6 fw-bold">
          {{ task_details["project_name"] }}
        </div>
        <div class="col-md-2 col-6">Milestone Name :</div>
        <div class="col-md-4 col-6 fw-bold">{{ task_details["name"] }}</div>
      </div>
      <div class="col-12 row g-0 mb-md-2">
        <div class="col-md-2 col-6">Task Description :</div>
        <div class="col-md-4 col-6 fw-bold">
          {{ task_details["description"] }}
        </div>
        <div class="col-md-6 col-12 row g-0">
          <div class="col-md-2 col-6">Start Date :</div>
          <div class="col-md-4 col-6 fw-bold">
            {{ task_details["start_date"] }}
          </div>
          <div class="col-md-2 col-6">Due Date :</div>
          <div class="col-md-4 col-6 fw-bold">
            {{ task_details["due_date"] }}
          </div>
        </div>
      </div>
      <div class="col-12 row g-0 mb-2">
        <div class="col-md-2 col-6">Estimated Hours :</div>
        <div class="col-md-4 col-6 fw-bold">{{ Estimate_time }}</div>
        <div class="col-md-6 col-12 row g-0">
          <div class="col-md-2 col-6">Task Status :</div>
          <div class="col-md-4 col-6 fw-bold">
            {{ task_details["task_status"] }}
          </div>
          <div class="col-md-3 col-6">Task Priority :</div>
          <div class="col-md-2 col-6 fw-bold text-start">
            {{ task_details["task_priority"] }}
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="formValidator()">
      <div class="d-sm-flex align-items-left mb-3">
        <div class="col-sm-2">
          <label class="mandatory">Date </label>
        </div>
        <datepicker
          v-model="report.date"
          :format="'yyyy-MM-dd'"
          placeholder="Select Date"
          :clear-button="true"
          :calendar-button="true"
          :clear-button-icon="'fa fa-times'"
          :input-class="'border-start-0'"
          :calendar-button-icon="'fa fa-calendar'"
          :bootstrap-styling="true"
          :disabled-dates="disabledDates"
          required
        ></datepicker>
      </div>

      <div class="d-sm-flex align-items-left p-0">
        <div class="col-sm-2">
          <label class="mandatory">Spent time (hh : mm)</label>
        </div>
        <div class="w-100">
          <time-picker-vue :config="report.time"></time-picker-vue>
          <small class="text-danger m-0 d-block" v-if="this.maxvalErr">{{
            this.maxvalErr
          }}</small>
          <small class="note text-muted m-0"
            ><span class="font-weight-bold text-dark">Note:</span> Please enter
            minutes in range 15-30-45</small
          >
        </div>
      </div>

      <div
        class="d-sm-flex align-items-left p-0 mb-3"
        v-if="action == 'approved'"
      >
        <div class="col-sm-2">
          <label class="mandatory">Approved time (hh : mm)</label>
        </div>
        <div class="w-100">
          <time-picker-vue :config="report.appv_time"></time-picker-vue>
          <small
            v-if="show_err_of_max_time != ''"
            class="text-danger p-0 m-0"
            >{{
              `*** ${show_err_of_max_time} ${ConvertMinutesToTime2(
                report.time.hours * 60 + report.time.minutes
              )} ***`
            }}</small
          >
        </div>
        <!-- <input type="text" class="form-control" @keypress="isNumber()" v-model="report.spent_time" :state="null"
          required placeholder="Enter time spent" oninvalid="this.setCustomValidity('Please enter time spent')"
          oninput="this.setCustomValidity('')"> -->
      </div>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label class="mandatory">Description</label>
        </div>
        <div class="w-100">
          <!-- <div class="" v-if="invalid_words.length"> -->
          <!-- <small class="text-danger m-0 d-block" style="text-decoration:underline">Description contains following invalid words:&nbsp;</small> -->
          <!-- <small class="text-danger m-0">Description contains following invalid words:&nbsp;</small> -->
          <!-- <small v-text="`( ${invalid_words.join(', ')} )`" class="text-danger m-0"></small> -->
          <!-- </div> -->
          <textarea
            id="textarea-formatter"
            rows="2"
            wrap="soft"
            class="form-control"
            v-model="report.description"
            :state="null"
            required
            placeholder="Enter description"
            oninvalid="this.setCustomValidity('Please enter description')"
            oninput="this.setCustomValidity('')"
          ></textarea>
          <small class="note text-muted"
            ><span class="fw-bold text-dark">Note:</span> Please do not include
            any user's firstname,lastname or salutations like sir/mam/bhai etc
            in report description</small
          >
        </div>
      </div>
      <div class="d-sm-flex align-items-left mb-3">
        <div class="col-sm-2">
          <label class="mandatory">Category</label>
        </div>
        <select
          v-model="report.task_categories"
          class="form-control"
          :state="null"
          required
          oninvalid="this.setCustomValidity('Please select category')"
          oninput="this.setCustomValidity('')"
        >
          <option value="" selected>Select Categories</option>
          <option
            v-for="items in categories"
            :key="items.id"
            :value="items.id"
            v-text="items.name"
          ></option>
        </select>
      </div>
      <div class="d-sm-flex align-items-left mb-3">
        <div class="col-sm-2">
          <label>Git commit number</label>
        </div>
        <div class="w-100">
          <input
            type="text"
            class="form-control"
            v-model="report.commit_number"
            placeholder="example : cbf1b9a,adc1g9p"
          />
          <small v-if="commit_err != ''" class="text-danger">{{
            commit_err
          }}</small>
        </div>
      </div>
      <div class="d-sm-flex align-items-left mb-3">
        <div class="col-sm-2">
          <label>Git commit link</label>
        </div>
        <div class="w-100">
          <input
            type="text"
            class="form-control"
            v-model="report.commit_link"
            placeholder="example : https://github.com/product-dev/productname/commit/342d0b6c65e1e24df7ee7a5a09y6755898e9a709"
          />
          <small v-if="url_err != ''" class="text-danger d-block">{{
            url_err
          }}</small>
        </div>
      </div>
      <div class="d-sm-flex align-items-left mb-3">
        <div class="col-sm-2">
          <label class="form-label">Select Files</label>
        </div>
        <input
          type="file"
          class="form-control"
          @change="onFileUpload"
          multiple
        />
      </div>
      <div class="text-end">
        <button
          type="submit"
          class="btn btn-primary btn-lg"
          :class="disabled ? 'disabled' : ''"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
  <div
    class="d-flex justify-content-center align-items-center p-0"
    v-else
    style="height: 39rem"
  >
    <div
      class="spinner-border text-primary mb-5"
      style="height: 8rem; width: 8rem"
    ></div>
    <!-- <UnauthorizedPage></UnauthorizedPage> -->
  </div>
  <!-- <div v-else>
    <UnauthorizedPage></UnauthorizedPage>
  </div> -->
</template>

<script>
import axios from "axios";
import Vue from "vue";
import user from "@/ajax/user";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import TimePickerVue from "../../components/TimePicker.vue";
import VueTimePicker from "vue2-timepicker";
Vue.use(VueSweetalert2);
const curr_date = new Date();
export default {
  name: "AddReports",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
    Multiselect,
    TimePickerVue,
    VueTimePicker,
  },
  data: function () {
    return {
      url_err: "",
      commit_err: "",
      isSubmitOrNot: true,
      show_err_of_max_time: "",
      report: {
        time: { hours: 0, minutes: 0 },
        appv_time: { hours: 0, minutes: 0 },
        task: "",
        description: "",
        task_categories: "",
        // for taking todays date in date field.
        date: new Date().toISOString().slice(0, 10),
        files: [],
        report_id: 0,
        commit_number: null,
        commit_link: null,
      },
      disabledDates: {
        from: new Date(Date.now()),
      },
      task_details: [],
      FILE: null,
      // upload var
      img_obj_id: [],
      img_save_path: [],
      task_files: [],
      file_type: "report_file",
      //
      permissions: window.localStorage.getItem("permissions"),
      categories: [],
      maxvalErr: "",
      ReportCreate: false,
      ReportUpdate: false,

      Estimate_time: "",
      data_loaded: false,
      invalid_words: [],
      disabled: false,
    };
  },
  props: {
    report_task_id: {
      type: Number,
    },
    report_id: {
      type: Number,
      default: 0,
    },
    task_id: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: null,
    },
  },
  watch: {
    report_task_id: {
      handler(nv, ov) {
        if (nv !== undefined && nv !== null) {
          this.getUserPermissions();
          this.CheckReportOath(nv);
          this.ReportCreate = true;
          this.ReportUpdate = false;
        }
      },
      immediate: true,
    },
    report_id: {
      handler(nv, ov) {
        if (nv) {
          this.ReportCreate = false;
          this.ReportUpdate = true;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.permissions.includes("reports.write")) {
      this.getUserPermissions();
      if (this.task_id) {
        this.CheckReportOath(this.task_id);
        this.ReportCreate = false;
        this.ReportUpdate = true;
      }
      if (this.report_id) {
        this.getreportsbyid(this.report_id);
        this.task_detail_show = false;
      }
    }
    this.getCategoryDropdown();
  },
  methods: {
    getCategoryDropdown() {
      user.getCategoryDropdown().then((result) => {
        this.categories = result.data.data;
      });
    },
    CheckReportOath(id) {
      // display task id in report form
      this.data_loaded = false;
      user.getTaskbyid(id).then((result) => {
        if (result.data.data != "") {
          this.task_details = result.data.data[0];
          this.report.task = result.data.data[0]["tasks_id"];
          this.Estimate_time = this.ConvertMinutesToTime(
            this.task_details["new_estimated_minutes"]
          );
          this.data_loaded = true;
        } else {
          this.data_loaded = true;
          this.$emit("close-modal");
          this.$swal(
            "Error",
            "You can fill the report of the same task that is assigned to you...",
            "warning"
          );
          // this.$router.push({ name: 'Tasks' });
        }
      });
    },
    getreportsbyid(id) {
      this.data_loaded = false;
      user
        .getreportsbyid(id)
        .then((result) => {
          if (result.data.code == 200) {
            this.data_loaded = true;
            this.report.date = result.data.data[0]["date"];
            this.report.time.hours = Math.floor(
              result.data.data[0]["new_spent_time"] / 60
            );
            this.report.time.minutes = Math.floor(
              result.data.data[0]["new_spent_time"] % 60
            );
            this.report.appv_time.hours = Math.floor(
              result.data.data[0]["new_approved_time"] / 60
            );
            this.report.appv_time.minutes = Math.floor(
              result.data.data[0]["new_approved_time"] % 60
            );
            this.report.description = result.data.data[0]["description"];
            this.report.task_categories = result.data.data[0]["category_id"];
            this.report.report_id = result.data.data[0]["id"];
            this.report.commit_number = result.data.data[0]["commit_number"];
            this.report.commit_link = result.data.data[0]["commit_link"];

            this.ReportUpdate = true;
          } else if (result.data.code == 404) {
            this.data_loaded = true;
            this.$swal(
              "Error",
              "You cannot update others report...",
              "warning"
            );
            this.$router.push({ name: "Reports" });
          } else {
            this.data_loaded = true;
            this.$swal("Error", "Found Error...", "warning");
            this.$router.push({ name: "Reports" });
          }
        })
        .catch((err) => {
          this.$emit("close-modal");
          this.$swal("Error", "Something went wrong", "error");
        });
    },
    // methods for files upload
    getPreAssighedUrl() {
      user.getPreAssighedUrl(this.file_type).then((result) => {
        // console.log(result.data.data.url);
        this.img_obj_id.push(result.data.data.object_key);
        this.img_save_path.push(result.data.data.url);
      });
    },
    onFileUpload(event) {
      // if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)){
      //     if(event.target.files[0].size < 1000000){
      this.FILE = event.target.files;
      for (var i = 0; i < this.FILE.length; i++) {
        this.getPreAssighedUrl();
        this.task_files.push(this.FILE[i]);
      }
      // console.log("this.FILE.type", this.FILE.type)
      // console.log(this.img_save_path)
      // this.getPreAssighedUrl();
      // console.log(this.FILE)
      //     }else{
      //         this.$swal('Error','Select an image whose size is less than 1 MB', 'warning')
      //         event.target.value = []
      //     }
      // }else{
      //     this.$swal('Error','Only jpg/jpeg and png files are allowed!', 'warning')
      //     event.target.value = []
      // }
    },
    formValidator() {
      this.url_err = "";
      this.commit_err = "";
      this.isSubmitOrNot = true;
      let spent_time_err = true;
      this.invalid_words.length = 0;
      const formData = new FormData();
      if (this.FILE) {
        for (var i = 0; i < this.FILE.length; i++) {
          this.report.files[i] = {
            name: this.FILE[i].name,
            path: this.img_obj_id[i],
          };
          formData.append("file", this.task_files[i]);
          // console.log("save path"+this.img_save_path[i],"image id"+this.img_obj_id[i],"image data"+this.task_files[i])
          axios
            .put(this.img_save_path[i], this.task_files[i], {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (result) {
              if (result.status == 200) {
              } else {
                this.$swal(
                  "Error",
                  "Files not uploaded successfully...",
                  "warning"
                );
              }
            });
        }
      }
      // validation of url
      if (this.report.commit_link != "" && this.report.commit_link != null) {
        if (!this.isValidHttpUrl(this.report.commit_link)) {
          this.isSubmitOrNot = false;
          this.url_err = "** Please insert a valid url **";
          return;
        }
      }
      // validation of url
      if (
        this.report.commit_number != "" &&
        this.report.commit_number != null
      ) {
        var get_all_commit_no = this.report.commit_number.split(",");
        var get_not_proper_c_no = 0;
        // console.log(this.report.commit_number,this.report.commit_number.split(','))
        for (var i = 0; i < get_all_commit_no.length; i++) {
          if (get_all_commit_no[i].trim().length != 7) {
            get_not_proper_c_no++;
          }
        }
        if (get_not_proper_c_no != 0) {
          this.isSubmitOrNot = false;
          this.commit_err = "** Please insert a valid commit number **";
          return;
        }
      }
      // validation for total minutes
      var total_minutes =
        parseInt(this.report.time.hours * 60) +
        parseInt(this.report.time.minutes);
      if (total_minutes == 0) {
        this.isSubmitOrNot = false;
        this.maxvalErr = "** Enter spent time...";
        return;
      } else {
        this.isSubmitOrNot = true;
        this.maxvalErr = "";
      }

      // For approve time validation
      let approved_time =
        parseInt(this.report.appv_time.hours * 60) +
        parseInt(this.report.appv_time.minutes);
      let spent_time =
        parseInt(this.report.time.hours * 60) +
        parseInt(this.report.time.minutes);
      if (approved_time > spent_time && this.action == "approved") {
        this.show_err_of_max_time = "Max limit is";
        this.isSubmitOrNot = false;
        return;
      } else {
        this.show_err_of_max_time = "";
      }

      // For spent time
      if (
        this.report.time.minutes != 15 &&
        this.report.time.minutes != 30 &&
        this.report.time.minutes != 45 &&
        this.report.time.minutes != 0 &&
        this.isSubmitOrNot
      ) {
        this.isSubmitOrNot = false;
        this.$swal
          .fire({
            title: "Do you want to continue",
            text: "You have not entered spent time in range of 15-30-45",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonColor: "#32a852",
            cancelButtonColor: "#f00",
          })
          .then((res) => {
            if (res.isConfirmed) {
              this.isSubmitOrNot = true;
              this.OnSubmitForm(this.isSubmitOrNot);
            }
          });
        return;
      }

      // For calling submit form function
      if (this.isSubmitOrNot) {
        this.OnSubmitForm(this.isSubmitOrNot);
      }
    },
    OnSubmitForm(check) {
      if (check) {
        this.disabled = true;
        this.report.action = this.action;
        this.report.commit_number == ""
          ? (this.report.commit_number = null)
          : "";
        this.report.commit_link == "" ? (this.report.commit_link = null) : "";
        this.report.date = moment(this.report.date).format("yyyy-MM-DD");
        if (this.ReportCreate) {
          this.report.time.hours = Number(this.report.time.hours);
          this.report.time.minutes = Number(this.report.time.minutes);
          this.report.confirm == true ? "" : (this.report.confirm = false);
          user
            .CreateReport(this.report)
            .then((result) => {
              if (result.data.code == 200) {
                this.$swal({
                  title: "Success",
                  text: "Report submitted successfully...",
                  icon: "success",
                  timer: 1500,
                });
                this.$emit("close-modal");
              } else if (result.data.code == 401) {
                this.$swal({
                  title: "Error",
                  text: "Not Authroized",
                  icon: "warning",
                  timer: 1500,
                });
                this.$emit("close-modal");
              } else {
                this.$swal({
                  title: "Error",
                  text: "Report not submitted...",
                  icon: "error",
                  timer: 1500,
                });
                this.$emit("close-modal");
              }
              // if(result.data.data.reward.length){
              this.disabled = false;
              // }
            })
            .catch((err) => {
              this.$swal({
                title: "Report description contains invalid words",
                html:
                  "<h6 style='color:red'>( " +
                  err.response.data.detail.join(" ,") +
                  " )</h6>",
                icon: "question",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                confirmButtonColor: "#32a852",
                cancelButtonColor: "#f00",
              }).then((res) => {
                if (res.isConfirmed) {
                  this.report.confirm = true;
                  this.OnSubmitForm(this.isSubmitOrNot);
                } else {
                  this.report.confirm = false;
                }
                this.disabled = false;
              });
            });
        } else if (this.ReportUpdate) {
          this.report.time.hours = Number(this.report.time.hours);
          this.report.time.minutes = Number(this.report.time.minutes);
          this.report.appv_time.hours = Number(this.report.appv_time.hours);
          this.report.appv_time.minutes = Number(this.report.appv_time.minutes);
          this.report.confirm == true ? "" : (this.report.confirm = false);
          user
            .UpdateReport(this.report)
            .then((result) => {
              if (result.data.code == 200) {
                this.$swal({
                  title: "Success",
                  text: "Report Updated Successfully...",
                  icon: "success",
                  timer: 1500,
                });
                this.$emit("close-modal");
              } else if (result.data.code == 401) {
                this.$swal({
                  title: "Error",
                  text: "Not Authroized",
                  icon: "error",
                  timer: 1500,
                });
                this.$emit("close-modal");
              } else {
                this.$swal({
                  title: "Error",
                  text: "Report not Updated...",
                  icon: "warning",
                  timer: 1500,
                });
                this.$emit("close-modal");
              }
              this.disabled = false;
            })
            .catch((err) => {
              this.$swal({
                title: "Report description contains invalid words",
                html:
                  "<h6 style='color:red'>( " +
                  err.response.data.detail.join(" ,") +
                  " )</h6>",
                icon: "question",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                width: "40rem",
                confirmButtonColor: "#32a852",
                cancelButtonColor: "#f00",
              }).then((res) => {
                if (res.isConfirmed) {
                  this.report.confirm = true;
                  this.OnSubmitForm(this.isSubmitOrNot);
                } else {
                  this.report.confirm = false;
                }
              });
              this.disabled = false;
            });
        }
      }
    },
    // number validation for spent time
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || this.report.spent_time.indexOf(".") >= 0)
      ) {
        // 46 is for dot
        evt.preventDefault();
      }
      return true;
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "dm-sans";
  src: url("../fonts/DMSans-Regular.ttf");
}
* {
  font-family: "dm-sans";
}
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
span {
  line-height: 40px !important;
}
.display {
  border: none;
  font-weight: bold;
}
.note {
  font-size: 13px;
}
.desc-err {
  background: rgba(255, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 2px;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
