<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="d-flex justify-content-center align-items-center p-5 mt-5"
        v-if="showError"
        key="error"
      >
        <h3>Something Went Wrong</h3>
      </div>
      <div
        class="row ps-3 g-0"
        v-else-if="tableData != null && tableData.length"
        key="loaded"
      >
        <div class="col-md-6 col-12">
          <table class="border table table-hover">
            <thead class="">
              <tr>
                <th class="text-center">Sr no.</th>
                <th></th>
                <th>Module Name</th>
                <th class="text-center">Usage(%)</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(detail, index) in tableData" is="tr">
                <tr
                  class="cursor-pointer"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  :data-bs-target="`#collapseItem${index + 1}`"
                >
                  <td class="text-center" style="width: 4rem">
                    {{ index + 1 }}
                  </td>
                  <td class="text-end" style="width: 2rem">
                    <i
                      class="fa fa-chevron-right text-secondary cursor-pointer sp-2"
                      style="font-size: 12px"
                      v-if="
                        !ignoreSubmodules &&
                        detail.submodules &&
                        detail.submodules.length
                      "
                    ></i>
                  </td>
                  <td class="">
                    <div class="d-flex align-items-center gap-2">
                      <span class="flex-fill">{{ detail.label }}</span>
                      <!-- <i
                          class="fa fa-chevron-right text-secondary cursor-pointer"
                          style="font-size: 14px"
                          v-if="detail.submodules.length"
                        ></i> -->

                      <!-- <span class="flex-fill">{{ detail.label }}</span> -->
                    </div>
                  </td>
                  <td class="text-center" style="width: 8rem">
                    {{ detail.percentage }}%
                  </td>
                </tr>
                <template v-if="!ignoreSubmodules">
                  <tr
                    class="collapse"
                    :id="`collapseItem${index + 1}`"
                    v-for="item in detail.submodules"
                    :key="item.id"
                  >
                    <td
                      class="text-muted border-end-0 border-start-0 bg-light text-center"
                      style="width: 4rem"
                    >
                      <span>&minus;</span>
                    </td>
                    <td
                      class="text-muted border-end-0 bg-light"
                      style="width: 2rem"
                    ></td>
                    <td class="text-muted border-start-0 bg-light">
                      <!-- <div class="d-flex align-items-center gap-2"> -->
                      <!-- <span>&minus;</span> -->
                      <!-- </div> -->
                      <span class="flex-fill">{{ item.label }}</span>
                    </td>
                    <td
                      class="text-muted bg-light text-center"
                      style="width: 8rem"
                    >
                      {{ item.percentage }}%
                    </td>
                  </tr>
                </template>
              </template>
              <!-- <tr > -->
              <!-- <tr>`
                    <td style="width: 3rem" class="text-center">
                    {{ item }}
                    </td>
                    <td>
                      <div class="d-flex align-items-center gap-2">
                        <i
                          class="fa fa-chevron-right text-secondary cursor-pointer"
                          style="font-size: 14px"
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          :data-bs-target="`#collapseItem${item}`"
                        ></i>
                        <span class="flex-grow">Who knows {{ item }}</span>
                      </div>
                    </td>
                    <td>{{ Math.floor(Math.random() * 100) }}%</td>
                  </tr>
                  <tr :id="`collapseItem${item}`">
                    <td></td>
                    <td>name</td>
                    <td>%</td>
                  </tr> -->
              <!-- </tr> -->
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-12 px-4 mt-2 d-md-block d-none">
          <!-- <div class="bg-white border px-2 pt-2 text-center border-start-0">
              <h5 class="">Visual Representation</h5>
            </div> -->
          <div class="position-sticky top-0 pt-3">
            <!-- <div
                style="height: 500px; width: 500px"
                class="bg-light shadow-sm border rounded-circle"
              ></div> -->
            <!-- <div class="mt-5 d-flex flex-wrap gap-2">
                <div v-for="item in 20" :key="item">Who knows {{ item }}</div>
              </div> -->
            <v-chart class="chart" :option="chartOptions" autoresize></v-chart>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center p-5 mt-5"
        key="loading"
        v-else-if="tableData == null"
      >
        <div
          class="spinner-border text-primary mb-5"
          style="height: 6rem; width: 6rem"
        ></div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center p-5 mt-5"
        key="empty"
        v-else
      >
        <h3>No Data Available</h3>
      </div>
    </transition>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";

import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  props: {
    chartOptions: {
      type: Object,
    },
    tableData: {
      required: true,
      default: null,
    },
    ignoreSubmodules: {
      default: false,
    },
    showError: {
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-action {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
::v-deep .collapse.show {
  display: table-row !important;
}
::v-deep .collapse {
  display: none !important;
}
::v-deep .collapsing {
  display: none !important;
  transition: 0s;
}
.chart {
  height: 800px;
}
::v-deep tr[aria-expanded="true"] .fa {
  transform: rotate(90deg);
  transition: 0.1s linear;
}

::v-deep tr[aria-expanded="false"] .fa {
  transition: 0.1s linear;
}
</style>
