<template>
  <!-- Begin Page Content -->
  <div class="container-fluid" :style="firstTable ? 'min-height: 600px':''">
    <div class="card shadow mb-4">
      <div
        class="card-header d-sm-flex align-items-center justify-content-between mb-4 py-3"
      >
        <h4 class="m-0 font-weight-bold text-primary" v-if="firstTable">Work Log</h4>
        <h4 class="m-0 font-weight-bold text-primary py-2" v-else>Team Mate's Work Log</h4>
      </div>

      <div v-if="permissions.includes('reports.read')" class="card-body m-0">
        <!-- Your Table -->
        <div class="table-responsive" v-if="firstTable">
          <h5 class="m-0 font-weight-bold text-primary">Your Work Log</h5>
          <br />
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChangefirst"
            @on-sort-change="onSortChangefirst"
            @on-column-filter="onColumnFilterfirst"
            @on-per-page-change="onPerPageChangefirst"
            :totalRows="TotalRecords1"
            :isLoading.sync="isLoadingfirsttable"
            :pagination-options="{
              enabled: true,
            }"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'date', type: 'desc' },
            }"
            :rows="your_tasks_rows"
            :columns="your_tasks_column"
          >
            <template slot="table-row" scope="props">
              <span v-if="props.column.field == 'description'">
                <span>{{props.row.description}}</span><br>
                <a v-if="props.row.commit_link != null" :href='props.row.commit_link' target="_blank">commit link</a>
              </span>
              <span v-else-if="props.column.label == 'Action'">
                <tr class="no-border-td-tr">
                  <!-- <td v-if="permissions.includes('reports.write')" class="no-border-td-tr">
                        <router-link :to="'/task/update/'+props.row.id" v-bind:class="[currentPage=='updatetask' ? 'active' : '']">
                          <i class="btn btn-primary" >Update</i>
                        </router-link>
                      </td> -->

                  <td
                    v-if="permissions.includes('reports.update')"
                    class="no-border-td-tr"
                  >
                    <router-link
                      :to="`/update-report/${props.row.id}/${props.row.tasks_id}`"
                      v-bind:class="[
                        currentPage == 'UpdateReport' ? 'active' : '',
                      ]"
                    >
                      <i class="btn btn-primary">Update</i>
                    </router-link>
                  </td>
                  <td
                    v-if="
                      permissions.includes('reports.delete') &&
                      props.row.created_by == currentUser.id &&
                      props.row.new_approved_time == null
                    "
                    class="no-border-td-tr"
                  >
                    <i
                      @click="delete_report(props.row.id)"
                      class="btn btn-danger"
                      >Delete</i
                    >
                  </td>
                </tr>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
            <template
              slot="column-filter"
              slot-scope="{ column, updateFilters }"
            >
              <span v-if="column.field == 'date'">
                <datepicker
                  :clear-button="true"
                  :format="formateDate"
                  placeholder="yyyy-mm-dd"
                  @input="(e) => updateFilters(column, e)"
                />
              </span>
              <!-- <span v-else-if="column.field == 'due_date'">
                    <datepicker :clear-button="true" :format="formateDate" placeholder="yyyy-mm-dd"
                      @input="(e) => updateFilters(column,e)"/>
                  </span> -->
            </template>
          </vue-good-table>

          <!-- PDF -->
          <div class="container-fluid mx-0 mt-4 p-0" v-if=firstTable>
            <button class="btn btn-primary mr-2" @click="pdfDownload(header1)" v-if="permissions.includes('pdf.show')">
              Pdf
            </button>
            <button class="btn btn-primary" @click="excelDownload(header1)" v-if="permissions.includes('excel.show')">
              Excel
            </button>
          </div>
        </div>

        <!--  -->
        <br />
        <!-- filters -->
        <form @submit.prevent="OnApplyFilters()" v-if="secondTable" class="container-fluid">
          <hr v-if="firstTable"/>

          <div class="row d-sm-flex align-items-center justify-content-between p-0" v-if="firstTable">
            <div class="col-7">
              <h5 class="m-0 font-weight-bold text-primary p-0">
                Team Mate's Work Log
              </h5>
            </div>
            <div class="col-5">
              <input v-model="task_id" class="form-control" type="search" placeholder="Search By Task Id ( Ex: 109,211,302) " aria-label="Search">
            </div>
          </div>


          <br v-if=firstTable />

          <div
            class="row d-sm-flex align-items-center justify-content-between p-0"
          >
            <flat-pickr
              placeholder="Select date range"
              v-model="config.date"
              :modelValue="config.date"
              :config="config"
              class="form-control col-md square mx-2 col"
              style="background-color: white"
              @on-change="GetArrayOfStartDate"
            ></flat-pickr>

            <!-- <select v-model="reqObj.sub_client" class="form-control" :state="null" required oninvalid="this.setCustomValidity('Please select name of the sub client')" oninput="this.setCustomValidity('')">
              <option value="">Select Sub Client</option>
              <option :key="index" v-for="(item, index) in sub_clients" :value="item.id">{{item.subclientname}}</option>
            </select> -->
            <div class="square col-md mx-2" v-if="permissions.includes('sub_clients.read')">
            <multiselect
              v-model="selected_sub_client"
              :hide-selected="true"
              :options="sub_clients"
              :multiple="true"
              placeholder="Select one or more SubClient"
              label="subclientname"
              track-by="id"
              class="square col-md mx-2 col report_filter_multiselect"
              group-label="label"
              group-values="sub_clients_data"
              :group-select="true"
            ></multiselect>
            </div>

            <!-- <multiselect
              v-model="value"
              :options="options"
              :multiple="true"
              group-values="project"
              group-label="sub_client"
              :group-select="true"
              class="square col-md mx-2"
              placeholder="Type to search"
              track-by="name"
              label="name"
            ></multiselect> -->

            <multiselect
              v-model="selected_projects"
              :hide-selected="true"
              :options="projects"
              :multiple="true"
              placeholder="Select one or more project"
              label="project_name"
              track-by="id"
              class="square col-md mx-2 col report_filter_multiselect"
              group-label="label"
              group-values="projects_data"
              :group-select="true"
            ></multiselect>

          </div>
          <div class="row mt-4">
            <multiselect
              v-model="selected_users"
              :hide-selected="true"
              :options="active_users_list"
              :multiple="true"
              placeholder="Select one or more User"
              label="first_name"
              track-by="id"
              class="col report_filter_multiselect"
              :custom-label="custom_label"
              group-label="label"
              group-values="users_data"
              :group-select="true"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>

            <multiselect
              v-model="selected_team_leads"
              :hide-selected="true"
              :options="team_leads"
              :multiple="true"
              placeholder="Select one or more Teams"
              :custom-label="custome_teams_label"
              label="first_name"
              track-by="id"
              class="col report_filter_multiselect"
              :group-select="true"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
            </multiselect>


            <multiselect
              v-model="selected_approve"
              :options="approve_dropdown"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Approved or Not Approved"
              label="label"
              track-by="name"
              class="square mx-2 col report_filter_multiselect"
              :preselect-first="true"
              return="name"
            >
              +
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              ></template>
              +
            </multiselect>

            <button class="btn btn-primary square mx-2">Filter</button>
            <!-- <button class="btn btn-danger square m-2" @click="ResetVariables()">Reset</button> -->
          </div>

          <div class="container-fluid mx-0 mt-2 p-0">
            <div class="btn ml-2" v-if="permissions.includes('excel.show') && permissions.includes('pdf.show')">
              <input v-model="h_b_c" type="checkbox" class="form-check-input" id="exampleCheck1">
              <small><label class="form-check-label" for="exampleCheck1" style="padding-top:0px!important"><b>Hours By Activity</b></label></small>
            </div>

            <div class="btn" v-if="permissions.includes('excel.show') && permissions.includes('pdf.show')">
              <input v-model="h_b_p" type="checkbox" class="form-check-input" id="exampleCheck1">
              <small><label class="form-check-label" for="exampleCheck1" style="padding-top:0px!important"><b>Hours By Project</b></label></small>
            </div><br>
            <button
              class="btn btn-primary mr-2"
              @click.prevent="pdfDownload(header2)"
              v-if="permissions.includes('pdf.show')"
            >
              Pdf
            </button>
            <button
              class="btn btn-primary mr-2"
              @click.prevent="excelDownload(header2)"
              v-if="permissions.includes('excel.show')"
            >
              Merge Excel
            </button>

            <button
              class="btn btn-primary"
              @click.prevent="excelDownload(header3)"
              v-if="permissions.includes('excel.show')"
            >
              Excel
            </button>
            <!-- <button
              class="btn btn-primary mx-3"
              @click.prevent="excelDownloadXLSX()"


            >
              Excel-xlsx
            </button> -->

          </div>
        </form>
        <!-- Here is the spinner -->
        <div
          class="container-fluid m-0 p-5 d-flex justify-content-center align-items-center"
          v-if="data_not_loaded"
        >
          <div
            class="spinner-border"
            role="status"
            style="height: 3rem; width: 3rem; color: #4e73df"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <!-- Loop table -->
        <div
          class="container-fluid m-0 p-5 d-flex justify-content-center align-items-center"
          v-if="data_load_msg"
        >
          <h5 v-text="data_load_msg"></h5>
        </div>
        <!-- No internet connection -->
        <div
          class="container-fluid m-0 p-5 d-flex justify-content-center align-items-center"
          v-if="no_internet"
        >
          <h5 v-text="no_internet"></h5>
        </div>
        <div
          class="table-responsive"
          v-if="
            data_not_loaded === false &&
            data_load_msg === '' &&
            no_internet === ''
          "
        >
          <div class="container-fluid mt-4">
            <div v-for="s of summary" class="d-flex justify-content-center align-items-center">
              <ul style="list-style: none; font-weight: 600" class="d-flex justify-content-between align-items-center">

                <li class="mr-3"><b>Total Users:</b> {{ s.user_count }}</li>

                <li class="mr-3">
                  <b>Working Hours: </b> {{ formatTimeStringFunction(Math.floor(s.working_hours / 60), (s.working_hours % 60)) }}
                </li>

                <li class="mr-3">
                  <b>Reported: </b> {{ formatTimeStringFunction(Math.floor(s.reported / 60), (s.reported % 60)) }}
                </li>

                <li class="mr-3">
                  <b>Not Reported: </b> {{ formatTimeStringFunction(Math.floor(s.not_reported / 60), (s.not_reported % 60)) }}
                </li>

                <div v-if="selected_approve.name == 'aproved' || selected_approve.name == 'both'">
                  <li class="mr-3">
                    <b>Approved: </b> {{ formatTimeStringFunction(Math.floor(s.approved / 60), (s.approved % 60)) }}
                  </li>
                </div>

                <div v-if="selected_approve.name == 'not_approved' || selected_approve.name == 'both'">
                  <li class="mr-3">
                    <b>Not Approved: </b> {{ formatTimeStringFunction(Math.floor(s.not_approved / 60), (s.not_approved % 60)) }}
                  </li>
                </div>

              </ul>
            </div>
          </div>

          <div v-for="(user, index) of custom_data" :key="index" class="my-3">
            <div
              class="card-header border d-flex justify-content-between align-items-center conatiner-fluid"
            >
              <h5 class="m-0 font-weight-bold text-primary">
                <!-- {{ user.user_name }}{{ "(" + user.report_date + ")" }} -->
                {{ user.user_name }} {{ "(" + user.report_date  + " - " + dayConverter(user.report_date) + ")"  }}
              </h5>

              <h5
                v-text="
                  'Total Spent time : ' + returnTotalSpentTime(user.reports)
                "
                class="p-0 text-primary"
                v-if="showApproveAll(user.reports)"
              ></h5>

              <button
                class="btn btn-success"
                @click="approveReport(user.reports, 'approve_all')"
                v-if="showApproveAll(user.reports)"
              >
                Approve All
              </button>
              <div v-else class="container w-25 p-0">
                <h5
                  v-text="
                    'Total Spent time : ' + returnTotalSpentTime(user.reports)
                  "
                  class="text-primary m-0"
                ></h5>
              </div>
            </div>

            <vue-good-table
              :rows="user.reports"
              :columns="custom_col"
              :key="key_ch"
            >
              <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'description'">
                <span>{{props.row.description}}</span><br>
                <a v-if="props.row.commit_link != null" :href='props.row.commit_link' target="_blank">commit link</a>
              </span>
                <span v-else-if="props.column.field == 'action'">
                  <tr
                    class="no-border-td-tr d-flex justify-content-center align-items-center"
                  >
                    <td
                      class="d-flex border-0 m-0 p-0 align-items-center"
                      v-if="
                        !props.row.is_approved && ((props.row.approve_show && props.row.user_id != currentUser.id) || currentUser.user_role == 1)
                      "
                    >
                      <button
                        class="btn btn-success ml-3 p-2"
                        @click="
                          approveReport(
                            props.row.id,
                            'approve',
                            user.reports[props.index]['spent_hours'],
                            user.reports[props.index]['spent_minutes']
                          )
                        "
                      >
                        Approve
                      </button>
                    </td>
                    <td
                      class="border-0 m-0 p-0"
                      v-else-if="props.row.is_approved == true"
                    >
                      Approved
                      <button v-if="permissions.includes('approvedhours.update')"
                        class="btn btn-link ml-3 p-2 mt-2"
                        @click="changeapprovedhours(user.reports[props.index])"
                      >
                        update
                      </button>
                      <button v-if="permissions.includes('approvedhours.delete')"
                        class="btn btn-link ml-3 p-2 mt-2"
                        @click="delete_report(props.row.id)"
                      >
                        delete
                      </button>
                    </td>
                    <td class="border-0 m-0 p-0" v-else>Not Approved</td>
                  </tr>
                </span>
                <span v-else-if="props.column.field == 'approved_hours'">
                  <tr
                    class="no-border-td-tr d-flex justify-content-center align-items-center"
                  >
                    <td
                      class="d-flex border-0 m-0 p-0 align-items-center"
                      v-if="!props.row.is_approved && ((props.row.approve_show && props.row.user_id != currentUser.id) || currentUser.user_role == 1)"
                    >
                      <my-time-picker-vue
                        :hours.sync="user.reports[props.index]['spent_hours']"
                        :minutes.sync="
                          user.reports[props.index]['spent_minutes']
                        "
                        :max_limit="String(props.row.new_spent_time)"
                      ></my-time-picker-vue>
                      <!-- <time-picker-vue
                        :config="{hours:0,minutes:0}"
                        :max_limit="String(props.row.new_spent_time)"
                      ></time-picker-vue> -->
                    </td>
                    <td
                      class="d-flex border-0 m-0 p-0 align-items-center"
                      v-else
                    >
                      {{ reported_hours(props.row.new_approved_time) }}
                    </td>
                  </tr>
                </span>
              </template>

              <!-- <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'report_desc'">
                      <read-more more-str="read more" :text="'dads'" less-str="read less" :max-chars="10"></read-more>
                    </span>
                  </template> -->

              <!-- <div slot="table-actions" class="row border m-0" v-if="showApproveAll(user.reports)">

                  </div> -->
            </vue-good-table>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <table v-show="false" id="testing-table">

      <div v-if="h_b_p">
        <tr>
          <td colspan="2">Hours By Project</td>
          <!-- Uncomment this for allocated header in excel -->
          <!-- <td>Allocated</td> -->
        </tr>
        <tr v-for="(item, index) of sheet2_data3" :key="index.id + index">
          <td v-text="item.name"></td>
          <td v-text="item.formatString"></td>
          <!-- this field is for allocated hours just uncomment it and map data here -->
          <!-- <td v-text="item.allocated_hours"></td> -->
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td></td>
        </tr>
      </div>
      <div v-if="true">
        <tr>
          <td colspan="2">Hours By Resources</td>
          <!-- Uncomment this for allocated header in excel -->
          <!-- <td>Allocated</td> -->
        </tr>
        <tr v-for="item of sheet2_data2" :key="item.id">
          <td v-text="item.name"></td>
          <td v-text="item.formatString"></td>
          <!-- this field is for allocated hours just uncomment it and map data here -->
          <!-- <td v-text="item.allocated_hours"></td> -->
        </tr>
        <tr>
          <td></td>
        </tr>
        <tr>
          <td></td>
        </tr>
        <div v-if="h_b_c">
          <tr>
            <td colspan="2">Activity</td>
          </tr>
          <tr
            v-for="(item, index) in sheet2_data1.table_data"
            :key="item.id + index"
          >
            <td v-text="item.name"></td>
            <td v-text="item.formatString"></td>
          </tr>
        </div>
      </div>
    </table>
    <!-- update report approved hours pop up model start -->
        <!-- <b-modal scrollable centered id="report-update-model" size="lg">
            <template #modal-header>
              <div class="w-100 bg-primary text-white p-2">
                <h4 class="p-0 m-0"><b><b-icon icon="pencil-square"></b-icon> Update Approved Hours </b></h4>
              </div>
            </template>
            <table class="table p-0 m-0">
              <thead class="bg-white">
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col">Task Summary</th>
                  <th scope="col">Report Summary</th>
                  <th scope="col">Spent Time</th>
                  <th scope="col">Approved Time</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>{{update_approved_report_details['project_name']}}</td>
                    <td><read-more more-str="read more" :text="update_approved_report_details.task_dec" less-str="read less" :max-chars="100"></read-more></td>
                    <td><read-more more-str="read more" :text="update_approved_report_details.description" less-str="read less" :max-chars="100"></read-more></td>
                    <td>
                      <time-picker-vue :config="updated_spent_time"></time-picker-vue>
                    <td>
                      <time-picker-vue :config="updated_approved_time"></time-picker-vue>
                      <p v-if="show_err_of_max_time !='' " class="text-danger p-0 m-0 text-center">{{show_err_of_max_time+' '+ConvertMinutesToTime(update_approved_report_details.new_spent_time)}}</p>
                    </td>
                  </tr>
              </tbody>
            </table>
            <template #modal-footer>
                <b-button class="btn btn-danger" @click="hideModal"><b>Close</b></b-button>
                 <b-button class="btn btn-success" @click="submitupdaterequest"><b>Save</b></b-button>
            </template>
        </b-modal> -->
      <!-- update report approved hours pop up model end -->
    <div v-if="!permissions.includes('reports.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import axios from "axios";
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment";
moment.locale('en');
import Datepicker from "vuejs-datepicker";
import myMixin from "@/mixins/Permission";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import TimePickerVue from "../../components/TimePicker.vue";
import MyTimePickerVue from "../../components/MyTimePicker.vue";
import Multiselect from "vue-multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ReadMore from "vue-read-more";

import { utils, writeFileXLSX, writeFile} from "xlsx-js-style";

Vue.use(ReadMore);
Vue.use(VueSweetalert2);
// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jsontoexcel } from "vue-table-to-excel";
// import ReadMore from 'vue-read-more';
const date = new Date();
export default {
  name: "Reports",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
    TimePickerVue,
    MyTimePickerVue,
    Multiselect,
    flatPickr,
  },
  data: function () {
    return {
      h_b_p:true,
      h_b_c:true,
      update_approved_report_details:{},
      updated_approved_time:{hours:0,minutes:0},
      updated_spent_time:{hours:0,minutes:0},
      show_err_of_max_time:'',
      team_leads:[],
      selected_team_leads:[],
      config: {
        date: null,
        mode: "range",
        dateFormat: "Y-m-d",
        defaultDate: [
          new Date(date.getFullYear(), date.getMonth(), 1),
          new Date().toISOString().substring(),
        ],
      },
      users: [],
      active_users_list:[],
      projects: [],
      sub_clients: [],

      selected_sub_client: [],
      selected_users: [],
      selected_start_date: [],
      selected_projects: [],
      selected_approve: [],
      task_id: '',
      totalRecordssecondtable: 1,

      approve_dropdown: [
        { label: "Not Approved", name: "not_approved" },
        { label: "Approved", name: "aproved" },
        { label: "Both", name: "both" },
      ],

      serverParams: {
        columnFilters: {
          users: null,
          sub_clients: null,
          projects: null,
          start_date_from: null,
          start_date_to: null,
          approve: null,
          flag: null,
          search_by_task_id: null,
        },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },

      warning: "",
      set_hours: { hours: 0, minutes: 0 },
      report_id: "",
      UserState: "",
      spent_hours: "",
      spent_minutes: "",
      reqObj: {
        description: "",
        spent_time: "",
        creater_name: "",
        date: "",
        project_name: "",
      },
      // first table
      currentPage: "",
      serverParamsfirst: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
        setcurrentPage: 1,
      },

      totalRecordsfirsttable: 0,
      TotalRecords1: 0,
      isLoadingfirsttable: false,
      your_tasks_rows: [],
      summary: [],
      your_tasks_column: [
        {
          label: "Date",
          field: "date",
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "MM/DD/YYYY", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: " Project",
          field: "project_name",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Task",
          field: "short_desc",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task short summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Spent time",
          field: "new_spent_time",
          formatFn: this.ConvertMinutesToTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Time spent in hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Report description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Approved Hours",
          field: "new_approved_time",
          formatFn: this.ConvertMinutesToTime,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting",
        },
      ],

      custom_col: [
        {
          label: "Task Id",
          field: "tasks_id",
        },
        {
          label: "Project",
          field: "project_name",
        },
        {
          label: "Category",
          field: "report_category",
        },
        {
          label: "Task",
          field: "task_dec",
        },
        {
          label: "Report",
          field: "description",
        },
        {
          label: "Spent time",
          field: "new_spent_time",
          formatFn: this.reported_hours,
        },
        {
          label: "Approved Hours",
          field: "approved_hours",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      // For first table pdf config
      header1: {
        name: "your_reports",
        thead: [
          "Date",
          "Project",
          "Task",
          "Spent time",
          "Description",
          "Approved Hours",
        ],
        body: [],
        // For second table pdf config
      },
      header2: {
        name: "team_mates_reports",
        thead: [
          "Date",
          "Name",
          "Project",
          "Activity",
          "Task",
          "Report",
          "Hours",
        ],
        body: [],
      },

      header3: {
        name: "test",
        thead: [
          "Date",
          "Name",
          "Project",
          "Activity",
          "Task",
          "Report",
          "Hours",
        ],
        body: [],
      },

      //For excel config
      excel: {
        data: [],
        head: "",
        filename: "",
      },
      permissions: window.localStorage.getItem("permissions"),
      time: { hours: "0", minutes: "0" },
      custom_data: [],
      temp_data: [],
      key_ch: 1,
      sheet2_data1: [],
      sheet2_data2: [],
      sheet2_data3: [],
      categories: [],
      data_not_loaded: true,
      data_load_msg: "",
      no_internet: "",

      options: [],
      value: []
    };
  },
  beforeMount() {
    this.setCurrentPage("AllTasks");
  },
  props:{
    firstTable:{
      default:true,
      type:Boolean
    },
    secondTable:{
      default:true,
      type:Boolean
    }
  },
  mounted() {

    if (this.permissions.includes("sub_clients.read")) {
      this.getAllSubClient();
      this.getUserPermissions();
    }

    if (this.permissions.includes("reports.read")) {
      let date1 = (this.serverParams.columnFilters["start_date_from"] =
        new Date(date.getFullYear(), date.getMonth(), 1));
      let date2 = (this.serverParams.columnFilters["start_date_to"] = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      ));
      this.getUsers();
      // this.getallyourreports();
      // this.displayYoursReportPages();
      // this.getAllSubClient();
      this.GetArrayOfStartDate([date1, date2]);
      // this.getAllSubclinetProjects();
      this.getAllProjects();
      // this.getCategory();
      this.getteammatesreport();
      // this.OnApplyFilters();
      this.GetAllTeamLeadDropdowns();
      // for get team vise reports
    }
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    GetAllTeamLeadDropdowns(){
        user.GetAllTeamLeadDropdowns().then((result) => {
          if(result.data.code == 200){
            this.team_leads = result.data.data
          }
        })
    },
    showApproveAll(data) {
      let show = false;
      let count = 0;
      for (let i of data) {
        if (
          i.is_approved == false &&
          i.approve_show == true &&
          i.user_id !== this.currentUser.id
        ) {
          show = true;
          count++;
        }
      }
      return show;
    },
    custom_label(name) {
      // console.log(this.users);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        // console.log(name.first_name+name.middle_name);
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    custome_teams_label(name) {
      // console.log(this.users);
      if(name.id == this.currentUser.id){
          return "My team";
      }else{
        if (name.last_name != null && name.middle_name != null) {
          return `${name.first_name} ${name.last_name.charAt(0).toUpperCase()}` + "'s team";
        } else if (name.last_name == null) {
          // console.log(name.first_name+name.middle_name);
          return `${name.first_name} ${name.middle_name}`+ "'s team";
        } else if (name.middle_name == null) {
          return `${name.first_name} ${name.last_name.charAt(0).toUpperCase()}`+ "'s team";
        }
      }
    },
    approveSpinner(data) {
      this.$swal({
        html: `<div class='h-100 overflow-hidden p-5'>
                <div class='spinner-border text-primary' style='width:5rem;height:5rem'></div>
                <h3 class='mt-5'>Approving</h3>
              </div>`,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          const controller = new AbortController();
          let time = 0;
          let time_id = setInterval(() => {
            time += 1;
          }, 1000);
          setTimeout(() => {
            if (time >= 15) {
            clearInterval(time_id);
            this.$swal({
              title: "Slow Internet",
              text: "It takes too much time to respond",
              icon: "info",
              timer: 2000,
            });
            }
            if(navigator.onLine && time<15){
              user
              .ApproveReport(data,controller.signal)
              .then((result) => {
                if (result.data.code === 200) {
                  this.$swal({
                    title: "Approved",
                    text: "Report approved succesfully",
                    icon: "success",
                    timer: 2500,
                  }).then(() => {
                    this.custom_data = [];
                    this.getteammatesreport();
                  });
                } else if (result.data.code == 405) {
                  this.$swal({
                    title: "Not Allowed",
                    text: "Report approve time is not allowed.....",
                    icon: "error",
                    timer: 2500,
                  });
                  this.getteammatesreport();
                } else {
                  this.$swal("Not Valid", "Report not approved", "error");
                }
              })
              .catch((err) => {
                // console.log(err);
                controller.abort();
                this.$swal({
                  title: "Something went worng",
                  icon: "error",
                  timer: 2500,
                });
              });
            }
            else{
              if(time<15){
                controller.abort();
                this.$swal({
                  title: "No internet Connection",
                  text:"Please check your interenet connection",
                  icon: "error",
                  timer: 2500,
                });
              }
            }
          },1000);
        },
      });
    },
    approveReport(id, type, hours, minutes) {
      let data = [];

      if (type == "approve") {
        data = [];

        // console.log("Original time",time)
        // console.log("Empty hours;",time.hours==="");
        // console.log("Empty minutes",time.minutes==="");
        // console.log("===if condition ",time.hours==="" || time.minutes==="")

        if (hours !== "" && minutes !== "") {
          data.push({ id: id, time: { hours: hours, minutes: minutes } });
          // console.log("-----------",data);
          //The below function contain all code for ajax request with vuw seet alert on base of cases
          this.approveSpinner(data);
        } else {
          this.$swal({
            title: "Not Allowed",
            text: "Approve time can not be blank",
            icon: "warning",
            timer: 2000,
          });
        }
      }

      if (type == "approve_all") {
        data = [];
        let show_modal = false,
          is_blank = false;
        for (let i of id) {
          if (i.is_approved == false && i.approve_show == true) {
            // console.log("time", i.time.hours, "minutes", i.time.minutes);
            if (i.spent_hours !== "" && i.spent_minutes !== "") {
              data.push({
                id: i.id,
                time: { hours: i.spent_hours, minutes: i.spent_minutes },
              });
              show_modal = false;
              is_blank = false;
            } else {
              show_modal = true;
              is_blank = true;
              break;
            }
          }
        }
        if (!show_modal) {
          this.approveSpinner(data);
        } else {
          if (!is_blank) {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal("Error", "Report Not Approved...", "warning");
          } else {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal(
              "Not Allowed",
              "Approve time can not be blank",
              "warning"
            );
          }
        }
      }
    },
    // =================== new report method ======================

    reported_hours(min) {
      if (min == null) {
        return "-";
      } else {
        var hours = "";

        var minutes = min;
        var hours = Math.floor(min / 60);
        minutes = min % 60;

        var minutes = minutes;

        var second = 0;

        var time =
          (hours >= 9 ? "" : "0") +
          hours +
          ":" +
          ((minutes >= 9 ? "" : "0") + minutes);
        return time;
      }
    },
    OnApplyFilters() {
      this.custom_data = [];
      this.getteammatesreport();
    },

    // add filters in filter list
    getteammatesreport() {
      // check = this.allInputCheck();

      let user_ids = this.selected_users.map((obj) => {
        return obj.id;
      });
      let project_ids = this.selected_projects.map((obj) => {
        return obj.id;
      });
      let sub_clients_ids = this.selected_sub_client.map((obj) => {
        return obj.id;
      });
      let team_lead_ids = this.selected_team_leads.map((obj) => {
        return obj.id;
      });
      // sub_clients
      let approve = this.selected_approve.name;
      // console.log(project_ids)
      this.serverParams.columnFilters["users"] = user_ids;
      this.serverParams.columnFilters["sub_clients"] = sub_clients_ids;
      this.serverParams.columnFilters["projects"] = project_ids;
      this.serverParams.columnFilters["team_lead_ids"] = team_lead_ids;
      this.serverParams.columnFilters["start_date_from"] =
        this.selected_start_date[0];
      this.serverParams.columnFilters["start_date_to"] =
        this.selected_start_date[1];
      this.serverParams.columnFilters["approve"] = approve;
      this.serverParams.columnFilters["search_by_task_id"] = this.task_id.trim();

      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
      };
      // To abort api
      const controller = new AbortController();
      //navigator.onLine is used to check for internet connection
      if(navigator.onLine){
        // time count
        let time=0;
          let id=setInterval(()=>{
            if(this.data_not_loaded){
              time++;
              if(time>process.env.VUE_APP_REQUEST_TIMEOUT){
              // if(this.data_not_loaded){
                this.data_not_loaded=false;
                this.no_internet="Slow internet connection";
                // }
                clearInterval(id);
                // controller.abort();
              }
            }
            else{
              clearInterval(id);
            }
            // console.log(time);
          },1000);
        if(time<process.env.VUE_APP_REQUEST_TIMEOUT){
          user.getteammatesreportserverParams(fields,controller.signal)
        .then((result) => {
          // console.log("----- report result",result.data.data);
          if (result.status === 200) {
            this.custom_data = (result.data.data.data).reverse();
            this.summary = result.data.data.summary;

            if (this.custom_data.length === 0) {
              this.data_not_loaded = false;
              this.data_load_msg = "No data available......";
            } else {
              this.data_not_loaded = false;
              this.data_load_msg = "";
            }
          }
        })
        .catch(() => {
          this.data_not_loaded = true;
          setTimeout(() => {
            controller.abort();
            this.data_not_loaded = false;
            this.no_internet = "Something went wrong....";
          }, 5000);
        });
        this.data_not_loaded = true;
          this.data_load_msg = "";
          this.no_internet = "";
        }
        else{

        }
      }
      else{
        this.data_not_loaded=true;
        this.no_internet="";
        setTimeout(()=>{
          this.data_not_loaded = false;
          this.no_internet="No internet connection";
          controller.abort();
        },1000);
      }
      // if(wait){
      //     setTimeout(()=>{
      //       alert("Connection Timeout");
      //     },10000);
      //   }
    },


    // allInputCheck() {
    //   // console.log("add all inputs",this.reqObj);
    //   let check = true;

    //   // pan validation
    //   if (this.task_id === "" || this.task_id === null) {
    //     check = true;
    //   } else {
    //     var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    //     this.TaskIdError = "";
    //     if (!regex.test(this.task_id)) {
    //       check = false;
    //       this.TaskIdError = "Please Enter Valid Ids";
    //     }
    //   }

    // },

    returnTotalSpentTime(reports) {
      let hr = 0,
        min = 0;
      reports.forEach((obj) => {
        hr += obj.spent_hours;
        min += obj.spent_minutes;
      });
      if (min >= 60) {
        hr += Math.floor(min / 60);
        min = min % 60;
      }
      return this.formatTimeStringFunction(hr, min);
    },
    GetArrayOfStartDate(selectedDates, time, instance) {
      let dates = [];
      for (var i = 0; i < selectedDates.length; i++) {
        var select_date = new Date(selectedDates[i]);
        select_date.setDate(select_date.getDate() + 1);
        dates.push(select_date.toISOString().slice(0, 10));
      }
      this.selected_start_date = dates;
      this.userListFilter();
    },

    GetArrayOfEndDate(selectedDates, time, instance) {
      let dates = [];
      for (var i = 0; i < selectedDates.length; i++) {
        var select_date = new Date(selectedDates[i]);
        select_date.setDate(select_date.getDate() + 1);
        dates.push(select_date.toISOString().slice(0, 10));
      }
      this.selected_end_date = dates;
    },

    // get users list
    getUsers() {
      user.getprojectandreportmeDropdown().then((result) => {
        // this.users = result.data.data;
        this.users = [{ label: "Select all", users_data: result.data.data }];
        this.userListFilter();
      });
    },
    userListFilter(){
      let temp=[];
      if(this.users.length!==0){
        // console.log(this.users[0]['users_data'])
        this.users[0]['users_data'].forEach(item=>{
        // console.log("doe ",item.doe);
        if(item.doe!==null){
          if(this.selected_start_date[0]<=item.doe){
              temp.push(item);
          }
        }
        else{
          temp.push(item);
        }
      });
      // console.log(temp)
      this.active_users_list=[ {label:"Select all",users_data:temp} ];
      }
    },

    getAllSubClient() {
      user.getAllSubClient().then((result) => {
        if(result.data.data[0]){
          // this.sub_clients = result.data.data;
          this.sub_clients = [
            { label: "Select all", sub_clients_data: result.data.data },
          ];
          // this.reqObj.sub_client = result.data.data[(result.data.data).length-1]['id']
        }
      });
    },
    // get project list
    getAllSubclinetProjects() {
      user.getAllSubclinetProjects().then((result) => {
        // console.log("--- < > ",result.data.data)
        this.options = result.data.data
        // this.options = [
        //   { label: "Select all", projects_data: result.data.data },
        // ];
      });
    },

    // get project list
    getAllProjects() {
      user.getAllProjects().then((result) => {
        // this.projects = result.data.data;
        this.projects = [
          { label: "Select all", projects_data: result.data.data },
        ];
      });
    },

    // =========================================

    //Pdf and Excel download
    pdfDownload(header) {
      const doc = new jsPDF();
      if (header.name == "your_reports") {
        this.tableBodyMaker(header);
        autoTable(doc, {
          head: [header.thead],
          body: header.body,
          styles: { fontSize: 8, halign: "center", valign: "middle" },
        });
        doc.save(new Date().toISOString().substring(0, 10));
      }
      if (header.name == "team_mates_reports") {
        this.getCategory();
        setTimeout(() => {
          this.tableBodyMaker(header);
          autoTable(doc, {
            head: [header.thead],
            body: header.body,
            styles: { fontSize: 8, halign: "center", valign: "middle" },
          });

          let new_body = [];
          // console.log("--------------------------------",this.sheet2_data1);
          if (this.categories.length !== 0) {
            this.sheet2_data1.table_data.map((item) => {
              new_body.push([item.name, item.formatString]);
            });
          }
          if(this.h_b_c){
            autoTable(doc.addPage(), {
              head: [["Category", "hours"]],
              body: new_body,
            });
          }
          autoTable(doc, {
            body: this.sheet2_data2,
            columns: [
              { header: "User", dataKey: "name" },
              { header: "Spent hours", dataKey: "formatString" },
            ],
          });
          if(this.h_b_p){
            autoTable(doc, {
              body: this.sheet2_data3,
              columns: [
                { header: "Project", dataKey: "name" },
                { header: "Spent hours", dataKey: "formatString" },
              ],
            });
          }
          // doc.addPage({html:});
          doc.save(new Date().toISOString().substring(0, 10));
        }, 500);
      }
    },
    //table bod maker
    tableBodyMaker(header) {
      const arr = [];
      header.body = [];
      if (header.name == "your_reports") {
        this.your_tasks_rows.forEach((obj, index) => {
          arr.push([]);
          arr[index].push(obj.date);
          arr[index].push(obj.project_name);
          arr[index].push(obj.short_desc);
          arr[index].push(obj.spent_time);
          arr[index].push(obj.description);
          if (obj.approved_time == null) {
            arr[index].push("-");
          } else {
            arr[index].push(obj.approved_time);
          }
        });
        header.body = arr;
        // console.log(header.body);
      }
      if (header.name == "team_mates_reports") {
        header.body = [];
        // console.log(this.custom_data);
        let count = 0;
        this.custom_data.forEach((obj, index) => {
          arr.push([]);
          // console.log("----",obj)
          obj.reports.forEach((rep, idx) => {
            arr[index + count].push(obj.report_date);
            arr[index + count].push(obj.user_name);
            arr[index + count].push(rep.project_name);
            arr[index + count].push(rep.report_category);
            arr[index + count].push(rep.task_dec);
            arr[index + count].push(rep.description);
            arr[index + count].push(obj.client);
            arr[index + count].push(obj.subclient);
            //For spent time
            // let hour = rep.new_spent_time / 60;
            let hour = rep.new_approved_time / 60;
            let shour = Math.floor(hour);
            let min = Math.round((hour - shour) * 60);
            arr[index + count].push(this.formatTimeStringFunction(shour, min));
            if (rep.is_approved) {
              hour = rep.new_approved_time / 60;
              shour = Math.floor(hour);
              min = Math.round((hour - shour) * 60);
              arr[index + count].push(shour + ":" + min);
              arr[index + count].push("Approved");
            } else {
              arr[index + count].push("-");
              arr[index + count].push("Not Approved");
            }
            arr.push([]);
            count++;
          });

        });
        header.body = arr.filter((item) => {
          return item.length != 0;
        });
        // console.log(header.body);
      }

      if (header.name == "test") {
        header.body = [];
        // console.log(this.custom_data);
        let count = 0;
        this.custom_data.forEach((obj, index) => {
          arr.push([]);
          // console.log(obj.reports)
          obj.reports.forEach((rep, idx) => {
            arr[index + count].push(obj.report_date);
            arr[index + count].push(obj.user_name);
            arr[index + count].push(rep.project_name);
            arr[index + count].push(rep.report_category);
            arr[index + count].push(rep.task_dec);
            arr[index + count].push(rep.description);
            //For spent time
            // let hour = rep.new_spent_time / 60;
            let hour = rep.new_approved_time / 60;
            let shour = Math.floor(hour);
            let min = Math.round((hour - shour) * 60);
            arr[index + count].push(this.formatTimeStringFunction(shour, min));
            if (rep.is_approved) {
              hour = rep.new_approved_time / 60;
              shour = Math.floor(hour);
              min = Math.round((hour - shour) * 60);
              arr[index + count].push(shour + ":" + min);
              arr[index + count].push("Approved");
            }
            else {
              arr[index + count].push("-");
              arr[index + count].push("Not Approved");
            }
            arr.push([]);
            count++;
          });
        });
        header.body = arr.filter((item) => {
          return item.length != 0;
        });
        // console.log(header.body);
      }

    },
    //Excel sheet generator
    excelDownload(header) {

      this.excel.data = [];
      let subclients=new Set();
      let clients=new Set();

      if (header.name == "your_reports") {
        this.tableBodyMaker(header);

        header.body.forEach((obj) => {

          const data = {
            Date: obj[0],
            Project: obj[1],
            Task: obj[2],
            Category: obj[3],
            Description: obj[4],
            Hours: obj[5],
          };
          this.excel.data.push(data);
        });
        this.excel.head = header.thead;
        this.excel.filename = "Your Task Report";
        const { data, head, filename } = this.excel;
        jsontoexcel.getXlsx(data, head, filename);
      }

      if (header.name == "team_mates_reports") {
        this.getCategory();
        this.tableBodyMaker(header);
        var arrForWidth = []

        header.body.forEach((obj) => {
          function convertH2M(timeInHour){

            var timeParts = timeInHour.split(":");
            var total_min=  Number(timeParts[0]) * 60 + Number(timeParts[1]);

            if (Number.isInteger(total_min / 60)) {
               return total_min / 60;
            }
            else {
                return parseFloat(total_min / 60).toFixed( 2 );
            }
          }
          // Changed index to 8 to solve issue for getting NaN in hours
          if (obj[8] != "00:00") {
            const data = [
                { v: obj[0] ,  s: { alignment:this.excelAlignment()} } ,
                { v: obj[1] ,  s: { alignment:this.excelAlignment()} } ,
                { v: obj[2] ,  s: { alignment:this.excelAlignment()} } ,
                { v: obj[3]  ,  s: { alignment:this.excelAlignment()} } ,
                { v: obj[4] ,  s: { alignment:this.excelAlignmentWithWrapText()} } ,
                { v: obj[5]  ,  s: { alignment:this.excelAlignmentWithWrapText()} } ,
                // Changed index to 8 to solve the issue of getting NaN in hours column of excel sheet(This issue arised due to addition of two columns in data namely clients,sublients)
                { v: convertH2M(obj[8])  ,  s: { alignment:this.excelAlignment()} } ,

              ];
              arrForWidth.push({
                Date:obj[0],
                Name:obj[1],
                Project:obj[2],
                Category:obj[3],
                Description:obj[4],
                Report:obj[5],
                // Changed index to 8 to solve the issue of getting NaN in hours column of excel sheet(This issue arised due to addition of two columns in data namely clients,sublients)
                  Hours:convertH2M(obj[8])
            })
            this.excel.data.push(data);
          }
          clients.add(obj[6]);
          subclients.add(obj[7]);
        });


        // seperating data header wise and calculating final width

        const data_width = {
          dataDate_width : 13,  //width of date will be same for all data
          dataName_width :  this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Name.trim(); })
                                          .map(el => el.length)),header.thead[1]),
          // this.calculateWidth(Math.max(...this.excel.data.map(function (el) { return el[1].trim(); })
          //                         .map(el => el.length)),header.thead[1]),
          dataProject_width :
          this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Project.trim(); })
                                  .map(el => el.length)),header.thead[2]),
          dataCategory_width :
          this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Category.trim(); })
                                  .map(el => el.length)),header.thead[3]),
          dataTask_width : 50, //max width is not fixed
          dataReport_width : 70, //max width is not fixed
          dataHours_width : 6, //width won't be greater than header name

        }

        this.excel.head = header.thead;

        // console.log(this.excel.head)
        // this.excel.filename = "Team Mates Task Report";

        // console.log(this.custom_data);
        // this.excelTable();

        setTimeout(() => {
          let ws = utils.json_to_sheet(this.excel.data);


          ws["!cols"] = [
            { width: data_width.dataDate_width },
            { width: data_width.dataName_width + 4 },
            { width: data_width.dataProject_width + 4 },
            { width: data_width.dataCategory_width + 4 },
            { width: data_width.dataTask_width },
            { width: data_width.dataReport_width },
            { width: data_width.dataHours_width },
          ];

          //adding css to all the header
          for(let i=1;i<=header.thead.length;i++){
            //console.log(`${String.fromCharCode(64+i)}1`)
            ws[`${String.fromCharCode(64+i)}1`]= { v:header.thead[i-1],
              s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()}}

          };

            let tsheet = utils.table_to_sheet(
            document.getElementById("testing-table")
          );
          tsheet["!cols"].push({ width: 20 }, { width: 10 });
          // console.log(tsheet);

          const wb = utils.book_new();
          utils.book_append_sheet(wb, tsheet, "Summary");
          utils.book_append_sheet(wb, ws, "All Users");

          // writeFileXLSX(
          //   wb,
          //   new Date().toISOString().substring(0, 10) + ".xlsx"
          // );
          let filename="";

          if(subclients.size>1 && clients.size>1){
            filename+=Array.from(clients).join(',').replace(/,/g,'&')+"_";
            filename+=Array.from(subclients).join(',').replace(/,/g,'&');
            filename.length>100 ? filename=filename.substring(0,101) : '';
          }
          else{
            filename=Array.from(clients)+"_"+Array.from(subclients);
          }
          writeFile(wb, filename+"_"+this.serverParams.columnFilters.start_date_from + "_" + this.serverParams.columnFilters.start_date_to + ".xlsx");
          // writeFile(wb, new Date().toISOString().substring(0, 10) + ".xlsx");

        }, 500);
      }

      // users wish sheets
      if (header.name == "test") {
        // get category
        this.getCategory();

        setTimeout(() => {
          // groupby alias and report merge

          const group_users = this.groupBy(this.custom_data, 'alias')


          // ordering for users
          var items1 = Object.keys(group_users).map(function(key) {
              return [key, group_users[key][0]['order']];
          });
          items1.sort(function(first, second) {
              return first[1] - second[1];
          });

          const wb = utils.book_new();

          let tsheet = utils.table_to_sheet(
            document.getElementById("testing-table")
          );
          tsheet["!cols"].push({ width: 20 }, { width: 10 });

          // summary sheet
          utils.book_append_sheet(wb, tsheet, "Summary");

          // main loop
          let subclients=new Set();
          let clients=new Set();
          items1.forEach((obj, index) => {
            for (const user in group_users) {
              if (user == obj[0]) {

                const arr = [];
                const arrForWidth = []
                let w_hours = 0;

                arr.push([
                  { v: "Date",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Name",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Project",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Activity",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Id",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Task",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Report",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                  { v: "Hours",  s: { font: this.excelFontSize() ,fill:  this.excelBgColor(),border:this.excelBorder()} },
                ]);

                group_users[user].forEach((obj, index) => {

                  subclients.add(obj.client);
                  clients.add(obj.subclient);

                  obj.reports.forEach((rep, idx) => {
                    let num1 = 0


                    // let hour = rep.new_spent_time / 60;
                    let hour = rep.new_approved_time / 60;
                    let shour = Math.floor(hour);
                    let min = Math.round((hour - shour) * 60);
                    // if new_spent_time
                    // if (Number.isInteger(rep.new_spent_time / 60)) {
                    //   num1 =  rep.new_spent_time / 60;
                    // }
                    // else {
                    //   num1 = parseFloat(rep.new_spent_time / 60).toFixed( 2 );
                    // }

                    if (Number.isInteger(rep.new_approved_time / 60)) {
                      num1 =  rep.new_approved_time / 60;
                    }
                    else {
                      num1 = parseFloat(rep.new_approved_time / 60).toFixed( 2 );
                    }
                    if (rep.new_approved_time > 0) {
                      // arr.push([moment(obj.report_date).format('MM/DD/YYYY') ,obj.first_name ,rep.project_name ,rep.report_category ,rep.tasks_id ,rep.task_dec ,rep.description, num1])
                      arr.push([
                          { v: obj.report_date ,  s: { alignment:this.excelAlignment()} },
                          { v: obj.user_name  ,  s: { alignment:this.excelAlignment()} },
                          { v: rep.project_name  ,  s: { alignment:this.excelAlignment()} },
                          { v: rep.report_category  ,  s: { alignment:this.excelAlignment()} } ,
                          { v:rep.tasks_id  ,  s: { alignment:this.excelAlignment()} },
                          { v: rep.task_dec ,  s: { alignment:this.excelAlignmentWithWrapText()} } ,
                          { v:  rep.description ,  s: { alignment:this.excelAlignmentWithWrapText()} },
                          { v: num1 ,  s: { alignment:this.excelAlignment()}
                        }])
                      arrForWidth.push({
                        Date:obj.report_date,
                        Name:obj.user_name,
                        Project:rep.project_name,
                        Category:rep.report_category,
                        Id:rep.tasks_id,
                        Description:rep.task_dec,
                        Report:rep.description,
                        Hours:num1
                      })
                      // w_hours = w_hours + rep.new_spent_time;
                      w_hours = w_hours + rep.new_approved_time;
                    }
                    // if (rep.is_approved) {
                    //   hour = rep.new_approved_time / 60;
                    //   shour = Math.floor(hour);
                    //   min = Math.round((hour - shour) * 60);
                    //   arr.push([obj.report_date ,obj.user_name ,rep.project_name ,rep.report_category ,rep.task_dec ,rep.description, this.formatTimeStringFunction(shour, min), (shour + ":" + min), "Approved"])
                    // }
                    // else {
                    //   arr.push([obj.report_date ,obj.user_name ,rep.project_name ,rep.report_category ,rep.task_dec ,rep.description, this.formatTimeStringFunction(shour, min), "-", "Not Approved"])
                    // }
                  });
                });

                var num = 0
                if (Number.isInteger( w_hours / 60)) {
                  num =  w_hours / 60;
                }
                else {
                  num = parseFloat(w_hours / 60).toFixed( 2 );
                }

                arr.push([,,,,,,{ v: "Total",  s: { font: this.excelFontSize() } },{ v: num,  s: { font: this.excelFontSize() ,alignment:this.excelAlignment()} }])
                // console.log("--- name and sum of hours",user, parseFloat(w_hours).toFixed( 2 ))

                var ws2 = utils.aoa_to_sheet(arr)


                const data_width = {
                  dataDate_width : 13,  //width of date will be same for all data
                  dataName_width :
                  this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Name.trim(); })
                                          .map(el => el.length)),header.thead[1]),
                  dataProject_width :
                  this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Project.trim(); })
                                          .map(el => el.length)),header.thead[2]),
                  dataCategory_width :
                  this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Category.trim(); })
                                          .map(el => el.length)),header.thead[3]),
                  dataId_width :
                  this.calculateWidth(Math.max(...arrForWidth.map(function (el) { return el.Id; }).map(String)
                                          .map(el => el.length)),'Id'),


                  dataTask_width : 50, //max width is not fixed
                  dataReport_width : 70, //max width is not fixed
                  dataHours_width : 6 //width won't be greater than header name

                }


                ws2["!cols"] = [
                  { width: data_width.dataDate_width },
                  { width: data_width.dataName_width + 4 },
                  { width: data_width.dataProject_width + 4 },
                  { width: data_width.dataCategory_width + 4 },
                  { width: data_width.dataId_width + 4},
                  { width: data_width.dataTask_width },
                  { width: data_width.dataReport_width },
                  { width: data_width.dataHours_width },
                ];
                ws2["!rows"] ={
                  s:{alignment: {vertical: "center",
                  horizontal: "center",
                  },
                }}

                if (num > 0) {
                  utils.book_append_sheet(wb, ws2, user + "(" + num + ")");
                }
              }
            }
          });

          let filename="";

          if(subclients.size>1 && clients.size>1){
            filename+=Array.from(clients).join(',').replace(/,/g,'&')+"_";
            filename+=Array.from(subclients).join(',').replace(/,/g,'&');
            filename.length>100 ? filename=filename.substring(0,101) : '';
          }
          else{
            filename=Array.from(clients)+"_"+Array.from(subclients);
          }
          writeFile(wb, filename+"_"+this.serverParams.columnFilters.start_date_from + "_" + this.serverParams.columnFilters.start_date_to + ".xlsx");
          // writeFile(wb, new Date().toISOString().substring(0, 10) + ".xlsx");


        }, 500);
      }

    },

    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    getCategory() {
      user.getCategoryDropdown().then((result) => {
        this.categories = [];
        this.categories = result.data.data;
        this.excelTable();
      });
    },
    formatTimeStringFunction(hours, minutes) {
      let formatString = "";
      if (hours < 10 && minutes < 10) {
        formatString = "0" + hours + ":0" + minutes;
        return formatString;
      } else if (hours < 10 && minutes > 10) {
        formatString = "0" + hours + ":" + minutes;
        return formatString;
      } else if (hours >= 10 && minutes < 10) {
        formatString = hours + ":0" + minutes;
        return formatString;
      } else {
        formatString = hours + ":" + minutes;
        return formatString;
      }
    },
    excelTable() {
      let sheet2_data1 = {
        title: "Hours by category",
        table_data: [],
      };
      // For adding id,name of category,hours,allocated hours in the table_data array
      this.categories.forEach((item) => {
        sheet2_data1.table_data.push({
          id: item.id,
          name: item.name,
          hours: 0,
          minutes: 0,
          allocated: 0,
          formatString: "",
        });
      });
      // console.log("from excel----- ",this.categories);
      //for first table in sheet calulating hours according to category
      this.custom_data.forEach((obj) => {
        obj.reports.forEach((rep) => {
          sheet2_data1.table_data.forEach((item) => {
            if (item.id === rep.category_id) {
              // item.hours += Math.floor(rep.new_spent_time / 60);
              // item.minutes += rep.new_spent_time % 60;
              item.hours += Math.floor(rep.new_approved_time / 60);
              item.minutes += rep.new_approved_time % 60;
            }
          });
        });
      });

      // For summation of data below field is added
      sheet2_data1.table_data.push({
        id: 1001,
        name: "Total",
        hours: 0,
        minutes: 0,
        formatString: "",
      });

      let length = sheet2_data1.table_data.length;

      //Actual summation of data
      sheet2_data1.table_data.forEach((row, index) => {
        // console.log(row.hours);
        if (index < length - 1) {
          sheet2_data1.table_data[length - 1]["hours"] += row.hours;
          sheet2_data1.table_data[length - 1]["minutes"] += row.minutes;
        }
      });

      //Validating data accroding to hh:mm format
      sheet2_data1.table_data.forEach((item) => {
        if (item.minutes >= 60) {
          let additional_hr = Math.floor(item.minutes / 60);
          item.hours += additional_hr;
          item.minutes = item.minutes % 60;
        }
        let time=(item.minutes+(item.hours*60))/60;
        // item.formatString = this.formatTimeStringFunction(
        //   item.hours,
        //   item.minutes
        // );
        item.formatString = Number.isInteger(time) ? time : time.toFixed(2);
      });
      // Filtering unfilled category

      //------------------------------------- id error issue
      let temp = [];
      temp = sheet2_data1.table_data.map((obj) => {
        // console.log(obj);
        if (
          (obj.hours != 0 && obj.minutes == 0) ||
          (obj.hours == 0 && obj.minutes != 0) ||
          (obj.hours != 0 && obj.minutes != 0)
        ) {
          return obj;
        }
        // return obj;
      });
      temp = temp.filter((obj) => obj);
      sheet2_data1.table_data = temp;
      // console.log("temp----- ",temp)
      this.sheet2_data1 = sheet2_data1;

      //for 2nd table in sheet

      let sheet2_data2 = {};
      this.custom_data.forEach((obj) => {
        if (Object.keys(sheet2_data2).length !== 0) {
          if (Object.keys(sheet2_data2).includes(obj.user_name)) {
            sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
          }
          else {
            // sheet2_data2[obj.user_name]=obj.reports;
            sheet2_data2[obj.user_name] = {
              total_hours: [],
              allocated_hours: "",
              order:obj.order
            };
            sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
          }
        }
        else {
          //Just uncomment the below line to add allocated hours array
          // sheet2_data2[obj.user_name]={ total_hours:[],allocated_hours:[] };

          //Here allocated hours is for the fututre use just push the data in it using spread operator
          // sheet2_data2[obj.user_name]["allocated_hours"].push();

          sheet2_data2[obj.user_name] = {
            total_hours: [],
            allocated_hours: [],
            order:obj.order
          };
          // this total hours contain the reports of the user irrespective to data from which total hours will be calcualted
          sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
        }
      });

      //final calculated data for 2nd table in sheet

      this.sheet2_data2 = [];

      for (let user in sheet2_data2) {
        let sum = 0;
        let min = 0;
        let str = "";
        sheet2_data2[user].total_hours.forEach((rep) => {
          // sum += Math.floor(rep.new_spent_time / 60);
          // min += rep.new_spent_time % 60;
          sum += Math.floor(rep.new_approved_time / 60);
          min += rep.new_approved_time % 60;
        });
        if (min % 60 >= 0) {
          sum += Math.floor(min / 60);
          min = min % 60;
        }
        let time=((sum*60)+min)/60
        //Just ucomment this line to map allocated hours
        // this.sheet2_data2.push({name:user,total_hours:sum,allocated_hours:null})
        // this.sheet2_data2.push({
        //   name: user,
        //   total_hours: sum,
        //   minutes: min,
        //   formatString: this.formatTimeStringFunction(sum, min),
        // });
        if (sum > 0 || min > 0) {
          this.sheet2_data2.push({
            name: user,
            total_hours: sum,
            minutes: min,
            order:sheet2_data2[user]['order'],
            formatString:Number.isInteger(time) ? time : time.toFixed(2)
          });
        }
      }
      //Logic for 2nd table total field
      this.sheet2_data2.push(
        this.sheet2_data1.table_data[this.sheet2_data1.table_data.length - 1]
      );
      let order_list=[]
      this.sheet2_data2.forEach(obj=>{
        if(!order_list.includes(obj.order) && obj.order!==undefined){
          order_list.push(obj.order);
        }
      });
      this.sheet2_data2.sort((a,b)=>{
        return a.order-b.order;
      })

      //For 3rd table of project

      let project_table = {};

      this.custom_data.forEach((obj) => {
        obj.reports.forEach((rep) => {
          if (Object.keys(project_table).length !== 0) {
            if (Object.keys(project_table).includes(rep.project_name)) {
              // console.log(rep.project_name)
              // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
              // project_table[rep.project_name].total_hours += rep.new_spent_time;
              project_table[rep.project_name].total_hours += rep.new_approved_time;
              // console.log(project_table[rep.project_name]["total_hours"],rep.project_name);
            } else {
              project_table[rep.project_name] = { total_hours: 0 };
              // project_table[rep.project_name].total_hours += rep.new_spent_time;
              project_table[rep.project_name].total_hours += rep.new_approved_time;
              // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
            }
          } else {
            project_table[rep.project_name] = { total_hours: 0 };
            // project_table[rep.project_name].total_hours += rep.new_spent_time;
            project_table[rep.project_name].total_hours += rep.new_approved_time;
            // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
          }
        });
      });

      this.sheet2_data3 = [];

      for (let item in project_table) {
        let hr = Math.floor(project_table[item]["total_hours"] / 60);
        let min = project_table[item]["total_hours"] % 60;
        if (min % 60 >= 0) {
          hr += Math.floor(min / 60);
          min = min % 60;
        }
        // console.log(this.formatTimeStringFunction(hr,min),hr,min);
        let time=((hr*60)+min)/60;
        this.sheet2_data3.push({
          name: item,
          hours: hr,
          minutes: min,
          formatString: Number.isInteger(time) ? time : time.toFixed(2)
        });
      }
      this.sheet2_data3.push(
        this.sheet2_data1.table_data[this.sheet2_data1.table_data.length - 1]
      );
      // console.log(this.sheet2_data3);
    },
    // first table column filters and functions

    search_your_report() {
      var fields = {
        filters: this.serverParamsfirst.columnFilters,
        messageData: this.message,
        sort: this.serverParamsfirst.sort,
        page: this.serverParamsfirst.page,
        perpage: this.serverParamsfirst.perPage,
      };
      user.search_your_report(fields).then((result) => {
        this.your_tasks_rows = [];
        this.your_tasks_rows = result.data.data.data;
        this.TotalRecords1 = result.data.data.total;
        // console.log(result.data.data.data);
      });
    },
    // getallyourreports() {
    //   user.getallyourreports(this.reqObj).then((result) => {
    //     this.your_tasks_rows = [];
    //     this.your_tasks_rows = result.data.data;
    //   });
    // },
    // displayYoursReportPages() {
    //   user
    //     .displayYoursReportPages(
    //       this.serverParamsfirst.page,
    //       this.serverParamsfirst.perPage
    //     )
    //     .then((result) => {
    //       this.your_tasks_rows = [];
    //       this.your_tasks_rows = result.data.data.data;
    //       this.totalRecordsfirsttable = result.data.data.total;
    //     });
    // },
    updateParamsfirst(newProps) {
      this.serverParamsfirst = Object.assign(
        {},
        this.serverParamsfirst,
        newProps
      );
    },
    onPageChangefirst(params) {
      this.updateParamsfirst({ page: params.currentPage });
      this.search_your_report();
    },

    onPerPageChangefirst(params) {
      this.updateParamsfirst({ perPage: params.currentPerPage });
      this.search_your_report();
    },

    onSortChangefirst(params) {
      this.updateParamsfirst({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_your_report();
    },

    onColumnFilterfirst(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsfirst(newObj);
      this.serverParamsfirst.page = 1;
      this.search_your_report();
    },

    // common functions
    getreportsbyid(id) {
      user.getreportsbyid(id).then((result) => {
        (this.warning = null),
          (this.spent_hours = null),
          (this.spent_minutes = null),
          // console.log(result.data.data)
          (this.reqObj.creater_name = result.data.data[0].first_name);
        this.reqObj.spent_time = result.data.data[0].new_spent_time;
        this.reqObj.description = result.data.data[0].description;
        this.reqObj.project_name = result.data.data[0].project_name;
        this.reqObj.date = result.data.data[0].date;
        this.spent_hours = Math.floor(this.reqObj.spent_time / 60);
        if (this.spent_hours == 0) {
          document.getElementById("input_for_hours").disabled = true;
        }
        this.spent_minutes = Math.floor(this.reqObj.spent_time % 60);
        this.set_hours.hours = this.spent_hours;
        this.set_hours.minutes = this.spent_minutes;
        // this.set_hours = this.reqObj.spent_time;
        // document.getElementById("set_hours_field").max = this.reqObj.spent_time;
      });
    },
    delete_report(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal(
            "Deleted",
            "You successfully delete this report",
            "success"
          );
          user.delete_report(id).then((result) => {
            if (result) {
              // this.displayYoursReportPages();
              this.search_your_report();
              this.getteammatesreport();
            }
          });
        } else {
          user.delete_report((error) => {
            // console.log(error);
          });
        }
      });
    },

    // pop up functions

    approve_report(id) {
      this.$root.$emit("bv::show::modal", "modal-prevent-report");
      this.report_id = id;
      this.getreportsbyid(id);
      // this.time={hours:null,minutes:null};
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      if (valid) {
        if (this.set_hours.hours == null || this.set_hours.minutes == null) {
          this.UserState = false;
        } else {
          var total_minutes =
            parseInt(this.set_hours.hours * 60) +
            parseInt(this.set_hours.minutes);
          if (total_minutes > this.reqObj.spent_time) {
            this.warning =
              "Maximum  limit is " +
              this.spent_hours +
              "h " +
              this.spent_minutes +
              "m";
          } else {
            if (total_minutes == 0) {
              this.warning = "Add time to approve report...";
            } else {
              this.UserState = valid;
              return valid;
            }
          }
        }
      }
    },
    resetModal() {
      this.UserState = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
      } else {
        this.set_hours.hours = parseInt(this.set_hours.hours);
        this.set_hours.minutes = parseInt(this.set_hours.minutes);
        user.ApproveReport(this.report_id, this.set_hours).then((result) => {
          if (result.data.code == 200) {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal(
              "Approved",
              "Report Approved Successfully...",
              "success"
            );
            // this.displayYoursReportPages();
            this.search_your_report();
          } else {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal("Error", "Report Not Approved...", "warning");
          }
        });
      }
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$bvModal.hide('modal-prevent-report-closing')
      // })
      return true;
    },
    changeapprovedhours(val){
      this.show_err_of_max_time = ''
      this.update_approved_report_details = val
      // console.log(this.update_approved_report_details)
      this.updated_approved_time.hours = (this.update_approved_report_details.new_approved_time/60).toString().split('.')[0]
      this.updated_approved_time.minutes = this.update_approved_report_details.new_approved_time%60
      this.updated_spent_time.hours = (this.update_approved_report_details.new_spent_time/60).toString().split('.')[0]
      this.updated_spent_time.minutes = this.update_approved_report_details.new_spent_time%60
      this.$root.$emit('bv::show::modal', 'report-update-model');
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'report-update-model', '#btnShow')
    },
    submitupdaterequest(){
      const send_details_for_update = {}
      // send_details_for_update
      send_details_for_update.new_approved_time = ((this.updated_approved_time.hours*60)+parseInt(this.updated_approved_time.minutes))
      send_details_for_update.new_spent_time = ((this.updated_spent_time.hours*60)+parseInt(this.updated_spent_time.minutes))
      send_details_for_update.user_id = this.update_approved_report_details.user_id
      send_details_for_update.id = this.update_approved_report_details.id
      if(((this.updated_approved_time.hours*60)+parseInt(this.updated_approved_time.minutes)) > this.update_approved_report_details.new_spent_time){
        this.show_err_of_max_time = '** max limit is'
      }else{
        user.UpdateApprovedReportDetails(send_details_for_update).then((result) => {
          if(result.data.code == 200){
            this.hideModal()
            this.$swal("Success", "Report approved time updated successfully...", "success");
            this.getteammatesreport()
          }else if(result.data.code == 404){
            this.hideModal()
            this.$swal("Error", "Report not found...", "warning");
            this.getteammatesreport()
          }else if(result.data.code == 401){
            this.hideModal()
            this.$swal("Error", "Not authorized for update approved hours...", "warning");
            this.getteammatesreport()
          }else{
            this.hideModal()
            this.$swal("Error", "Report approved hours not updated...", "warning");
          }
        })
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    // Excel Color, Size, Alignment,AlignmentWithWrapText,  Border
    excelBgColor(){
      return {
        fgColor: { rgb: "d3d3d3" }
      }

    },
    excelFontSize(){
      return {
        bold: true
      }
    },
    excelAlignment(){
      return {

        vertical:"top",
        horizontal:"left"
      }
    },
    excelAlignmentWithWrapText(){
      return {
        wrapText: true,
        vertical:"top",
        horizontal:"left"
      }
    },
    excelBorder(){
      return{
        top: { style: "thin",color:{ rgb: "000000"  } },
        bottom: { style: "thin",color:{ rgb: "000000" } },
        right: { style: "thin",color:{ rgb: "000000" } },
        left: { style: "thin",color:{ rgb: "000000" } },


      }
    },

    // Excel calculate Width
    calculateWidth(width_data,header_width_data){

      if(width_data<=header_width_data.length){
        return header_width_data.length
      }
      else if (width_data>header_width_data.length && width_data<header_width_data.length*2){
        return width_data
      }
      else if (width_data==header_width_data.length){
        return width_data
      }
      else{
        if (header_width_data=='Id'){
          return width_data
        }
        return header_width_data.length*2
      };
    },

    dayConverter(date){
      return `${moment(date).format("ddd")}`;
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  // watch:{
  //   custom_data:{
  //     handeler(nv.)
  //   }
  // }
};
</script>

<style>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.report_filter_multiselect .multiselect__tags {
    height: auto;
    max-height:70px;
    max-width: 100%;
    display:block;
    padding: 8px 40px 0 8px;
    border-radius: 0px!important;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
    overflow-y: scroll;
    overflow-x:none!important;
    scroll-behavior: smooth;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
