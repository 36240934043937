<template>
  <div class="">
    <div v-if="currentUser.user_role == 1" class="card my-4 border-0 shadow-sm">
      <!-- Title and button -->
      <div class="card-body">
        <div class="px-2 pb-3">
          <h4 class="text-md-start text-center text-primary m-0">
            Asset's History
          </h4>
        </div>
        <redmine-filter
          ref="redmineFilter"
          page="assets_history"
          :serverParams="serverParams"
          :set_filters="assets_histroy_params"
          @redmine-filter-data="onRedmineFilterHandler"
          @reset-server-params="onResetServerParams"
        ></redmine-filter>
        <div class="p-2">
          <vue-good-table
            styleClass="vgt-table no-border"
            mode="remote"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            :totalRows="total_records"
            :isLoading.sync="isLoading"
            :rows="assets_rows"
            :columns="assets_columns"
            :pagination-options="{
              enabled: true,
              perPageDropdown: perpage_list,
            }"
          >
            <!-- <template
              slot="column-filter"
              slot-scope="{ column, updateFilters }"
            >
              <span v-if="column.field == 'price'">
                <input
                  type="number"
                  min="0"
                  class="form-control rounded-1"
                  style="height: 25.47px; font-size: 14px"
                  placeholder="Price"
                  @input="(e) => updateFilters(column, e.target.value)"
                />
              </span>
            </template> -->
            <template slot="table-row" slot-scope="props">
              <template v-if="props.column.field == 'price'">
                &#8377;{{ props.row.price.toLocaleString() }}
              </template>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <CustomPagination
                class="p-2"
                :pageTabs="4"
                :total="props.total"
                :pageChanged="props.pageChanged"
                :perPageChanged="props.perPageChanged"
                :serverParams="serverParams"
              ></CustomPagination>
            </template>
          </vue-good-table>
          <!-- styleClass="vgt-table condensed" -->
        </div>
      </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized Access</h2>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
import myMixin from "@/mixins/Permission";
import RedmineFilter from "../../components/redmine_filter.vue";
import Vue, { provide } from "vue";

export default {
  mixins: [myMixin],
  components: {
    CustomPagination,
    RedmineFilter,
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.setCurrentPage("Assets");
    $("#assetModal").on("hidden.bs.modal", this.resetModal);
    this.getUserPermissions();
    this.assetsHistoryParamsHandler();
    // this.getAssetCategory();
    // this.getAssetsHistory();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  data() {
    return {
      siteLogReq: {
        site_name: "assets_history",
      },
      isLoading: false,
      assets_rows: [],
      assets_obj: null,
      assets_columns: [
        {
          label: "Asset Name",
          field: "name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Prefix",
          field: "prefix",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },

        {
          label: "Model",
          field: "model",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Serial No",
          field: "serial_no",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Price",
          field: "price",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Status",
          field: "status",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Notes",
          field: "notes",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated To",
          field: "full_name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated By",
          field: "allocated_by",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Allocated At",
          field: "start_date",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Deallocated At",
          field: "end_date",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
        {
          label: "Deallocated By",
          field: "deallocated_by",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          // filterOptions: {
          //   enabled: true,
          //   trigger: "enter",
          // },
        },
      ],
      serverParams: {
        filters: {},
        page: 1,
        perpage: 100,
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
      },
      category: [],
      total_records: null,
      category_name: null,
      perpage_list: [100, 200, 300, 400, 500],
      assets_histroy_params: null,
    };
  },
  // provide() {
  //   return {
  //     serverParams: this.serverParams,
  //   };
  // },
  methods: {
    ...mapActions("common", ["setCurrentPage"]),
    assetsHistoryParamsHandler() {
      if (this.currentUser.user_role == 1) {
        let serialNoList = JSON.parse(localStorage.getItem("serial-no-list"));
        if (serialNoList) {
          this.assets_histroy_params = serialNoList.shift();
          localStorage.setItem("serial-no-list", JSON.stringify(serialNoList));
        }
      }
    },
    onResetServerParams(serverParams) {
      this.serverParams = Object.assign({}, serverParams);
      this.$nextTick(() => {
        this.$refs.redmineFilter.applyFilter();
      });
    },
    onRedmineFilterHandler(data, total) {
      this.assets_rows = data;
      this.total_records = total;
    },
    dateHandler(event, item) {
      item.default_value = moment(event[0]).format("YYYY-MM-DD");
    },
    submitFormatter(assets) {
      assets.forEach((item) => {
        item.prefix = item.fields[0].default_value;
        item.name = item.fields[1].default_value;
        item.model = item.fields[2].default_value;
        item.serial_no = item.fields[3].default_value;
        item.description = item.fields[4].default_value;
        item.price = Number(item.fields[5].default_value);
        item.asset_cat_id = item.asset_cat_id;
        item.fields = item.fields.slice(6);
        delete item["invoice_no"];
      });
      // console.log("Assets ",assets);
    },
    submitAsset() {
      this.submitFormatter(this.assets_obj);
      user
        .updateAssets(this.assets_obj[0])
        .then((res) => {
          Swal.fire({
            title: res.data.message,
            icon: "success",
          });
          this.getAssetsHistory();
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.detail,
            icon: "error",
          });
        });
      $("#assetModal").modal("hide");
    },
    resetModal() {
      this.assets_obj = null;
    },
    assetModalHandler(id) {
      $("#assetModal").modal("show");
      this.getAssetsHistoryById(id);
    },
    getAssetsById(id) {
      user.getAssetsById(id).then((res) => {
        this.assets_obj = res.data.data;
        this.assets_obj.map((item) => {
          let new_fields = [
            {
              field_name: "Prefix",
              field_type: "str",
              default_value: item.prefix,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Name",
              field_type: "str",
              default_value: item.name,
              max_chars: 1000,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Model",
              default_value: item.model,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Serial No.",
              default_value: item.serial_no,
              field_type: "str",
              max_chars: 255,
              is_active: true,
              is_mandatory: true,
            },
            {
              field_name: "Description",
              default_value: item.description,
              field_type: "str",
              max_chars: 1000,
              is_active: true,
              is_mandatory: false,
            },
            {
              field_name: "Price",
              default_value: item.price,
              field_type: "int",
              min_limit: 0,
              is_active: true,
              is_mandatory: false,
            },
          ];
          this.category_name = this.category.find(
            (cat) => cat.id == item.asset_cat_id
          ).name;
          item.fields.unshift(...new_fields);
          item.fields.map((obj) => {
            if (obj.field_type == "range date") {
              obj.config.enable = [
                {
                  from: obj.start_date,
                  to: obj.end_date,
                },
              ];
            }
          });
        });
      });
    },
    getAssetsHistory() {
      user.getAssetsHistory(this.serverParams).then((res) => {
        this.assets_rows = res.data.data.data;
        this.total_records = res.data.data.total;
      });
    },
    deleteAsset(id) {
      Swal.fire({
        title: "Do you want to delete asset ?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        icon: "warning",
      }).then((res) => {
        if (res.isConfirmed) {
          user
            .deleteAsset(id)
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.getAssetsHistory();
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
      });
    },
    onColumnFilter(params) {
      // console.log("columnFilter");
      // var newObj = { filters: Object.assign({}) };
      // Object.keys(params.columnFilters).forEach(function (key) {
      //   if (params.columnFilters[key] !== "")
      //     newObj.filters[key] = params.columnFilters[key];
      // });
      // this.updateParams(newObj);
      // this.serverParams.page = 1;
      // this.getAssetsHistory();
      // this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },

    onPerPageChange(params) {
      this.updateParams({
        perpage: params.currentPerPage,
      });
      this.serverParams.page = 1;
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },
    getAssetCategory() {
      user.getAssetCategoryList().then((res) => {
        this.category = [];
        this.category = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.modal-content {
  transition: 0.2s ease-in-out;
}
</style>
