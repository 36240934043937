<template>
  <div class="container-fluid" style="min-height: 600px">
    <div class="card mb-4" v-show="permissions.includes('users.write')">
      <div
        class="
          card-header
          d-sm-flex
          align-items-center
          justify-content-between
          mb-4
          py-3
        "
      >
        <h4 class="mb-0 text-primary font-weight-bold">Users</h4>
        <router-link
          class="
            startbuttons
            d-none d-sm-inline-block
            m-0
            float-right
            btn btn-success
            shadow-sm
            btn-lg
          "
          :to="{ name: 'Users' }"
        >
          Go Back
        </router-link>
      </div>
      <div class="card-body">
        <form
          onSubmit="return false;"
          data-parsley-validate="true"
          @submit.prevent="verifyOpertations()"
        >
          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="first_name" class="mandatory">First Name</label>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="reqObj.first_name"
              :state="null"
              required
              placeholder="First Name"
              oninvalid="this.setCustomValidity('Please enter first name')"
              oninput="this.setCustomValidity('')"
              @keypress="isLetter($event)"
            />
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="middle_name">Middle Name</label>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Middle Name"
              v-model="reqObj.middle_name"
              @keypress="isLetter($event)"
            />
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="last_name" class="mandatory">Last Name</label>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              v-model="reqObj.last_name"
              @keypress="isLetter($event)"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please enter user last name')"
              oninput="this.setCustomValidity('')"
            />
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="doj" class="mandatory">Date Of Joining</label>
            </div>
            <datepicker
              type="date"
              placeholder="yyyy-mm-dd"
              v-model="reqObj.doj"
              :clear-button="true"
              :typeable="true"
              :disabled-dates="disabledDates"
              :format="formateDate"
              required
            ></datepicker>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="username" class="mandatory">Username</label>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Username"
              v-model="reqObj.username"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please enter username')"
              oninput="this.setCustomValidity('')"
            />
          </div>
          <br />

          <div class="d-sm-flex align-items-left" v-if="this.editid == '0'">
            <div class="col-sm-2">
              <label for="password" class="mandatory">Password</label>
            </div>
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="reqObj.password"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please enter password')"
              oninput="this.setCustomValidity('')"
            />
            <!-- <p style="color : #FF0000;">{{reqObj.passwordError}}</p> -->
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="c_country" class="mandatory">Current Country</label>
            </div>
            <select
              id="cCountry"
              v-model="reqObj.c_country"
              class="form-control"
              v-on:change="getCurrentStatesList()"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please select country')"
              oninput="this.setCustomValidity('')"
            >
              <option value="">Select Country</option>
              <option
                :key="index"
                v-for="(country, index) in countries2"
                :value="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="user-role" class="mandatory">User Role</label>
            </div>
            <select
              v-model="reqObj.user_role"
              class="form-control"
              :disabled="user_role != 1 ? true : false"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please select user role')"
              oninput="this.setCustomValidity('')"
            >
              <option value="">Select user role</option>
              <option
                :key="index"
                v-for="(item, index) in roles"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="user-type" class="mandatory">User Type</label>
            </div>
            <select
              v-model="reqObj.user_type"
              class="form-control"
              :state="null"
              required
              oninvalid="this.setCustomValidity('Please select user type')"
              oninput="this.setCustomValidity('')"
            >
              <option value="">Select user type</option>
              <option
                :key="index"
                v-for="(item, index) in user_type"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="User manager id" class="mandatory">Report To</label>
            </div>
            <select v-model="reqObj.report_to" class="form-control" required>
              <option value="">Select manager name</option>
              <option
                :key="index"
                v-for="(item, index) in managers"
                :value="item.id"
              >
                {{ item.first_name }} {{ item.last_name }}
              </option>
            </select>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="login slot" class="mandatory">Login Slot</label>
            </div>
            <flat-pickr
              type="time"
              class="w-100 bg-white form-control"
              placeholder="Select login slot"
              :config="config"
              v-model="reqObj.login_slot"
              @on-change="loginSlotInput"
              oninvalid="this.setCustomValidity('Please select login time')"
              oninput="this.setCustomValidity('')"
              :modelValue="reqObj.login_slot"
            ></flat-pickr>
            <p style="color: #ff0000">{{ reqObj.login_slot_error }}</p>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="user-role" class="mandatory">Per day hours</label>
            </div>
            <!-- <input type="number" class="form-control" step="0.5" max="24" value="5" v-model="reqObj.per_day_minutes" required placeholder="Enter per day hours"> -->
            <vue-time-picker
              format="HH:mm"
              :minute-interval="15"
              v-model="reqObj.per_day_minutes"
            ></vue-time-picker>
            <p style="color: #ff0000">{{ reqObj.per_day_minutes_error }}</p>
          </div>
          <br />

          <div class="d-sm-flex align-items-left">
            <div class="col-sm-2">
              <label for="user-role" class="mandatory"
                >Weekend Working Strategy</label
              >
            </div>
            <select v-model="reqObj.weekend_strategy" class="form-control">
              <option value="" selected>
                --Select Weekend Working Strategy --
              </option>
              <option value="1">All saturday off</option>
              <option value="2">Alternate saturday off</option>
              <option value="3">All saturday on</option>
            </select>
          </div>
          <br />

          <!-- Load more fileds button -->

          <div
            class="
              container-fluid
              m-0
              p-0
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <button
              class="btn btn-outline-dark border-0"
              type="button"
              v-if="!showFields"
              @click="showFields = !showFields"
            >
              Show More fields....
            </button>
          </div>
          <!-- Feilds to load -->
          <div class="m-0 p-0" v-if="showFields">
            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="user-role">User Designation</label>
              </div>
              <select
                v-model="reqObj.user_designation"
                class="form-control"
                :state="null"
                oninvalid="this.setCustomValidity('Please select user role')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select user designation</option>
                <option
                  :key="index"
                  v-for="(item, index) in designations"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <br />
            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="pan">Pan Card Number</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="example - DOAPM1234M"
                maxlength="10"
                v-model="reqObj.pan"
                @input="reqObj.panError = ''"
              />
              <p style="color: #ff0000">{{ reqObj.panError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="uan_no">Unique Account Number</label>
              </div>
              <input
                type="number"
                class="form-control"
                placeholder="example - 100904319456"
                maxlength="12"
                v-model="reqObj.uan_no"
                @input="reqObj.uan_no_Error = ''"
              />
              <p style="color: #ff0000">{{ reqObj.uan_no_Error }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="dob">Date Of birth</label>
              </div>
              <datepicker
                type="date"
                placeholder="yyyy-mm-dd"
                v-model="reqObj.dob"
                :clear-button="true"
                :typeable="true"
                :disabled-dates="disabledDates"
                :format="formateDate"
                @input="reqObj.dobError = ''"
              ></datepicker>
              <p style="color: #ff0000">{{ reqObj.dobError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="doe">Date Of Exit</label>
              </div>
              <datepicker
                type="date"
                placeholder="yyyy-mm-dd"
                v-model="reqObj.doe"
                :clear-button="true"
                :typeable="true"
                :format="formateDate"
              ></datepicker>
              <!-- <datepicker type="date" placeholder="yyyy-mm-dd" v-model="reqObj.doe" :clear-button="true" :typeable="true"
   :disabled-dates="{to: reqObj.doj}" :format="formateDate"></datepicker> -->
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="emp_code">Employee Code</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Employee Code"
                maxlength="10"
                :state="null"
                v-model="reqObj.emp_code"
                oninvalid="this.setCustomValidity('Please enter employee code')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="o_email">Office Email</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="example@gmail.com"
                v-model="reqObj.o_email"
                @input="reqObj.emailError = ''"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter office email')"
                oninput="this.setCustomValidity('')"
              />
              <p style="color: #ff0000">{{ reqObj.emailError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="blood_group">Blood Group</label>
              </div>
              <select
                v-model="reqObj.blood_group"
                class="form-control"
                :state="null"
                oninvalid="this.setCustomValidity('Please select blood group')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select Blood Group</option>
                <option value="A+">A+</option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="O+">O+</option>
                <option value="A-">A-</option>
                <option value="B-">B-</option>
                <option value="AB-">AB-</option>
                <option value="O-">O-</option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="emergency_contact_number">Emergency Contact Number</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Emergency Contact Number"
                maxlength="10"
                v-model="reqObj.emergency_contact_number"
                @keypress="isNumber($event)"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter emergency contact number')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="emergency_contact_name">Emergency Contact Name</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Emergency Contact Name"
                v-model="reqObj.emergency_contact_name"
                @keypress="isLetter($event)"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter user emergency contact name')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <br />


            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="emergency_contact_relation">Emergency Contact Relation</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Emergency Contact Relation"
                v-model="reqObj.emergency_contact_relation"
                @keypress="isLetter($event)"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter user emergency contact relation')"
                oninput="this.setCustomValidity('')"
              />
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="medical_info">Medical Information</label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Medical Information"
                v-model="reqObj.medical_info"
              />
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_mobile">Personal Mobile </label>
              </div>
              <!-- <input type="text"  class="form-control" placeholder="Personal Mobile" maxlength="10" v-model="reqObj.p_mobile" @input="reqObj.p_mobileError=''" -->
              <input
                type="text"
                class="form-control"
                placeholder="Personal Mobile"
                maxlength="10"
                v-model="reqObj.p_mobile"
                @keypress="isNumber($event)"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter mobile number')"
                oninput="this.setCustomValidity('')"
              />
              <p style="color: #ff0000">{{ reqObj.p_mobileError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_email">Personal Email</label>
              </div>
              <input
                type="email"
                class="form-control"
                placeholder="example@gmail.com"
                v-model="reqObj.p_email"
                @input="reqObj.p_emailError = ''"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter personal email')"
                oninput="this.setCustomValidity('')"
              />
              <p style="color: #ff0000">{{ reqObj.p_emailError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_add1">Permanent Address</label>
              </div>
              <textarea
                id="pAddress"
                rows="2"
                wrap="soft"
                class="form-control"
                placeholder="Address"
                v-model="reqObj.p_add1"
                @keydown.space="preventLeadingSpace($event)"
                @keydown.enter.prevent
                :state="null"
                oninvalid="this.setCustomValidity('Please enter address')"
                oninput="this.setCustomValidity('')"
              ></textarea>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_country">Permanent Address Country</label>
              </div>
              <select
                id="pCountry"
                v-model="reqObj.p_country"
                class="form-control"
                v-on:change="getPermanentStatesList()"
                :state="null"
                oninvalid="this.setCustomValidity('Please select country')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select Country</option>
                <option
                  :key="index"
                  v-for="(country, index) in countries"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_state">Permanent Address State</label>
              </div>
              <select
                id="pState"
                v-model="reqObj.p_state"
                class="form-control"
                v-on:change="getPermanentCitiesList()"
                :state="null"
                oninvalid="this.setCustomValidity('Please select state')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select State</option>
                <option
                  :key="index"
                  v-for="(state, index) in states"
                  :value="state.id"
                >
                  {{ state.statename }}
                </option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_city">Permanent Address City </label>
              </div>
              <select
                id="pCity"
                v-model="reqObj.p_city"
                class="form-control"
                :state="null"
                oninvalid="this.setCustomValidity('Please select city')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select City</option>
                <option
                  :key="index"
                  v-for="(city, index) in cities"
                  :value="city.id"
                >
                  {{ city.cityname }}
                </option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="p_zip">Permanent Address Zip Code</label>
              </div>
              <input
                type="text"
                id="pZipCode"
                class="form-control"
                placeholder="Zip Code"
                maxlength="6"
                v-model="reqObj.p_zip"
                @input="reqObj.p_zipError = ''"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter zip code')"
                oninput="this.setCustomValidity('')"
              />
              <p class="formErrorMessage" style="color: #ff0000">
                {{ reqObj.p_zipError }}
              </p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="c_add1">Current Address</label>
              </div>
              <textarea
                id="cAddress"
                rows="2"
                wrap="soft"
                class="form-control"
                placeholder="Address Line"
                v-model="reqObj.c_add1"
                @keydown.enter.prevent
                @keydown.space="preventLeadingSpace($event)"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter current address')"
                oninput="this.setCustomValidity('')"
              ></textarea>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="c_state">Current Address State</label>
              </div>
              <select
                id="cState"
                v-model="reqObj.c_state"
                class="form-control"
                v-on:change="getCurrentCitiesList()"
                :state="null"
                oninvalid="this.setCustomValidity('Please select state')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select State</option>
                <option
                  :key="index"
                  v-for="(state, index) in states2"
                  :value="state.id"
                >
                  {{ state.statename }}
                </option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="c_city">Current Address City </label>
              </div>
              <select
                id="cCity"
                v-model="reqObj.c_city"
                class="form-control"
                :state="null"
                oninvalid="this.setCustomValidity('Please select city')"
                oninput="this.setCustomValidity('')"
              >
                <option value="">Select City</option>
                <option
                  :key="index"
                  v-for="(city, index) in cities2"
                  :value="city.id"
                >
                  {{ city.cityname }}
                </option>
              </select>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="c_zip">Current Address Zip</label>
              </div>
              <input
                type="text"
                id="cZipCode"
                class="form-control"
                placeholder="Zip code"
                maxlength="6"
                v-model="reqObj.c_zip"
                @input="reqObj.c_zipError = ''"
                :state="null"
                oninvalid="this.setCustomValidity('Please enter zip code')"
                oninput="this.setCustomValidity('')"
              />
              <p style="color: #ff0000">{{ reqObj.c_zipError }}</p>
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="bank_name">Bank Name</label>
              </div>
              <input
                type="text"
                id="bank_name"
                class="form-control"
                placeholder="Bank Name"
                v-model="reqObj.bank_name"
                oninvalid="this.setCustomValidity('Please enter Bank Name')"
                oninput="this.setCustomValidity('')"
              />
              <!-- <p style="color: #ff0000">{{ reqObj.c_zipError }}</p> -->
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="bank_account_no">Bank Account No</label>
              </div>
              <input
                type="text"
                id="bank_account_no"
                class="form-control"
                placeholder="Bank Account No"
                v-model="reqObj.bank_account_no"
                oninvalid="this.setCustomValidity('Please enter Bank Account No')"
                oninput="this.setCustomValidity('')"
              />
              <!-- <p style="color: #ff0000">{{ reqObj.c_zipError }}</p> -->
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="ifsc_no">IFSC No</label>
              </div>
              <input
                type="text"
                id="ifsc_no"
                class="form-control"
                placeholder="IFSC No"
                v-model="reqObj.ifsc_no"
                oninvalid="this.setCustomValidity('Please enter IFSC No')"
                oninput="this.setCustomValidity('')"
              />
              <!-- <p style="color: #ff0000">{{ reqObj.c_zipError }}</p> -->
            </div>
            <br />
            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="esic_no">ESIC No</label>
              </div>
              <input
                type="text"
                id="esic_no"
                class="form-control"
                placeholder="ESIC No"
                v-model="reqObj.esic_no"
                oninvalid="this.setCustomValidity('Please enter ESIC No')"
                oninput="this.setCustomValidity('')"
              />
              <!-- <p style="color: #ff0000">{{ reqObj.c_zipError }}</p> -->
            </div>
            <br />

            <div class="d-sm-flex align-items-left">
              <div class="col-sm-2">
                <label for="gender">Gender</label>
              </div>

              <div class="col-6 text-left px-0 py-0">
                <input type="radio" id="Male" value='M'  v-model="reqObj.gender" />
                <label for="Male" class='px-1 '>Male</label>
                <input type="radio" id="female" value='F' v-model="reqObj.gender" />
                <label for="female" class='px-1'>Female</label>
                <input type="radio" id="other" value='O' v-model="reqObj.gender" />
                <label for="other" class='px-1' >Other</label>
                <input type="radio" id="unknown" value='U' v-model="reqObj.gender" />
                <label for="unknown" class='px-1' >Unknown</label>
            </div>
            </div>
            <br />

          </div>
          <div
            class="
              container-fluid
              m-0
              p-0
              d-flex
              justify-content-center
              align-items-center
            "
          >
            <button
              class="btn btn-outline-dark border-0"
              type="button"
              v-if="showFields"
              @click="showFields = !showFields"
            >
              Show Less fields....
            </button>
          </div>
          <div class="text-right">
            <button class="btn btn-primary btn-lg" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <UnauthorizedPage></UnauthorizedPage>
  </div> -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import moment from "moment";
moment.locale('en');
import Datepicker from "vuejs-datepicker";
import VueDatetimePickerJs from "vue-date-time-picker-js";
import axios from "axios";
import "vue-datetime/dist/vue-datetime.css";
import Vue from "vue";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueTimePicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "Dashboard",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
    flatPickr,
    VueTimePicker,
  },
  data: function () {
    return {
      showFields: false,
      reqObj: {
        first_name: null,
        middle_name: null,
        last_name: null,
        pan: null,
        uan_no:null,
        panError: null,
        dob: null,
        dobError: null,
        doj: new Date().toISOString().slice(0, 10),
        doe: null,
        emp_code: null,
        o_email: null,
        emailError: null,
        username: null,
        password: null,
        passwordError: null,
        blood_group: null,
        medical_info: null,
        p_mobile: null,
        p_mobileError: null,
        p_email: null,
        p_emailError: null,
        p_add1: null,
        p_city: null,
        p_state: null,
        p_country: null,
        p_zip: null,
        p_zipError: "",
        c_add1: null,
        c_city: null,
        c_state: null,
        c_country: null,
        c_zip: null,
        c_zipError: null,
        report_to: "",
        login_slot: null,
        login_slor_error: "",
        user_role: null,
        user_designation: null,
        user_type: null,
        per_day_minutes: { HH: "09", mm: "00" },
        weekend_strategy: 1,
        per_day_minutes_error: null,
        bank_name: null,
        bank_account_no: null,
        esic_no: null,
        ifsc_no:null,
        gender: "U",
        emergency_contact_number:null,
        emergency_contact_name:null,
        emergency_contact_relation:null,
      },
      unauthorized: 0,
      roles: [],
      designations: [],
      user_type: [],
      countries: [],
      countries2: [],
      states: [],
      states2: [],
      cities: [],
      cities2: [],
      disabledDates: {
        from: new Date(Date.now()),
      },
      editid: 0,
      managers: [],
      permissions: window.localStorage.getItem("permissions"),
      user_role: localStorage.getItem("user_role"),
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        enableInput: false,
      },
      role: localStorage.getItem("user_role"),
    };
  },

  methods: {
    ...mapActions("userStore", [
      "setCurrentUser",
      "logoutUser",
      "setAccessToken",
    ]),
    ...mapActions("common", ["setCurrentPage"]),

    getUserDesignationDropdown() {
      user.getUserDesignationDropdown().then((result) => {
        this.designations = result.data.data;
        // console.log(this.designations);
      });
    },
    getUserTypeDropdown() {
      user.getUserTypeDropdown().then((result) => {
        this.user_type = result.data.data;
        // console.log("user type--------",result)
      });
    },
    loginSlotInput(selectedDates, time, instance) {
      this.reqObj.login_slot = time;
    },

    // validation for first name
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    preventLeadingSpace(evt) {
      // only prevent the keypress if the value is blank
      if (!evt.target.value) evt.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (evt.target.value[0] == " ")
        evt.target.value = evt.target.value.replace(/^\s*/, "");
    },

    getCurrentStatesList() {
      user.get_statesList(this.reqObj.c_country).then((result) => {
        this.states2 = result.data;
      });
    },

    getCurrentCitiesList() {
      user.get_citiesList(this.reqObj.c_state).then((result) => {
        this.cities2 = result.data;
      });
    },

    getPermanentStatesList() {
      user.get_statesList(this.reqObj.p_country).then((result) => {
        this.states = result.data;
      });
    },
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getPermanentCitiesList() {
      user.get_citiesList(this.reqObj.p_state).then((result) => {
        this.cities = result.data;
      });
    },

    allInputCheck() {
      // console.log("add all inputs",this.reqObj);
      let check = true;

      // password validation
      // var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
      // this.reqObj.passwordError = "";
      // if ( !regex.test(this.reqObj.password)) {
      //   check = false;
      //   this.reqObj.passwordError = "Please enter valid password";
      // }

      // pan validation
      if (this.reqObj.pan === "" || this.reqObj.pan === null) {
        check = true;
      } else {
        var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
        this.reqObj.panError = "";
        if (!regex.test(this.reqObj.pan)) {
          check = false;
          this.reqObj.panError = "Please Enter Valid PAN No";
        }
      }

      // uan number validation
      if (this.reqObj.uan_no === "" || this.reqObj.uan_no === null) {
        check = true;
      } else {
        var regex = /^\d+$/;
        this.reqObj.uan_no_Error = "";
        if (regex.test(this.reqObj.uan_no) && this.reqObj.uan_no.length == 12) {
        } else {
          check = false;
          this.reqObj.uan_no_Error = "Please Enter Valid UAN No";
        }
      }

      // birthdate validation
      let currentDate = new Date();
      let birthDate = new Date(this.birthdateformateDate(this.reqObj.dob));
      let difference = currentDate - birthDate;
      let age = Math.floor(difference / 31557600000);
      if (age < 19) {
        check = false;
        this.reqObj.dobError = "you have to be older than 19";
      }
      if (age > 100) {
        check = false;
        this.reqObj.dobError = "you have to be younger than 100";
      }

      // PhoneNumber validation
      // var regex = /^[6-9]\d{2}[2-9]\d{2}\d{4}$/;
      // this.reqObj.p_mobileError = "";
      // if ( !regex.test(this.reqObj.p_mobile)) {
      //   check = false;
      //   this.reqObj.p_mobileError = "Mobile number must be 10 digits starting from 6,7,8,9";
      // }

      // office email validation
      // var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.reqObj.o_email === "" || this.reqObj.o_email === null) {
        check = true;
      } else {
        var regex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.reqObj.emailError = "";
        if (!regex.test(this.reqObj.o_email)) {
          check = false;
          this.reqObj.emailError = "please enter valid email";
        }
      }

      // personalemail validation
      if (this.reqObj.p_email === "" || this.reqObj.p_email === null) {
        check = true;
      } else {
        var regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.reqObj.p_emailError = "";
        if (!regex.test(this.reqObj.p_email)) {
          check = false;
          this.reqObj.p_emailError = "Please enter valid email";
        }
      }

      // personal Zip code validation
      if (this.reqObj.p_zip === "" || this.reqObj.p_zip === null) {
        check = true;
      } else {
        var regex = /^\d{6}(-\d{4})?$/;
        this.reqObj.p_zipError = "";
        if (!regex.test(this.reqObj.p_zip)) {
          check = false;
          this.reqObj.p_zipError = "Please enter valid zip code";
        }
      }

      // current zip code validation
      if (this.reqObj.c_zip === "" || this.reqObj.c_zip === null) {
        check = true;
      } else {
        var regex = /^\d{6}(-\d{4})?$/;
        this.reqObj.c_zipError = "";
        if (!regex.test(this.reqObj.c_zip)) {
          check = false;
          this.reqObj.c_zipError = "Please enter valid zip code";
        }
      }
      this.reqObj.login_slot_errpr = "";
      if (this.reqObj.login_slot == null || this.reqObj.login_slot == "") {
        this.reqObj.login_slot_error = "Please select the login slot";
        check = false;
      }
      //for checking
      this.reqObj.per_day_minutes_error = "";
      if (
        this.reqObj.per_day_minutes["HH"] == "" &&
        this.reqObj.per_day_minutes["mm"] == ""
      ) {
        this.reqObj.per_day_minutes_error =
          "\tPlease select your per day hours";
        check = false;
      }
      return check;
    },

    // getUsers() {
      // user.getUsers(this.reqObj).then((result) => {
        // console.log("this is hello",result.data);
      // });
    // },

    getmanageridDropdown() {
      user.get_all_users().then((result) => {
        // console.log("-----",result.data);
        this.managers = result.data;
      });
    },

    getUserRoleDropdown() {
      user.getUserRoleDropdown(this.reqObj).then((result) => {
        // console.log(result);
        // console.log("user roles",this.reqObj.roles);
        this.roles = result.data.data;
      });
    },

    get_countries() {
      user.get_countries(this.reqObj).then((result) => {
        this.countries = result.data;
        this.countries2 = result.data;
      });
    },

    getUserById(id) {
      user.getUserById(id).then((result) => {
        // user.getUserProfile(id).then((result) => {
        // console.log("report_to",this.reqObj.report_to);
        // console.log("user id is",result.data);
        // console.log('state',this.countries[result.data.p_country-1],result.data.p_country)
        // console.log('-------',result.data.p_state)
        // console.log(JSON.stringify(result.data[0]['id']));
        this.reqObj.first_name = result.data["first_name"];
        this.reqObj.middle_name = result.data["middle_name"];
        this.reqObj.last_name = result.data["last_name"];
        this.reqObj.pan = result.data["pan"];
        this.reqObj.uan_no = result.data["uan_no"];
        this.reqObj.dob = result.data["dob"];
        this.reqObj.doj = result.data["doj"];
        this.reqObj.doe = result.data["doe"];
        this.reqObj.emp_code = result.data["emp_code"];
        this.reqObj.o_email = result.data["o_email"];
        this.reqObj.username = result.data["username"];
        this.reqObj.blood_group = result.data["blood_group"];
        this.reqObj.medical_info = result.data["medical_info"];
        this.reqObj.p_mobile = result.data["p_mobile"];
        this.reqObj.p_email = result.data["p_email"];
        this.reqObj.p_add1 = result.data["p_add1"];
        this.reqObj.p_city = result.data["p_city"];
        this.reqObj.p_country = result.data["p_country"];
        this.getPermanentStatesList();
        this.reqObj.p_state = result.data["p_state"];
        this.getPermanentCitiesList();
        this.reqObj.p_zip = result.data["p_zip"];
        this.reqObj.c_add1 = result.data["c_add1"];
        this.reqObj.c_country = result.data["c_country"];
        this.getCurrentStatesList();
        this.reqObj.c_state = result.data["c_state"];
        this.getCurrentCitiesList();
        this.reqObj.c_city = result.data["c_city"];
        this.reqObj.c_zip = result.data["c_zip"];
        this.reqObj.report_to = result.data["report_to"];
        this.reqObj.login_slot = result.data["login_slot"];
        this.reqObj.user_role = result.data["user_role"];
        this.reqObj.user_designation = result.data["designation_id"];
        this.reqObj.user_type = result.data["user_type"];
        this.reqObj.per_day_minutes = result.data["per_day_minutes"];
        this.reqObj.weekend_strategy = result.data["weekend_strategy"];

        const time = result.data["per_day_minutes"];
        const hr = time / 60;
        const rhr = Math.floor(hr);
        const min = (hr - rhr) * 60;
        const rhr_ = (rhr > 9 ? "" : "0") + rhr;
        const min_ = (min > 9 ? "" : "0") + min;
        this.reqObj.per_day_minutes = { HH: String(rhr_), mm: String(min_) };
        // console.log("api-------",this.reqObj.per_day_minutes);
      });
    },

    verifyOpertations() {
      let check = true;
      check = this.allInputCheck();
      const temp_login = this.reqObj.per_day_minutes;
      if (check == true) {
        const hh = Number(this.reqObj.per_day_minutes["HH"]);
        const mm = Number(this.reqObj.per_day_minutes["mm"]);
        this.reqObj.per_day_minutes = hh * 60 + mm;
        this.reqObj.doj = new Date(this.reqObj.doj).toISOString().slice(0, 10);
        // this.reqObj.per_day_minutes=Number(this.reqObj.per_day_minutes);
        // this.reqObj.weekend_strategy=Number(this.reqObj.weekend_strategy);
        if (this.editid == 0) {
          user.newUserAdd(this.reqObj).then((result) => {
            if (result.data.code == "200") {
              // this.getUsers();
              alert(result.data.message);
              this.$router.push({ name: "Users" });
            } else {
              this.reqObj.per_day_minutes = temp_login;
              alert(result.data.message);
            }
          });
        } else {
          // console.log("-=-=-=-",this.reqObj);
          user.updatetheuser(this.editid, this.reqObj).then((result) => {
            if (result.data.code == "200") {
              alert(result.data.message);
              this.$router.push({ name: "Users" });
            } else {
              alert(result.data.message);
            }
          });
          return check;
        }
      } else {
        // console.log("validation failed");
      }
    },
    birthdateformateDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formateValueDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    logout() {
      this.logoutUser();
      this.$nextTick(() => {
        this.getCurrentUser();
      });
    },
  },
  mounted() {
    this.setCurrentPage("Dashboard");
    if (this.permissions.includes("users.write")) {
      // this.getUsers();
      this.getUserPermissions();
      // console.log(this.getCurrentUser);
      // console.log("user");
      // const usr_role=localStorage.getItem('user_role');
      // console.log("----------",usr_role);
      // if(usr_role==1){
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        this.editid = this.$route.params.id;
        this.getUserById(this.$route.params.id);
      }
      this.getmanageridDropdown();
      this.getUserRoleDropdown();
      this.get_countries();
      this.getUserDesignationDropdown();
      this.getUserTypeDropdown();
          // $(".fl-flex-label").flexLabel();
      //}
    } else {
      this.$router.push("/unauthorized-page");
    }
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
