<template>
  <div class="my-4">
    <div class="card shadow-sm border-0" v-if="currentUser.user_role==1">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="text-primary p-3 m-0" v-text="title"></h4>
        <router-link to="/assets-category" tag="a" class="text-secondary px-3 text-decoration-none">
          <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Go Back
        </router-link>
      </div>
        <div class="d-flex justify-content-center align-items-center p-5" v-if="category_fields.length==0 && category_id">
            <span class="spinner-border text-primary"></span>
        </div>
        <div class="card-body pt-0" v-else>
            <form @submit.prevent="saveCategory">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="category_name" class="form-label mandatory">Category Name  </label>
                        <input type="text" id="category_name" placeholder="Enter the name of category" required v-model="reqObj.name" class="form-control"/>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="category_prefix" class="form-label mandatory">Category Prefix  </label>
                        <input type="text" for="category_prefix" v-model="reqObj.prefix" required placeholder="Enter the prefix for category" class="form-control" @input="trimer(reqObj,'prefix',3)"/>
                    </div>
                </div>
                <div>
                    <div v-for="(item, index) in category_fields" class="card-body border rounded-3 my-3" :key="item.id">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="field_name" class=" form-label mandatory">Field Name</label>
                                <input class="form-control" id="field_name" v-model="item.field_name" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" required placeholder="Enter the field name"/>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="field_type" class=" form-label mandatory">Field Type</label>
                            <small class="text-danger m-0 position-absolute" v-text="item.type_error_msg"></small>
                            <multiselect v-model="item.field_type" @select="feildTypeSelection($event, item)" :options="field_list" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'"></multiselect>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'int' || item.field_type == 'float'">
                            <label for="min_imit" class=" form-label mandatory">Min Limit</label>
                            <input class="form-control" id="min_imit" required v-model.number="item.min_limit" @keypress="isNumber" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" type="number" :step="item.field_type == 'int' ? 1 : 0.5" placeholder="Enter the min limit" min="0"/>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'int' || item.field_type == 'float'">
                            <label for="max_imit" class=" form-label mandatory">Max Limit</label>
                            <input class="form-control" id="max_imit" required type="number" @keypress="isNumber" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" v-model.number="item.max_limit" :step="item.field_type == 'int' ? 1 : 0.5" placeholder="Enter the max limit" min="0"/>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'str'">
                            <label for="max_chars" class=" form-label mandatory">Max Chars</label>
                            <input class="form-control" id="max_chars" @keypress="isNumber" type="number" required v-model.number="item.max_chars" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" max="1000" placeholder="Enter the max chars limit" min="0"/>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'range date'">
                            <label for="start_date" class=" form-label mandatory">Start Date</label>
                            <small class="text-danger" v-text="item.start_date_msg"></small>
                            <flat-pickr class="form-control" id="start_date" :config="item.config" @on-change="inputDate($event, item, 'start_date')" v-model="item.start_date" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" :modelValue="item.start_date" placeholder="Enter the start date"></flat-pickr>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'range date'">
                            <label for="end_date" class=" form-label mandatory">End Date</label>
                            <small class="text-danger" v-text="item.end_date_msg"></small>
                            <flat-pickr class="form-control" id="end_date" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" :config="item.config" @on-change="inputDate($event, item, 'end_date')" v-model="item.end_date" :modelValue="item.end_date" placeholder="Enter the end date"></flat-pickr>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'singleselect dropdown'">
                            <label for="enter_optins" class=" form-label mandatory">Enter options</label>
                            <small class="text-danger" v-text="item.error_msg"></small>
                            <input class="form-control" id="enter_optins" v-model="item.input_value" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" required @input="optionCreator($event, item)" placeholder="Enter the options (,) seperated Ex. blue,red,green"/>
                        </div>
                        <div class="form-group col-md-12" v-if="item.field_type == 'multiselect dropdown'">
                            <label for="enter_optins" class=" form-label mandatory">Enter options</label>
                            <small class="text-danger" v-text="item.error_msg"></small>
                            <input class="form-control" v-model="item.input_value" required :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" @input="optionCreator($event, item)" placeholder="Enter the options (,) seperated Ex. blue,red,green"/>
                        </div>
                        <div class="row mb-4 no-gutters" v-if="item.field_type &&item.field_type !== 'range date' &&item.field_type !== 'single date'">
                          <div class="col-2" v-if="item.field_type !== 'singleselect dropdown' &&item.field_type !== 'multiselect dropdown'">
                          <label for="optins" class=" form-label">Default Value</label>
                        </div>
                        <div class="col-md-12" v-else>
                          <label for="optins" class=" form-label mandatory">Options</label>
                        </div>
                            <span v-if="item.field_type == 'str'">
                                <input placeholder="Enter the default value" class="form-control"  id="optins" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" type="text" v-model="item.input_value" @input="trimer(item,'input_value',item.max_chars)"/>
                            </span>
                                <span v-else-if="item.field_type == 'int'">
                                <input placeholder="Enter the default value" @keypress="isNumber" class="form-control"  id="optins" v-model.number="item.default_value" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" type="number" :max="item.max_limit" :min="item.min_limit"/>
                            </span>
                                <span v-else-if="item.field_type == 'float'">
                                <input placeholder="Enter the default value" @keypress="isNumber" class="form-control"  id="optins" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" v-model.number="item.default_value" type="number" step="0.5" :max="item.max_limit" :min="item.min_limit"/>
                            </span>
                            <span v-else-if="item.field_type == 'bool'">
                                <multiselect v-model="item.default_value" :disabled="item.disabled" :class="item.disabled ? 'bg-light' : 'bg-white'" :options="item.options" @select="optionCreator"></multiselect>
                            </span>
                            <div v-else-if="item.field_type == 'singleselect dropdown'">
                            <div class="border p-2 bg-light">
                                <span class="text-muted" v-if="!item.options.length">
                                  Options will be shown here
                                </span>
                                <h5 class="rounded-3 d-inline-block me-2" v-for="(options, index) in item.options" :key="index">
                                    <span class="badge badge-success" v-text="options"></span>
                                </h5>
                            </div>
                            </div>
                            <span v-else-if="item.field_type == 'multiselect dropdown'">
                            <div class="border p-2 bg-light">
                                <span class="text-muted" v-if="!item.options.length">
                                Options will be shown here
                                </span>
                                <h5 class="rounded-3 d-inline me-2" v-for="(options, index) in item.options" :key="index">
                                  <span class="badge badge-success" v-text="options"></span>
                                </h5>
                            </div>
                            </span>
                        </div>
                        <div class="d-flex align-items-center gap-2 justify-content-start">
                            <div class="form-check">
                              <label for="remember_me" class="form-check-label">Active</label>
                                <input type="checkbox" id="remember_me" class="form-check-input" v-model="item.is_active"/>
                            </div>
                            <div class="form-check">
                              <label for="remember_me" class="form-check-label">Mandatory</label>
                                <input type="checkbox" id="remember_me" class="form-check-input" v-model="item.is_mandatory"/>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-danger btn-sm d-block ms-auto" type="button" v-if="!item.disabled" @click="removeCategoryField(index)">
                                Remove
                            </button>
                        </div>
                    </div>
                    <button class="btn btn-primary ms-auto d-block btn-sm" @click="addCategoryField" type="button">Add Field</button>
                    <div class="px-2" v-if="category_fields.length > 0">
                        <button class="btn btn-primary m-2" type="submit" v-if="category_id == null">
                            Save
                        </button>
                        <button class="btn btn-primary m-2" type="submit" v-else>
                            Update
                        </button>
                        <router-link tag="button" :to="{ name: 'AssetsCategory' }" class="btn btn-outline-secondary" type="button">
                            Cancel
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div v-else>
      <h2 class="text-muted">Unauthorized access</h2>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import Multiselect from "vue-multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapState, mapActions } from "vuex";
import VueGoodTable from "vue-good-table";
Vue.use(VueGoodTable);
import moment from "moment";
// import { Swal } from "sweetalert2/dist/sweetalert2";
export default {
  props: ["id"],
  mounted() {
    if(this.id){
      this.category_id = this.id;
    }
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  data() {
    return {
      title: "Add Category",
      field_list: [
        "int",
        "float",
        "str",
        "bool",
        "single date",
        "range date",
        "singleselect dropdown",
        "multiselect dropdown",
      ],
      category_id: null,
      reqObj: {
        name: null,
        prefix: null,
        fields: null,
      },
      fields_error_msg: "",
      category_fields: [],
      data_type_fields: {
        default: {
          id:null,
          field_name: null,
          field_type: null,
          is_mandatory: false,
          is_active: true,
        },
        int: {
          max_limit: null,
          min_limit: null,
          default_value: null,
          type_error_msg: "",
        },
        bool: {
          options: ["true", "false"],
          default_value: null,
          type_error_msg: "",
        },
        str: {
          max_chars: null,
          default_value: null,
          type_error_msg: "",
        },
        float: {
          max_limit: null,
          min_limit: null,
          default_value: null,
          type_error_msg: "",
        },
        "single date": {
          config: {
            dateFormat: "Y-m-d",
            mode: "single",
          },
          default_value: new Date(),
          type_error_msg: "",
          error_msg:"",
          default_value: null,
        },
        "range date": {
          start_date: null,
          end_date: null,
          config: {
            dateFormat: "Y-m-d",
            mode: "single",
          },
          default_value: new Date(),
          start_date_msg: "",
          end_date_msg: "",
          type_error_msg: "",
          error_msg:"",
          default_value: null,
        },
        "singleselect dropdown": {
          options: [],
          default_value: null,
          input_value:null,
          type_error_msg: "",
          error_msg:"",
          error_msg: "",
        },
        "multiselect dropdown": {
          options: [],
          default_value: null,
          input_value:null,
          type_error_msg: "",
          error_msg: "",
        },
      },
    };
  },
  watch: {
    category_id(nv, ov) {
      if (nv) {
        this.title = "Update Category";
        this.getCategoryById(nv);
      }
    },
  },
  methods: {
    trimer(item,key,len){
      item[key]=item[key].length>len ? item[key].slice(0,len) : item[key];
    },
    feildTypeSelection(type, item) {
      for(let key of Object.keys(item)){
        if(!Object.keys(this.data_type_fields['default']).includes(key)){
          delete item[key];
        }
      }
      item = Object.assign(item, this.data_type_fields[type]);
      item.id=Math.random()*10;
      // this.$forceUpdate();
    },
    addCategoryField() {
      let fields_copy = Object.assign({}, this.data_type_fields["default"]);
      this.category_fields.push(fields_copy);
    },
    inputDate(event, item, key) {
      item[key] = event[0];
      if (item.start_date && item.end_date) {
        if (!moment(item.start_date).isSameOrBefore(moment(item.end_date))) {
          item.end_date = item.start_date;
          this.$forceUpdate();
        }
      }
    },
    removeCategoryField(index) {
      this.category_fields.splice(index, 1);
    },
    getCategoryById(id) {
      this.category_fields = [];
      user.getAssetCategoryById(id).then((res) => {
        this.category_fields = res.data.data[0].fields;
        this.reqObj.name = res.data.data[0].name;
        this.reqObj.prefix = res.data.data[0].prefix;
        this.category_fields.map((item) => {
          item.disabled = true;
        });
      });
    },
    optionCreator(event, item) {
      const pattern = /^([a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*)(,?)$/;
      item.input_value=item.input_value.trim();
      if (pattern.test(item.input_value)) {
        item.options = item.input_value.split(",").filter(item=>item);
      } else {
        item.options = item.input_value.split(",").filter(item=>item && item!=" ");
        item.input_value=item.options.join(",");
        // console.log(item.options);
      }
      this.$forceUpdate();
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    saveCategory() {
      let check = true;
      this.reqObj.fields = this.category_fields.slice();
      if (this.category_fields.length == 0) {
        this.fields_error_msg = "Please add atleast 1 field in category...!";
      } else {
        this.reqObj.fields.forEach((item) => {
          item.default_value == "" ? (item.default_value = null) : "";
          if (item.field_type == null) {
            item.type_error_msg = "Please select data type";
            check = false;
            this.$forceUpdate();
            return;
          }
          if (item.field_type == "range date") {
            if (item.start_date == null) {
              item.start_date_msg = "** Please enter the start date **";
              this.$forceUpdate();
              check = false;
              return;
            } else {
              item.start_date_msg = "";
              check = true;
            }
            // return;
          }
          if (
            item.field_type == "singleselect dropdown" ||
            item.field_type == "multiselect dropdown"
          ) {
            if (item.options.length == 0) {
              item.error_msg = "** Please enter the options **";
              check = false;
              this.$forceUpdate();
              return;
            } else {
              item.error_msg = "";
              check = true;
            }
          }
        });
        if(check){
          let tempObj = JSON.parse(JSON.stringify(this.reqObj));
          tempObj.fields.map(item=>{
            if(item.field_type=="range date"){
              item.start_date=moment(item.start_date).format("YYYY-MM-DD");
              item.end_date=moment(item.end_date).format("YYYY-MM-DD");
            }
            delete item['disabled'];
            delete item["id"];
            delete item['type_error_msg'];
            delete item['start_date_msg']
            delete item['end_date_msg']
          });
          if (this.category_id) {
          tempObj.id=Number(this.category_id);
          user
            .updateAssetCategory(JSON.stringify(tempObj))
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.$router.push({ name: "AssetsCategory" });
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        } else {
          user
            .addAssetCategory(JSON.stringify(tempObj))
            .then((res) => {
              Swal.fire({
                title: res.data.message,
                icon: "success",
              });
              this.$router.push({ name: "AssetsCategory" });
            })
            .catch((err) => {
              Swal.fire({
                title: err.response.data.detail,
                icon: "error",
              });
            });
        }
        }
      }
    },
  },
};
</script>

