<template>
    <!-- Begin Page Content -->
    <div class="container-fluid " style="min-height:600px;">
        <div class="card shadow mb-4">
            <div class="card-header d-sm-flex align-items-center justify-content-between mb-4 py-3" >
                <h4 class="m-0 font-weight-bold text-primary">Tasks</h4>
                <router-link v-if="permissions.includes('tasks.add')" class="startbuttons d-none d-sm-inline-block float-right btn btn-sm btn-success shadow-sm btn-sm " :to="'/task/add'" v-bind:class="[currentPage=='AddTask' ? 'active' : '','nav-item']">
                   Add Task
                </router-link>
            </div>
            <div v-if="permissions.includes('tasks.read')" class="card-body">
                <div v-if="totalRecordsfirsttable != 0" class="table-responsive">
                  <h5 class="m-0 font-weight-bold text-primary">Your Tasks</h5><br>
                    <vue-good-table
                        mode="remote"
                        @on-page-change="onPageChangefirst"
                        @on-sort-change="onSortChangefirst"
                        @on-column-filter="onColumnFilterfirst"
                        @on-per-page-change="onPerPageChangefirst"
                        :totalRows="TotalRecords1"
                        :isLoading.sync="isLoadingfirsttable"
                        :pagination-options="{
                          enabled: true,
                        }"
                        :sort-options="{
                          enabled: true,
                          initialSortBy: {field: 'start_date', type: 'desc'}
                        }"
                        :rows="your_tasks_rows"
                        :columns="your_tasks_column"
                    >
                    <template slot="table-row" scope="props">
                      <span v-if="props.column.label =='Action'">
                        <tr class="no-border-td-tr">
                          <td v-if="permissions.includes('tasks.add') && props.row.created_by == currentUser.id" class="no-border-td-tr">
                            <router-link :to="'/task/update/'+props.row.id" v-bind:class="[currentPage=='updatetask' ? 'active' : '']">
                              <i class="btn btn-primary" >Update</i>
                            </router-link>
                          </td>
                          <td v-if="permissions.includes('tasks.delete') && props.row.created_by == currentUser.id" class="no-border-td-tr">
                            <!-- {{ permissions.includes('tasks.delete') && props.row.created_by == currentUser.id }} -->
                            <i @click="delete_task(props.row.id)" class="btn btn-danger">Delete</i>
                          </td>
                        <!-- router link for adding report form -->
                          <td v-if="permissions.includes('reports.add')" class="no-border-td-tr">
                            <router-link :to="'/add-report/'+props.row.id" v-bind:class="[currentPage=='AddReport' ? 'active' : '']">
                              <i class="btn btn-primary" >+</i>
                            </router-link>
                          </td>
                        </tr>
                      </span>
                      <span v-if="props.column.field == 'description'">
                        <read-more more-str="read more" :text="props.row.description" less-str="read less" :max-chars="100"></read-more>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                    <template slot="column-filter" slot-scope="{ column, updateFilters} ">
                      <span v-if="column.field == 'start_date'">
                       <datepicker :clear-button="true" :format="formateDate" placeholder="yyyy-mm-dd"
                        @input="(e) => updateFilters(column,e)"/>
                      </span>
                      <span v-else-if="column.field == 'due_date'">
                        <datepicker :clear-button="true" :format="formateDate" placeholder="yyyy-mm-dd"
                          @input="(e) => updateFilters(column,e)"/>
                      </span>
                      </template>
                    </vue-good-table>
                </div>
                <div v-if="totalRecordssecondtable != 0" class="table-responsive">
                  <h5 class="m-0 font-weight-bold text-primary">Team Mates Tasks</h5><br>
                    <vue-good-table
                        mode="remote"
                        @on-page-change="onPageChangesecond"
                        @on-sort-change="onSortChangesecond"
                        @on-column-filter="onColumnFiltersecond"
                        @on-per-page-change="onPerPageChangesecond"
                        :totalRows="TotalRecords2"
                        :isLoading.sync="isLoadingsecondtable"
                        :pagination-options="{
                          enabled: true,
                        }"
                        :sort-options="{
                          enabled: true,
                          initialSortBy: {field: 'start_date', type: 'desc'}
                        }"
                        :rows="team_mates_tasks_row"
                        :columns="team_mates_tasks_column"
                    >
                    <template slot="table-row" scope="props">
                      <span v-if="props.column.label =='Action'">
                        <tr class="no-border-td-tr">
                          <td v-if="permissions.includes('tasks.write') && props.row.created_by == currentUser.id" class="no-border-td-tr">
                            <router-link :to="'/task/update/'+props.row.id" v-bind:class="[currentPage=='updatetask' ? 'active' : '']">
                              <i class="btn btn-primary" >Update</i>
                            </router-link>
                          </td>
                          <td v-if="permissions.includes('tasks.delete') && props.row.created_by == currentUser.id" class="no-border-td-tr">
                            <i @click="delete_task(props.row.id)" class="btn btn-danger">Delete</i>
                          </td>
                        <!-- router link for adding report form -->
                          <!-- <td v-if="permissions.includes('tasks.write')" class="no-border-td-tr">
                            <router-link :to="'/add/report/'+props.row.id" v-bind:class="[currentPage=='AddReport' ? 'active' : '']">
                              <i class="btn btn-primary" >+</i>
                            </router-link>
                          </td> -->
                        </tr>
                      </span>
                      <span v-if="props.column.field == 'description'">
                        <read-more more-str="read more" :text="props.row.description" less-str="read less" :max-chars="100"></read-more>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                    <template slot="column-filter" slot-scope="{ column, updateFilters} ">
                      <span v-if="column.field == 'start_date'">
                       <datepicker :clear-button="true" :format="formateDate" placeholder="yyyy-mm-dd"
                        @input="(e) => updateFilters(column,e)"/>
                      </span>
                      <span v-else-if="column.field == 'due_date'">
                        <datepicker :clear-button="true" :format="formateDate" placeholder="yyyy-mm-dd"
                          @input="(e) => updateFilters(column,e)"/>
                      </span>
                      </template>
                    </vue-good-table>
                </div>
            </div>


        </div>
        <div v-if="!permissions.includes('tasks.read')">
          <UnauthorizedPage></UnauthorizedPage>
        </div>
    </div>
    <!-- End of Main Content -->
</template>
<script>
import user from '@/ajax/user';
import { mapState,mapActions } from 'vuex';
import Vue from 'vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import myMixin from '@/mixins/Permission'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UnauthorizedPage from '@/views/Layouts/UnauthorizedPage.vue'
import Multiselect from 'vue-multiselect'
import ReadMore from 'vue-read-more';

Vue.use(ReadMore);
Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: 'Tasks',
  mixins: [myMixin],
  components:
  {
    Multiselect,
    Datepicker,
    UnauthorizedPage
  },
  data: function () {
    return {
      assign:{
        users:'',
        task_id : 0,
      },
      UserState:'',
      users:[],
      checkval:[],

      // first table
      currentPage: "",
      serverParamsfirst: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "desc",
          },
        ],
        page: 1,
        perPage: 10,
        setcurrentPage: 1,
      },

      totalRecordsfirsttable: 0,
      TotalRecords1:0,
      isLoadingfirsttable: false,
      your_tasks_rows: [],

      your_tasks_column:[

        {
          label: " Project",
          field: "project_name",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: " Mile Stone",
          field: "name",
          type: "value",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Milestone name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: " Short description",
          field: "short_desc",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task short summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
           }
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label: "Start Date",
          field: "start_date",
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Due Date",
          field: "due_date",
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Estimation time",
          field: "new_estimated_minutes",
          formatFn:this.ConvertMinutesToTime,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label:"Status",
          field: "task_status",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["TO DO", "IN PROGRESS", "QA", "RE-OPENED", "DONE", "RELEASED"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label:"Priority",
          field: "priority",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems:["HIGH","LOW","MEDIUM"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilterfirst, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting"
        },

      ],

      // second table

      serverParamssecond: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "desc",
          },
        ],
        page: 1,
        perPage: 10,
        setcurrentPage: 1,
      },
      totalRecordssecondtable: 0,
      TotalRecords2:0,
      isLoadingsecondtable: false,
      team_mates_tasks_row: [],

      team_mates_tasks_column:[
        {
          label: " Username",
          field: "username",
          tdClass:'text-center',
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Username", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: " Project",
          field: "project_name",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: " Mile Stone",
          field: "name",
          type: "value",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Milestone name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: " Short description",
          field: "short_desc",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task short summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
           }
        },
        {
          label: "Description",
          field: "description",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label: "Start Date",
          field: "start_date",
          filterable: true,
          sortable: true,
          firstSortType: 'desc',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Due Date",
          field: "due_date",
          filterable: true,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "yyyy-mm-dd", // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
          }
        },
        {
          label: "Estimation time",
          field: "new_estimated_minutes",
          formatFn:this.ConvertMinutesToTime,
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Task hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems:"",  // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label:"Status",
          field: "task_status",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project Status", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["TO DO", "IN PROGRESS", "QA", "RE-OPENED", "DONE", "RELEASED"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label:"Priority",
          field: "priority",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project Priority", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: ["HIGH","LOW","MEDIUM"], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFiltersecond, //custom filter function that
            trigger: "enter" //only trigger on enter not on keyup
         }
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting"
        },

      ],

      permissions:window.localStorage.getItem('permissions'),
    }
  },
    beforeMount(){
        this.setCurrentPage('AllTasks');
    },
    mounted(){
      if(this.permissions.includes('tasks.read')){
        this.getUserPermissions();
        this.search_your_task();
        this.search_team_mates_task();
      }
    },
    methods: {
        ...mapActions('userStore', ['setCurrentUser','logoutUser']),
        ...mapActions('common', ['setCurrentPage']),


    // first table column filters and functions

    search_your_task() {
      var fields = {
        filters: this.serverParamsfirst.columnFilters,
        messageData: this.message,
        sort: this.serverParamsfirst.sort,
        page:this.serverParamsfirst.page,
        perpage:this.serverParamsfirst.perPage
      };
      user.search_your_task(fields).then((result) => {
        this.your_tasks_rows = []
        this.your_tasks_rows = result.data.data.data
        this.TotalRecords1 = result.data.data.f_total
        this.totalRecordsfirsttable = result.data.data.total
        // console.log(result.data.data.total);
      });
    },

    updateParamsfirst(newProps) {
      this.serverParamsfirst = Object.assign({},this.serverParamsfirst,newProps);
    },
    onPageChangefirst(params) {
      this.updateParamsfirst({ page: params.currentPage });
      this.search_your_task();
    },

    onPerPageChangefirst(params) {
      this.updateParamsfirst({ perPage: params.currentPerPage });
      this.search_your_task();
    },

    onSortChangefirst(params) {
      this.updateParamsfirst({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_your_task();
    },

    onColumnFilterfirst(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsfirst(newObj);
      this.serverParamsfirst.page = 1;
      this.search_your_task();
    },


    // second table column filters and functions


    search_team_mates_task() {
      var fields = {
        filters: this.serverParamssecond.columnFilters,
        messageData: this.message,
        sort: this.serverParamssecond.sort,
        page:this.serverParamssecond.page,
        perpage:this.serverParamssecond.perPage
      };
      user.search_team_mates_task(fields).then((result) => {
        this.team_mates_tasks_row = [];
        this.team_mates_tasks_row = result.data.data.data;
        this.TotalRecords2 = result.data.data.f_total;
        this.totalRecordssecondtable = result.data.data.total

        this.team_mates_tasks_row.forEach(obj=>{
          let arr=obj.username;
          let t = ""
          if(arr.length>1){
            for(let i = 0; i < arr.length; i++) {
              t += arr[i] + ", ";
            }
            obj.username=t;
            // obj.username=arr[0]+','+arr[1];
          }
          else{
            obj.username=arr[0];
          }
        });
      });
    },

    updateParamssecond(newProps) {
      this.serverParamssecond = Object.assign({},this.serverParamssecond,newProps);
    },

    onPageChangesecond(params) {
      this.updateParamssecond({ page: params.currentPage });
      this.search_team_mates_task();
    },

    onPerPageChangesecond(params) {
      this.updateParamssecond({ perPage: params.currentPerPage });
      this.search_team_mates_task();
    },
    onSortChangesecond(params) {
      this.updateParamssecond({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_team_mates_task();
    },

    onColumnFiltersecond(params) {
      //remove empty fields
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamssecond(newObj);
      this.serverParamssecond.page = 1;
      this.search_team_mates_task();
    },


    // common functions
    delete_task(id) {
        this.$swal({
          title: "Delete",
          text: "Are you sure you want to delete?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: '',
          confirmButtonText: "Yes, Delete it!",
          closeOnConfirm: true
        }).then((result) => {
         if(result.value) {
            // this.$swal('Deleted', 'You successfully delete this task', 'success')
            user.delete_task(id).then((result) => {
              if(result.data.code == 424){
                this.$swal(result.data.data, result.data.message, 'warning')
              }
              if(result.data.code == 200){
                this.$swal(result.data.data, result.data.message, 'success')
              }
              // if result.data.code
              // this.$swal(result.data.data, result.data.message, result.data.data)
          });
        } else {
            user.delete_task(error => {
            // console.log(error);
        });
          }
    });
    },

    formateDate(date) {
      return moment(date).format('yyyy-MM-DD');
    },
  },
  computed: {
    ...mapState('userStore', ['currentUser'])
  },
}
</script>

<style scoped>
.startbuttons{
    padding: 5px 30px 5px 30px;
    margin: 10px;
    font-size: 20px;
}
.disableButton{
    opacity: 0.5;
    pointer-events: none;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
