<template>
  <div>
    <div class="d-flex me-3">
      <div
        class="rounded-circle img-fluid"
        v-for="(item, index) in avatarList"
        :key="item.user_id"
        :style="{
          'z-index': index + 1,
          'margin-left':
            (index == 0 && avatarList.length == 1) ||
            (index == 0 && avatarList.length > 1)
              ? '4px'
              : '-10px',
        }"
      >
        <!-- For user profile images within limit -->
        <v-lazy-image
          :title="`${item.first_name} ${item.last_name}`"
          class="object-fit-contain rounded-circle img"
          style="
            width: 32px;
            height: 32px;
            background-color: rgba(0, 0, 0, 0.5);
          "
          @click.stop
          v-if="!Object.hasOwn(item, 'popover_list')"
          type="button"
          :src="item.image ? item.image : default_img_url"
        />
        <!-- For user profile images which excludes the limit -->
        <div v-else>
          <div
            class="rounded-circle border-light text-white text-center bg-info p-1 shadow-sm"
            style="width: 32px; height: 32px"
            @click.stop
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#moreUser"
          >
            <span>{{ item.popover_list.length }}+</span>
          </div>
          <!-- collapsable menu for profile -->
          <div
            id="moreUser"
            class="collapse shadow-sm rounded-2 bg-white border mt-1"
            style="min-width: 8.5rem"
          >
            <!-- to center profile images -->
            <div class="d-flex justify-content-center align-items-center">
              <!-- code for profile -->
              <v-lazy-image
                @click.stop
                type="button"
                :title="`${i.first_name} ${i.last_name}`"
                class="rounded-circle my-1 img"
                style="width: 32px; height: 32px; margin-left: -10px"
                :src="i.image ? i.image : default_img_url"
                v-for="(i, idx) in item.popover_list"
                :key="i.user_id"
                :style="{ 'z-index': idx + 1 }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from "bootstrap";
import VLazyImage from "v-lazy-image/v2";
export default {
  components: {
    VLazyImage,
  },
  computed: {
    avatarList() {
      let new_list = this.avatars.reduce((acc, cv, index) => {
        if (index > this.limit - 1) {
          // console.log
          if (Object.keys(acc[this.limit - 1]).includes("popover_list")) {
            acc[this.limit - 1]["popover_list"].push(cv);
          } else {
            acc[this.limit - 1]["popover_list"] = [];
            acc[this.limit - 1]["popover_list"].push(cv);
          }
          return acc;
        } else {
          acc.push(cv);
          return acc;
        }
      }, []);
      return new_list;
    },
  },
  data() {
    return {
      default_img_url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6OGENDwS-GSs0d5kfCym9qyD99xVmFdXTdw&usqp=CAU",
      avatars: this.users_details,
    };
  },
  props: {
    users_details: { type: Array, default: [] },
    limit: { type: Number, default: 3 },
  },
  mounted() {
    // console.log([...document.querySelectorAll('[data-bs-toggle="tooltip"]')]);
    // [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
    //   (el) => new Tooltip(el)
    // );
  },
};
</script>
<style scoped>
.img {
  border: 1px solid gainsboro;
  cursor: pointer;
}
.img:hover {
  border: 1px solid white;
}
</style>
