<template>
  <!-- Begin Page Content -->
  <div>
    <div
      class="card shadow-sm border-0 my-4"
      v-if="permissions.includes('snapshot.report')"
    >
      <div class="card-body">
        <!-- Header -->
        <!-- <div class="px-2 pt-2 d-flex justify-content-between align-items-center">
                    <h4 class="font-bold-m-0 text-primary">Snapshot By Year</h4>
                </div> -->
        <!-- Filters -->
        <div class="mb-3 row">
          <div class="col-md-2 col-12">
            <h4
              class="font-bold-m-0 text-md-start text-center text-primary px-2 pt-2"
            >
              Productivity Report
            </h4>
          </div>
          <div
            class="col-md-10 col-12 d-flex mt-md-0 mt-4 flex-md-row flex-column justify-content-end align-items-center gap-2"
          >
            <multiselect
              :options="user_list"
              v-model="reqObj.users"
              track-by="id"
              label="full_name"
              :multiple="true"
              group-label="label"
              group-values="users_data"
              :group-select="true"
              :close-on-select="false"
              :custom-label="custom_label"
              placeholder="Select the user(s)"
            >
            </multiselect>
            <multiselect
              :options="project_type_list"
              v-model="reqObj.project_type"
              track-by="id"
              label="label"
              placeholder="Select the project type"
            >
            </multiselect>
            <multiselect
              :options="client_list"
              v-model="reqObj.clients"
              track-by="id"
              label="name"
              :multiple="true"
              group-label="label"
              group-values="clients_data"
              :group-select="true"
              :close-on-select="false"
              placeholder="Select the client(s)"
            >
            </multiselect>
            <multiselect
              :options="organization_list"
              v-model="reqObj.organizations"
              track-by="id"
              label="name"
              :multiple="true"
              group-label="label"
              group-values="user_organization"
              :group-select="true"
              :close-on-select="false"
              placeholder="Select the organization(s)"
            >
            </multiselect>
            <multiselect
              :options="date_range_list"
              v-model="reqObj.selected_range"
              @select="getFilteredUsers"
              placeholder="Select the date range"
              track-by="label"
              label="label"
            >
            </multiselect>
            <button class="btn btn-primary" @click="applyFilters">
              Filter
            </button>
          </div>
        </div>
        <!-- Table body -->
        <div class="">
          <div class="">
            <div
              class="hstack gap-3 justify-content-end align-items-center mb-2 px-2"
            >
              <div class="me-auto">
                <div class="nav nav-underline">
                  <span
                    class="nav-item"
                    @click="optionChanger('for_all_users')"
                  >
                    <span
                      class="nav-link pb-1"
                      :class="page == 'for_all_users' ? 'active' : ''"
                      >For all users</span
                    >
                  </span>
                  <span class="nav-item" @click="optionChanger('for_tls')">
                    <span
                      class="nav-link pb-1"
                      :class="page == 'for_tls' ? 'active' : ''"
                      >For TL's</span
                    >
                  </span>
                </div>
              </div>
              <div class="text-end mb-2" v-if="rows">
                <a
                  class="link link-underline cursor-pointer link-underline-opacity-0 link-underline-opacity-100-hover"
                  @click="exportReport"
                  v-if="exported"
                  >Export</a
                >
                <div
                  v-else
                  class="spinner spinner-border text-primary"
                  style="height: 20px; width: 20px"
                ></div>
              </div>
              <div class="text-end mb-2" v-if="rows">
                Count : {{ rows.length }}
              </div>
            </div>
            <!-- FOR ALL USERS -->
            <!-- <div class="" v-if="page == 'for_all_users'"> -->
            <div v-if="rows == null">
              <div class="p-5 text-center">
                <div
                  class="spinner spinner-border text-primary"
                  style="height: 4rem; width: 4rem"
                ></div>
              </div>
            </div>
            <div class="" v-else-if="rows.length">
              <table class="table table-striped text-center text-danger">
                <thead class="table-light">
                  <tr class="">
                    <th
                      class="col-1 px-5 text-dark-emphasis position-sticky top-0 z-1"
                    >
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">Username</div>
                        <!-- <div class="d-flex flex-column justify-content-center align-items-center">
                                                <i class="fa fa-caret-up fs-6"></i>
                                                <i class="fa fa-caret-down"></i>
                                            </div> -->
                      </div>
                    </th>
                    <th
                      class="col-1 text-dark-emphasis position-sticky top-0 z-1"
                    >
                      <div class="d-flex align-items-center">
                        <div class="flex-grow-1">Client</div>
                        <!-- <div class="d-flex flex-column justify-content-center align-items-center">
                                                <i class="fa fa-caret-up"></i>
                                                <i class="fa fa-caret-down"></i>
                                            </div> -->
                      </div>
                    </th>
                    <th
                      class="col-1 text-dark-emphasis position-sticky top-0 z-1"
                    >
                      B/NB
                    </th>
                    <th
                      class="col-1 text-dark-emphasis position-sticky top-0 z-1"
                    >
                      Total
                    </th>
                    <th
                      class="position-sticky top-0 z-1"
                      v-for="(item, index) in months"
                      :key="index"
                    >
                      <span class="text-dark-emphasis"
                        >{{ item.month_year_str }} |
                      </span>
                      <span class="text-secondary">{{
                        item.working_days
                      }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    v-for="(item, index) in rows"
                    :keys="index"
                    class="align-middle"
                  >
                    <td class="text-dark-emphasis">{{ item.full_name }}</td>
                    <td class="text-dark-emphasis">{{ item.client_name }}</td>
                    <td class="text-dark-emphasis">{{ item.project_type }}</td>
                    <td class="text-dark-emphasis">
                      {{ item.grand_total_time }}
                    </td>
                    <td
                      class="text-dark-emphasis"
                      v-for="(i, idx) in item.summary"
                      :key="idx"
                    >
                      {{ i.total_time }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="p-5" v-else>
              <h4 class="text-center fw-normal">No data available</h4>
            </div>
            <!-- <div class="" v-if="page == 'for_tls'">
              <h1 class="p-5">tl report</h1>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>

<script>
import UnauthorizedPage from "./UnauthorizedPage.vue";
import user from "@/ajax/user";
import moment from "moment";
import { Multiselect } from "vue-multiselect";
export default {
  components: {
    UnauthorizedPage,
  },
  data() {
    return {
      exported: true,
      rows: null,
      months: [],
      master_user_list: [],
      user_list: [],
      client_list: [],
      organization_list: [],
      page: null,
      siteLogReq: {
        site_name: "productivity_report",
      },
      project_type_list: [
        {
          id: 1,
          label: "Billable ( B )",
          value: "B",
        },
        {
          id: 2,
          label: "Non Billable ( NB )",
          value: "NB",
        },
        {
          id: 3,
          label: "Both ( B/NB )",
          value: "Both",
        },
      ],
      sort_fields: [],
      date_range_list: [
        {
          label: "1st Apr22 to 31st Mar23",
          value: ["2022-04-01", "2023-03-31"],
        },
        {
          label: "1st Apr23 to 31st Mar24",
          value: ["2023-04-01", "2024-03-31"],
        },
        {
          label: "1st Apr24 to 31st Mar25",
          value: ["2024-04-01", "2025-03-31"],
        },
        {
          label: "1st Apr25 to 31st Mar26",
          value: ["2025-04-01", "2026-03-31"],
        },
      ],
      reqObj: {
        users: [],
        clients: [],
        organizations: [],
        selected_range: [],
        project_type: null,
      },
    };
  },
  methods: {
    optionChanger(page) {
      if (this.page != page) {
        this.page = page;
        this.reqObj.users = [];
        this.reqObj.clients = [];
        this.reqObj.organizations = [];
        this.reqObj.selected_range = this.date_range_list[1];
        this.reqObj.project_type = this.project_type_list[2];
        this.applyFilters();
      }
    },
    exportReport() {
      let reqObjCopy = structuredClone(this.reqObj);
      reqObjCopy.organizations = reqObjCopy.organizations.map(
        (item) => item.id
      );
      reqObjCopy.users = reqObjCopy.users.map((item) => item.id);
      reqObjCopy.selected_range = reqObjCopy.selected_range.value;
      reqObjCopy.clients = reqObjCopy.clients.map((item) => item.id);
      reqObjCopy.project_type = reqObjCopy.project_type?.value;
      let startYear = moment(reqObjCopy.selected_range[0]).format("YY");
      let endYear = moment(reqObjCopy.selected_range[1]).format("YY");
      this.exported = false;
      if (this.page == "for_all_users") {
        user
          .exportProductivityReport(reqObjCopy)
          .then((res) => {
            let blob = res.data;
            console.log(res);
            // console.log(blob);

            let url = window.URL.createObjectURL(new Blob([blob]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Productivity_Report_${startYear}-${endYear}.xlsx`
            );
            link.click();

            window.URL.revokeObjectURL(url);
            this.exported = true;
          })
          .catch((err) => {
            Swal.fire({
              title: "Failed to export report",
              color: "white",
              toast: true,
              icon: "error",
              iconColor: "white",
              position: "top-end",
              background: "rgb(220, 20, 60)",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
              width: "30rem",
            });
            this.exported = true;
          });
      } else {
        user
          .exportApprovedHoursReport(reqObjCopy)
          .then((res) => {
            let blob = res.data;
            console.log(res);
            // console.log(blob);

            let url = window.URL.createObjectURL(new Blob([blob]));
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Approved_Hours_Report_${startYear}-${endYear}.xlsx`
            );
            link.click();

            window.URL.revokeObjectURL(url);
            this.exported = true;
          })
          .catch((err) => {
            Swal.fire({
              title: "Failed to export report",
              color: "white",
              toast: true,
              icon: "error",
              iconColor: "white",
              position: "top-end",
              background: "rgb(220, 20, 60)",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
              width: "30rem",
            });
            this.exported = true;
          });
      }
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    getProductivityReport(reqObj) {
      this.rows = null;
      this.months.length = 0;
      user.getProductivityReport(reqObj).then((res) => {
        this.rows = res.data.data.data;
        this.months = res.data.data.headers;
      });
    },
    getApprovedHoursReport(reqObj) {
      this.rows = null;
      this.months.length = 0;
      user.getApprovedHoursReport(reqObj).then((res) => {
        this.rows = res.data.data.data;
        this.months = res.data.data.headers;
      });
    },
    getUserOrganizationDropdown() {
      user.getListOrganization().then((result) => {
        this.organization_list = [
          { label: "Select all", user_organization: result.data.data },
        ];
      });
    },
    getUsers() {
      user.getUsers().then((result) => {
        this.master_user_list = [
          { label: "Select all", users_data: result.data.data },
        ];
        this.getFilteredUsers();
      });
    },
    getClients() {
      user.getclientDropdown().then((res) => {
        this.client_list = [
          { label: "Select all", clients_data: res.data.data },
        ];
      });
    },
    getFilteredUsers() {
      let [start_date, end_date] = this.reqObj.selected_range.value;
      let filtered_user_list = this.master_user_list[0].users_data.filter(
        (item) => {
          // console.log(item.first_name, item.last_name, moment(item.doe));
          return (
            item.doe == null ||
            (item.doe != null &&
              moment(item.doe).isBetween(start_date, end_date, null, "[]"))
          );
        }
      );
      this.reqObj.users = this.reqObj.users.filter((item) => {
        // console.log(item.first_name, item.last_name, moment(item.doe));
        return (
          item.doe == null ||
          (item.doe != null &&
            moment(item.doe).isBetween(start_date, end_date, null, "[]"))
        );
      });
      this.user_list = structuredClone(this.master_user_list);
      this.user_list[0].users_data = filtered_user_list;
      // console.log(this.user_list);
    },
    applyFilters() {
      let reqObjCopy = structuredClone(this.reqObj);
      reqObjCopy.organizations = reqObjCopy.organizations.map(
        (item) => item.id
      );
      reqObjCopy.users = reqObjCopy.users.map((item) => item.id);
      reqObjCopy.selected_range = reqObjCopy.selected_range.value;
      reqObjCopy.clients = reqObjCopy.clients.map((item) => item.id);
      reqObjCopy.project_type = reqObjCopy.project_type?.value;
      if (this.page == "for_all_users") {
        this.getProductivityReport(reqObjCopy);
      }
      if (this.page == "for_tls") {
        this.getApprovedHoursReport(reqObjCopy);
      }
    },
  },
  mounted() {
    this.getUsers();
    this.getClients();
    this.getUserOrganizationDropdown();
    this.reqObj.selected_range = this.date_range_list[1];
    this.reqObj.project_type = this.project_type_list[2];
    this.optionChanger("for_all_users");
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  beforeMount() {
    this.permissions = localStorage.getItem("permissions");
  },
};
</script>

<style scoped>
.nav-link.active {
  color: #583d72;
  font-size: 14px !important;
  border-bottom: 2px solid #583d72;
  /* transition: 0.2s ease-in-out; */
}
.nav-link {
  font-size: 14px !important;
  color: #79869f;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  /* transition: 0.2s ease-in-out; */
}

.fa {
  font-size: 10px !important;
}

thead th {
  font-weight: 450 !important;
}

thead {
  font-size: 15px;
}

tbody {
  font-size: 14px;
}
</style>
