<template>
  <div class="">
    <div class="d-flex justify-content-center align-items-center p-4" v-if="!dataLoaded">
      <div class="spinner-border text-primary" style="height: 4rem; width: 4rem"></div>
    </div>
    <div v-else>
      <form @submit.prevent="verifyOpertations()">
        <div v-if="permissions.includes('projects.write')" class="">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <span class="nav-item nav-link" :class="this.git_details ? 'active' : ''" id="nav-home-tab"
                data-bs-toggle="tab" data-bs-target="#project_details_tab" role="tab" aria-controls="nav-home"
                aria-selected="true">Project details</span>
              <span class="nav-item nav-link" id="nav-profile-tab" :class="this.git_details ? '' : 'active'"
                data-bs-toggle="tab" data-bs-target="#git_settings_tab" role="tab" aria-controls="nav-profile"
                aria-selected="false">Git settings</span>
            </div>
          </nav>
          <div class="tab-content mt-4" id="nav-tabContent">
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-home-tab"
              id="project_details_tab">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="sub client" class="form-label mandatory">Sub Client</label>
                  <select v-model="reqObj.sub_client" class="form-control" required>
                    <option :value="null">Select Sub Client</option>
                    <option :key="index" v-for="(item, index) in sub_clients" :value="item.id">
                      {{ item.subclientname }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="project_name" class="form-label mandatory">Name</label>
                  <input type="text" class="form-control" id="project_name" v-model="reqObj.name" required
                    placeholder="Name of the Project" maxlength="50" />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="project description" class="form-label mandatory">Description</label>
                  <textarea id="textarea-formatter" rows="2" wrap="soft" class="form-control"
                    v-model="reqObj.description" required placeholder="Project Description" maxlength="254"></textarea>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="project starting date" class="form-label mandatory">Start date</label>
                  <datepicker type="date" placeholder="yyyy-mm-dd" @input="hidedates = reqObj.start_date"
                    v-model="reqObj.start_date" :value="dateval" :clear-button="true" :calendar-button="true"
                    :clear-button-icon="'fa fa-times'" :input-class="'border-start-0'"
                    :calendar-button-icon="'fa fa-calendar'" :bootstrap-styling="true" required :typeable="true"
                    :format="formateDate" :disabled-dates="disabledDates"></datepicker>
                  <span class="text-danger fs-12">{{
      reqObj.start_dateError
    }}</span>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="project estimated end date" class="form-label">Estimated End date</label>
                  <datepicker placeholder="yyyy-mm-dd" v-model="reqObj.end_date" :disabled-dates="{ to: hidedates }"
                    :format="'yyyy-MM-dd'" @input="reqObj.end_dateError = ''" :clear-button="true"
                    :calendar-button="true" :clear-button-icon="'fa fa-times'" :input-class="'border-start-0'"
                    :calendar-button-icon="'fa fa-calendar'" :bootstrap-styling="true" :typeable="true" required>
                  </datepicker>
                  <span class="text-danger fs-12">{{
      reqObj.end_dateError
    }}</span>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="project manager id" class="form-label mandatory">Manager's Name</label>
                  <select v-model="reqObj.managerid" class="form-control" required>
                    <option :value="null">Select manager name</option>
                    <option :key="index" v-for="(item, index) in managers" :value="item.id">
                      {{ item.first_name }} {{ item.last_name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6 mb-3">
                  <label for="project status" class="form-label mandatory">Status</label>
                  <select v-model="reqObj.project_status_id" class="form-control" required>
                    <option :value="null" selected>Select Status</option>
                    <option :key="index" v-for="(item, index) in status" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="project type" class="form-label mandatory">Type of Project</label>
                  <select class="form-control" v-model="reqObj.project_type" required>
                    <option :value="null">Select type of project</option>
                    <option value="1">Fixed</option>
                    <option value="2">Hourly</option>
                  </select>
                </div>

                <div class="input-group col-md-6 mb-3">
                  <label for="project image" class="input-group-text">Image</label>
                  <input type="file" @change="onFileUpload" class="form-control" />
                </div>

                <div
                  class="col-md-12 form-check form-check-reverse d-flex justify-content-start ps-3 align-items-center">
                  <label for="project type" class="form-check-label mandatory">Is Billable</label>
                  <input type="checkbox" class="form-check-input ms-2" v-model="reqObj.is_billable" name="isBillable"
                    id="isBillable">
                </div>
              </div>
            </div>
            <div class="tab-pane fade" role="tabpanel" aria-labelledby="nav-profile-tab" id="git_settings_tab">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="project name">Repository url</label>
                  <input type="url" class="form-control" v-model="reqObj.repo_url"
                    placeholder="Example : https://github.com/product-dev/productname" />
                  <span v-if="url_err != null" class="text-danger">{{
                    url_err
                    }}</span>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="project name">Access token</label>
                  <input type="text" class="form-control" v-model="reqObj.access_token"
                    placeholder="Example : ghp_JjdfI5D4Y6cKb9vgbBwHqlxLlAjF3S1pOiAv" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <UnauthorizedPage></UnauthorizedPage>
        </div>
        <div class="text-end mt-4">
          <button class="btn btn-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import "vue-datetime/dist/vue-datetime.css";
import Vue from "vue";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export default {
  name: "Addproject",
  mixins: [myMixin],
  props: {
    editid: { type: Number, default: 0 },
    project_id: { default: null },
  },
  data: function () {
    return {
      url_err: "",
      isSubmitOrNot: true,
      isHidden: true,
      FILE: null,
      git_details: true,
      image_type: "project",
      img_save_path: "",
      reqObj: {
        sub_client: null,
        name: null,
        start_date: new Date().toISOString().slice(0, 10),
        start_dateError: null,
        description: null,
        end_date: null,
        end_dateError: null,
        project_type: 1,
        managerid: null,
        estimated_start_time: null,
        project_status_id: null,
        image: null,
        repo_url: null,
        access_token: null,
        is_billable: true,
      },
      disabledDates: {
        // to: new Date(new Date().setDate(new Date().getDate()-1)),
      },
      hidedates: moment().subtract(1, "days").toDate(),
      dateval: "",
      sub_clients: [],
      // editid:0,
      managers: [],
      status: [],
      permissions: window.localStorage.getItem("permissions"),
      aliased: "",
      dataLoaded: false,
    };
  },
  components: {
    Datepicker,
    UnauthorizedPage,
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    disableTo(val) {
      this.reqObj.start_date.to = val;
    },
    getProjectUnAssignUsers(id) {
      user.getProjectUnAssignUsers(id).then((result) => {
        this.aliased = result.data.data;
        // console.log(result.data.data)
        // console.log(this.aliased)
      });
    },
    onFileUpload(event) {
      if (event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
        if (event.target.files[0].size < 1000000) {
          this.FILE = event.target.files[0];
          // console.log("this.FILE.type", this.FILE.type)
          // console.log(this.img_save_path)
          this.getPreAssighedUrl();
        } else {
          this.$swal(
            "Error",
            "Select an image whose size is less than 1 MB",
            "warning"
          );
          event.target.value = [];
        }
      } else {
        this.$swal(
          "Error",
          "Only jpg/jpeg and png files are allowed!",
          "warning"
        );
        event.target.value = [];
      }
    },
    getPreAssighedUrl() {
      user.getPreAssighedUrl(this.image_type).then((result) => {
        this.reqObj.image = result.data.data.object_key;
        this.img_save_path = result.data.data.url;
      });
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    getAllProjects() {
      user.getAllProjects(this.reqObj).then((result) => {
        // console.log("this is hello",result.data);
      });
    },
    getAllSubClient() {
      user.getAllSubClient(this.reqObj).then((result) => {
        if (result.data.data[0]) {
          this.sub_clients = result.data.data;
          // this.reqObj.sub_client = result.data.data[(result.data.data).length-1]['id']
        }
      });
    },
    getmanageridDropdown() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        // console.log(result);
        this.managers = result.data.data;
      });
    },
    getAllProjectStatus() {
      user.getAllProjectStatus(this.reqObj).then((result) => {
        if (result.data.data[0]) {
          this.status = result.data.data;
          // this.reqObj.project_status_id = result.data.data[0]["id"];
        }
      });
    },
    getProjectbyid(id) {
      user.getProjectbyid(id).then((result) => {
        // console.log(JSON.stringify(result.data[0]['id']));
        this.reqObj.sub_client = result.data.data[0]["sub_client_id"];
        this.reqObj.name = result.data.data[0]["project_name"];
        this.reqObj.start_date = new Date(result.data.data[0]["start_date"])
          .toISOString()
          .slice(0, 10);
        this.reqObj.description = result.data.data[0]["description"];
        if (result.data.data[0]["estimated_end_date"] !== null) {
          this.reqObj.end_date = new Date(
            result.data.data[0]["estimated_end_date"]
          )
            .toISOString()
            .slice(0, 10);
        } else {
          this.reqObj.end_date = result.data.data[0]["estimated_end_date"];
        }
        this.reqObj.project_type = result.data.data[0]["project_type"];
        this.reqObj.managerid = result.data.data[0]["project_manager_userid"];
        this.reqObj.estimated_start_time =
          result.data.data[0]["estimated_start_time"];
        this.reqObj.repo_url = result.data.data[0]["repo_url"];
        this.reqObj.access_token = result.data.data[0]["access_token"];
        this.reqObj.is_billable = result.data.data[0]["is_billable"];

        // this.reqObj.status = result.data.data[0]['status'];
        this.reqObj.project_status_id = result.data.data[0]["id"];
        this.dataLoaded = true;
      });
    },
    getMilestonedate(id) {
      user.getMilestonedate(id).then((result) => {
        if (result.data && result.data.length > 0) {
          // console.log(JSON.stringify(result.data [0]['id']));
          this.reqObj.estimated_start_time =
            result.data.data[0]["estimated_start_time"];
        }
      });
    },

    // allInputCheck(){
    //   console.log("add all inputs",this.reqObj);
    //   let check = true;

    // validation for start date
    // var regex = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/;
    // this.reqObj.start_dateError = "";
    // if(! regex.test(this.reqObj.start_date)) {
    //   check = false;
    //   this.reqObj.start_dateError ="Please enter valid date";
    // }

    // validation for end date
    //   var regex = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/;
    //   this.reqObj.end_dateError = "";
    //   if(! regex.test(this.reqObj.end_date)) {
    //     check = false;
    //     this.reqObj.end_dateError ="Please enter valid date";
    //   }
    //   return check;
    // },
    // formateDate(date) {
    //   return moment(date).format('yyyy-MM-DD');
    // },

    verifyOpertations() {
      $("#project_model").modal("hide");
      const formdata = new FormData();
      if (this.FILE) {
        formdata.append("file", this.FILE);
        axios
          .put(this.img_save_path, this.FILE, {
            headers: {
              "Content-Type": "image/png",
            },
          })
          .then((res) => {
            // console.log(res)
            if (!res.status == 200) {
              this.$swal(
                "Error",
                "image not uploaded successfully...",
                "warning"
              );
            }
          });
      }
      var count_total = 0;
      this.url_err = "";
      for (var i = 0; i < this.aliased.length; i++) {
        if (
          this.aliased[i]["alias"] != null &&
          this.aliased[i]["order"] != ""
        ) {
          count_total++;
        } else {
          this.isHidden = false;
          this.isSubmitOrNot = false;
        }
      }
      if (count_total == this.aliased.length) {
        this.isSubmitOrNot = true;
      }

      if (this.reqObj.repo_url != null) {
        if (!this.isValidHttpUrl(this.reqObj.repo_url)) {
          this.isSubmitOrNot = false;
          this.url_err = "**Please insert a valid url";
        }
      }
      // let check = true;
      // check = this.allInputCheck();
      // if(check == true){
      if (!this.project_id) {
        user
          .createProject(this.reqObj)
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "Project Added Successfully !!!",
              icon: "success",
              timer: 2500,
            });
            this.$emit("close-modal");
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Add Project !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      } else {
        // if user does not have a permission then
        user
          .updateProject(this.project_id, this.reqObj)
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "Project Updated Successfully !!!",
              icon: "success",
              timer: 2500,
            });
            this.$emit("close-modal");
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Update Project !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
        // The below logic is for the client alias when it is in project but currently it is in client alias
        // if(!this.permissions.includes('alias.write')){
        // }
        // else{
        //   console.log("alias write",this.aliased);
        //   // if user have permission then check validations of alias
        //   var count_total = 0
        //   for(var i=0;i<this.aliased.length;i++){
        //     if(this.aliased[i]['alias'] != null && this.aliased[i]['order'] != ''){
        //       count_total++
        //     }else{
        //       this.isHidden = false
        //     }
        //   }
        //   console.log("count total ",count_total);
        //   // check all alias count is same as not null alias count
        //   if(count_total == this.aliased.length){
        //     // if user have a permission then
        //     user.UpdateUsersAlias(this.aliased).then((result) => {
        //         if(result.data.code == 200){
        //             user.updateProject(this.editid, this.reqObj).then((result) => {
        //               if(result){
        //                 if(result.data.code == 200){
        //                   this.$swal('Success','Project Details updated successfully', 'success')
        //                   this.$router.push({name: 'Projects'});
        //                 }else{
        //                   this.$swal('Error','Project Details not updated', 'success')
        //                   this.$router.push({name: 'Projects'});
        //                 }
        //               }
        //             });
        //         }else if(result.data.code == 402){
        //           this.Err_index_no_of_aliased = result.data.data
        //           // console.log(result.data.data)
        //         }else{
        //           this.$swal('Error','Project Details not updated', 'success')
        //           this.$router.push({name: 'Projects'});
        //         }
        //     });
        //   }
        // }
      }
      // }
      // else{
      //   console.log("validation failed");
      // }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    // DisplayGitDetails(bool){
    //   this.git_details = bool
    // },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
  },
  watch: {
    project_id: {
      handler(nv, ov) {
        if (nv) {
          this.getProjectbyid(nv);
          this.getMilestonedate(nv);
          this.getProjectUnAssignUsers(nv);
        } else {
          this.dataLoaded = true;
        }
      },
      immediate: true,
    },
    reqObj: {
      handler(newValue, OldValue) {
        if (
          newValue.end_date < newValue.start_date &&
          newValue.end_date != ""
        ) {
          newValue.end_date = newValue.start_date;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.permissions.includes("projects.write")) {
      this.getAllProjects();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        // console.log("Update Project" + this.$route.params.id);
        this.editid = this.$route.params.id;
        this.getProjectbyid(this.$route.params.id);
        this.getMilestonedate(this.$route.params.id);
        this.getProjectUnAssignUsers(this.editid);
      }
      // $(".fl-flex-label").flexLabel();
      this.getmanageridDropdown();
      this.getAllSubClient();
      this.getAllProjectStatus();
      this.getUserPermissions();
      // this.getStatusById(this.reqObj.project_status_id);
    }
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}

.disableButton {
  opacity: 0.5;
  pointer-events: none;
}

.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#user_name_field {
  outline: none !important;
}

::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}

::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
