<template>
  <div>
    <!-- Begin Page Content -->
    <div class="" v-if="permissions.includes('quotes.list')">
      <div class="card border-0 shadow-sm my-4">
        <div class="card-body">
          <div
            class="d-flex justify-content-between align-items-center px-2 pb-3"
          >
            <h4 class="text-primary m-0">Quotes</h4>
            <button
              class="btn btn-primary btn-sm"
              @click="quoteModalHandler('add')"
              v-if="permissions.includes('quotes.create')"
            >
              Add Quote
            </button>
          </div>
          <div v-if="permissions.includes('quotes.list')" class="p-2">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :pagination-options="{
                enabled: true,
                perPageDropdown: perpage_list,
              }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" scope="props">
                <template v-if="props.column.label == 'Action'">
                  <button
                    class="btn-trash m-2"
                    @click="delete_quote(props.row.id)"
                    v-if="permissions.includes('quotes.delete')"
                  >
                    <i class="fa fa-trash fs-5"></i>
                  </button>
                </template>
              </template>
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'date'">
                  <datepicker
                    :typeable="true"
                    :format="'yyyy-MM-dd'"
                    placeholder="yyyy-mm-dd"
                    @input="
                      (e) =>
                        updateFilters(
                          column,
                          e
                            ? e.toISOString().substring(0, 10)
                            : updateFilters(column, '')
                        )
                    "
                  />
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="quoteModal"
        aria-hidden="true"
        tabindex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h3
                class="text-dark font-weight-bold mb-0"
                id="taskModalLongTitle"
                v-text="modal_title"
              ></h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <add-quotes
                v-if="modal_title == 'Add Quote'"
                @close-modal="quoteModalHandler('close')"
              ></add-quotes>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from "@/mixins/Permission";
import Datepicker from "vuejs-datepicker";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import AddQuotes from "./AddQuotes.vue";
import CustomPagination from "../../components/CustomPagination.vue";

Vue.use(VueSweetalert2);

// import VueAxios from 'vue-axios';
// Vue.use(VueAxios,axios)

export default {
  name: "Quotes",
  mixins: [myMixin],
  components: { UnauthorizedPage, Datepicker, AddQuotes, CustomPagination },
  data: function () {
    return {
      siteLogReq: {
        site_name: "quotes",
      },
      permissions: window.localStorage.getItem("permissions"),
      currentPage: "",
      isLoading: false,
      columns: [
        {
          label: "Image",
          field: "image",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Quote Image", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Text",
          field: "text",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Quote Text", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Date",
          field: "date",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Display Date", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "custom-th disable-sorting",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      perpage_list: [100, 200, 300, 400, 500],
      modal_title: null,
      quote_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Quotes");
    $("#quoteModal").on("hidden.bs.modal", this.hideModalEventHandler);
    if (this.permissions.includes("quotes.list")) {
      // this.getAllQuotes();
      // this.getAllQuotesPages();
      this.getUserPermissions();
    }
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),

    hideModalEventHandler() {
      this.modal_title = null;
      this.quote_id = null;
      $("#quoteModal").modal("hide");
    },
    quoteModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add Quote";
        $("#quoteModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Quote";
        this.quote_id = id;
        $("#quoteModal").modal("show");
      } else {
        $("#quoteModal").modal("hide");
        this.search_quotes();
      }
    },

    search_quotes() {
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("search",fields);
      user.search_quotes(fields).then((result) => {
        this.rows = [];
        this.reqObj = result.data.data;
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
    // getClientbyid(id) {
    //   user.getClientbyid(id).then((result) => {
    //     console.log(result.data);
    //   });
    // },
    delete_quote(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          user
            .delete_quote(id)
            .then((result) => {
              // this.getAllQuotes();
              this.$swal(
                "Deleted",
                "You successfully delete this quote",
                "success"
              );
              this.search_quotes();
            })
            .catch((err) => {
              if (err.response) {
                Swal.fire({
                  title: "Error",
                  text: err.response.data.detail,
                  icon: "error",
                  timer: 2500,
                });
                return;
              }
              Swal.fire({
                title: "Error",
                text: "Something went wrong !!!",
                icon: "error",
                timer: 2500,
              });
            });
        } else {
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.search_quotes();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.search_quotes();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_quotes();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      {
        field: "project_name";
        field: "slug";
        field: "first_name";
      }
      this.serverParams.page = 1;
      var filtersPr = JSON.parse(JSON.stringify(this.serverParams));
      var filters = filtersPr.columnFilters;
      // console.log("Client filters", filters);
      this.search_quotes();
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
</style>
