<template>
  <form
    onSubmit="return false;"
    data-parsley-validate="true"
    @submit.prevent="verifyOpertations()"
  >
    <div class="mb-3">
      <label for="User" class="form-label mandatory">User</label>
      <multiselect
        v-model="leave.user_id"
        :options="users"
        :multiple="!update"
        :close-on-select="update"
        :clear-on-select="true"
        :preserve-search="true"
        placeholder="Select Multiple User"
        label="first_name"
        :custom-label="custom_label"
        track-by="id"
        group-label="label"
        group-values="users_data"
        :group-select="true"
        :allow-empty="true"
      >
        <template
          slot="selection"
          slot-scope="{ values, search, isOpen }"
        ></template>
      </multiselect>
      <small
        class="text-danger"
        v-if="errorMsg.umsg"
        v-text="errorMsg.umsg"
      ></small>
    </div>
    <div class="mb-3">
      <label for="Date" class="form-label mandatory">Date</label>
      <datepicker
        placeholder="yyyy-mm-dd"
        v-model="leave.date"
        format="yyyy-MM-dd"
        :disabled-dates="state"
        :typeable="true"
        :clear-button="true"
        :calendar-button="true"
        :clear-button-icon="'fa fa-times'"
        :input-class="'border-start-0'"
        :calendar-button-icon="'fa fa-calendar'"
        :bootstrap-styling="true"
        :required="true"
      >
        <!-- <datepicker placeholder="yyyy-mm-dd" v-model="leave.date" :clear-button="true" :typeable="true" > -->
      </datepicker>
    </div>

    <div class="mb-3">
      <label for="Leave Type" class="form-label mandatory">Leave Type</label>
      <multiselect
        v-model="leave.leave_type"
        :options="leave_type"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder="Select Leave Type"
        label="name"
        track-by="id"
      >
        <template
          slot="selection"
          slot-scope="{ values, search, isOpen }"
        ></template>
      </multiselect>
      <small
        class="text-danger"
        v-if="errorMsg.ltmsg"
        v-text="errorMsg.ltmsg"
      ></small>
    </div>

    <div class="mb-3">
      <label for="Credit or Debit" class="form-label mandatory"
        >Credit / Debit</label
      >
      <multiselect
        v-model="leave.credit_debit_type"
        :options="credit_debit"
        :close-on-select="true"
        :clear-on-select="true"
        :preserve-search="true"
        placeholder="Credit or Debit"
        label="label"
        track-by="name"
        return="name"
      >
        <template
          slot="selection"
          slot-scope="{ values, search, isOpen }"
        ></template>
      </multiselect>
      <small
        class="text-danger"
        v-if="errorMsg.cdmsg"
        v-text="errorMsg.cdmsg"
      ></small>
    </div>

    <div class="mb-3">
      <label for="Credit" class="form-label mandatory">No of days</label>
      <input
        type="number"
        step="0.5"
        class="form-control"
        v-model="leave.no_of_days"
        required
        placeholder="No Of Days"
        @keypress="isNumber($event)"
        min="0"
      />
    </div>

    <div class="text-end">
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import user from "@/ajax/user";
export default {
  components: { Datepicker },
  props: ["cdObj"],
  data() {
    return {
      leave: {
        date: "",
        leave_type: "",
        user_id: [],
        credit_debit_type: "",
        no_of_days: 0,
      },
      users: [],
      no_of_days: [],
      leave_type: [],
      state: {
        to: "",
        from: "",
        // to:new Date(original_date.getFullYear(),original_date.getMonth(),3),
        // from:new Date(original_date.getFullYear(),original_date.getMonth()+1,4)
      },
      credit_debit: [
        { label: "Credit", name: "credit" },
        { label: "Debit", name: "debit" },
      ],
      errorMsg: {
        umsg: "",
        ltmsg: "",
        cdmsg: "",
      },
      permissions: window.localStorage.getItem("permissions"),
      update: false,
    };
  },
  mounted() {
    if (this.permissions.includes("leaves.write")) {
      this.LeaveTypeDropdown();
      this.getuseridDropdown();
      this.DropDownNoOfDays();
    }
  },
  methods: {
    custom_label(name) {
      // console.log("-----",name.first_name)
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    DropDownNoOfDays() {
      var min = 0,
        max = 10,
        segment = 0.5,
        list = [];

      // Loop between min and max, increment by segment
      for (var i = min; i <= max; i += segment) {
        list.push({ label: i, name: i });
      }
      this.no_of_days = list;
    },
    LeaveTypeDropdown() {
      user.getLeaveType().then((result) => {
        this.leave_type = result.data.data;
      });
    },
    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = [{ label: "Select all", users_data: result.data.data }];
      });
    },
    verifyOpertations() {
      // console.log("========== ",this.update);
      if (!this.leave.user_id.length && !this.update) {
        this.errorMsg.umsg = "** Please select user **";
        return;
      } else {
        this.errorMsg.umsg = "";
      }
      if (![this.leave.user_id].length && this.update) {
        this.errorMsg.umsg = "** Please select user **";
        return;
      } else {
        this.errorMsg.umsg = "";
      }

      if (!this.leave.leave_type) {
        this.errorMsg.ltmsg = "** Please select leave type **";
        return;
      } else {
        this.errorMsg.ltmsg = "";
      }

      if (!this.leave.credit_debit_type) {
        this.errorMsg.cdmsg = "** Please select value**";
        return;
      } else {
        this.errorMsg.cdmsg = "";
      }

      if (!this.update) {
        var users_ids = [];

        for (let i = 0; i < this.leave.user_id.length; i++) {
          if (this.leave.user_id[i].id) {
            users_ids.push(this.leave.user_id[i].id);
          } else {
            for (let j = 0; j < this.leave.user_id[i].ids.length; j++) {
              if (this.leave.user_id[i].ids[j].id) {
                users_ids.push(this.leave.user_id[i].ids[j].id);
              }
            }
          }
        }
        if (this.leave.credit_debit_type === undefined) {
          this.leave.credit_debit_type = "";
        }
        const prepared_date=new Date(this.leave.date).toISOString().substring(0,10);
        var data = {
          user_ids: users_ids,
          date: new Date(prepared_date).toISOString(),
          leave_type: this.leave.leave_type.id,
          credit_debit: this.leave.credit_debit_type.name,
          no_of_days: this.leave.no_of_days,
        };
        user
          .createCreditDebitLeaves(data)
          .then((result) => {
            if (result.data.code == 200) {
              Swal.fire({
                title: "Success",
                text: "Entry Added Successfully !!!",
                icon: "success",
                timer: 2500,
              });
              this.leave.credit_debit_type = "";
              this.leave.date = "";
              this.leave.leave_type = "";
              this.leave.user_id = [];
              this.leave.no_of_days = 0;
              this.$emit("fetch-data");
            } else {
              Swal.fire({
                title: "Error",
                text: "Something went wrong !!!",
                icon: "error",
                timer: 2500,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Add Entry !!!",
              icon: "error",
              timer: 2500,
            });
          });
        this.$emit("fetch-data");
      }
      else {
        // console.log(data);
        var data = {
          id: this.leave.id,
          user_ids: [this.leave.user_id.id],
          date: this.leave.date,
          leave_type: this.leave.leave_type.id,
          credit_debit: this.leave.credit_debit_type.name,
          no_of_days: this.leave.no_of_days,
        };
        user
          .update_credit_debit_entries(data)
          .then((result) => {
            if (result.data.code == 200) {
              Swal.fire({
                title: "Success",
                text: "Entry Updated Successfully !!!",
                icon: "success",
                timer: 2500,
              });
              this.$emit("fetch-data");
            } else {
              Swal.fire({
                title: "Error",
                text: "Something went wrong !!!",
                icon: "error",
                timer: 2500,
              });
            }
            this.$emit("fetch-data");
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Update Entry !!!",
              icon: "error",
              timer: 2500,
            });
          });
        this.$emit("close-modal");
      }
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode >= 48 && charCode <= 57) ||charCode === 46){
        return true;
      } 
      else {
        evt.preventDefault();
        return false;
      }
    }
  },
  watch: {
    cdObj: {
      handler(nv, ov) {
        if (nv) {
          this.update = true;
          this.leave.user_id = this.users[0].users_data.find(
            (item) => item.id == nv.user_id
          );
          this.leave.leave_type = this.leave_type.find(
            (item) => item.name == nv.name
          );
          this.leave.date = new Date(nv.date).toISOString()
          this.leave.no_of_days = nv.no_of_days;
          this.leave.id = nv.id;
          if (nv.credit == "1") {
            this.leave.credit_debit_type = this.credit_debit[0];
          }
          if (nv.debit == "1") {
            this.leave.credit_debit_type = this.credit_debit[1];
          }
          // console.log(this.leave_type);
          // console.log(this.leave);
        } else {
          this.leave = Object.assign(
            {},
            {
              date: "",
              leave_type: "",
              user_id: [],
              credit_debit_type: "",
              no_of_days: 0,
            }
          );
          this.update = false;
        }
      },
    },
  },
};
</script>
<style scoped>
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
