<template>
  <div>
    <div v-if="permissions.includes('siteusage.read')">
      <h4 class="container-fluid text-start text-dark mt-4">
        Site usage Report
      </h4>

      <!-- Tab -->
      <div class="px-3">
        <ul class="nav border-bottom text-center">
          <li
            class="nav-item px-2"
            @click.prevent="onTabChange('web')"
            :style="webStyle"
          >
            <a
              class="nav-link px-0 py-2 fs-6"
              style="color: inherit; cursor: pointer"
              >Web</a
            >
          </li>
          <li
            class="nav-item p-0"
            :style="mobileStyle"
            style="cursor: pointer"
            @click.prevent="onTabChange('mobile')"
          >
            <a class="nav-link px-3 py-2 fs-6" style="color: inherit">Mobile</a>
          </li>
        </ul>
      </div>
      <transition name="fade" mode="out-in">
        <!-- Web Tab -->
        <div
          v-if="selectedTab === 'web'"
          class="text-center mt-3 px-3"
          key="web_filters"
        >
          <div class="g-0">
            <div class="row d-flex flex-wrap">
              <!-- Datepicker and User Filter -->
              <div
                class="col-12 col-md-4 col-lg-3 d-flex flex-column mb-3 mb-md-0"
              >
                <datepicker
                  class="custom-datepicker"
                  :minimum-view="'month'"
                  ref="month_picker"
                  :maximum-view="'year'"
                  :format="'yyyy-MM'"
                  :disabled-dates="disabledMonths"
                  :bootstrap-styling="true"
                  v-model="webFilters.searched_date"
                  :calendar-button="true"
                  placeholder="Select Month-Year"
                  :calendar-button-icon="'fa fa-calendar'"
                  @selected="generateTeamMonthOptions"
                >
                </datepicker>
              </div>

              <div
                class="col-12 col-md-4 col-lg-3 d-flex flex-row mb-3 mb-md-0"
              >
                <multiselect
                  :options="user_list"
                  v-model="webFilters.filtered_users"
                  :multiple="false"
                  placeholder="Select a User"
                  :custom-label="custom_label"
                  track-by="id"
                  group-values="users_data"
                  :hide-selected="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  group-label="label"
                >
                </multiselect>
                <button
                  class="btn btn-light bg-white"
                  @click="clearSelection('webFilters')"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>

              <div class="col-12 col-md-2 col-lg-1">
                <button
                  class="btn btn-primary"
                  @click="fetchData()"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Tab -->
        <div
          v-if="selectedTab === 'mobile'"
          class="container-fluid text-center mt-3 px-3"
        >
          <div class="g-0">
            <div class="row d-flex flex-wrap">
              <div
                class="col-12 col-md-3 col-lg-3 d-flex flex-column mb-3 mb-md-0"
              >
                <datepicker
                  class="custom-datepicker"
                  :minimum-view="'month'"
                  ref="month_picker"
                  :maximum-view="'year'"
                  :format="'yyyy-MM'"
                  :disabled-dates="disabledMonths"
                  :bootstrap-styling="true"
                  v-model="mobileFilters.searched_date"
                  :calendar-button="true"
                  placeholder="Select Month-Year"
                  :calendar-button-icon="'fa fa-calendar'"
                  @selected="generateTeamMonthOptionsMobile"
                >
                </datepicker>
              </div>

              <div
                class="col-12 col-md-4 col-lg-3 d-flex flex-row mb-3 mb-md-0"
              >
                <multiselect
                  :options="mobile_user_list"
                  v-model="mobileFilters.filtered_users"
                  :multiple="false"
                  placeholder="Select a User"
                  :custom-label="custom_label"
                  track-by="id"
                  group-values="users_data"
                  :hide-selected="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  group-label="label"
                >
                </multiselect>
                <button
                  class="btn btn-light bg-white"
                  @click="clearSelection('mobileFilters')"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>

              <div
                class="col-12 col-md-3 col-lg-2 d-flex flex-row mb-3 mb-md-0"
              >
                <multiselect
                  v-model="mobileFilters.selected_platform"
                  :options="platformOptions"
                  :multiple="false"
                  placeholder="Select OS"
                  :clear-on-select="false"
                  :close-on-select="true"
                >
                </multiselect>
              </div>

              <div class="col-12 col-md-2 col-lg-1">
                <button
                  class="btn btn-primary"
                  @click="fetchDataMobile()"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!--SiteUsageTable  -->
      <div class="mt-4 mb-4">
        <SiteUsageTable
          :chartOptions="chartOptions"
          :tableData="details"
          :ignoreSubmodules="selectedTab == 'mobile'"
          :showError="showError"
        ></SiteUsageTable>
      </div>
    </div>

    <div v-else>
      <UnauthorizedPage></UnauthorizedPage>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import user from "@/ajax/user";
import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";
import ReadMore from "vue-read-more";
import "bootstrap-icons/font/bootstrap-icons.css";
import Datepicker from "vuejs-datepicker";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

import SiteUsageTable from "../../components/SiteUsageTable.vue";

Vue.use(ReadMore);

export default {
  components: {
    Multiselect,
    Datepicker,
    UnauthorizedPage,
    SiteUsageTable,
  },
  data() {
    return {
      platformOptions: ["IOS", "Android"],
      data: null,
      details: null,
      isLoading: true,
      error: null,
      users: [],
      master_user_list: [],
      user_list: [],
      mobile_user_list: [],
      selectedTab: null,
      webFilters: {
        filtered_users: null,
        searched_date: moment().format("YYYY-MM"),
      },
      mobileFilters: {
        filtered_users: null,
        selected_platform: null,
        searched_date: moment().format("YYYY-MM"),
      },
      chartOptions: null,
      rows: [],
      disabledMonths: {
        from: new Date(),
      },
      permissions: window.localStorage.getItem("permissions"),
      showError: false,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    filteredDetailsWithMultipleSubmodules() {
      return this.details.filter((module) => module.submodules.length > 1);
    },
    filteredDetailsWithSingleSubmodule() {
      return this.details.filter((module) => module.submodules.length <= 1);
    },
    webStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.selectedTab == "web") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    mobileStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.selectedTab == "mobile") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
  },
  watch: {},
  mounted() {
    this.getUsers();
    this.getUsersMobile();
    this.onTabChange("web");
  },

  methods: {
    onTabChange(tabName) {
      if (this.selectedTab == tabName) return;
      this.selectedTab = tabName;
      this.webFilters.filtered_users = null;
      this.mobileFilters.filtered_users = null;
      this.mobileFilters.selected_platform = null;
      this.details = null;
      this.webFilters.searched_date = new Date();
      this.mobileFilters.searched_date = new Date();

      if (tabName === "web") {
        this.fetchData();
      } else if (tabName === "mobile") {
        this.fetchDataMobile();
      }
    },

    // PieChart
    generateChartOptions() {
      let options = {
        tooltip: {},
        title: {},
        legend: { data: [] },
        series: [],
      };
      options.legend = {
        // right: "right",
        bottom: "bottom",
        top: "700",
        data: this.details
          .filter((item) => item.percentage > 0)
          .map((item) => item.label),
      };
      options.tooltip = {
        trigger: "item",
        formatter: "{b} ({d}%)",
      };
      options.title = {
        text: "Visual Representation",
        left: "center",
      };
      options.series = [
        {
          type: "pie",
          radius: "60%",
          // roseType: "radius",
          center: ["52%", "45%"],
          selectedMode: "single",
          stillShowZeroSum: false,
          emphasis: {
            // focus: "self",
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
              gapWidth: 500,
            },
          },
          avoidLabelOverlap: true,
          // minAngle: 10,
          labelLine: {
            show: true,
            length: 20,
            length2: 20,
            // minSurfaceAngle: 100,
            // smooth: true,
          },
          itemStyle: {
            borderRadius: 0,
          },
          label: {
            // formatter: "{b}\n({d}%)",
            overflow: "wrap",
            // fontSize: 10,
            // padding: [2, 5, 2, 5],
            formatter: function (params) {
              // return `{nameStyle | ${params.label}} \n {
              //   percentStyle | ${params.percent}
              // }`;
              return `${params.name}(${params.percent}%)`;
            },
            rich: {
              name: {
                width: 100, // Set a fixed width to control the wrapping
                align: "left",
                fontSize: 12,
                overflow: "auto",
              },
            },
          },
          data: this.details.map((item) => {
            return {
              label: {
                show: item.percentage > 0,
              },
              name: item.label,
              value: item.percentage,
            };
          }),
        },
      ];
      this.chartOptions = options;
    },

    // Web API
    fetchData() {
      this.showError = false;
      this.details = null;
      const payload = {
        filtered_users: this.webFilters.filtered_users
          ? [this.webFilters.filtered_users.id]
          : [],
      };
      if (this.webFilters.searched_date) {
        const startDate = moment(this.webFilters.searched_date, "YYYY-MM")
          .startOf("month")
          .format("MM/YYYY");
        const endDate = moment(this.webFilters.searched_date, "YYYY-MM")
          .endOf("month")
          .format("MM/YYYY");
        payload.date = [startDate, endDate];
      }
      user
        .getSiteModuleLogReport(payload)
        .then((response) => {
          if (response.data.code === 200) {
            this.data = response.data;
            this.details = this.data.data.details;
            if (this.details) {
              this.generateChartOptions();
            }
            this.message = response.data.message;
          } else {
            this.message = response.data.message;
            this.details = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.showError = true;
        });
    },

    // Mobile API
    fetchDataMobile() {
      this.showError = false;
      this.details = null;
      const payload = {
        filtered_users: this.mobileFilters.filtered_users
          ? [this.mobileFilters.filtered_users.id]
          : [],
        os: this.mobileFilters.selected_platform,
      };
      // console.log(this.mobileFilters.searched_date);
      if (this.mobileFilters.searched_date) {
        const startDate = moment(this.mobileFilters.searched_date, "YYYY-MM")
          .startOf("month")
          .format("MM/YYYY");
        const endDate = moment(this.mobileFilters.searched_date, "YYYY-MM")
          .endOf("month")
          .format("MM/YYYY");
        payload.date = [startDate, endDate];
      }
      // console.log("Payload:", payload);
      user
        .getMobileSiteModuleLogReport(payload)
        .then((response) => {
          this.data = response.data;
          this.details = this.data.data.details || [];
          this.generateChartOptions();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.showError = true;
        });
    },

    // Web
    getUsers() {
      user.getUsers().then((result) => {
        this.master_user_list = [
          { label: "Select all", users_data: result.data.data },
        ];
        this.getFilteredUsers();
      });
    },
    getFilteredUsers() {
      // console.log("fu called..");
      let end_of_month = moment(this.webFilters.searched_date)
        .endOf("month")
        .format("YYYY-MM-DD");
      let end_date = moment(end_of_month);
      let filtered_user_list = this.master_user_list[0].users_data.filter(
        (item) => {
          return (
            item.doj != null &&
            moment(item.doj, "YYYY-MM-DD").isSameOrBefore(end_date) &&
            (item.doe == null ||
              moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date))
          );
        }
      );
      if (this.webFilters.filtered_users != null) {
        [this.webFilters.filtered_users] = [
          this.webFilters.filtered_users,
        ].filter((item) => {
          return (
            item.doj != null &&
            moment(item.doj, "YYYY-MM-DD").isSameOrBefore(end_date) &&
            (item.doe == null ||
              moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date))
          );
        });
      }
      this.user_list = structuredClone(this.master_user_list);
      this.user_list[0].users_data = filtered_user_list;
    },

    generateTeamMonthOptions(val) {
      if (val != null) {
        this.webFilters.searched_date = val;
      }
      this.getFilteredUsers();
    },

    // Mobile
    getUsersMobile() {
      user.getUsers().then((result) => {
        this.master_user_list = [
          { label: "Select all", users_data: result.data.data },
        ];
        this.getFilteredUsersMobile();
      });
    },
    getFilteredUsersMobile() {
      let end_of_month = moment(this.mobileFilters.searched_date)
        .endOf("month")
        .format("YYYY-MM-DD");
      let end_date = moment(end_of_month);
      let filtered_user_list = this.master_user_list[0].users_data.filter(
        (item) => {
          return (
            item.doj != null &&
            moment(item.doj, "YYYY-MM-DD").isSameOrBefore(end_date) &&
            (item.doe == null ||
              moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date))
          );
        }
      );
      if (this.mobileFilters.filtered_users != null) {
        [this.mobileFilters.filtered_users] = [
          this.mobileFilters.filtered_users,
        ].filter((item) => {
          return (
            item.doj != null &&
            moment(item.doj, "YYYY-MM-DD").isSameOrBefore(end_date) &&
            (item.doe == null ||
              moment(item.doe, "YYYY-MM-DD").isSameOrAfter(end_date))
          );
        });
      }
      this.mobile_user_list = structuredClone(this.master_user_list);
      this.mobile_user_list[0].users_data = filtered_user_list;
    },

    generateTeamMonthOptionsMobile(val) {
      if (val != null) {
        this.mobileFilters.searched_date = val;
      }
      this.getFilteredUsersMobile();
    },

    custom_label(name) {
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },

    clearSelection() {
      if (this.selectedTab === "web") {
        this.webFilters.filtered_users = null;
        this.fetchData();
      } else if (this.selectedTab === "mobile") {
        this.mobileFilters.filtered_users = null;
        this.fetchDataMobile();
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-action {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}

::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 70%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
