<template>
  <!-- Begin Page Content -->
  <div class="">
    <div
      class="card my-4 border-0 shadow-sm"
      v-if="permissions.includes('clients.read')"
    >
      <div class="card-body">
        <div
          class="d-flex gap-md-0 gap-2 justify-content-between flex-wrap align-items-center px-2 pb-3"
        >
          <h4 class="text-primary m-0 order-md-0 order-1">Clients</h4>
          <div
            class="order-3 mx-md-0 mx-auto order-md-0 mt-md-0 mt-2"
            v-if="
              permissions.includes('clients.read') &&
              permissions.includes('sub_clients.read')
            "
          >
            <div class="btn-group" role="group">
              <router-link
                :to="{ name: 'Clients' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'Clients'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
              >
                Clients
              </router-link>
              <router-link
                :to="{ name: 'SubClients' }"
                class="btn btn-sm"
                :class="
                  this.$route.name == 'SubClients'
                    ? 'btn-primary'
                    : 'btn-outline-primary bg-white text-primary'
                "
              >
                SubClients
              </router-link>
            </div>
          </div>
          <button
            class="btn btn-primary btn-sm order-2 order-md-0"
            v-if="
              permissions.includes('clients.write') &&
              permissions.includes('clients.add')
            "
            @click="clientModalHandler('add')"
          >
            Add Client
          </button>
          <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#add_project" class="btn btn-primary">Add client </button> -->
          <!-- <router-link
            v-if="
              permissions.includes('clients.write') &&
              permissions.includes('clients.add')
            "
            class="btn btn-primary"
            :to="{ name: 'AddClient' }"
          >
            Add Client
          </router-link> -->
        </div>

        <div v-if="permissions.includes('clients.read')" class="p-2">
          <div class="table-responsive">
            <vue-good-table
              styleClass="vgt-table no-border"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="FilteredRecords"
              :isLoading.sync="isLoading"
              :pagination-options="{ enabled: true }"
              :rows="rows"
              :columns="columns"
            >
              <template slot="table-row" scope="props">
                <template v-if="props.column.label == 'Action'">
                  <template v-if="permissions.includes('clients.write')">
                    <div
                      class="d-flex justify-content-center align-items-center gap-3 p-2"
                    >
                      <button class="btn-view">
                        <i
                          @click="AssignUser(props.row.id)"
                          class="opt-users fs-5"
                          data-toggle="modal"
                          data-target="#assign-client-users"
                        ></i>
                      </button>
                      <button
                        class="btn-pencil"
                        @click="clientModalHandler('update', props.row.id)"
                      >
                        <i class="fa fa-pencil fs-5"></i>
                      </button>
                      <button
                        class="btn-trash"
                        v-if="permissions.includes('clients.delete')"
                        @click="deleteClient(props.row.id)"
                      >
                        <i class="fa fa-trash fs-5"></i>
                      </button>
                    </div>
                  </template>
                  <!-- <span class="bg-light"><br><small style="font-size:10px">{{CreatedAndUpdatedAt(props.row.created_at,props.row.updated_at)}}</small></span> -->
                </template>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <CustomPagination
                  class="p-2"
                  :pageTabs="4"
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                  :serverParams="serverParams"
                ></CustomPagination>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!permissions.includes('clients.read')">
      <UnauthorizedPage></UnauthorizedPage>
    </div>
    <!-- Client add/update modal -->
    <div class="modal fade" id="clientModal" aria-hidden="true" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3
              class="modal-title text-dark font-weight-bold pl-3"
              id="taskModalLongTitle"
              style="font-size: 26px"
              v-text="modal_title"
            ></h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="overflow-y: auto">
            <add-client
              @close-modal="clientModalHandler('close')"
              v-if="modal_title == 'Update Client'"
              :client_id="client_id"
            ></add-client>
            <add-client
              @close-modal="clientModalHandler('close')"
              v-if="modal_title == 'Add Client'"
            ></add-client>
          </div>
        </div>
      </div>
    </div>
    <!-- model for assign users to client : START -->
    <div
      class="modal fade"
      id="assign-client-users"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="text-dark m-0" id="exampleModalLabel">
              Assign Users To Client
            </h3>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row g-0">
              <div class="col-12 w-100">
                <div class="">
                  <multiselect
                    class="project_multiselect"
                    v-model="assign.users"
                    :hide-selected="true"
                    :options="users"
                    :multiple="true"
                    :custom-label="custom_label"
                    placeholder="Select Multiple User"
                    label="first_name"
                    track-by="id"
                    group-label="label"
                    group-values="user_data"
                    :group-select="true"
                    @select="multiEvent($event, 'add')"
                    @remove="multiEvent($event, 'remove')"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    ></template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div
              class="row bg-light my-2 mx-1 text-center shadow-sm p-2"
              v-if="assign.users.length > 0"
            >
              <div class="col-3">
                <b>Username</b>
              </div>
              <div class="col-6">
                <b>Alias</b>
              </div>
              <div class="col-3">
                <b>Order</b>
              </div>
            </div>
            <div class="row">
              <draggable
                :list="selected_users"
                @change="onDrag($event)"
                group="list"
                v-if="selected_users.length"
              >
                <div
                  class="row mx-1 my-2"
                  v-for="(user, index) in selected_users"
                  :key="index"
                >
                  <div class="col-3 mt-2">
                    <p class="">
                      <b>{{ index + 1 }} . </b> {{ custom_label(user) }}
                    </p>
                  </div>
                  <div class="col-6 pl-4 pr-4">
                    <input
                      type="text"
                      v-model="user.alias"
                      class="form-control"
                    />
                  </div>
                  <div class="col-3">
                    <input
                      type="number"
                      :value="orderMaker(user, index)"
                      @input="orderMaker(user, Number($event.target.value))"
                      ref="order"
                      class="form-control"
                    />
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="AssignUsersToClient()"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- model for assign users to client : END -->
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import AddClient from "./AddClient.vue";
import { mapState, mapActions } from "vuex";
import Vue from "vue";
import axios from "axios";
import myMixin from "@/mixins/Permission";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import CustomPagination from "../../components/CustomPagination.vue";
import draggable from "vuedraggable";

Vue.use(VueSweetalert2);

export default {
  name: "Clients",
  mixins: [myMixin],
  components: { UnauthorizedPage, draggable, AddClient, CustomPagination },
  data: function () {
    return {
      currentPage: "",
      isLoading: false,
      siteLogReq: {
        site_name: "clients",
      },
      columns: [
        {
          label: "Name",
          field: "name",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Client name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Manager",
          field: "first_name",
          type: "value",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Manager name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Slug",
          field: "slug",
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "slug", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          thClass: "disable-sorting custom-th",
          tdClass: "custom-td text-nowrap",
        },
      ],
      rows: [],
      totalRecords: 0,
      FilteredRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },
      permissions: window.localStorage.getItem("permissions"),
      // for assign users
      assign: {
        users: [],
        project_id: 0,
      },
      users: [],
      UserState: "",
      checkval: [],
      selected_users: [],
      modal_title: null,
      client_id: null,
    };
  },
  mounted() {
    this.setCurrentPage("Clients");
    // this.getAllClient();
    $("#clientModal").on("hidden.bs.modal", this.hideModalEventHandler);
    this.getUserPermissions();
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    hideModalEventHandler() {
      this.modal_title = null;
      this.client_id = null;
      $("#clientModal").modal("hide");
    },
    clientModalHandler(action, id) {
      if (action == "add") {
        this.modal_title = "Add Client";
        $("#clientModal").modal("show");
      } else if (action == "update") {
        this.modal_title = "Update Client";
        this.client_id = id;
        $("#clientModal").modal("show");
      } else {
        $("#clientModal").modal("hide");
        this.search_client();
      }
    },
    orderMaker(user, order) {
      user["alias"] = user["alias"];
      user["order"] = order + 1;
      return order + 1;
    },
    // getAllClient() {
    //   user.getAllClient().then((result) => {
    //     this.rows = [];
    //     this.rows = result.data.data.data;
    //     this.totalRecords = result.data.data.total;
    //   });
    // },
    multiEvent(event, action) {
      if (action == "add") {
        let obj = Object.assign({}, event);
        obj.alias = obj.first_name;
        this.selected_users.push(obj);
      } else {
        this.selected_users = this.selected_users.filter(
          (item) => item.id != event.id
        );
      }
    },
    onDrag(event) {
      this.assign.users.forEach((item) => {
        if (item.id == event.moved.element.id) {
          // console.log(item.order,event.element);
          item.order = event.moved.newIndex + 1;
        }
      });
    },
    custom_label(name) {
      // console.log(name.first_name,name.middle_name,name.last_name);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    GetAllValidUsersList() {
      user.GetAllValidUsersList().then((result) => {
        this.users = [{ label: "Select all", user_data: result.data.data }];
        // console.log(this.users);
      });
    },
    getClientUnAssignUsers(id) {
      user.getClientUnAssignUsers(id).then((result) => {
        this.assign.users = result.data.data;
        this.checkval = this.assign.users;
        this.selected_users = [...this.assign.users];
      });
    },

    AssignUser(id) {
      $("#assign-client-users").modal("show");
      // this.$root.$emit('bv::show::modal', 'assign-client-users')
      this.assign.client_id = id;
      this.assign.users = [];
      this.GetAllValidUsersList();
      this.getClientUnAssignUsers(id);
    },

    AssignUsersToClient() {
      var dataObj = {};
      dataObj.users = this.selected_users.map((item) => {
        return { id: item.id, alias: item.alias, order: item.order };
      });
      dataObj.client_id = this.assign.client_id;
      user.SetAssignedUsersToClient(dataObj).then((result) => {
        if (result.data.code == 200) {
          $("#assign-client-users").modal("hide");
          this.$swal(
            "Success",
            "User assigned successfully to client...",
            "success"
          );
        } else if (result.data.code == 401) {
          this.$swal("Error", "User Aliased Already exist...", "danger");
        }
      });
    },

    search_client() {
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      user.search_client(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.FilteredRecords = result.data.data.total;
      });
    },
    deleteClient(id) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#32a852",
        cancelButtonColor: "#f00",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          user.deleteClient(id).then((result) => {
            if (result.data.code == 424) {
              this.$swal(result.data.data, result.data.message, "warning");
            }
            if (result.data.code == 200) {
              this.$swal(result.data.data, result.data.message, "success");
            }
            this.search_client();
          });
        }
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.search_client();
    },

    onPerPageChange(params) {
      this.updateParams({ perpage: params.currentPerPage });
      this.serverParams.page = 1;
      this.search_client();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_client();
    },

    onColumnFilter(params) {
      //remove empty fields
      var newObj = { columnFilters: {} };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.search_client();
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>
