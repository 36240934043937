<template>
  <div class="">
    <div
      class="d-flex justify-content-center align-items-center p-4"
      v-if="!dataLoaded"
    >
      <div
        class="spinner-border text-primary"
        style="height: 4rem; width: 4rem"
      ></div>
    </div>
    <div v-else>
      <form @submit.prevent="validator()">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="project" class="form-label mandatory">Project</label>
            <select
              v-model="reqObj.project_id"
              id="project"
              class="form-control"
              @click="onProjectSelected(reqObj.project_id)"
              required
              oninvalid="this.setCustomValidity('Please select name of the project')"
              oninput="this.setCustomValidity('')"
            >
              <option value="">Select Project Name</option>
              <option
                :key="index"
                v-for="(item, index) in projectsname"
                :value="item.id"
              >
                {{ item.project_name }}
              </option>
            </select>
          </div>

          <div class="col-md-6 mb-3">
            <label for="milestones_name" class="form-label mandatory"
              >Name</label
            >
            <input
              type="text"
              class="form-control"
              id="milestones_name"
              v-model="reqObj.name"
              required
              placeholder="Please enter name of the Milestone"
              oninvalid="this.setCustomValidity('Please enter name of the milestone')"
              oninput="this.setCustomValidity('')"
            />
          </div>

          <div class="col-md-6 mb-3">
            <label for="Estimated end Date" class="form-label mandatory"
              >Estimated Start Time</label
            >
            <datepicker
              placeholder="yyyy-mm-dd"
              :disabled-dates="disabledDates"
              :value="reqObj.estimated_start_time"
              @input="reqObj.estimated_start_time = reqObj.estimated_start_time"
              :format="formateDate"
              v-model="reqObj.estimated_start_time"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0'"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
            >
            </datepicker>
            <small class="text-danger" v-text="errorMsg.estart_msg"></small>
          </div>

          <div class="col-md-6 mb-3">
            <label class="form-label mandatory">Estimated End Time</label>
            <!-- input-class="form-control" -->
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="reqObj.estimated_end_time"
              :disabled-dates="{ to: reqObj.estimated_start_time }"
              @input="reqObj.estimated_end_time = reqObj.estimated_end_time"
              :format="formateDate"
              :clear-button="true"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
            ></datepicker>
            <small class="text-danger" v-text="errorMsg.eend_msg"></small>
          </div>

          <div class="col-md-6 mb-3">
            <label class="form-label mandatory">Actual Start Time</label>
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="reqObj.actual_start_time"
              :disabled-dates="{ to: reqObj.estimated_start_time }"
              :clear-button="true"
              :format="formateDate"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
            ></datepicker>
            <small class="text-danger" v-text="errorMsg.astart_msg"></small>
          </div>

          <div class="col-md-6 mb-3">
            <label class="form-label mandatory">Actual End Time</label>
            <datepicker
              placeholder="yyyy-mm-dd"
              v-model="reqObj.actual_end_time"
              :disabled-dates="{ to: reqObj.actual_start_time }"
              :clear-button="true"
              :format="formateDate"
              :calendar-button="true"
              :clear-button-icon="'fa fa-times'"
              :input-class="'border-start-0  '"
              :calendar-button-icon="'fa fa-calendar'"
              :bootstrap-styling="true"
              :typeable="true"
              required
            ></datepicker>
            <small class="text-danger" v-text="errorMsg.aend_msg"></small>
          </div>

          <div class="col-md-12 mb-3">
            <label for="milestones_name" class="form-label mandatory"
              >Details</label
            >
            <textarea
              id="milestones_name"
              rows="2"
              class="form-control"
              v-model="reqObj.details"
              required
              placeholder="Details of the Milestone"
              oninvalid="this.setCustomValidity('Please enter details of the milestone')"
              oninput="this.setCustomValidity('')"
            ></textarea>
          </div>
        </div>
        <div class="text-end mt-4">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import axios from "@/ajax/axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import { mapState, mapActions } from "vuex";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

export default {
  name: "AddMilestones",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
  },
  props: ["milestone_id"],
  data: function () {
    return {
      reqObj: {
        project_id: "",
        name: "",
        estimated_start_time: "",
        estimated_end_time: "",
        actual_start_time: "",
        actual_end_time: "",
        details: "",
        start_date: "",
      },
      errorMsg: {
        estart_msg: "",
        eend_msg: "",
        astart_msg: "",
        aend_msg: "",
      },
      projectsname: [],
      editid: 0,
      permissions: window.localStorage.getItem("permissions"),
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      },
      dataLoaded: false,
    };
  },
  mounted() {
    if (this.permissions.includes("milestones.write")) {
      this.getprojectDropdown();
      this.getUserPermissions();
    }
  },

  methods: {
    getprojectDropdown() {
      user.getAllProjects(this.reqObj).then((result) => {
        this.projectsname = result.data.data;
        // if(result.data.data[0]){
        //   this.reqObj.project_id = result.data.data[(result.data.data).length-1]['project_id']
        // }
      });
    },
    // getAllMilestones() {
    //     user.getAllMilestones(this.reqObj).then((result) => {
    //     // console.log(result.data);
    //    });
    // },
    getMilestonebyid(id) {
      user.getMilestonebyid(id).then((result) => {
        // console.log(result.data.data[0]['id']);
        this.reqObj.name = result.data.data[0]["name"];
        this.reqObj.estimated_start_time = new Date(
          result.data.data[0]["estimated_start_time"]
        );
        this.reqObj.estimated_end_time = new Date(
          result.data.data[0]["estimated_end_time"]
        );
        this.reqObj.actual_start_time = new Date(
          result.data.data[0]["actual_start_time"]
        );
        this.reqObj.actual_end_time = new Date(
          result.data.data[0]["actual_end_time"]
        );
        this.reqObj.details = result.data.data[0]["details"];
        this.reqObj.project_id = result.data.data[0]["project_id"];
        this.dataLoaded = true;
      });
    },
    validator() {
      if (!this.reqObj.estimated_start_time) {
        this.errorMsg.estart_msg = "** Please enter date **";
        return;
      } else {
        this.errorMsg.estart_msg = "";
      }
      if (!this.reqObj.estimated_end_time) {
        this.errorMsg.eend_msg = "** Please enter date **";
        return;
      } else {
        this.errorMsg.eend_msg = "";
      }
      if (!this.reqObj.actual_start_time) {
        this.errorMsg.astart_msg = "** Please enter date **";
        return;
      } else {
        this.errorMsg.astart_msg = "";
      }
      if (!this.reqObj.actual_end_time) {
        this.errorMsg.aend_msg = "** Please enter date **";
        return;
      } else {
        this.errorMsg.aend_msg = "";
      }
      this.MilestonesOpertations();
    },
    MilestonesOpertations() {
      if (!this.milestone_id) {
        var field = {
          project_id: this.reqObj.project_id,
          name: this.reqObj.name,
          estimated_start_time: this.reqObj.estimated_start_time,
          estimated_end_time: this.reqObj.estimated_end_time,
          actual_start_time: this.reqObj.actual_start_time,
          actual_end_time: this.reqObj.actual_end_time,
          details: this.reqObj.details,
        };
        user
          .createMilestones(field)
          .then((result) => {
            // console.log(result.data);
            if (result.data.code == 200) {
              Swal.fire({
                title: "Success",
                text: "Miestone Added Successfully !!!",
                icon: "success",
                timer: 2500,
              });
              this.$emit("close-modal");
            } else {
              Swal.fire({
                title: "Error",
                text: "Failed to Add Milestone !!!",
                icon: "error",
                timer: 2500,
              });
              this.$emit("close-modal");
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Something went wrong !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      } else {
        var field = {
          project_id: this.reqObj.project_id,
          name: this.reqObj.name,
          estimated_start_time: this.reqObj.estimated_start_time,
          estimated_end_time: this.reqObj.estimated_end_time,
          actual_start_time: this.reqObj.actual_start_time,
          actual_end_time: this.reqObj.actual_end_time,
          details: this.reqObj.details,
        };
        user
          .updateMilestone(this.milestone_id, field)
          .then((result) => {
            if (result.data.code == 200) {
              Swal.fire({
                title: "Success",
                text: "Milestone Updated Successfully !!!",
                icon: "success",
                timer: 2500,
              });
              this.$emit("close-modal");
            } else {
              Swal.fire({
                title: "Error",
                text: "Something went wrong !!!",
                icon: "error",
                timer: 2500,
              });
              this.$emit("close-modal");
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Add Milestone !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    onProjectSelected(item) {
      // console.log(item);
      for (let index = 0; index < this.projectsname.length; index++) {
        if (this.projectsname[index].id == item) {
          this.reqObj.start_date = this.projectsname[index].start_date;
        }
      }
    },
  },
  watch: {
    reqObj: {
      handler(newValue, OldValue) {
        if (
          newValue.estimated_end_time < newValue.estimated_start_time &&
          newValue.estimated_end_time != ""
        ) {
          newValue.estimated_end_time = newValue.estimated_start_time;
        }
        if (
          newValue.actual_end_time < newValue.actual_start_time &&
          newValue.actual_end_time != ""
        ) {
          newValue.actual_end_time = newValue.actual_start_time;
        }
      },
      deep: true,
    },
    milestone_id: {
      handler(nv, ov) {
        if (nv) {
          this.getMilestonebyid(nv);
        } else {
          this.dataLoaded = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
::v-deep .vdp-datepicker__calendar-button .input-group-text {
  height: 100%;
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-right: 0px;
  border-bottom-right-radius: 0;
}
::v-deep .vdp-datepicker__clear-button .input-group-text {
  height: 100%;
  background-color: white;
  border-top-left-radius: 0;
  border-left: 0px;
  border-bottom-left-radius: 0;
  border-color: #f0f0f0;
}
</style>
