var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.permissions.includes('users.enable'))?_c('div',{staticClass:"card border-0 shadow-sm my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex flex-md-row flex-column align-items-center justify-content-between gap-2 px-2 pb-3"},[_c('h4',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v("Users")]),(
            _vm.permissions.includes('users.read') &&
            _vm.permissions.includes('users.enable')
          )?_c('div',{staticClass:"btn-group",staticStyle:{"width":"15rem"},attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-sm",class:this.$route.name == 'Users'
                ? 'btn-primary'
                : 'btn-outline-primary bg-white text-primary',attrs:{"to":{ name: 'Users' }}},[_vm._v(" Active ")]),_c('router-link',{staticClass:"btn btn-sm",class:this.$route.name == 'DeletedUsers'
                ? 'btn-primary'
                : 'btn-outline-primary bg-white text-primary',attrs:{"to":{ name: 'DeletedUsers' }}},[_vm._v(" Inactive ")])],1):_vm._e(),_c('div',{staticClass:"px-5"},[_vm._v(" ")])]),(_vm.permissions.includes('users.enable'))?_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"pagination-options":{
              enabled: true,
              perPageDropdown: _vm.perpage_list,
            },"rows":_vm.rows,"columns":_vm.columns,"styleClass":"vgt-table"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"column-filter",fn:function({ column, updateFilters }){return [(column.label == 'Date Of Joining')?_c('span',[_c('datepicker',{attrs:{"clear-button":true,"clear-button-icon":'fa fa-times',"placeholder":"yyyy-mm-dd","format":"yyyy-MM-dd"},on:{"input":(e) =>
                      updateFilters(
                        column,
                        e
                          ? e.toISOString().substring(0, 10)
                          : updateFilters(column, '')
                      )}})],1):(column.label == 'Slot')?_c('span',[_c('input',{staticClass:"form-control rounded-1",staticStyle:{"height":"25.47px","font-size":"14px"},attrs:{"type":"time"},on:{"input":(e) => updateFilters(column, e.target.value)}})]):_vm._e()]}},{key:"table-row",fn:function(props){return [(props.column.label == 'Action')?_c('span',[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-3 p-2"},[(_vm.permissions.includes('users.read'))?_c('router-link',{staticClass:"btn btn-view",attrs:{"to":'/prof/' + props.row.id,"tag":"button"}},[_c('i',{staticClass:"fa fa-eye fs-5"})]):_vm._e(),(_vm.permissions.includes('users.enable'))?_c('button',{staticClass:"btn btn-add",on:{"click":function($event){return _vm.make_user_active(props.row.id)}}},[_c('i',{staticClass:"fa fa-user fs-5"})]):_vm._e()],1)]):_vm._e()]}}],null,false,1624227743)})],1)]):_vm._e()])]):_vm._e(),(!_vm.permissions.includes('users.enable'))?_c('div',[_c('UnauthorizedPage')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }