<template>
  <div>
    <div class="pt-3">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'LeavesApplication' }" tag="a">
            Leaves
          </router-link>
        </li>
        <li class="breadcrumb-item active">Apply Leave</li>
      </ol>
    </div>
    <div
      class="card border-0 shadow-sm"
      v-show="permissions.includes('leaves.write')"
    >
      <div class="card-body">
        <div
          class="d-flex align-items-center justify-content-between px-2 pb-3"
        >
          <div v-if="current_is === 'UpdateLeaveApplication'">
            <h4 class="mb-0 text-primary font-weight-bold">
              Update Leave Application
            </h4>
          </div>
          <div v-else>
            <h4 class="mb-0 text-primary font-weight-bold">
              Leave Application
            </h4>
          </div>
        </div>
        <div class="p-2">
          <form
            data-parsley-validate="true"
            @submit.prevent="verifyOpertations()"
          >
            <div class="mb-3">
              <label for="User" class="mandatory form-label fs-6">User</label>
              <!-- :value="leave.user_id" -->
              <multiselect
                v-model="leave.user_id"
                :options="users"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select Multiple User"
                label="first_name"
                :custom-label="custom_label"
                track-by="id"
                group-label="label"
                :disabled="disabled"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                ></template>
              </multiselect>
            </div>

            <div class="mb-3">
              <label for="Leave Type" class="mandatory form-label">Type</label>
              <select
                class="form-control"
                v-model="leave.types"
                :state="null"
                placeholder="Full \ Half"
                oninput="this.setCustomValidity('')"
                @change="ClearValue()"
                required
                :disabled="disabled"
              >
                <option value>Select Leave Type</option>
                <option
                  :key="index"
                  v-for="(item, index) in types"
                  :value="item.value"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>

            <!-- If type Half -->
            <div v-if="leave.types == 'half'">
              <div class="mb-3">
                <label for="Half" class="form-label">Half</label>
                <select
                  v-model="leave.half_day_for"
                  class="form-select"
                  placeholder="1st / 2nd half"
                  oninvalid="this.setCustomValidity('Please select type')"
                  oninput="this.setCustomValidity('')"
                  required
                  :disabled="disabled"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in half_day_for"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <!-- Date -->
                <label for="From Date" class="mandatory form-label">Date</label>
                <datepicker
                  placeholder="yyyy-mm-dd"
                  v-model="leave.from_date"
                  :clear-button="true"
                  :format="formateDate"
                  @input="set_date()"
                  :disabled="disabled"
                  :disabled-dates="state"
                  :required="true"
                  :bootstrap-styling="true"
                  class="w-100"
                ></datepicker>
                <!-- <datepicker placeholder="yyyy-mm-dd" v-model="leave.from_date" :clear-button="true" :format="formateDate" required></datepicker> -->
              </div>
            </div>

            <!-- If type Full -->
            <div v-if="leave.types == 'full'">
              <div class="mb-3">
                <label for="no of days" class="form-label">No of Days</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="leave.no_of_days"
                  placeholder="Days"
                  oninput="this.setCustomValidity('')"
                  disabled
                />
              </div>
              <div
                class="d-flex align-items-center justfy-content-center gap-2 mb-3"
              >
                <!-- From Date -->
                <div class="w-100">
                  <label for="From Date" class="mandatory form-label"
                    >From Date</label
                  >
                  <datepicker
                    placeholder="yyyy-mm-dd"
                    v-model="leave.from_date"
                    :clear-button="true"
                    :disabled-dates="state"
                    :format="formateDate"
                    @input="set_date()"
                    :required="true"
                    :disabled="disabled"
                    :bootstrap-styling="true"
                    class="w-100"
                  ></datepicker>
                </div>

                <!-- To Date -->
                <div class="w-100">
                  <label for="To Date" class="mandatory form-label"
                    >To Date</label
                  >
                  <datepicker
                    placeholder="yyyy-mm-dd"
                    v-model="leave.to_date"
                    :clear-button="true"
                    :disabled-dates="state1"
                    :format="formateDate"
                    @closed="days_count()"
                    :required="true"
                    :disabled="disabled"
                    :bootstrap-styling="true"
                    class="w-100"
                  >
                  </datepicker>
                </div>
              </div>
            </div>
            <div>
              <label for="Leave Reason" class="mandatory form-label"
                >Reason</label
              >
              <textarea
                id="textarea-formatter"
                class="form-control"
                v-model="leave.reason"
                :state="null"
                maxlength="1000"
                required
                placeholder="Enter Reason"
                oninvalid="this.setCustomValidity('Please enter Reason')"
                oninput="this.setCustomValidity('')"
                :disabled="disabled"
              >
              </textarea>
            </div>
            <div class="mx-0 px-0 text-end mt-1 mb-2">
              {{ leave.reason.length }}/1000
            </div>
            <div class="text-right">
              <button
                type="submit"
                :disabled="disabled"
                :class="disabled ? 'disabled' : ''"
                class="btn btn-primary btn-lg"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import moment, { relativeTimeThreshold } from "moment";
import { mapState, mapActions } from "vuex";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

const original_date = new Date();

export default {
  name: "AddLeave",
  components: { Multiselect, Datepicker },

  data() {
    return {
      disabled: false,
      leave: {
        reason: "",
        from_date: "",
        to_date: "",
        no_of_days: 0.0,
        user_id: "",
        types: "",
        half_day_for: "",
        approved_by: 0,
        reject_by: 0,
        stage: "",
      },
      user_id: [],
      users: [],
      leave_no_of_days: "",
      dropdowndays: [],
      current_is: "",
      leavetype: [],

      types: {
        0: { name: "Full", value: "full" },
        1: { name: "Half", value: "half" },
      },

      state: {
        to: new Date(
          moment().subtract(1, "month").startOf("month").format("MM-DD-YYYY")
        ),
      },
      state1: {
        to: new Date(
          moment().subtract(1, "month").startOf("month").format("MM-DD-YYYY")
        ),
      },
      half_day_for: {
        0: { name: "1st Half" },
        1: { name: "2nd Half" },
      },
      editid: 0,
      permissions: window.localStorage.getItem("permissions"),
    };
  },

  mounted() {
    // console.log(new Date(moment().subtract('month',1).startOf('month').format("MM-DD-YYYY")));
    if (this.permissions.includes("leaves.write")) {
      this.LeaveTypeDropdown();
      this.getuseridDropdown();
      this.getCurrentUser();
      if (
        typeof this.$route.params.id != "undefined" &&
        this.$route.params.id != 0
      ) {
        this.editid = this.$route.params.id;
        this.getLeavesById(this.$route.params.id);
      }
      if (this.$router.history.current.name) {
        this.current_is = this.$router.history.current.name;
      }
      // this.DropDownNoOfDays();
    }
  },
  watch: {
    "leave.to_date": function () {
      // console.log("-----to_date")
      if (
        moment(this.leave.from_date).format("YYYY-MM-DD") >
        moment(this.leave.to_date).format("YYYY-MM-DD")
      ) {
        this.leave.to_date = this.leave.from_date;
      }
      this.days_count();
    },
    "leave.from_date": function () {
      // console.log("-----from_date")
      if (this.leave.types == "half") {
        // console.log("----- half ",this.leave.to_date,this.leave.from_date)

        if (
          moment(this.leave.from_date).format("YYYY-MM-DD") >
          moment(this.leave.to_date).format("YYYY-MM-DD")
        ) {
          // console.log("----- if > ",this.leave.to_date,this.leave.from_date)
          this.leave.to_date = this.leave.from_date;
        }

        if (
          moment(this.leave.from_date).format("YYYY-MM-DD") <
          moment(this.leave.to_date).format("YYYY-MM-DD")
        ) {
          // console.log("----- if < ",this.leave.to_date,this.leave.from_date)
          this.leave.to_date = this.leave.from_date;
        }
      }

      if (this.leave.to_date != "" || this.leave.to_date != null) {
        if (
          moment(this.leave.from_date).format("YYYY-MM-DD") >
          moment(this.leave.to_date).format("YYYY-MM-DD")
        ) {
          this.leave.to_date = this.leave.from_date;
        }
      }
      this.days_count();
    },
    "leave.types": function () {
      // console.log("----- type ")
      if (this.leave.types == "half") {
        this.leave.to_date = this.leave.from_date;
        this.leave.no_of_days = 0.5;
      } else if (this.leave.types == "full") {
        if (this.leave.no_of_days == 0.5) {
          this.leave.no_of_days = 0;
        }
      }

      // if (this.leave.to_date != "" || this.leave.to_date != null) {
      //   if (moment(this.leave.from_date).format('YYYY-MM-DD') > moment(this.leave.to_date).format('YYYY-MM-DD')) {
      //     this.leave.to_date = this.leave.from_date
      //   }
      // }
    },
  },

  methods: {
    ...mapActions("userStore", ["setCurrentUser"]),
    set_date() {
      this.leave.to_date = this.leave.from_date;
      this.state1.to = this.leave.from_date;
      // alert(this.state1.to)
    },
    days_count() {
      // const moment = require("moment");
      if (this.leave.types == "full") {
        const startDate = moment(this.leave.from_date).format("YYYY-MM-DD");
        const endDate = moment(this.leave.to_date).format("YYYY-MM-DD");
        // if(startDate.getDay() == 6 || startDate.getDay() == 5) {
        // }
        const diffInDays = moment(endDate).diff(moment(startDate), "days");
        if (Number.isNaN(diffInDays)) {
          this.leave.no_of_days = 0;
          return;
        }
        this.leave.no_of_days = diffInDays + 1;
      }
    },

    LeaveTypeDropdown() {
      user.getLeaveType().then((result) => {
        this.leavetype = result.data.data;
      });
    },

    custom_label(name) {
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },

    getCurrentUser() {
      user.getCurrentUser().then((result) => {
        this.setCurrentUser(result.data);
        // this.leave.user_id = result.data.id
      });
    },

    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = result.data.data;
        let uid = JSON.parse(localStorage.getItem("current_user"));
        this.users.find((obj) => {
          if (obj.id === uid) this.leave.user_id = obj;
        });
      });
    },

    getLeavesById(id) {
      user.getLeaveById(id).then((result) => {
        // console.log(result.data.data);
        this.leave.reason = result.data.data.leaves[0]["reason"];
        this.leave.from_date = result.data.data.leaves[0]["from_date"];
        this.leave.to_date = result.data.data.leaves[0]["to_date"];
        this.leave.no_of_days = result.data.data.leaves[0]["no_of_days"];
        this.leave.types = result.data.data.leaves[0]["leave_type"];
        this.leave.half_day_for = result.data.data.leaves[0]["half"];
        this.user_id = result.data.data.leaves[0]["user_id"];

        this.state1.to = new Date(result.data.data.leaves[0]["to_date"]);
        this.leave.user_id = {
          id: result.data.data.leaves[0]["user_id"],
          first_name: result.data.data.leaves[0]["first_name"],
          last_name: result.data.data.leaves[0]["last_name"],
          middle_name: result.data.data.leaves[0]["middle_name"],
        };
        // this.leave.approved_by = result.data.data.leaves[0]['approved_by'];
        // console.log("=====",this.user_id);
      });
    },

    verifyOpertations() {
      if (!this.leave.from_date) {
        Swal.fire("Error", "Date Feild Cannot be empty", "error");
        return;
      }

      if (this.leave.to_date == "" || this.leave.to_date == null) {
        this.leave.to_date = this.leave.from_date;
      }

      if (this.leave.from_date > this.leave.to_date) {
        this.leave.to_date = this.leave.from_date;
      }
      // let reqObj = structuredClone(this.leave);
      // reqObj.user_id=this.leave.user_id;
      // this.leave.user_id = user_arr.id;
      // this.user_id = user_arr.id;

      this.disabled = true;
      let f_date = new Date(this.leave.from_date);
      let t_date = new Date(this.leave.to_date);
      this.leave.from_date = moment(f_date).format("YYYY-MM-DD HH:mm:ss");
      this.leave.to_date = moment(t_date).format("YYYY-MM-DD HH:mm:ss");
      // this.leave.to_date = new Date(
      // t_date.getTime() + Math.abs(t_date.getTimezoneOffset() * 60000)
      // ).toISOString();

      if (this.editid == 0) {
        this.leave.stage = "applied";
        if (this.leave.reason.length > 1000) {
          Swal.fire(
            "Error",
            "Descrption should contain 1000 characters only...!",
            "error"
          );
          return;
        }
        let reqObj = structuredClone(this.leave);
        reqObj.user_id = this.leave.user_id.id;
        user.createLeaves(reqObj).then((result) => {
          if (result.data.code == 200) {
            this.$swal("Success", "Leave created successfully...", "success");
            this.$router.push({ name: "LeavesApplication" });
          }
          if (result.data.code == 400) {
            if (
              Object.keys(result.data.data ? result.data.data : []).includes(
                "is_sandwich"
              )
            ) {
              this.$swal({
                title: "Do you want to apply sandwich leave ?",
                // text:'A sandwich leave will occur according to your date selection',
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                width: "40em",
              }).then((data) => {
                if (data.isConfirmed == true) {
                  result.data.is_sandwich = true;
                  reqObj.is_sandwich = result.data.is_sandwich;
                  this.disabled = true;
                  user.createLeaves(reqObj).then((result) => {
                    if (result.data.code == 200) {
                      this.$swal(
                        "Success",
                        "Leave created successfully...",
                        "success"
                      );
                      this.$router.push({ name: "LeavesApplication" });
                    }
                    if (result.data.code == 400) {
                      this.$swal("Error", result.data.message, "error");
                      this.disabled = false;
                    }
                  });
                  // console.log("sandwitch ",result.data.is_sandwich);
                } else {
                  // this.leave.user_id = user_arr;
                  this.disabled = false;
                }
              });
            } else {
              this.$swal("Error", result.data.message, "error");
              this.disabled = false;
            }
            return;
          }
          this.leave.reason = "";
          this.leave.from_date = "";
          this.leave.to_date = "";
          this.leave.no_of_days = 0;
          // this.leave.user_id = user_arr;
          this.leave.types = "";
          this.leave.half_day_for = "";
          this.leave.approved_by = 0;
          this.leave.reject_by = 0;
          this.leave.stage = "";
          this.disabled = false;
          // this.$router.push({name: 'LeavesApplication'});
        });
      } else {
        // this.leave.user_id = this.user_id;
        if (this.leave.reason.length > 1000) {
          Swal.fire(
            "Error",
            "Descrption should contain 1000 characters only...!",
            "error"
          );
          this.disabled = false;
          return;
        }
        // console.log("before",this.leave.from_date);
        this.leave.stage = "applied";
        // let f_date=new Date(this.leave.from_date);
        // let t_date=new Date(this.leave.to_date);
        // this.leave.from_date=new Date(f_date.getTime()+Math.abs(f_date.getTimezoneOffset()*60000)).toISOString();
        // this.leave.to_date=new Date(t_date.getTime()+Math.abs(t_date.getTimezoneOffset()*60000)).toISOString();
        // console.log("from--",this.leave.from_date);
        // console.log("to--",this.leave.to_date);
        // console.log(this.leave.to_date);
        let reqObj = structuredClone(this.leave);
        reqObj.user_id = this.leave.user_id.id;
        user.updateLeaves(this.editid, reqObj).then((result) => {
          if (result.data.code == 200) {
            this.$swal("Success", "Leave updated successfully...", "success");
            this.$router.push({ name: "LeavesApplication" });
          } else if (result.data.code == 400) {
            if (
              Object.keys(result.data.data ? result.data.data : []).includes(
                "is_sandwich"
              )
            ) {
              this.$swal({
                title: "Do you want to apply sandwich leave ?",
                // text:'A sandwich leave will occur according to your date selection',
                icon: "warning",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                width: "40em",
              }).then((data) => {
                if (data.isConfirmed == true) {
                  result.data.is_sandwich = true;
                  reqObj.is_sandwich = result.data.is_sandwich;
                  this.disabled = true;
                  user.updateLeaves(this.editid, reqObj).then((result) => {
                    if (result.data.code == 200) {
                      this.$swal(
                        "Success",
                        "Leave updated successfully...",
                        "success"
                      );
                      // this.$swal("Success", "Leave created successfully...", "success");
                      this.$router.push({ name: "LeavesApplication" });
                    }
                    if (result.data.code == 400) {
                      this.$swal("Error", result.data.message, "error");
                      this.disabled = false;
                    }
                  });
                  // console.log("sandwitch ",result.data.is_sandwich);
                } else {
                  this.disabled = false;
                }
              });
            } else {
              this.$swal("Error", result.data.message, "error");
              this.disabled = false;
            }
            return;
            // this.$swal('Error',result.data.message, 'warning')
            // this.$router.push({ name: "LeavesApplication" });
          }
        });
        this.disabled = false;
      }
    },

    formateDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    ClearValue() {
      this.leave.from_date = "";
      this.leave.to_date = "";
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
label {
  padding-top: 5px;
}
</style>
