<template>
  <div>
    <!-- Begin Page Content -->
    <div class="container-fluid " style="min-height:600px;">
      <div class="card shadow mb-4">
        <div class="card-header d-sm-flex align-items-center justify-content-between py-3">
          <h4 class="m-0 font-weight-bold text-primary">User Allocation Report</h4>
        </div>
        <div v-if="true" class="card-body">
          <div class="container-fluid">
            <form @submit.prevent="OnApplyFilters()">
            <div class="row d-sm-flex align-items-center justify-content-between py-3">
              <flat-pickr placeholder="Select start date to start date from" v-model="config.date" :modelValue="config.date"  :config="config" class="form-control col-md square m-2" style="background-color:white;" @on-change="GetArrayOfStartDate"></flat-pickr>
              <flat-pickr placeholder="Select due date to due date from" v-model="config.date" :modelValue="config.date"  :config="config" class="form-control col-md square m-2 " style="background-color:white" @on-change="GetArrayOfEndDate"></flat-pickr>
              <multiselect v-model="selected_projects" :hide-selected="true" :options="projects" :multiple="true" placeholder="Select one or more project" label="project_name" track-by="id" class="square col-md m-2" group-label="label" group-values="projects_data" :group-select="true">
              </multiselect>
              <multiselect v-model="selected_users" :hide-selected="true" :options="users" :multiple="true" placeholder="Select one or more User" label="first_name" track-by="id" class="square col-md m-2" group-label="label" group-values="users_data" :group-select="true">
              </multiselect>
              <button class="btn btn-primary square m-2">Filter</button>
              <!-- <button class="btn btn-danger square m-2" @click="ResetVariables()">Reset</button> -->
            </div>
            </form>
          </div>
          <div class="table-responsive">
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :pagination-options="{
                          enabled: true,
                        }"
              :rows="rows"
              :columns="columns"
            >
            </vue-good-table>
          </div>
          <button class="btn btn-primary" @click="pdfDownload">Pdf</button>
          <button class="btn btn-primary ml-3" @click="excelDownload">Excel</button>
        </div>
      </div>
      <div v-if="false">
        <UnauthorizedPage></UnauthorizedPage>
      </div>
    </div>
  </div>
  <!-- End of Main Content -->
</template>
<script>
import user from "@/ajax/user";
import {mapState, mapActions} from "vuex";
import Vue from "vue";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";
import myMixin from '@/mixins/Permission'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment/moment";
import {jsontoexcel} from "vue-table-to-excel";
import Multiselect from 'vue-multiselect'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

Vue.use(VueSweetalert2);

export default {
  name: "UserAllocationReport",
  mixins: [myMixin],
  components: {UnauthorizedPage,Multiselect,flatPickr},
  data: function () {
    return {
      config:{
        date:null,
        mode:"range",
        dateFormat: "Y-m-d"
      },
      users:[],
      projects:[],
      selected_users:[],
      selected_start_date:[],
      selected_end_date:[],
      selected_projects:[],
      permissions: window.localStorage.getItem('permissions'),
      currentPage: 1,
      isLoading: false,
      columns: [
        {
          label: "Username",
          field: "username",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "User first name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Project Name",
          field: "project_name",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Task Summary",
          field: "task_summary",
          sortable: true,
          filterable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "task summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Estimated time",
          field: "new_estimated_minutes",
          sortable: true,
          filterable: true,
          formatFn:this.ConvertMinutesToTime,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: "Estimated hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          "users":null,
          "projects":null,
          "start_date_from":null,
          "start_date_to":null,
          "due_date_from":null,
          "due_date_to":null
        },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
      },
      json: {
        dataSource: [],
        head: [
          "Username",
          "Project Name",
          "Task Summary",
          "Estimated Hours",
        ],
        fileName: "",
      },
      edited: [],
    };
  },

  mounted() {
    this.getUserPermissions();
    this.DisplayAlluserAllocationReportPages();
    this.getUsers();
    this.getAllProjects();
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    GetArrayOfStartDate(selectedDates,time,instance){
      let dates=[];
      for(var i=0;i<selectedDates.length;i++){
        var select_date = new Date(selectedDates[i])
        select_date.setDate(select_date.getDate() + 1);
        dates.push(select_date.toISOString().slice(0, 10));
      }
      this.selected_start_date=dates;
      // console.log(this.selected_start_date)
    },
    GetArrayOfEndDate(selectedDates,time,instance){
      let dates=[];
      for(var i=0;i<selectedDates.length;i++){
        var select_date = new Date(selectedDates[i])
        select_date.setDate(select_date.getDate() + 1);
        dates.push(select_date.toISOString().slice(0, 10));
      }
      this.selected_end_date=dates;
      // console.log(this.selected_end_date)
    },
    // get users list
    getUsers() {
      user.getUsers().then((result) => {
        this.users =[ {label:"Select all",users_data:result.data.data} ];
        // console.log(this.users);
      });
    },
    // get project list
    getAllProjects() {
      user.getAllProjects().then((result) => {
        this.projects =[ {label:"Select all",projects_data:result.data.data} ];
        // this.projects = result.data.data;
      });
    },
    // get users allocation report
    GetAllUserAllocationReport() {
      user.GetAllUserAllocationReport().then((result) => {
        this.rows = [];
        this.rows = result.data.data;
        // console.log(result.data);
      });
    },
    // get users allocation display pages
    DisplayAlluserAllocationReportPages(){
      user.DisplayAlluserAllocationReportPages(this.serverParams.page,this.serverParams.perPage).then((result) => {
          this.rows = [];
          this.rows = result.data.data.data;
          this.totalRecords = result.data.data.total;
        });
    },
    // add filters in filter list
    UserAllocationFilterList(){
      this.serverParams.columnFilters["users"] = this.selected_users
      this.serverParams.columnFilters["projects"] = this.selected_projects
      this.serverParams.columnFilters["start_date_from"] = this.selected_start_date[0]
      this.serverParams.columnFilters["start_date_to"] = this.selected_start_date[1]
      this.serverParams.columnFilters["due_date_from"] = this.selected_end_date[0]
      this.serverParams.columnFilters["due_date_to"] = this.selected_end_date[0]
      var fields = {
        filters: this.serverParams.columnFilters,
        messageData: this.message,
        sort: this.serverParams.sort,
        page:this.serverParams.page,
        perPage:this.serverParams.perPage
      };
      // console.log("search",fields);
      user.UserAllocationFilterList(fields).then((result) => {
        this.rows = [];
        this.rows = result.data.data.data;
        this.totalRecords = result.data.data.total;
      });
    },
    getUserAllocationReport() {
      var selectedDate = "";
      if (
        typeof this.searched_date_input_box != "undefined" &&
        this.searched_date_input_box != "" &&
        this.searched_date_input_box != 0
      ) {
        selectedDate =
          "?searched_date=" +
          moment(this.searched_date_input_box).format("yyyy-MM-DD");
      }
      // console.log(selectedDate);
      user.getAttendanceReport(selectedDate).then((result) => {
        var actualResult = result.data;
        var resultData = actualResult.data.users;
        this.rows = actualResult.data.users;
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.UserAllocationFilterList();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.UserAllocationFilterList();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.UserAllocationFilterList()
    },
    onColumnFilter(params) {
      var newObj = {columnFilters: {}};
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParams(newObj);
      this.serverParams.page = 1;
      this.UserAllocationFilterList();
    },
    newJson() {
      this.rows.forEach((obj, index) => {
        this.edited.push([]);
        this.edited[index].push(obj.username);
        this.edited[index].push(obj.project_name);
        this.edited[index].push(obj.task_summary);
        this.edited[index].push(obj.new_estimated_minutes);
      });
    },
    pdfDownload() {
      const doc = new jsPDF();
      this.newJson();
      autoTable(doc, {
        head: [this.json.head],
        body: this.edited,
        styles: {fontSize: 8, halign: 'center', valign: 'middle'},
      });
      var curr_date = moment(this.searched_date_input_box).format("yyyy-MM-DD");
      doc.save(curr_date + "_user_allocation_report.pdf");
    },
    excelDownload() {
      var curr_date = moment(this.searched_date_input_box).format("yyyy-MM-DD");
      this.json['fileName'] = curr_date + "_user_allocation_report.xlsx";
      let customData = {};
      let temp = [];
      this.newJson();
      this.edited.forEach((arr, index) => {
        customData = {
          "Username": arr[0],
          "Project Name": arr[1],
          "Task Summary": arr[2],
          "Estimated Hour": arr[3],
        };
        temp.push(customData);
      });
      this.json.dataSource = temp;
      const {dataSource, head, fileName} = this.json;
      jsontoexcel.getXlsx(dataSource, head, fileName);
    },
    ResetVariables(){
      this.selected_users = []
      this.selected_start_date = []
      this.selected_projects = []
      this.selected_end_date = []

      this.UserAllocationFilterList()
    },
    OnApplyFilters(){
      this.UserAllocationFilterList();
    }

  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
};

</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}

.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.square{
  border-radius: 0px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
