<template>
  <div class="vue-tempalte add-items">
    <div class="d-sm-flex align-items-center justify-content-between mb-3">
      <div class="col">
        <h1 class="h3 mb-0 text-gray-800">Items</h1>
      </div>
    </div>
    <br />

    <form onSubmit="return false;" data-parsley-validate="true" @submit.prevent="verifyOpertations()">

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label for="name">Name</label>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="item.name"
          :state="null"
          required
          placeholder="Enter Item"
          oninvalid="this.setCustomValidity('Please enter Item')"
          oninput="this.setCustomValidity('')"
        />
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary btn-lg">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import user from "@/ajax/user";
import Datepicker from "vuejs-datepicker";

export default {
  name: "Dashboard",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      item: {
        name: "",
      },
      currentPage: '',
      editid:0,
    };
  },
  methods: {
    verifyOpertations(){
        if(this.editid == 0){
          user.createItem(this.item).then((result) => {
            alert("Submited successfully",result);
            this.$router.push({ name: "item" });
          });
        }
        else{
            alert("Not Now");

        //   user.updateasset(this.editid, this.item).then((result) => {
        //     alert("Updated successfully",result);
        //     this.$router.push({ name: "Assets" });
        //   });
        }
      }
    },

    // isNumber(evt) {
    //   evt = evt ? evt : window.event;
    //   var charCode = evt.which ? evt.which : evt.keyCode;
    //   if (
    //     (charCode < 48 || charCode > 57) &&
    //     (charCode !== 46 || this.report.spent_time.indexOf(".") >= 0)
    //   ) {
    //     // 46 is for dot
    //     evt.preventDefault();
    //   }
    //   return true;
    // },

    mounted() {
      if(typeof(this.$route.params.id) != 'undefined' && this.$route.params.id != 0) {
        this.editid=this.$route.params.id;
      }
    },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
