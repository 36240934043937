<template>
  <!-- Start of sidebar -->
  <ul
    class="left-sidebar list-unstyled mb-0 px-0"
    style="height: 100vh"
    id="accordionSidebar"
  >
    <!-- Logo -->
    <li class="nav-item sidebar-logo">
      <img class="logo" src="../img/leftsidebar.png" alt="" width="40" />
      <!-- <span class="sidebar-border"></span> -->
    </li>

    <!-- Dashboard -->
    <router-link
      tag="li"
      id="link"
      :to="'/dashboard'"
      v-bind:class="[currentPage == 'Dashboard' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Dashboard')"
    >
      <div class="inner-wrapper">
        <i class="opt-grid"></i><span>Dashboard</span>
      </div>
    </router-link>

    <!-- Attendance -->
    <li
      v-bind:class="[currentPage == 'Attendance' ? 'active' : '', 'nav-item']"
    >
      <div
        class="inner-wrapper"
        data-bs-toggle="collapse"
        data-bs-target="#collapseAttendance"
        aria-expanded="false"
        aria-controls="collapseAttendance"
        @click="collapser('Attendance')"
      >
        <i class="fa fa-calendar"></i>
        <span>Attendance</span>
        <i class="fa fa-chevron-down"></i>
      </div>
      <ul
        id="collapseAttendance"
        class="collapse"
        aria-labelledby="collapseAttendance"
      >
        <li v-if="permissions.includes('monthly_daily.report')">
          <router-link
            :to="'/attendance'"
            id="link"
            v-bind:class="[
              currentPage == 'Attendance' ? 'active' : '',
              'nav-item',
            ]"
          >
            Daily Attendance
          </router-link>
        </li>
        <li v-if="permissions.includes('monthly_attendance.report')">
          <router-link
            :to="'/monthly-attendance-report'"
            id="link"
            v-bind:class="[
              currentPage == 'MonthlyAttendance' ? 'active' : '',
              'nav-item',
            ]"
          >
            Monthly Attendance
          </router-link>
        </li>
        <li v-if="permissions.includes('attendance.update')">
          <router-link
            :to="'/update-attendance'"
            id="link"
            v-bind:class="[
              currentPage == 'UpdateAttendance' ? 'active' : '',
              'nav-item',
            ]"
          >
            Request Update
          </router-link>
        </li>
        <li v-if="permissions.includes('leaves.read')">
          <router-link
            :to="'/leaves-application'"
            id="link"
            v-bind:class="[
              currentPage == 'LeavesApplication' ? 'active' : '',
              'nav-item',
            ]"
          >
            Leaves
          </router-link>
        </li>
      </ul>
    </li>

    <!-- Users -->
    <router-link
      v-show="permissions.includes('users.read')"
      tag="li"
      id="link"
      :to="'/users'"
      v-bind:class="[currentPage == 'Users' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Users')"
    >
      <div class="inner-wrapper">
        <i class="opt-user"></i>
        <span>Users</span>
      </div>
    </router-link>

    <!-- Org Chart -->
    <router-link
      v-show="
        permissions.includes('hierarchy.read') && currentUser.user_role == 1
      "
      tag="li"
      id="link"
      :to="'/orgchart'"
      v-bind:class="[currentPage == 'OrgChart' ? 'active' : '', 'nav-item']"
      @click.native="collapser('OrgChart')"
    >
      <div class="inner-wrapper">
        <!-- <i class="opt-user"></i> -->
        <i class="fa fa-sitemap" aria-hidden="true"></i>
        <span>Org Chart</span>
      </div>
    </router-link>

    <!-- Clients -->
    <router-link
      v-show="permissions.includes('clients.read')"
      tag="li"
      id="link"
      :to="'/clients'"
      v-bind:class="[currentPage == 'Clients' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Clients')"
    >
      <div class="inner-wrapper">
        <i class="opt-users"></i>
        <span>Clients</span>
      </div>
    </router-link>

    <!-- Projects -->
    <router-link
      v-show="permissions.includes('projects.read')"
      tag="li"
      id="link"
      :to="'/Projects'"
      v-bind:class="[currentPage == 'Projects' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Projects')"
    >
      <div class="inner-wrapper">
        <i class="opt-briefcase"></i>
        <span>Projects</span>
      </div>
    </router-link>

    <!-- Milestones -->
    <router-link
      v-show="permissions.includes('milestones.read')"
      tag="li"
      id="link"
      :to="'/milestones'"
      v-bind:class="[currentPage == 'Milestones' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Milestones')"
    >
      <div class="inner-wrapper">
        <i class="fa fa-flag-o"></i>
        <span>Milestones</span>
      </div>
    </router-link>

    <!-- Templates -->
    <!-- v-show="permissions.includes('tasks.read')" -->
    <router-link
      tag="li"
      id="link"
      :to="'/templates'"
      v-bind:class="[currentPage == 'Templates' ? 'active' : '', 'nav-item']"
      v-if="currentUser.user_role == 1"
      @click.native="collapser('Templates')"
    >
      <div class="inner-wrapper">
        <i class="fa fa-file-text-o"></i>
        <span>Templates</span>
      </div>
    </router-link>

    <!-- Tasks -->
    <router-link
      v-show="permissions.includes('tasks.read')"
      tag="li"
      id="link"
      :to="'/tasks'"
      v-bind:class="[currentPage == 'Tasks' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Tasks')"
    >
      <div class="inner-wrapper">
        <i class="opt-task-list"></i>
        <span>Tasks</span>
      </div>
    </router-link>

    <!-- summary -->
    <router-link
      v-show="permissions.includes('reports.read')"
      tag="li"
      id="link"
      :to="'/summary-report'"
      v-bind:class="[
        currentPage == 'Summary Report' ? 'active' : '',
        'nav-item',
      ]"
      @click.native="collapser('Activties')"
    >
      <div class="inner-wrapper">
        <i class="fa fa-list-alt fw-bold"></i>
        <span>Summary</span>
      </div>
    </router-link>

    <!-- Activities -->
    <router-link
      v-show="permissions.includes('reports.read')"
      tag="li"
      id="link"
      :to="'/reports'"
      v-bind:class="[currentPage == 'Activities' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Activties')"
    >
      <div class="inner-wrapper">
        <i class="opt-project-icon fw-bold"></i>
        <span>Activities</span>
      </div>
    </router-link>

    <!-- Reports -->
    <li
      v-show="permissions.includes('daily.report')"
      v-bind:class="[currentPage == 'Reports' ? 'active' : '', 'nav-item']"
    >
      <div
        class="inner-wrapper"
        data-bs-toggle="collapse"
        data-bs-target="#collapseReports"
        aria-expanded="false"
        aria-controls="collapseReports"
        @click="collapser('Reports')"
      >
        <i class="fa fa-file-text-o fw-bold"></i>
        <span>Reports</span>
        <i class="fa fa-chevron-down float-end"></i>
      </div>
      <ul
        id="collapseReports"
        class="collapse"
        aria-labelledby="headingReports"
      >
        <li class="" v-if="permissions.includes('daily.report')">
          <router-link :to="'/daily_discrepancy'" id="link" tag="a">
            Daily Discrepancy
          </router-link>
        </li>
        <li class="" v-if="permissions.includes('snapshot.report')">
          <router-link :to="'/Snapshot'" id="link" tag="a">
            Snapshot
          </router-link>
        </li>
        <li class="" v-if="permissions.includes('snapshot.report')">
          <router-link :to="'/productivity-report'" id="link" tag="a">
            Productivity Report
          </router-link>
        </li>
        <li class="" v-if="permissions.includes('siteusage.read')">
          <router-link :to="'/siteusage-report'" id="link" tag="a">
            Site Usage
          </router-link>
        </li>

      </ul>
    </li>

    <!-- Assets -->
    <li
      v-if="currentUser.user_role == 1 && permissions.length"
      :class="[currentPage == 'Assets' ? 'active' : '', 'nav-item']"
    >
      <div
        class="inner-wrapper"
        data-bs-toggle="collapse"
        data-bs-target="#collapseAssets"
        aria-expanded="false"
        aria-controls="collapseAssets"
        @click="collapser('Assets')"
      >
        <i class="opt-asset-management"></i>
        <span>Assets</span>
        <i class="fa fa-chevron-down float-end"></i>
      </div>
      <ul
        id="collapseAssets"
        class="collapse"
        aria-labelledby="headingAttendance"
      >
        <li>
          <router-link :to="'/assets'" id="link"> List </router-link>
        </li>
        <li>
          <router-link :to="'/assets-history'" id="link"> History </router-link>
        </li>
        <li>
          <router-link :to="'/assets-category'" id="link">
            Categories
          </router-link>
        </li>
        <li>
          <router-link :to="'/vendor'" id="link"> Vendor </router-link>
        </li>
        <li>
          <router-link :to="'/invoice'" id="link"> Invoices </router-link>
        </li>
      </ul>
    </li>

    <!-- Goals -->
    <router-link
      v-show="permissions.includes('goals.read')"
      tag="li"
      :to="'/goals'"
      v-bind:class="[currentPage == 'Goals' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Goals')"
    >
      <div class="inner-wrapper">
        <i class="opt-goal"></i>
        <span>Goals</span>
      </div>
    </router-link>

    <!-- Quotes -->
    <router-link
      v-show="permissions.includes('quotes.list')"
      tag="li"
      :to="'/quotes'"
      v-bind:class="[currentPage == 'Quotes' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Quotes')"
    >
      <div class="inner-wrapper">
        <i class="fa fa-commenting-o"></i>
        <span>Quotes</span>
      </div>
    </router-link>

    <!-- Notifications -->
    <router-link
      tag="li"
      v-show="permissions.includes('notifications.read')"
      :to="'/notifications'"
      v-bind:class="[
        currentPage == 'Notifications' ? 'active' : '',
        'nav-item',
      ]"
      @click.native="collapser('Notifications')"
    >
      <div class="inner-wrapper">
        <i class="opt-notification"></i>
        <span>Notifications</span>
      </div>
    </router-link>

    <!-- Rewards -->
    <router-link
      tag="li"
      :to="'/rewards'"
      v-if="
        permissions.includes('rewards.read') &&
        [1, 2].includes(currentUser.user_type)
      "
      v-bind:class="[currentPage == 'Rewards' ? 'active' : '', 'nav-item']"
      @click.native="collapser('Rewards')"
    >
      <div class="inner-wrapper">
        <i class="fa fa-trophy fs-6"></i>
        <span>Rewards</span>
      </div>
    </router-link>
  </ul>
  <!-- End of Sidebar -->
</template>
<script>
import user from "@/ajax/user";
import { mapState, mapActions } from "vuex";
import myMixin from "@/mixins/Permission";
import EventBus from "../../helpers/event-bus";
import { Collapse } from "bootstrap";
var self;

export default {
  name: "Dashboard",
  mixins: [myMixin],
  data: function () {
    return {
      permissions: [],
      user_type: "",
    };
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    collapser(page) {
      Array.from(document.getElementsByClassName("collapse"))
        .filter((item) => item.classList.contains("show"))
        .map((item) => new Collapse(item).hide());
    },
  },
  beforeMount() {
    self = this;
  },
  mounted() {
    this.getUserPermissions();
    this.setCurrentPage("Dashboard");
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    ...mapState("common", ["currentPage"]),
  },
};
EventBus.$on("permission-callback", function (callback) {
  if (typeof self != "undefined") {
    self.permissions = [];
    self.permissions = localStorage.getItem("permissions");
    self.user_type = JSON.parse(localStorage.getItem("user_type"));
  }
});
</script>
<style scoped>
.inner-wrapper[aria-expanded="true"] .fa-chevron-down {
  transform: rotate(-90deg);
  transition: 0.2s linear;
}

.inner-wrapper[aria-expanded="false"] .fa-chevron-down {
  transition: 0.2s linear;
}
</style>
