<template>
  <div class="mx-0 p-0 custom">
    <LeavesVue :firstTable="false" :tag="'h4'"></LeavesVue>
    <!-- <hr class="my-4 mx-2"> -->
    <UpdateVue :firstTable="false"></UpdateVue>
    <!-- <hr class="my-4 mx-2"> -->
    <div class="bg-white shadow-sm rounded-3 px-3 pt-1">
      <WorklogVue class="" :firstTable="false" :tag="'h4'"></WorklogVue>
    </div>
  </div>
</template>

<script>
import UpdateVue from './UpdateAttendance.vue';
import LeavesVue from './OldLeaves.vue';
import WorklogVue from './NewReports.vue';
import EventBus from '../../helpers/event-bus';
export default{
  components:{
    UpdateVue,
    LeavesVue,
    WorklogVue
  },
  mounted(){
    EventBus.$emit('dashboard-worklog',true);
  }
}
</script>
