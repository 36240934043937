<template>
  <div class="">
    <div
      class="d-flex justify-content-center align-items-center p-4"
      v-if="!dataLoaded"
    >
      <div
        class="spinner-border text-primary"
        style="height: 4rem; width: 4rem"
      ></div>
    </div>
    <div v-else>
      <form @submit.prevent="onSubmitSubClient()">
        <div class="mb-3">
          <label for="client_name" class="form-label mandatory"
            >Client Name</label
          >
          <select
            v-model="reqObj.client_id"
            id="client_name"
            class="form-control"
            :state="null"
            required
            oninvalid="this.setCustomValidity('Please select client manager name')"
            oninput="this.setCustomValidity('')"
          >
            <option value="">Select Client name</option>
            <option
              :key="index"
              v-for="(item, index) in clients"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>

        <div class="mb-3">
          <label for="subclient_name" class="form-label mandatory"
            >Sub Client Name</label
          >
          <input
            type="text"
            class="form-control"
            id="subclient_name"
            v-model="reqObj.subclientname"
            :state="null"
            required
            placeholder=" Enter sub client name"
            oninvalid="this.setCustomValidity('Please enter sub client name')"
            oninput="this.setCustomValidity('')"
          />
        </div>

        <div class="mb-3">
          <label for="person_name" class="form-label mandatory"
            >Contact Person Name</label
          >
          <input
            type="text"
            class="form-control"
            id="person_name"
            v-model="reqObj.contactPerson"
            :state="null"
            required
            placeholder=" Enter contact person"
            oninvalid="this.setCustomValidity('Please enter contact person name')"
            oninput="this.setCustomValidity('')"
          />
        </div>

        <div class="mb-3">
          <label for="contact_email" class="form-label mandatory"
            >Contact Email</label
          >
          <input
            type="text"
            class="form-control"
            id="contact_email"
            v-model="reqObj.contactEmail"
            :state="null"
            required
            placeholder=" Enter contact Email"
            oninvalid="this.setCustomValidity('Please enter Contact Email')"
            oninput="this.setCustomValidity('')"
          />
        </div>

        <div class="mb-3">
          <label for="client_manager" class="form-label mandatory"
            >Manager's Name</label
          >
          <select
            v-model="reqObj.manager_user_id"
            class="form-control"
            id="client_manager"
            :state="null"
            required
            oninvalid="this.setCustomValidity('Please select client manager name')"
            oninput="this.setCustomValidity('')"
          >
            <option value="">Select manager name</option>
            <option
              :key="index"
              v-for="(item, index) in managers"
              :value="item.id"
            >
              {{ item.first_name }} {{ item.last_name }}
            </option>
          </select>
        </div>
        <div class="text-end mt-4">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from "@/ajax/user";
import moment from "moment";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import myMixin from "@/mixins/Permission";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

export default {
  name: "Dashboard",
  mixins: [myMixin],
  props: ["subclient_id"],
  components: { UnauthorizedPage },
  data: function () {
    return {
      reqObj: {
        client_id: "",
        subclientname: "",
        contactPerson: "",
        contactEmail: "",
        manager_user_id: "",
      },
      managers: [],
      clients: [],
      editid: 0,
      permissions: window.localStorage.getItem("permissions"),
      dataLoaded: false,
    };
  },
  mounted() {
    if (this.permissions.includes("sub_clients.write")) {
      this.getUserPermissions();
      // $(".fl-flex-label").flexLabel();
      this.getmanageridDropdown();
      this.getclientDropdown();
    }
  },
  methods: {
    getmanageridDropdown() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        // console.log(result);
        this.managers = result.data.data;
      });
    },
    getclientDropdown() {
      user.getclientDropdown(this.reqObj).then((result) => {
        // console.log(result);
        this.clients = result.data.data;
        if (typeof this.$route.params.id == "undefined") {
          if (result.data.data[0]) {
            this.reqObj.client_id =
              result.data.data[result.data.data.length - 1]["id"];
          }
        }
      });
    },

    // getAllSubClient() {
    //   user.getAllSubClient(this.reqObj).then((result) => {
    //     // console.log(result.data);
    //   });
    // },

    getSubClientbyid(id) {
      user.getSubClientbyid(id).then((result) => {
        // console.log("lajksd",result.data.data);
        this.reqObj.client_id = result.data.data[0]["client_id"];
        this.reqObj.subclientname = result.data.data[0]["subclientname"];
        this.reqObj.contactPerson = result.data.data[0]["contactPerson"];
        this.reqObj.contactEmail = result.data.data[0]["contactEmail"];
        this.reqObj.manager_user_id = result.data.data[0]["manager_user_id"];
        this.dataLoaded = true;
      });
    },

    // onSunmitClient() is used for task create or update if id editid=0 then createClient() perform otherwise updateClient() is perform
    onSubmitSubClient() {
      if (!this.subclient_id) {
        user
          .createSubClient(this.reqObj)
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "SubClient Added Successfully !!!",
              icon: "success",
              timer: 2500,
            });
            this.$emit("close-modal");
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Add SubClient !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      } else {
        user
          .updateSubClient(this.subclient_id, this.reqObj)
          .then((result) => {
            Swal.fire({
              title: "Success",
              text: "SubClient Updated Successfully !!!",
              icon: "success",
              timer: 2500,
            });
            this.$emit("close-modal");
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Failed to Update SubClient !!!",
              icon: "error",
              timer: 2500,
            });
            this.$emit("close-modal");
          });
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  watch: {
    subclient_id: {
      handler(nv, ov) {
        // console.log(nv, ov);
        if (nv) {
          this.getSubClientbyid(nv);
        } else {
          this.dataLoaded = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}
.mandatory:after {
  content: "*";
  padding: 0px 2px;
  color: red;
}
</style>
