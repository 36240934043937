<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center p-4"
      v-if="!dataLoaded"
    >
      <div
        class="spinner-border text-primary"
        style="height: 4rem; width: 4rem"
      ></div>
    </div>
    <div v-else>
      <form @submit.prevent="onSubmitClient()" class="">
      <div class="mb-3">
        <label for="client_name" class="form-label mandatory">Name</label>
        <input
          type="text"
          class="form-control"
          id="client_name"
          v-model="reqObj.name"
          :state="null"
          required
          placeholder=" Enter client name"
          oninvalid="this.setCustomValidity('Please enter client name')"
          oninput="this.setCustomValidity('')"
        />
      </div>
      <div class="mb-3">
        <label for="client_manager" class="form-label mandatory"
          >Manager's Name</label
        >
        <select
          v-model="reqObj.manager_id"
          id="client_manager"
          class="form-control"
          required
          oninvalid="this.setCustomValidity('Please select client manager name')"
          oninput="this.setCustomValidity('')"
        >
          <option value="">Select manager name</option>
          <option
            :key="index"
            v-for="(item, index) in managers"
            :value="item.id"
          >
            {{ item.first_name }} {{ item.last_name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="client_slug" class="form-label mandatory">Slug</label>
        <textarea
          id="client_slug"
          rows="2"
          wrap="soft"
          class="form-control"
          v-model="reqObj.slug"
          :state="null"
          required
          placeholder=" Enter slug"
          oninvalid="this.setCustomValidity('Please enter client slug')"
          oninput="this.setCustomValidity('')"
        ></textarea>
      </div>
      <div class="row"></div>
      <div class="text-end">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
    </div>
  </div>
</template>

<script>
import myMixin from "@/mixins/Permission";
import user from "@/ajax/user";
import moment from "moment";
moment.locale("en");
import { mapState, mapActions } from "vuex";
import UnauthorizedPage from "@/views/Layouts/UnauthorizedPage.vue";

export default {
  name: "AddClient",
  mixins: [myMixin],
  components: { UnauthorizedPage },
  props:['client_id'],
  data: function () {
    return {
      reqObj: {
        name: "",
        manager_id: "",
        slug: "",
      },
      managers: [],
      editid: 0,
      permissions: window.localStorage.getItem("permissions"),
      dataLoaded:false
    };
  },
  mounted() {
    if (this.permissions.includes("clients.write")) {
      // if (
      //   typeof this.$route.params.id != "undefined" &&
      //   this.$route.params.id != 0
      // ) {
      //   // console.log("Update task" + this.$route.params.id);
      //   this.editid = this.$route.params.id;
      //   this.getClientbyid(this.$route.params.id);
      // }
          // $(".fl-flex-label").flexLabel();
      this.getUserPermissions();
      this.getmanageridDropdown();
    }
  },
  methods: {
    getmanageridDropdown() {
      user.getmanageridDropdown(this.reqObj).then((result) => {
        // console.log(result);
        this.managers = result.data.data;
      });
    },

    getClientbyid(id) {
      user.getClientbyid(id).then((result) => {
        this.reqObj.name = result.data.data[0]["name"];
        this.reqObj.manager_id = result.data.data[0]["client_manager_userid"];
        this.reqObj.slug = result.data.data[0]["slug"];
        this.dataLoaded=true;
      });
    },

    // onSunmitClient() is used for task create or update if id editid=0 then createClient() perform otherwise updateClient() is perform
    onSubmitClient() {
      if (!this.client_id) {
        user.createClient(this.reqObj).then((result) => {
          if(result.data.code==200){
            Swal.fire({
              title:"Success",
              text:"Client Added Successfully !!!",
              icon:"success",
              timer:2500
            })
            this.$emit('close-modal');
          }
          else{
            Swal.fire({
              title:"Error",
              text:"Failed to Add Client !!!",
              icon:"error",
              timer:2500
            })
            this.$emit('close-modal');
          }
        })
        .catch(err=>{
          Swal.fire({
            title:"Error",
            text:"Something Went Wrong !!!",
            icon:"error",
            timer:2500
          })
          this.$emit('close-modal');
        });
      } else {
        user.updateClient(this.client_id, this.reqObj).then((result) => {
          if(result.data.code==200){
            Swal.fire({
              title:"Success",
              text:"Client Updated Successfully !!!",
              icon:"success",
              timer:2500
            })
            this.$emit('close-modal');
          }
          else{
            Swal.fire({
              title:"Error",
              text:"Failed To Update Client !!!",
              icon:"error",
              timer:2500
            })
            this.$emit('close-modal');
          }
        }).catch(err=>{
          Swal.fire({
            title:"Error",
            text:"Something Went Wrong !!!",
            icon:"error",
            timer:2500
          })
          this.$emit('close-modal');
        });
      }
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  watch:{
    client_id:{
      handler(nv,ov){
        if(nv){
          this.getClientbyid(nv);
        }
        else{
          this.dataLoaded=true;
        }
      },
      immediate:true
    }
  }
};
</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}
.mandatory:after {
  content: "*";
  padding: 0px 2px;
  color: red;
}
</style>
