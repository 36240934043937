<template>
  <div>
    <!-- Container for rewards section start -->
    <div class="pt-3 pb-5 px-4 bg-white rounded-4 shadow-sm">
      <h5 class="text-primary mb-4 text-center">This month's earned rewards</h5>

      <!-- Loading spinner -->
      <div
        v-if="loading"
        class="col d-flex justify-content-center align-items-center"
        style="height: 0px"
      >
        <div
          class="spinner-border text-primary"
          style="height: 80px; width: 80px; margin-top: 200px"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="currentUser.user_type != 4">
        <v-chart id="echartsContainer" :options="chartOptions"></v-chart>
      </div>
      <div v-else>
        <p></p>
      </div>
    </div>

    <!-- Error message display -->
    <div v-if="error" class="text-danger">{{ error }}</div>
  </div>
</template>

<script>
import Vue from "vue";
import * as echarts from "echarts";
import moment from "moment";
import user from "@/ajax/user";
import ReadMore from "vue-read-more";
import { Error, FetchError } from "../../Utils/String";
import { mapState } from "vuex";
import { use } from "echarts/core";
import { BarChart } from "echarts/charts";
import { GridComponent, DatasetComponent } from "echarts/components";
import VChart from "vue-echarts";
use([BarChart, DatasetComponent, GridComponent]);
Vue.use(ReadMore);

export default {
  data() {
    return {
      data: null,
      error: null,
      chartOptions: null,
      filter_data: {
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      },
      chartHeight: "370px",
      loading: true,
      maxAvailableCount: 0,
      chartInstance: null,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  components: {
    VChart,
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const obj = {
          id: this.currentUser.id,
          start_date: this.filter_data.from,
          end_date: this.filter_data.to,
        };
        user
          .getRewardsSummary(obj)
          .then((res) => {
            this.data = res.data.data;
            // console.log(this.data);

            this.maxAvailableCount = Math.max(
              ...this.data.map((item) => item.available)
            );
            this.loading = false;
            this.renderChart();
          })
          .catch((error) => {
            console.error(Error, error);
            this.error = FetchError;
            this.loading = false;
          });
      } catch (error) {
        console.error(Error, error);
        this.error = FetchError;
      }
    },
    renderChart() {
      var chartDom = document.getElementById("echartsContainer");
      var myChart = echarts.init(chartDom);
      const labels = this.data.map((item) => item.name);
      const earnedCounts = this.data.map((item) => item.earned);
      const availableCounts = this.data.map((item) => item.available);
      const remainingCounts = availableCounts.map(
        (available, index) => available - earnedCounts[index]
      );

      this.chartOptions = {
        legend: {
          data: [
            "Earned",
            {
              name: "Remaining",
              itemStyle: {
                color: "rgb(127, 127, 127,0.2)",
              },
            },
          ],
          textStyle: {
            color: "black",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "value",
          max: this.maxAvailableCount,
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: (value) => {
              const secondToLastValue = this.maxAvailableCount - 10;
              return value === secondToLastValue ? "" : value;
            },
          },
        },
        yAxis: {
          type: "category",
          data: labels,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            name: "Earned",
            type: "bar",
            stack: "total",
            showBackground: true,
            backgroundColor: "green",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                { offset: 1, color: "rgb(34, 182, 198)" },
                { offset: 0, color: "rgb(15, 213, 172) " },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 1, color: "rgb(26, 160, 174)" },
                  { offset: 0, color: "rgb(13, 190, 155)" },
                ]),
              },
            },
            label: {
              show: true,
              position: "inside",
              color: "white",
              formatter: function (params) {
                // Hide the label if the value is 0
                return params.value === 0 ? "" : params.value;
              },
            },
            data: earnedCounts,
          },
          {
            name: "Remaining",
            type: "bar",
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "transparent",
            },
            itemStyle: {
              color: "none",
            },
            label: {
              show: true,
              position: "inside",
              color: "black",
            },
            data: remainingCounts,
          },
        ],
      };
      this.chartOptions && myChart.setOption(this.chartOptions);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style>
#echartsContainer {
  width: 100%;
  height: 400px;
}
</style>
