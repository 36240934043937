<template>
  <div v-if="permissions.includes('assets.write')" class="vue-tempalte add-asset">
    <div class="d-sm-flex align-items-center justify-content-between mb-3">
      <div class="col">
        <h1 class="h3 mb-0 text-gray-800">Assets</h1>
      </div>
    </div>
    <br />

    <form
      onSubmit="return false;"
      data-parsley-validate="true"
      @submit.prevent="verifyOpertations()"
    >


      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
            <label for="item" class="mandatory">Item</label>
          </div>
           <select v-model="asset.item_id" class="form-control" :state="null" oninvalid="this.setCustomValidity('Please select Item')" oninput="this.setCustomValidity('')" >
            <option :value="null">Select Item</option>
            <option :key="index" v-for="(item, index) in items" :value="item.id">{{item.name}}</option>
          </select>
      </div><br>


      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label for="name" class="mandatory">Name</label>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="asset.name"
          :state="null"
          required
          placeholder="Enter Name"
          oninvalid="this.setCustomValidity('Please enter Name')"
          oninput="this.setCustomValidity('')"

        />
      </div><br>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label for="description" class="mandatory">Description</label>
        </div>
        <textarea
          id="textarea-formatter"
          rows="2"
          wrap="soft"
          class="form-control"
          v-model="asset.description"
          :state="null"
          required
          placeholder="Enter Description"
          oninvalid="this.setCustomValidity('Please enter description')"
          oninput="this.setCustomValidity('')"
        ></textarea>
      </div><br>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label for="price" class="mandatory">Price</label>
        </div>
        <input
          type="text"
          class="form-control"
          v-model="asset.price"
          :state="null"
          required
          placeholder="Enter Price"
          oninvalid="this.setCustomValidity('Please enter price')"
          oninput="this.setCustomValidity('')"
          @keypress="isNumber($event)"
        />
      </div><br>

      <div class="d-sm-flex align-items-left">
        <div class="col-sm-2">
          <label for="assign" class="mandatory">Assign To User</label>
        </div>
        <select v-model="asset.user_id" class="form-control" :state="null" oninvalid="this.setCustomValidity('Please select Assign To User')" oninput="this.setCustomValidity('')">
          <option :value="null">Select User</option>
          <option :key="index" v-for="(item, index) in users" :value="item.id">
          {{item.first_name}} {{ item.last_name}}
          </option>
        </select>
      </div><br>

      <div class="text-right">
        <button type="submit" class="btn btn-primary btn-lg">Submit</button>
      </div>

    </form>
  </div>
  <div v-else>
   <UnauthorizedPage></UnauthorizedPage>
  </div>
</template>

<script>
import user from "@/ajax/user";
import Datepicker from "vuejs-datepicker";
import myMixin from '@/mixins/Permission'
import UnauthorizedPage from '@/views/Layouts/UnauthorizedPage.vue'

export default {
  name: "Addasset",
  mixins: [myMixin],
  components: {
    Datepicker,
    UnauthorizedPage,
  },
  data: function () {
    return {

        currentPage: '',
        selectedIndex: 0,
        asset: {
          name: "",
          description: "",
          price: "",
          item_id: null,
          user_id: null,
        },
        users:[],
        items: [],
        editid: 0,
        permissions:window.localStorage.getItem('permissions'),
    };
  },

  mounted() {
    if(this.permissions.includes('assets.write')){
        this.getUserPermissions();
      if (typeof this.$route.params.id != "undefined" && this.$route.params.id != 0) {
        this.editid = this.$route.params.id;
        this.getAssetById(this.$route.params.id);
      }
          // $(".fl-flex-label").flexLabel();
        this.getitemDropdown();
        this.getuseridDropdown();
    }
  },

  methods: {

    getuseridDropdown() {
      user.getmanageridDropdown().then((result) => {
        this.users = result.data.data;
      });
    },

    getitemDropdown() {
      user.getItems().then((result) => {
        this.items = result.data;
    });
    },

    verifyOpertations() {
      if (this.editid == 0) {
        user.createAsset(this.asset).then((result) => {
          if (result.data.code == 200) {
            alert("Submited successfully");
          }
          else {
            alert("You are not authroized to access this page");
          }
          this.$router.push({ name: "Assets" });
        });
      } else {
        user.updateAsset(this.editid, this.asset).then((result) => {
          if (result.data.code == 200) {
            alert("Updated successfully", result);
          }
          else {
            alert("You are not authroized to access this page");
          }
          this.$router.push({ name: "Assets" });
        });
      }
    },

    getAssetById(id) {
      user.getAssetById(id).then((result) => {
        this.asset.name = result.data.data[0]['name'];
        this.asset.description = result.data.data[0]['description'];
        this.asset.price = result.data.data[0]['price'];
        this.asset.item_id = result.data.data[0]['item_id'];
        this.asset.user_id = result.data.data[0]['user_id'];
      })
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)){
        return true;
      }
      else {
        e.preventDefault();
      }
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || this.report.spent_time.indexOf(".") >= 0)
      ) {
        // 46 is for dot
        evt.preventDefault();
      }
      return true;
    },
  },

}

</script>

<style scoped>
.startbuttons {
  padding: 5px 30px 5px 30px;
  margin: 10px;
  font-size: 20px;
}
.disableButton {
  opacity: 0.5;
  pointer-events: none;
}
.v-text-field {
  width: 400px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
</style>
